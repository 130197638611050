import React from "react";
import cats from "../../assets/cats.svg";
import "./CategoryBadge.scss";

function CategoryBadge(props) {
  return (
    <div className="CategoryBadge">
      <img src={cats} alt="cats" height="10px" style={{ marginRight: "5px" }} />
      {props.categoryName}
    </div>
  );
}

export default CategoryBadge;
