import axios from "axios";
import { IpURL } from "./URL";

export const getCountryCodeService = () => {
  return axios({
    method: "GET",
    url: IpURL,
    headers: {
      accept: "application/json, text/plain, */*",
      "api-version": 28,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });
};
