import React, { useRef, useEffect, useState } from "react";
import "./readMoreText.scss";
// import { Trans , useTranslation } from "react-i18next";

export default function ReadMore({ desc }) {
  const containerRef = useRef();
  const [showReadMore, setShowReadMore] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    let viewportHeight = document.body.clientHeight;
    if (containerRef.current.clientHeight > viewportHeight / 10) {
      setShowReadMore(true);
      setOpen(false);
    }
  }, [desc]);

  useEffect(() => {
    if (open) return;
    let { scrollHeight, clientHeight } = containerRef.current;
    let { scrollTop } = document.scrollingElement;

    document.scrollingElement.scrollTop =
      scrollTop + clientHeight - scrollHeight;
  }, [open]);

  return (
    <>
      <div
        ref={containerRef}
        style={{ height: open ? "auto" : "8vh" }}
        className="readMoreText"
      >
        {desc}
      </div>
      {showReadMore && (
        <div
          onClick={() => setOpen(!open)}
          className="readMoreText_readMoreBTN"
        >
          {open ? "Read Less" : "Read More"}
        </div>
      )}
    </>
  );
}
