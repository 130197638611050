import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Input from "../../../common/Input";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
function BankAccountDetails(props) {
  const { state } = useLocation();
  // console.log("banldetaiskl",state.data);

  useLayoutPageHeading("Bank Account Details");

  return (
    <div className="bankDetails">
      <div className="bankDetails__form">
        <Input
          // key={input?.id}
          disabled={true}
          label={"Beneficiary Name*"}
          placeholder={"Beneficiary Name"}
          // pattern={input?.pattern}
          // type={input?.type}
          value={state.data.beneficiaryName}
        />
        <Input
          // key={input?.id}
          disabled={true}
          label={"Account Number*"}
          placeholder={"Account Number"}
          // pattern={input?.pattern}
          // type={input?.type}
          value={state.data.accountNumber}
        />
        <Input
          // key={input?.id}
          disabled={true}
          label={"IFSC Code*"}
          placeholder={"IFSC Code"}
          // pattern={input?.pattern}
          // type={input?.type}
          value={state.data.ifscCode}
        />
        <Input
          // key={input?.id}
          disabled={true}
          label={"Relationship with Beneficiary*"}
          placeholder={"Relationship"}
          // pattern={input?.pattern}
          // type={input?.type}
          value={state.data.relationshipWithUser}
        />
        <Input
          // key={input?.id}
          disabled={true}
          label={"Email ID*"}
          placeholder={"Email ID"}
          // pattern={input?.pattern}
          // type={input?.type}
          value={state.data.emailId}
        />
      </div>
    </div>
  );
}
export default BankAccountDetails;
