import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/groupDetails.scss";
import grpPic from "../../../assets/purchase/grpPic.svg";
import Button from "../../../common/Button";
import * as actions from "../../../store/Purchase/actions";
import { connect } from "react-redux";
import CustomUserAvatar from "../../../common/CustomUserAvator";

import Accordion from "./Accordian";
import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
import { useNavigate } from "react-router-dom";
import dShape from "../../../assets/endUser/dShape.png";
import dShapeBlck from "../../../assets/endUser/dShapeBlck.png";
import x from "../../../assets/x.png";
import couponapplyicon from "../../../assets/couponapplyicon.png";
import edit from "../../../assets/endUser/edit.png";
import info from "../../../assets/endUser/info.png";
import ReactLoading from "react-loading";
import CouponInput from "../../../common/CouponInput";
import ReadMore from "../../../common/ReadMoreText";
import CategoryBadge from "../../../common/CategoryBadge";
import ChannelBadge from "../../../common/ChannelBadge";
import PayPageContent from "./PaymentPage/PayPageContent";
import PayPageStaticContent from "./PaymentPage/PayPageContent";
import PayHeader from "./PaymentPage/PayHeader";
import GroupDescription from "./PaymentPage/GroupDescription";
function GroupDetails(props) {
  let {
    setPurchaseScreen,
    handlePricingChange,
    pricingPayload,
    pageData,
    pageDataError,
    applyCouponApi,
    applyCouponSuccess,
    applyCouponFailure,
    setCouponCodeValue,
    couponCode,
    applyCouponLoader,
    v2Enabled,
  } = props;
  const navigate = useNavigate();
  let [disabled, setDisabled] = useState(true);
  let sm = [];
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [applyBlockOpen, setApplyBlockOpen] = useState(false);
  const [codeApplied, setCodeApplied] = useState(false);
  // const [couponCode,setCouponCode]= useState();
  const [couponError, setCouponError] = useState(false);
  const [successCode, setSuccessCode] = useState(false);
  const [detailSheet, setDetailSheet] = useState(false);
  const [priceDetailSheetDesktop, setPriceDetailSheetDesktop] = useState(false);
  const [newStatus, setNewStatus] = useState(false);
  const [newData, setNewData] = useState(null);
  const [id, setId] = useState(null);
  //price detail data and final price
  const [planPriceCoupon, setPlanPriceCoupon] = useState("");
  const [planDiscountCoupon, setPlanDiscountCoupon] = useState("");
  const [couponDiscountPlan, setCouponDiscountPlan] = useState("");
  const [finalPriceCoupon, setFinalPriceCoupon] = useState("");
  // console.log("v2group",v2Enabled);
  const messageRouteFlag = window.location.pathname.includes("/m");
  useEffect(() => {
    window.scrollTo(0, 0);
    pageDataError ? setDisabled(true) : setDisabled(false);
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) setPurchaseScreen("userDetails");
    });
  }, []);

  useEffect(() => {
    if (selectedPlan == null) {
      setDefaultSelection();
    }
  }, [pageData]);

  useEffect(() => {
    if (applyCouponSuccess) {
      setApplyBlockOpen(false);
      setSuccessCode(true);
      setCouponError(false);
      setPlanPriceCoupon(applyCouponSuccess?.currentPlanData?.planPrice);
      setPlanDiscountCoupon(applyCouponSuccess?.currentPlanData?.planDiscount);
      setFinalPriceCoupon(applyCouponSuccess?.currentPlanData?.finalPrice);
      setCouponDiscountPlan(
        applyCouponSuccess?.currentPlanData?.couponDiscount
      );
    } else if (applyCouponFailure) {
      setCouponError(true);
      setApplyBlockOpen(true);
      setSuccessCode(false);
      setNewData(null);
      setNewStatus(false);
    }
  }, [applyCouponSuccess, applyCouponFailure, applyCouponLoader]);
  useEffect(() => {
    if (
      applyCouponSuccess &&
      applyCouponSuccess?.allPlansData &&
      applyCouponSuccess?.allPlansData.length > 0
    ) {
      let couponsTobeApplied = [];
      couponsTobeApplied.push(selectedPlan?.id);
      for (let i = 0; i < applyCouponSuccess?.allPlansData.length; i++) {
        couponsTobeApplied.push(applyCouponSuccess?.allPlansData[i].planId);
      }
      let copy = [];
      for (let i = 0; i < pageData.subscriptionPlan.length; i++) {
        if (selectedPlan?.id == pageData.subscriptionPlan[i].id) {
          let data = applyCouponSuccess?.currentPlanData;
          let oldData = { ...pageData.subscriptionPlan[i] };
          oldData["couponData"] = data;
          copy.push(oldData);
          continue;
        }

        if (couponsTobeApplied.indexOf(pageData.subscriptionPlan[i].id) >= 0) {
          let data = applyCouponSuccess?.allPlansData.find(
            (couponData) => couponData.planId == pageData.subscriptionPlan[i].id
          );
          let oldData = { ...pageData.subscriptionPlan[i] };
          oldData["couponData"] = data;
          copy.push(oldData);
          continue;
        }
        copy.push(pageData.subscriptionPlan[i]);
      }
      setNewData(copy);
    } else if (applyCouponSuccess && applyCouponSuccess?.currentPlanData) {
      let copy = [];
      for (let i = 0; i < pageData?.subscriptionPlan?.length; i++) {
        if (selectedPlan?.id == pageData.subscriptionPlan[i].id) {
          let data = applyCouponSuccess?.currentPlanData;
          let oldData = { ...pageData.subscriptionPlan[i] };
          oldData["couponData"] = data;
          copy.push(oldData);
          continue;
        } else {
          copy.push(pageData.subscriptionPlan[i]);
        }
      }
      setNewData(copy);
    }
  }, [applyCouponSuccess]);

  useEffect(() => {
    // console.log("newDataStatus", sm, sm.length);
    if (newData && newData.length > 0) {
      setNewStatus(true);
    } else {
      setNewStatus(false);
    }
  }, [newData]);
  useEffect(() => {
    if (selectedPlan) {
      if (selectedPlan?.couponData) {
        setPlanPriceCoupon(selectedPlan?.couponData?.planPrice);
        setPlanDiscountCoupon(selectedPlan?.couponData?.planDiscount);
        setFinalPriceCoupon(selectedPlan?.couponData?.finalPrice);
        setCouponDiscountPlan(selectedPlan?.couponData?.couponDiscount);
      } else {
        setApplyBlockOpen(false);
        setCodeApplied(false);
        setCouponError(false);
        setSuccessCode(false);
        setDetailSheet(false);
        setNewData(null);
        setNewStatus(false);
        // setExtraoff(null);
        // setCouponFinalPrice(null);
        props.resetCoupon(true);
        props.setCouponCodeValue("");
      }
    }
  }, [selectedPlan]);
  const setDefaultSelection = async () => {
    await handlePricingChange(pageData?.subscriptionPlan[0]);
    setSelectedPlan(pageData?.subscriptionPlan[0]);
  };

  useEffect(() => {
    if (couponCode && couponCode.length > 0) {
      setCodeApplied(true);
    } else {
      setCodeApplied(false);
    }
  }, [couponCode]);

  const applyCouponAction = () => {
    console.log("btnpplu");
    setId(selectedPlan?.id);
    let payload = {
      couponName: couponCode,
      groupId: window.location.pathname.split("/").pop(),
      plan: selectedPlan,
    };
    props.applyCouponApi(payload);
  };
  const onChangeValue = (value) => {
    setCouponCodeValue(value);
  };
  const renderInputBlock = () => {
    return (
      <div className="freeGroup">
        <div className="freeGroup_Input">
          <div className="freeGroup_InputBlock">
            <CouponInput
              placeholder="Enter Coupon Code"
              value={couponCode}
              onChange={(e) =>
                props.setCouponCodeValue(e.target.value.toUpperCase())
              }
              className="freeGroup_inputClass"
              maxLength={25}
            />
          </div>
          <div
            onClick={codeApplied ? applyCouponAction : null}
            className={
              codeApplied ? "freeGroup_selected_Applied" : "freeGroup_Apply"
            }
          >
            {"Apply"}
          </div>
        </div>
      </div>
    );
  };

  const renderPriceDetail = () => {
    console.log("COMING IN PRICE DETAIL BOX");
    return (
      <div className="priceDetail">
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_bold">Order Summary</div>
          <div onClick={() => setDetailSheet(false)}>
            <img src={x} style={{ width: "21px", height: "21px" }} />
          </div>
        </div>
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_text">Original Price</div>
          <div className="priceDetail_priceDetailItem_price">
            ₹{planPriceCoupon}/-
          </div>
        </div>
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_text">Discount</div>
          <div className="priceDetail_priceDetailItem_price">
            - ₹{planDiscountCoupon}/-
          </div>
        </div>
        <div className="priceDetail_priceDetailItem">
          <div className="couponNameBox">
            <div className="priceDetail_priceDetailItem_text">
              Coupon Discount
            </div>
            <div className="priceDetail_priceDetailItem_links">
              ({couponCode})
            </div>
          </div>
          <div className="priceDetail_priceDetailItem_price">
            - ₹{couponDiscountPlan}/-
          </div>
        </div>
        <div
          style={{ width: "100%", height: "1px", border: "1px dashed #E5E5E5" }}
        ></div>
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_bold">Payable Amount</div>
          <div className="priceDetail_priceDetailItem_payable">
            ₹{finalPriceCoupon}/-
          </div>
        </div>
      </div>
    );
  };

  const renderPriceDetailDesktop = () => {
    return (
      <div className="priceDetailDesktop" id="priceDesktop">
        <div className="priceDetailDesktop_box">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="priceDetailDesktop_box_heading">Order Summary</div>
            <div
              style={{ width: "16px", height: "16px", cursor: "pointer" }}
              onClick={() => setPriceDetailSheetDesktop(false)}
            >
              <img
                src={x}
                alt="Close Icon"
                style={{ width: "100%", height: "100%" }}
              ></img>
            </div>
          </div>
          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_title">
              Original Price
            </div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              ₹{planPriceCoupon}/-
            </div>
          </div>

          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_title">Discount</div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              - ₹{planDiscountCoupon}/-
            </div>
          </div>

          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_title">
              Coupon discount
              <div className="priceDetailDesktop_box_infoBox_title_couponname">
                ({couponCode})
              </div>
            </div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              - ₹{couponDiscountPlan}/-
            </div>
          </div>

          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_payableTitle">
              Payable Amount
            </div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              ₹{finalPriceCoupon}/-
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderCouponCodeBlock = () => {
    return (
      <div className="CouponCodeBlock">
        {applyBlockOpen ? (
          <div className={couponError ? "errorContainer" : "CouponCodeOpen"}>
            {/* {couponError && (
              <div className="errorContainer_redContainer">
                Code you entered is invalid
              </div>
            )} */}
            <div className="CouponCodeOpen_head">
              <div className="CouponCodeOpen_row">
                <div className="CouponCodeBlock_img">
                  <img
                    src={dShapeBlck}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="CouponCodeOpen_openText">
                  Have a Coupon code?
                </div>
              </div>
              {/* close btn */}
              <div
                onClick={() => {
                  setApplyBlockOpen(false);
                  setSuccessCode(false);
                  setCouponError(false);
                  setNewData(null);
                  setNewStatus(false);
                  props.resetCoupon(true);
                  props.setCouponCodeValue("");
                }}
                className="CouponCodeOpen_close"
              >
                <img src={x} style={{ width: "100%", height: "100%" }} />
              </div>
            </div>
            {renderInputBlock()}
          </div>
        ) : (
          <div
            onClick={!successCode ? () => setApplyBlockOpen(true) : null}
            className="CouponCodeBlock_haveACouponCode"
          >
            {!successCode && (
              <div className="CouponCodeBlock_img">
                <img
                  src={successCode ? dShapeBlck : dShape}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            )}
            <div className="CouponCodeBlock_text">
              {successCode ? (
                <div className="successCodeBlock">
                  <div className="CouponCodeBlock_img">
                    <img
                      src={successCode ? dShapeBlck : dShape}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="successCodeBlock_name">{couponCode}</div>
                  <div>coupon code applied</div>
                  {/* <div>Applied</div> */}
                  <div
                    onClick={() => {
                      setSuccessCode(false);
                      setApplyBlockOpen(true);
                    }}
                    className="editBtn"
                  >
                    <div className="editBtn_img">
                      <img
                        src={edit}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div className="editBtn_text">Edit</div>
                  </div>
                </div>
              ) : (
                <div style={{ fontSize: "12px" }}>Have a Coupon code?</div>
              )}
            </div>
          </div>
        )}
        {detailSheet && renderPriceDetail()}
      </div>
    );
  };

  const renderCouponCodeBlockDesktop = () => {
    return (
      <div className="CouponCodeBlockDesktop">
        {!applyBlockOpen && !successCode && (
          <div
            className="CouponCodeBlockDesktop_text"
            onClick={() => setApplyBlockOpen(true)}
          >
            <div className="CouponCodeBlockDesktop_text_image">
              <img src={dShape} style={{ width: "100%", height: "100%" }} />
            </div>
            Have a Coupon Code ?
          </div>
        )}
        {!applyBlockOpen && successCode && (
          <div className="CouponCodeBlockDesktop_couponAppliedtext">
            <div className="CouponCodeBlockDesktop_couponAppliedtext_image">
              <img src={dShapeBlck} style={{ width: "100%", height: "100%" }} />
            </div>
            <div className="CouponCodeBlockDesktop_couponAppliedtext_couponName">
              {couponCode}
            </div>
            <div>coupon code applied</div>

            <div
              className="CouponCodeBlockDesktop_couponAppliedtext_editBtn"
              onClick={() => {
                setSuccessCode(false);
                setApplyBlockOpen(true);
              }}
            >
              <div className="CouponCodeBlockDesktop_couponAppliedtext_editBtn_img">
                <img src={edit} style={{ width: "100%", height: "100%" }} />
              </div>
              Edit
            </div>
          </div>
        )}
        {applyBlockOpen && (
          <div className="CouponCodeBlockDesktop_applyCouponCodeBox">
            <div className="applyBox">
              <div style={{ display: "flex" }}>
                <span>
                  <img
                    src={dShapeBlck}
                    alt="Coupon apply icon"
                    style={{ width: "100%", height: "100%" }}
                  ></img>
                </span>
                <h2>Apply your coupon code</h2>
              </div>
              <div
                onClick={() => {
                  setApplyBlockOpen(false);
                  setSuccessCode(false);
                  setCouponError(false);
                  setNewData(null);
                  setNewStatus(false);
                  props.resetCoupon(true);
                  props.setCouponCodeValue("");
                }}
                style={{
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                  alignSelf: "center",
                }}
              >
                <img src={x} style={{ width: "100%", height: "100%" }} />
              </div>
            </div>
            <div className="inputBox">{renderInputBlock()}</div>
          </div>
        )}
      </div>
    );
  };

  function ReadMoreText({ children }) {
    console.log("her: ", children);
    const content = children;
    const [showMore, setShowMore] = useState(false);
    const toggleReadMore = () => {
      setShowMore(!showMore);
    };
    return (
      <div>
        <div
          style={{ "white-space": "pre-line" }}
          className="groupDetails_description-content"
        >
          {showMore ? content : content.slice(0, 200)}
        </div>
        {content?.length > 200 && (
          <span onClick={toggleReadMore}>
            {showMore ? "...show Less" : "...Read More"}
          </span>
        )}
      </div>
    );
  }
  return (
    <div className="groupDetails">
      <PayHeader pageData={pageData} />
      <GroupDescription pageData={pageData} />
      <div className="groupDetails_pricing">
        <p className="groupDetails_pricing-title">Pricing Details</p>
        {messageRouteFlag ? (
          <div className="groupDetails_pricing-payDiv">
            <p className="groupDetails_pricing-payDivText">You Pay</p>
            <p className="groupDetails_pricing-payDivPrice">
              ₹{pageData?.messagePrice}/-
            </p>
          </div>
        ) : (
          <div className="groupDetails_pricing-content">
            {pageData?.subscriptionPlan?.paymentPeriod ? (
              <div className="groupDetails_pricing_list">
                <label className="groupDetails_pricing_list-label">
                  <div className="groupDetails_pricing_list-labelDiv">
                    <input
                      type="radio"
                      id={1}
                      value={pageData?.subscriptionPlan?.paymentPeriod?.period}
                      checked={
                        pricingPayload.period ==
                        pageData?.subscriptionPlan?.paymentPeriod?.period
                      }
                      onChange={() => {
                        handlePricingChange(
                          pageData?.subscriptionPlan?.paymentPeriod
                        );
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    <span className="groupDetails_pricing_list-text">
                      {pageData?.subscriptionPlan?.paymentPeriod?.period}
                    </span>
                  </div>

                  <p className="groupDetails_pricing_list-price">
                    ₹{pageData?.subscriptionPlan?.paymentPeriod?.price}/-
                  </p>
                </label>
              </div>
            ) : null}
            {/* {console.log("groupNewData", newData, newStatus)} */}
            {(newStatus ? newData : pageData?.subscriptionPlan)?.map(
              (plan, index) => {
                // console.log("plna", plan);
                return (
                  <div
                    className={`groupDetails_SinglePlan ${
                      (
                        plan?.id
                          ? plan?.id === selectedPlan?.id
                          : plan === selectedPlan
                      )
                        ? "selectedPlan"
                        : ""
                    }`}
                    onClick={() => {
                      handlePricingChange(plan);
                      setSelectedPlan(plan);
                    }}
                  >
                    <div className="groupDetails_SinglePlan_Left">
                      <input
                        type="radio"
                        id={1}
                        // value={pageData?.subscriptionPlan?.paymentPeriod?.period}
                        checked={
                          plan?.id
                            ? plan?.id === selectedPlan?.id
                            : plan === selectedPlan
                        }
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <div className="groupDetails_pricing_Column">
                        <div className="groupDetails_SinglePlan_Period">
                          {plan?.selectedPeriod?.label == "Custom Period" ||
                          plan?.selectedPeriod == "Custom Period"
                            ? plan?.periodTitle
                            : plan?.selectedPeriod}
                        </div>
                        {plan?.couponData &&
                          plan?.couponData?.couponDiscount && (
                            <div className="groupDetails_pricing_list_extraoff">
                              {`Extra ₹${plan?.couponData?.couponDiscount}/- off`}
                            </div>
                          )}
                      </div>
                    </div>

                    {plan?.selectedOffer?.length ? (
                      <div className="groupDetails_SinglePlan_Offer">
                        {plan?.selectedOffer}
                      </div>
                    ) : null}

                    <div className="groupDetails_SinglePlan_Right">
                      {plan?.discount ? (
                        <div className="groupDetails_SinglePlan_Price">
                          ₹{plan?.price}/-
                        </div>
                      ) : null}

                      {plan?.price == 0 ? (
                        <div className="groupDetails_SinglePlan_FinalPrice">
                          Free!
                        </div>
                      ) : (
                        <div className="groupDetails_SinglePlan_FinalPrice">
                          ₹
                          {plan?.couponData
                            ? plan?.couponData?.finalPrice
                              ? plan?.couponData?.finalPrice
                              : parseFloat(plan?.price) -
                                parseFloat(plan?.discount)
                            : applyCouponSuccess && plan?.id == selectedPlan?.id
                            ? applyCouponSuccess?.currentPlanData?.finalPrice
                            : parseFloat(plan?.price) -
                              parseFloat(plan?.discount)}
                          /-
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            )}

            {pageData?.subscriptionPlan?.accessPrice ? (
              <div className="groupDetails_pricing_list">
                <label className="groupDetails_pricing_list-label">
                  <div className="groupDetails_pricing_list-labelDiv">
                    <input
                      type="radio"
                      id={2}
                      checked={!pricingPayload?.period}
                      onClick={() => {
                        handlePricingChange({
                          price: pageData?.subscriptionPlan?.accessPrice,
                        });
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    <span className="groupDetails_pricing_list-text">
                      One Time
                    </span>
                  </div>

                  <p className="groupDetails_pricing_list-price">
                    ₹{pageData?.subscriptionPlan?.accessPrice}/-
                  </p>
                </label>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <PayPageStaticContent
        pageData={pageData?.type}
        navigate={navigate}
        sebiFlag={pageData?.sebi}
      />

      {renderCouponCodeBlockDesktop()}

      <div className="groupDetails_footer">
        {/* CouponCode */}
        {/* {console.log("selecr", selectedPlan?.selectedOffer,v2,localStorage.getItem("v2Data"))} */}
        {selectedPlan?.selectedOffer !== undefined &&
          selectedPlan?.selectedOffer != "Free Trial" &&
          v2Enabled &&
          renderCouponCodeBlock()}

        {applyCouponLoader ? (
          <div className="couponLoader">
            <ReactLoading
              type={"spin"}
              color={"#000000"}
              height={"40px"}
              width={"40px"}
            />
          </div>
        ) : selectedPlan?.price == 0 ? (
          <div className="groupDetails_footer_free">
            <div className="groupDetails_footer-text">Free</div>
            <p
              style={{ color: "#C4C4C4", fontSize: "10px" }}
            >{`for ${selectedPlan?.customValue} days`}</p>
          </div>
        ) : (
          <div className="priceOuterBlock">
            <div className="priceBlock">
              {selectedPlan?.price !== undefined ? (
                <p className="groupDetails_footer-text">
                  ₹
                  {successCode
                    ? !!finalPriceCoupon
                      ? finalPriceCoupon
                      : parseFloat(selectedPlan?.price) -
                        parseFloat(selectedPlan?.discount)
                    : selectedPlan?.discount
                    ? parseFloat(selectedPlan?.price) -
                      parseFloat(selectedPlan?.discount)
                    : parseFloat(selectedPlan?.price)}
                  /-
                </p>
              ) : null}
              {successCode && !!finalPriceCoupon && (
                <div
                  onClick={() => setDetailSheet(true)}
                  className="priceBlock_img"
                >
                  <img src={info} style={{ width: "100%", height: "100%" }} />
                </div>
              )}
            </div>

            {/* PRICE BLOCK FOR DESKTOP */}
            <div className="priceBlock_Desktop">
              {priceDetailSheetDesktop && renderPriceDetailDesktop()}

              {selectedPlan?.price !== undefined ? (
                <p className="groupDetails_footer-text">
                  ₹
                  {successCode
                    ? !!finalPriceCoupon
                      ? finalPriceCoupon
                      : parseFloat(selectedPlan?.price) -
                        parseFloat(selectedPlan?.discount)
                    : selectedPlan?.discount
                    ? parseFloat(selectedPlan?.price) -
                      parseFloat(selectedPlan?.discount)
                    : parseFloat(selectedPlan?.price)}
                  /-
                </p>
              ) : null}
              {successCode && !!finalPriceCoupon && (
                <div
                  onClick={() =>
                    setPriceDetailSheetDesktop(!priceDetailSheetDesktop)
                  }
                  className="priceBlock_img"
                >
                  <img src={info} style={{ width: "100%", height: "100%" }} />
                </div>
              )}
            </div>

            {successCode && !!finalPriceCoupon && (
              <div className="savedPrice">
                you saved ₹{couponDiscountPlan + planDiscountCoupon}
              </div>
            )}
          </div>
        )}

        <div className="groupDetails_footer-button">
          {selectedPlan?.price !== undefined ? (
            <Button
              disabled={disabled}
              onClick={() => {
                props.resetFormDetails(true);
                setPurchaseScreen("userDetails");
              }}
            >
              {selectedPlan?.price == 0 ? "Proceed" : "Pay Now"}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formDetails: state?.purchaseReducer?.formDetails,
    generateOtp: state?.purchaseReducer?.generateOtp,
    pricingPayload: state?.purchaseReducer?.pricingPayload,
    pageData: window.location.pathname.includes("/m")
      ? state?.purchaseReducer?.pageData?.data?.[0]
      : state?.purchaseReducer?.pageData?.data,
    pageDataError: state?.purchaseReducer?.pageDataError,
    applyCouponSuccess: state?.purchaseReducer?.applyCouponSuccess,
    applyCouponFailure: state?.purchaseReducer?.applyCouponFailure,
    couponCode: state?.purchaseReducer?.couponCode,
    applyCouponLoader: state?.purchaseReducer?.applyCouponLoader,
    v2Enabled: state?.user?.v2Enabled,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setPurchaseScreen: (payload) =>
      dispatch(actions.setPurchaseScreen(payload)),
    handlePricingChange: (payload) =>
      dispatch(actions.handlePricingChange(payload)),
    applyCouponApi: (payload) => dispatch(actions.applyCouponApi(payload)),
    setCouponCodeValue: (payload) =>
      dispatch(actions.setCouponCodevalue(payload)),
    resetCoupon: (payload) => dispatch(actions.resetCoupon(payload)),
    resetFormDetails: (payload) => dispatch(actions.resetFormDetails(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(GroupDetails);
