import React from "react";
import { useNavigate } from "react-router";
import "../../../scss/Details/Earnings.scss";

function Earnings(props) {
  const { totalEarnings, purchases, messageId, groupId, data } = props;
  const navigate = useNavigate();
  // console.log("totalEarnings",totalEarnings, typeof totalEarnings);
  const goToStatement = () => {
    window.payload = { groupId };
    navigate("/ChannelEarnings");
    // navigate(
    //   `/${messageId ? "message" : "group"}/${
    //     messageId ? messageId : groupId
    //   }/statement`
    // );
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="Earnings">
      <div className="Earnings_Top">
        <div>
          <div
            className="Earnings_Top_Text"
            onClick={() => console.log(data.members.active)}
          >
            Total Earnings
          </div>
          {/* <div className="Earnings_Top_Subtext">₹{totalEarnings}</div> */}

          <div className="Earnings_Top_Subtext">
            ₹{totalEarnings ? numberWithCommas(totalEarnings) : 0}/-
          </div>
        </div>
        <div>
          <div className="Earnings_Top_Text">Total Purchases</div>
          <div className="Earnings_Top_Subtext">{purchases}</div>
        </div>
      </div>

      {/* {earnings.statementHistory && earnings.statementHistory?.length ? ( */}
      {data?.members?.active + data?.members?.inActive >= 1 ? (
        <div className="Earnings_Footer" onClick={goToStatement}>
          View Statement
        </div>
      ) : null}
      {/* ) : null} */}
    </div>
  );
}

export default Earnings;
