import CONSTANTS from "./constants";

const channelEarningsInitialState = {
  loadingStateChannelEarnings: false,
  apiResponseChannelEarnings: [],
  totalEarnings: 0,
  totalPurchases: 0,
  offset: 0,
};

const channelEarningsReducer = (
  state = channelEarningsInitialState,
  action
) => {
  let temp = state.apiResponseChannelEarnings;
  switch (action.type) {
    case CONSTANTS.CHANNEL_EARNINGS_GET_CLEAR: {
      return {
        ...state,
        apiResponseChannelEarnings: [],
        totalEarnings: 0,
        totalPurchases: 0,
        offset: 0,
      };
    }
    case CONSTANTS.CHANNEL_EARNINGS_GET_LOADER: {
      return {
        ...state,
        loadingStateChannelEarnings: true,
        offset: 0,
      };
    }
    case CONSTANTS.CHANNEL_EARNINGS_SUCCESS: {
      return {
        ...state,
        loadingStateChannelEarnings: false,
        apiResponseChannelEarnings: [
          ...temp,
          ...action?.payload.paymentBreakdown,
        ],
        totalEarnings: action?.payload?.totalAmount,
        totalPurchases: action?.payload?.totalPurchases,
        offset: action.payload.offset,
      };
    }
    case CONSTANTS.CHANNEL_EARNINGS_GET_FAILURE: {
      return {
        ...state,
        loadingStateChannelEarnings: false,
        apiResponseChannelEarnings: [],
        totalEarnings: 0,
        totalPurchases: 0,
        offset: 0,
      };
    }
    default:
      return state;
  }
};

export default channelEarningsReducer;
