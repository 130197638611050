import React from "react";

const SideBarItem = ({ icon, label, isHighlighted, handleClick }) => {
  return (
    <div
      className={`sideBarWrapper__sideBar__bottom__item ${
        isHighlighted && "sideBarWrapper__sideBar__bottom__item--isHighlighted"
      }`}
      onClick={handleClick}
    >
      {icon && (
        <img src={icon} alt="sidebarItemIcon" height="24px" width="24px" />
      )}
      {label && <p>{label}</p>}
    </div>
  );
};

export default SideBarItem;
