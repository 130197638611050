import React from "react";
import "../../scss/PreAuth/PreAuth.scss";
import calendar from "../../assets/calendar.png";
import moment from "moment";
const ExpiryDate = (props) => {
  const { data } = props;

  if (data) {
    const { differenceInDays, expiryDate, joinedDate } = data;

    return (
      <div className="preAuth_expiryBlock">
        <div className="preAuth_expiryBlock_calendarText">
          <img src={calendar} style={{ width: "18px", height: "16px" }} />
          <p
            style={{
              color: "rgba(60, 72, 82, 0.6)",
              fontSize: "14px",
              marginLeft: "3px",
            }}
          >
            Expiry Date
          </p>
        </div>
        <div className="preAuth_expiryBlock_dateExpiry">
          <p
            style={{
              color: "#3C4852",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {moment(expiryDate).format("DD MMMM, YYYY")}
          </p>
          <p
            style={{ color: "#F49F29", fontSize: "14px" }}
          >{`Expiring in ${differenceInDays} days`}</p>
        </div>
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
            color: "#3C4852",
          }}
        >{`Your current plan started on ${moment(joinedDate).format(
          "DD MMMM, YYYY"
        )}`}</p>
      </div>
    );
  }
  return <></>;
};

export default ExpiryDate;
