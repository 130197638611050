import React, { useState } from "react";
import "./channelEarnings.scss";
import { useNavigate } from "react-router-dom";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import { useEffect } from "react";
import calendarIcon from "../../assets/calendarIcon.svg";
import blueSearch from "../../assets/blueSearch.png";
import phoneED from "../../assets/phoneED.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as actions from "./sagaStore/actions";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const ListItem = ({ item, index }) => {
  const [show, setShow] = useState();
  const freeTrial = item?.currentPlan
    ? JSON.parse(item.currentPlan).selectedOffer == "Free Trial"
      ? true
      : false
    : false;
  return (
    <div>
      <div key={index} className="container_itemContainer">
        <div className="container_itemContainer_left">
          <div className="container_name">{item?.buyer?.name}</div>
          {item.buyer.mobile && (
            <div className="container_date">
              <img
                height={"14px"}
                width={"14px"}
                src={phoneED}
                style={{ marginRight: 9 }}
              />
              <div className="container_mobile">
                {`+${item.buyer.countryCode} ${item.buyer.mobile}`}
              </div>
            </div>
          )}
          <div className="container_tagContainer">
            {item.migrated ? (
              <div className="container_subscription">Migrated</div>
            ) : null}
            {item.currentPlan ? (
              item.currentPlan.selectedPeriod == "Lifetime" ? (
                <div className="container_lifetime">Lifetime</div>
              ) : (
                <div className="container_subscription">
                  {JSON.parse(item.currentPlan)?.periodTitle
                    ? JSON.parse(item.currentPlan)?.periodTitle
                    : JSON.parse(item.currentPlan)?.selectedPeriod}{" "}
                </div>
              )
            ) : null}
            {item.joinedBy && !item.isLeft ? (
              <div className="container_joined">Joined</div>
            ) : item.isLeft ? (
              <div className="container_notJoined">Left</div>
            ) : (
              <div className="container_notJoined">Not Joined</div>
            )}

            {item.renewed && (
              <div className="container_subscription">Renewed</div>
            )}
            {freeTrial && <div className="container_subscription">Free</div>}
            {item.couponName && (
              <div className="container_coupon">
                {item.couponName.length < 10
                  ? item.couponName
                  : `${item.couponName.slice(0, 10)}...`}
              </div>
            )}
          </div>
        </div>
        <div className="container_itemContainer_right">
          <div className="container_dateText">
            {moment(item.createdAt).format("MMM DD, YYYY")}
          </div>
          {
            <div className="container_amount">
              {item.migrated ? `₹0/-` : `₹${item.amount}/-`}
            </div>
          }
          {!freeTrial && !item.migrated && (
            <div className="container_details" onClick={() => setShow(!show)}>
              {show ? "Hide details" : "View details"}
            </div>
          )}
        </div>
      </div>
      {show && (
        <div className="container_detailsContainer">
          <div className="container_detailsLeft">
            <div className="container_detailsText">Original Price</div>
            {item.planDiscount > 0 && (
              <div className="container_detailsText">Discount</div>
            )}
            {item.couponName && (
              <div className="container_detailsText">
                {`Coupon (${item.couponName})`}
              </div>
            )}
            <div className="container_detailsText">
              Internet Handling Charges
            </div>
          </div>
          <div>
            <div className="container_detailsAmount">
              {`₹${item.orignalPrice}/-`}
            </div>
            {item.planDiscount > 0 && (
              <div className="container_detailsAmount">
                ₹{item?.planDiscount}/-
              </div>
            )}
            {item.couponName && (
              <div className="container_detailsAmount">
                ₹{item?.couponDiscount}/-
              </div>
            )}
            <div className="container_detailsAmount">
              ₹{item?.fankonnectCommission}/-
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ChannelEarnings = (props) => {
  const v2 = props.version2;
  console.log("v2: ", v2);
  useLayoutPageHeading("Earning Dashboard");
  const [selectedType, setSelectedType] = useState("overall");
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const navigate = useNavigate();

  useEffect(() => {
    if (props.channelEarnings.length == 0) {
      if (v2) {
        props.getChannelEarnings({
          groupId: window.payload.groupId,
          offset: props.offset,
          version2: true,
        });
      } else {
        props.getChannelEarnings({
          groupId: window.payload.groupId,
          offset: props.offset,
        });
      }
    }

    return () => props.clearChannelEarningsData();
  }, []);

  return (
    <div>
      <div className="container_earningsContainer">
        <div className="container_earningType">
          <div
            onClick={async () => {
              setSelectedType("overall");
              props.clearChannelEarningsData();
              if (v2) {
                props.getChannelEarnings({
                  groupId: window.payload.groupId,
                  offset: 0,
                  version2: true,
                });
              } else {
                props.getChannelEarnings({
                  groupId: window.payload.groupId,
                  offset: 0,
                });
              }
            }}
            className={
              selectedType == "overall"
                ? "container_selectedType"
                : "container_typeName"
            }
          >
            Overall
          </div>
          <div
            onClick={async () => {
              setSelectedDate(new Date());
              setSelectedMonth(null);
              setSelectedType("monthly");
              const date = new Date();
              props.clearChannelEarningsData();
              if (v2) {
                props.getChannelEarnings({
                  groupId: window.payload.groupId,
                  offset: 0,
                  version2: true,
                  startDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-${new Date(
                    moment(date).year(),
                    moment(date).month(),
                    1
                  ).getDate()}`,
                  endDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-${new Date(
                    moment(date).year(),
                    moment(date).month() + 1,
                    0
                  ).getDate()}`,
                });
              } else {
                props.getChannelEarnings({
                  groupId: window.payload.groupId,
                  offset: 0,
                  startDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-${new Date(
                    moment(date).year(),
                    moment(date).month(),
                    1
                  ).getDate()}`,
                  endDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-${new Date(
                    moment(date).year(),
                    moment(date).month() + 1,
                    0
                  ).getDate()}`,
                });
              }
            }}
            className={
              selectedType == "monthly"
                ? "container_selectedType"
                : "container_typeName"
            }
          >
            Monthly
          </div>
        </div>
        <div className="container_totalEarningsContainer">
          <div className="container_totalEarningsTitle">
            {selectedType == "overall" ? (
              "Total Earnings"
            ) : (
              <DatePicker
                className="myEarningsContainer_temp"
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                  props.clearChannelEarningsData();
                  if (v2) {
                    props.getChannelEarnings({
                      groupId: window.payload.groupId,
                      offset: 0,
                      version2: true,
                      startDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month(),
                        1
                      ).getDate()}`,
                      endDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month() + 1,
                        0
                      ).getDate()}`,
                    });
                  } else {
                    props.getChannelEarnings({
                      groupId: window.payload.groupId,
                      offset: 0,
                      startDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month(),
                        1
                      ).getDate()}`,
                      endDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month() + 1,
                        0
                      ).getDate()}`,
                    });
                  }
                }}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
              />
            )}
          </div>
          <div className="container_totalEarningsValue">
            ₹{props.totalEarnings}/-
          </div>
        </div>
      </div>
      <div className="container_searchOuterBox">
        <div className="container_totalPurchasesHeading">
          Total Purchases ({props.totalPurchases})
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            style={{ marginRight: "4px" }}
            height={"20px"}
            width={"20px"}
            src={blueSearch}
          />
          <div
            onClick={() => {
              navigate("/EarningDashSearch");
            }}
            className="container_searchText"
          >
            Search
          </div>
        </div>
      </div>
      <div className="container_listContainer">
        <InfiniteScroll
          dataLength={props?.channelEarnings?.length || 100}
          next={() => {
            if (selectedType == "overall") {
              if (v2) {
                props.getChannelEarnings({
                  offset: props.offset,
                  groupId: window.payload.groupId,
                  version2: true,
                });
              } else {
                props.getChannelEarnings({
                  offset: props.offset,
                  groupId: window.payload.groupId,
                });
              }
            } else {
              const date = selectedDate;
              if (v2) {
                props.getChannelEarnings({
                  groupId: window.payload.groupId,
                  startDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-01`,
                  endDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-${new Date(
                    moment(date).year(),
                    moment(date).month() + 1,
                    0
                  ).getDate()}`,
                  offset: props.offset,
                  version2: true,
                });
              } else {
                props.getChannelEarnings({
                  groupId: window.payload.groupId,
                  startDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-01`,
                  endDate: `${moment(date).year()}-${moment(date).format(
                    "MM"
                  )}-${new Date(
                    moment(date).year(),
                    moment(date).month() + 1,
                    0
                  ).getDate()}`,
                  offset: props.offset,
                });
              }
            }
          }}
          hasMore={true}
          height={"90vh"}
        >
          {props.channelEarnings.length > 0 &&
            props.channelEarnings.map((item, index) => (
              <ListItem item={item} index={index} key={index} />
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ channelEarningsReducer, user }) => {
  return {
    channelEarnings: channelEarningsReducer.apiResponseChannelEarnings,
    totalEarnings: channelEarningsReducer.totalEarnings,
    totalPurchases: channelEarningsReducer.totalPurchases,
    offset: channelEarningsReducer.offset,
    version2: user.version2,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    getChannelEarnings: (payload) =>
      dispatch(actions.getChannelEarningsAction(payload)),
    clearChannelEarningsData: (payload) =>
      dispatch(actions.clearChannelEarningsData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(ChannelEarnings);
