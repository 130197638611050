import CONSTANTS from "./constants";

export const getAllCoupons = (payload) => {
  return {
    type: CONSTANTS.ALL_COUPONS_GET,
    payload,
  };
};
export const couponAction = (payload) => {
  return {
    type: CONSTANTS.COUPON_ACTIVE_INACTIVE_DELETE,
    payload,
  };
};
