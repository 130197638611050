import React, { useState } from "react";
import "./styles.scss";
import { getUserToken } from "../../utils/getAxiosConfig";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import { connect, useDispatch, useSelector } from "react-redux";
import noEarningsIcon from "../../assets/noEarningsIcon.svg";
import ContentShimmmer from "./shimmer";
import ReactGA from "react-ga";
import EVENTS from "../../utils/gaEvents";
import SingleContent from "./SingleContent";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonDatePicker from "./DatePicker";
import moment from "moment";
import * as actions from "./sagaStore/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import calendar from "../../assets/calendar.png";
import blueInfo from "../../assets/blueInfo.png";
import Button from "../../common/Button";
import Bottomsheet from "../../common/Bottomsheet";
import greenOne from "../../assets/greenOne.png";
import greenTwo from "../../assets/greenTwo.png";
import greenThree from "../../assets/greenThree.png";
import greenFour from "../../assets/greenFour.png";

const MyEarnings = (props) => {
  const show = true;
  // useLayoutPageHeading("My Earnings");

  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState("overall");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openBottomSheet, setOpenBottomSheet] = useState(false);

  React.useEffect(() => {
    ReactGA.event({
      category: EVENTS.HOME.CATEGORY,
      action: EVENTS.HOME.ACTIONS.OPEN_EARNINGS,
    });
  }, []);

  useEffect(() => {
    console.log("vertsion2: ", props.version2);
    if (props.earnings.length == 0) {
      if (props.version2) {
        props.getEarnings({
          offset: 0,
          version2: true,
        });
      } else {
        props.getEarnings({
          offset: 0,
        });
      }
    }

    return () => props.clearEarnings();
  }, []);

  return (
    <React.Fragment>
      <div className="myEarningsContainer_earningsContainer">
        {openBottomSheet && (
          <Bottomsheet
            header={"Please Note"}
            handleClose={() => setOpenBottomSheet(false)}
          >
            <div>
              <div className="myEarningsContainer_btSheetListItem">
                <div className="myEarningsContainer_stepperText">
                  Your earnings will get automatically settled to you bank
                  account.
                </div>
              </div>

              <div className="myEarningsContainer_btSheetListItem">
                <img
                  className="myEarningsContainer_btSheetStepper"
                  src={greenOne}
                />
                <div className="myEarningsContainer_stepperText">
                  It takes upto 48 hours to settle the amount in your bank
                  account.
                </div>
              </div>
              <div className="myEarningsContainer_btSheetListItem">
                <img
                  className="myEarningsContainer_btSheetStepper"
                  src={greenTwo}
                />
                <div className="myEarningsContainer_stepperText">
                  Settlements are not made on weekends and bank holidays.
                </div>
              </div>
              <div className="myEarningsContainer_btSheetListItem">
                <img
                  className="myEarningsContainer_btSheetStepper"
                  src={greenThree}
                />
                <div className="myEarningsContainer_stepperText">
                  So for the transactions happening a day before bank holidays
                  or weekends will settle to your bank account on the next
                  working day
                </div>
              </div>
              <div className="myEarningsContainer_gotItBTNContainer">
                <Button
                  onClick={() => {
                    setOpenBottomSheet(false);
                  }}
                >
                  {"Got It"}
                </Button>
              </div>
            </div>
          </Bottomsheet>
        )}
        <div className="myEarningsContainer_earningTypeParent">
          <div className="myEarningsContainer_earningType">
            <div
              onClick={() => {
                setSelectedType("overall");
                props.clearEarnings();
                if (props.version2) {
                  props.getEarnings({
                    offset: 0,
                    version2: true,
                  });
                } else {
                  props.getEarnings({
                    offset: 0,
                  });
                }
              }}
              className={
                selectedType == "overall"
                  ? "myEarningsContainer_selectedType"
                  : "myEarningsContainer_typeName"
              }
            >
              Overall
            </div>
            <div
              onClick={() => {
                setSelectedType("monthly");
                setSelectedDate(new Date());
                props.clearEarnings();
                const date = new Date();
                if (show) {
                  props.getEarnings({
                    startDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-01`,
                    endDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-${new Date(
                      moment(date).year(),
                      moment(date).month() + 1,
                      0
                    ).getDate()}`,
                    offset: 0,
                    version2: true,
                  });
                } else {
                  props.getEarnings({
                    startDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-01`,
                    endDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-${new Date(
                      moment(date).year(),
                      moment(date).month() + 1,
                      0
                    ).getDate()}`,
                    offset: 0,
                  });
                }
              }}
              className={
                selectedType == "monthly"
                  ? "myEarningsContainer_selectedType"
                  : "myEarningsContainer_typeName"
              }
            >
              Monthly
            </div>
          </div>
          {props.version2 && selectedType == "monthly" && (
            <div className="myEarningsContainer_DateContainer">
              <img
                src={calendar}
                height={"18px"}
                width={"18px"}
                style={{ marginRight: 5 }}
              />
              <DatePicker
                className="myEarningsContainer_temp"
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                  props.clearEarnings();
                  if (props.version2) {
                    props.getEarnings({
                      startDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-01`,
                      endDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month() + 1,
                        0
                      ).getDate()}`,
                      offset: 0,
                      version2: true,
                    });
                  } else {
                    props.getEarnings({
                      startDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-01`,
                      endDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month() + 1,
                        0
                      ).getDate()}`,
                      offset: 0,
                    });
                  }
                }}
                dateFormat="MMMM"
                showMonthYearPicker
              />
            </div>
          )}
        </div>
        {props.version2 ? (
          // <div style={{ backgroundColor: 'red' }} className="myEarningsContainer_showTotalEarningsContainer">
          //   <div className="myEarningsContainer_showColumn">
          //     <div className="myEarningsContainer_showColumnRowOne">{`₹${props.totalEarnings}`}</div>
          //     <div className="myEarningsContainer_showColumnRowTwo">
          //       Total Earnings
          //     </div>
          //   </div>
          //   <div>
          //     <div className="myEarningsContainer_showColumnRowOne">
          //       {props.settlementSum ? `₹${props.settlementSum}` : "₹0"}
          //     </div>
          //     <div className="myEarningsContainer_showColumnRowTwoSettlements">
          //       <div>Total Settlement</div>
          //       <div>
          //         <img
          //           onClick={() => {
          //             setOpenBottomSheet(true);
          //           }}
          //           height={"16px"}
          //           width={"16px"}
          //           style={{ marginLeft: 4, marginTop: 3 }}
          //           src={blueInfo}
          //         />
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <div className="myEarningsContainer_totalEarningsContainer">
            <div className="myEarningsContainer_totalEarningsTitle">
              Total Earnings
            </div>
            <div className="myEarningsContainer_totalEarningsValue">
              ₹{props.totalEarnings}/-
            </div>
          </div>
        ) : (
          <div className="myEarningsContainer_totalEarningsContainer">
            <div className="myEarningsContainer_totalEarningsTitle">
              {selectedType == "overall" ? (
                "Total Earnings"
              ) : props.version2 ? null : (
                <DatePicker
                  className="myEarningsContainer_temp"
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    props.clearEarnings();
                    props.getEarnings({
                      startDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-01`,
                      endDate: `${moment(date).year()}-${moment(date).format(
                        "MM"
                      )}-${new Date(
                        moment(date).year(),
                        moment(date).month() + 1,
                        0
                      ).getDate()}`,
                      offset: 0,
                    });
                  }}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                />
              )}
              {/* Total Earnings */}
            </div>
            <div className="myEarningsContainer_totalEarningsValue">
              ₹{props.totalEarnings}/-
            </div>
          </div>
        )}
      </div>
      {props?.earnings.length > 0 ? (
        <div
          // ref={scrollRef}
          className="myEarningsContainer"
        >
          <div className="myEarningsContainer_channelWiseBreakDown">
            Channel wise breakdown
          </div>

          <InfiniteScroll
            dataLength={props?.earnings?.length || 100}
            next={() => {
              if (selectedType == "overall") {
                if (props.version2) {
                  props.getEarnings({
                    offset: props.offset,
                    version2: true,
                  });
                } else {
                  props.getEarnings({
                    offset: props.offset,
                  });
                }
              } else {
                const date = selectedDate;
                if (props.version2) {
                  props.getEarnings({
                    startDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-01`,
                    endDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-${new Date(
                      moment(date).year(),
                      moment(date).month() + 1,
                      0
                    ).getDate()}`,
                    offset: props.offset,
                    version2: true,
                  });
                } else {
                  props.getEarnings({
                    startDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-01`,
                    endDate: `${moment(date).year()}-${moment(date).format(
                      "MM"
                    )}-${new Date(
                      moment(date).year(),
                      moment(date).month() + 1,
                      0
                    ).getDate()}`,
                    offset: props.offset,
                  });
                }
              }
            }}
            hasMore={true}
            height={"90vh"}
          >
            {props.earnings &&
              props.earnings.map((data, index) => (
                <div>
                  <SingleContent
                    type={2}
                    onclick={() => {
                      navigate("/ChannelEarnings");
                    }}
                    data={data}
                  />
                </div>
              ))}
          </InfiniteScroll>
        </div>
      ) : (
        <React.Fragment>
          {props.loadingStateGetEarnings ? (
            <ContentShimmmer />
          ) : (
            <div className="Home_empty">
              <img src={noEarningsIcon} className="Home_empty-img" />
              <p className="Home_empty-text">Your Earnings will show here</p>
              <p className="Home_empty-subtext">
                Please create & share the payment links with your audience
              </p>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ earningsReducer, user }) => {
  return {
    earnings: earningsReducer.apiResponseGetEarnings,
    totalEarnings: earningsReducer.totalEarnings,
    loadingStateGetEarnings: earningsReducer.loadingStateGetEarnings,
    offset: earningsReducer.offset,
    v2Enabled: user.v2Enabled,
    version2: user.version2,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    getEarnings: (payload) => dispatch(actions.getMyEarningsAction(payload)),
    clearEarnings: (payload) => dispatch(actions.clearEarningsData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(MyEarnings);
