import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SingleContent from "../singleContent";
import * as actions from "../../../../store/Home/actions";
import { resetState } from "../../../../store/CreateMessage/actions";
import ReactLoading from "react-loading";
import Bottomsheet from "../../../../common/Bottomsheet";
import locked from "../../../../assets/emptyStates/lockedContent.png";
import ShareBottomsheet from "../../../../components/ShareBottomsheet";
import InfiniteScroll from "react-infinite-scroll-component";

const MessagesTab = (props) => {
  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    props.fetchMessages({ limit: props?.limit, offset: 0 });
    props.resetMessageForm();
    return () => props.resetState();
  }, []);

  const [socialLinks, setSocialLinks] = useState({});

  return (
    <div className="Home_Groups">
      <InfiniteScroll
        dataLength={props?.messages?.length || 100}
        next={() =>
          props.fetchMessages({ limit: props.limit, offset: props.offset })
        }
        hasMore={true}
        height={"90vh"}
        refreshFunction={() =>
          props.fetchMessages({ limit: props.limit, offset: 0 })
        }
        pullDownToRefresh
        pullDownToRefreshThreshold={80}
      >
        {props?.isLoading ? (
          <div className="Loader">
            <ReactLoading
              type={"spinningBubbles"}
              color={"#2278FF"}
              height={50}
              width={50}
            />
          </div>
        ) : props?.messages?.length ? (
          <>
            {props.messages.map((message) => (
              <SingleContent
                data={message}
                key={message.id}
                type={2}
                setShowShare={setShowShare}
                setSocialLinks={setSocialLinks}
              />
            ))}
          </>
        ) : (
          <div className="Home_empty">
            <img src={locked} className="Home_empty-img" />
            <p className="Home_empty-text">Create Your First Locked Message</p>
            <p className="Home_empty-subtext">
              Tap the “+” icon to start monetising your knowledge
            </p>
          </div>
        )}
      </InfiniteScroll>

      {showShare ? (
        <ShareBottomsheet
          socialLinks={socialLinks}
          handleClose={() => setShowShare(false)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state?.home?.isLoading,
    messages: state?.home?.messages,
    groups: state?.home?.groups,
    limit: state?.home?.limit,
    offset: state?.home?.offset,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    fetchMessages: (payload) => dispatch(actions.fetchMessages(payload)),
    resetState: () => dispatch(actions.resetState()),
    resetMessageForm: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(MessagesTab);
