import { call, put, select, takeLatest } from "redux-saga/effects";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";
import { getMyEarnings } from "./services";

function* getMyEarningsSaga(payload) {
  try {
    const apiCall = yield call(getMyEarnings, payload);
    const { earnings } = apiCall?.data?.data;
    if (earnings.length > 0) {
      payload.payload.offset = payload.payload.offset + 10;
      let temp = { ...apiCall?.data?.data };
      temp.offset = payload.payload.offset;
      const getAction = yield call(
        getActionObject,
        CONSTANTS.MY_EARNINGS_SUCCESS,
        temp
      );
      yield put({ ...getAction });
    }
  } catch (error) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.MY_EARNINGS_GET_FAILURE
    );
    yield put({ ...getAction });
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.MY_EARNINGS_GET, getMyEarningsSaga);
}
