import React, { useRef, useEffect, useState } from "react";
import "../../../scss/Purchase/otp.scss";
import OtpInput from "../../../common/OtpInput";
import lock from "../../../assets/purchase/lock.svg";
import Timer from "../../../common/Timer";
import Button from "../../../common/Button";
import * as actions from "../../../store/Purchase/actions";
import { connect, useSelector } from "react-redux";
import whatsapp from "../../../assets/userDetail/whatsapp.png";
import ReactLoading from "react-loading";
function Otp(props) {
  let {
    setPurchaseScreen,
    setFormDetails,
    generateOtp,
    generateOtpAction,
    verifyOtpAction,
    verifyOtp,
    formDetails,
    handleRazorPay,
    otpValueAction,
    getOrderStatus,
    verifyOtpLoading,
    waChecked,
    WAChecked,
  } = props;
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(15);
  const firstOtpElRef = useRef();
  const backSpaceRef = useRef();
  let [disabled, setDisabled] = useState(true);
  const pageData = useSelector(
    (state) => state?.purchaseReducer?.pageData?.data
  );
  const IpData = useSelector((state) => state.getCountryCodeReducer.data);
  const whatsappChecked = waChecked;
  useEffect(() => {
    if (otp.join("").toString()?.length == 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp]);

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      firstOtpElRef?.current?.focus();
    }, 400);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const otpHandler = (element, index) => {
    const reg = new RegExp("^[0-9]*$");
    if (
      element.target.value.trim() &&
      element.target.nextSibling &&
      reg.test(element.target.value)
    ) {
      element.target.nextSibling.focus();
    }

    if (element.target.value.length <= 1 && reg.test(element.target.value)) {
      setOtp([
        ...otp.map((data, idx) => {
          return idx === index ? element.target.value : data;
        }),
      ]);
    }
  };

  const resendOtpHandler = () => {
    //CHECK CASES FOR INTERNATIONAL USER ALSO IN RESEND OTP

    //INDIAN
    if (IpData !== null && IpData?.countryExt === "91") {
      let formData = {
        mobile: formDetails?.mobile,
        whatsappStatus: waChecked,
        type: pageData?.type,
        countryCode: "91",
        groupId: pageData?.id,
      };
      generateOtpAction(formData);
      // resendHandler();
      setSeconds(15);
    }

    //INTERNATIONAL
    else if (IpData !== null && IpData?.countryExt !== "91") {
      let formData = {
        email: formDetails?.email,
        whatsappStatus: waChecked,
        type: pageData?.type,
        groupId: pageData?.id,
        // countryCode: IpData?.countryISO,
      };
      generateOtpAction(formData);
      // resendHandler();
      setSeconds(15);
    }

    //WHEN IP API DOESN'T WORK
    else {
      let formData = {
        mobile: formDetails?.mobile,
        whatsappStatus: waChecked,
        type: pageData?.type,
        countryCode: "91",
        groupId: pageData?.id,
      };
      generateOtpAction(formData);
      // resendHandler();
      setSeconds(15);
    }
  };

  const backspaceHandler = (e) => {
    if (e.keyCode == 13) {
      let payload = () => {
        if (IpData !== null && IpData?.countryExt === "91") {
          return {
            otp: otp.join("").toString(),
            sessionId: generateOtp?.sessionId,
            mobile: formDetails?.mobile,
            whatsappStatus: waChecked,
            countryCode: "91",
          };
        } else {
          return {
            otp: otp.join("").toString(),
            sessionId: generateOtp?.sessionId,
            email: formDetails?.email,
            whatsappStatus: waChecked,
          };
        }
      };

      const finalpayload = payload();

      otpValueAction(finalpayload.otp);
      verifyOtpAction(finalpayload);
    }
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
      return;
    }
    clearTimeout(backSpaceRef.current);
    backSpaceRef.current = setTimeout(() => {
      if (e.keyCode === 8 && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }, 0);
  };

  return (
    <div className="otp">
      <p className="otp_text">
        We’ve sent an OTP to your{" "}
        {IpData !== null && IpData?.countryISO !== "91"
          ? "Email"
          : pageData?.type == "whatsappGroup" ||
            pageData?.type == "whatsappCommunity"
          ? "Whatsapp"
          : "mobile number"}
      </p>

      {/* IF NO DATA COMES FROM API */}
      {IpData === null && (
        <div className="otp_number">
          <p className="otp_number-code">+{IpData?.countryISO}</p>
          <div className="otp_number-input">{formDetails.mobile}</div>
          <button
            className="otp_number-button"
            onClick={() => {
              WAChecked(true);
              setPurchaseScreen("userDetails");
            }}
          >
            Edit
          </button>
        </div>
      )}

      {/* IF DATA COMES FROM API,CHECK FOR INDIAN/INTERNATIONAL USER */}
      {IpData !== null && IpData?.countryISO === "91" ? (
        <div className="otp_number">
          <p className="otp_number-code">+{IpData?.countryISO}</p>
          <div className="otp_number-input">{formDetails.mobile}</div>
          <button
            className="otp_number-button"
            onClick={() => {
              WAChecked(true);
              setPurchaseScreen("userDetails");
            }}
          >
            Edit
          </button>
        </div>
      ) : (
        <div className="otp_number">
          <div className="otp_number-input">{formDetails?.email}</div>
          <button
            className="otp_number-button"
            onClick={() => {
              WAChecked(true);
              setPurchaseScreen("userDetails");
            }}
          >
            Edit
          </button>
        </div>
      )}
      <p className="otp_content">Enter OTP here</p>
      <div className="otp_field">
        {otp.map((data, index) => {
          return (
            <OtpInput
              additionalClasses="otp_inputStyle"
              type="number"
              id="otp2"
              key={index}
              value={data}
              handleChange={(e) => otpHandler(e, index)}
              onKeyDown={(e) => backspaceHandler(e, index)}
              refEl={index === 0 ? firstOtpElRef : null}
              onFocus={(e) => e.target.select()}
              onWheel={(event) => event.currentTarget.blur()}
            />
          );
        })}
      </div>
      {seconds ? (
        <div className="otp_waiting">
          Resend in &nbsp;
          <Timer
            minutes={minutes}
            seconds={seconds}
            setMinutes={setMinutes}
            setSeconds={setSeconds}
          />{" "}
          &nbsp;seconds
        </div>
      ) : (
        <p className="otp_waiting" onClick={resendOtpHandler}>
          Send OTP again
        </p>
      )}

      {/* NOTE FOR OTP */}

      {(IpData === null || IpData?.countryISO === "91") && (
        <>
          {pageData.type != "whatsappGroup" && (
            <div className="otp_numberWarningContainer">
              <div className="otp_numberWarningHeading">Please Note</div>
              <div className="otp_numberWarning">
                If you do not receive the OTP on your registered mobile number,
                check the following
              </div>
              <div className="otp_numberWarning">
                1. You have good network connectivity
              </div>
              <div className="otp_numberWarning">
                2. You have not activated DND(Do Not Disturb) with your mobile
                operator for the number you are registering with us. If yes,
                please disable your DND.
              </div>
              <div className="otp_numberWarning">
                3. Even after following the above two points, if you don't get
                an OTP try registering from a different mobile number.
              </div>
            </div>
          )}
        </>
      )}

      <div className="userDetails_footer">
        <div className="userDetails_footer-container">
          <img src={lock} alt="" className="userDetails_footer-img" />
          <p className="userDetails_footer-text">
            Secure payment with Razorpay
          </p>
        </div>
        <div className="userDetails_footer-button">
          <Button
            onClick={() => {
              let payload = () => {
                if (IpData?.countryISO === "91") {
                  return {
                    otp: otp.join("").toString(),
                    sessionId: generateOtp?.sessionId,
                    mobile: formDetails?.mobile,
                    whatsappStatus: waChecked,
                    countryCode: "91",
                  };
                } else if (IpData?.countryISO !== "91") {
                  return {
                    otp: otp.join("").toString(),
                    sessionId: generateOtp?.sessionId,
                    whatsappStatus: waChecked,
                    email: formDetails?.email,
                    // countryCode: IpData?.countryISO,
                  };
                } else {
                  return {
                    otp: otp.join("").toString(),
                    sessionId: generateOtp?.sessionId,
                    mobile: formDetails?.mobile,
                    whatsappStatus: waChecked,
                    countryCode: "91",
                  };
                }
              };
              console.log("PAYLOAD IS", payload());
              const finalPayload = payload();
              otpValueAction(finalPayload.otp);
              verifyOtpAction(finalPayload);
              // setPurchaseScreen("register");
            }}
            disabled={disabled || verifyOtpLoading}
          >
            {verifyOtpLoading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
            ) : (
              "Verify"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formDetails: state?.purchaseReducer?.formDetails,
    generateOtp: state?.purchaseReducer?.generateOtp,
    verifyOtp: state?.purchaseReducer?.verifyOtp,
    verifyOtpLoading: state?.purchaseReducer?.verifyOtpLoading,
    waChecked: state?.purchaseReducer?.waChecked,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setPurchaseScreen: (payload) =>
      dispatch(actions.setPurchaseScreen(payload)),
    setFormDetails: (payload) => dispatch(actions.setFormDetails(payload)),
    verifyOtpAction: (payload) => dispatch(actions.verifyOtpAction(payload)),
    generateOtpAction: (payload) =>
      dispatch(actions.generateOtpAction(payload)),
    otpValueAction: (payload) => dispatch(actions.otpValueAction(payload)),
    getOrderStatus: (payload) => dispatch(actions.getOrderStatus(payload)),
    WAChecked: (payload) => dispatch(actions.WAChecked(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(Otp);
