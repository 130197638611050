import { put, call, select, takeLatest, take } from "redux-saga/effects";
import CONSTANTS from "./constants";
import { submitLeadCaptureForm } from "./services";

function* submitLeadCaptureSaga(data) {
  yield put({
    type: CONSTANTS.LEAD_LOADER,
  });
  console.log("leadData", data);
  const apiResp = yield call(submitLeadCaptureForm, data.payload);
  console.log("apiRespLead", apiResp.status);
  if (apiResp.status == "200") {
    yield put({
      type: CONSTANTS.LEAD_SUCCESS,
      payload: true,
    });
  } else {
    yield put({
      type: CONSTANTS.LEAD_FAILURE,
      payload: true,
    });
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.SUBMIT_LEAD_DATA, submitLeadCaptureSaga);
}
