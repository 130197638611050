import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Input from "../../common/Input";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import Button from "../../common/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { addBankAccountDetailsPostAction } from "./store/actions";
import { createGroup } from "../../store/CreateGroup/actions";
import { createMessage } from "../../store/CreateMessage/actions";
import { layoutPageHeadingSet } from "../../layout/store/actions";
import ReactLoading from "react-loading";
import "./styles.scss";
import useLayoutPageBackNavigation from "../../customHooks/useLayoutPageBackNavigation";
import ReactGA from "react-ga";
import EVENTS from "../../utils/gaEvents";
import verifyTick from "../../assets/multibank/verifyTick.png";
import information from "../../assets/multibank/information.png";
import { useEffect } from "react";
import * as actions from "../../routes/bankDetails/store/actions";
import Dropdown from "../../components/Dropdown";
const BankDetails = (props) => {
  // REDUX DISPATCH
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useLayoutPageBackNavigation("/group");
  const { state } = useLocation();
  const [formEditable, setFormEditable] = React.useState(true);
  const [accountIcon, setAccountIcon] = React.useState(null);
  const [accountNumber, setAccountNumber] = React.useState(null);
  const [cacheAccount, setCacheAccount] = React.useState(null);
  const [confirmAccountNumber, setConfirmAccountNumber] = React.useState(null);
  const [allFieldsFilled, setFieldsFilled] = React.useState(true);
  const [ifscerror, setIfscError] = React.useState(true);
  const [disabledEmail, setDisabledEmail] = React.useState(
    state?.email != null ? true : false
  );
  const [errors, setErrors] = React.useState({
    nameError: true,
    accountError: true,
    ifscError: true,
    confirmAccountError: null,
    emailId: true,
  });
  const [bankAccountDetails, setBankAccountDetails] = React.useState({
    beneficiaryName: "",
    accountNumber: "",
    ifscCode: "",
    emailId: state?.email != null ? state?.email : "",
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [relationShip, setRelationShip] = React.useState(null);
  const relationShipOptions = [
    {
      id: 1,
      label: "Self",
      value: "Self",
    },
    {
      id: 2,
      label: "Mother",
      value: "Mother",
    },
    {
      id: 3,
      label: "Father",
      value: "Father",
    },
    {
      id: 4,
      label: "Brother",
      value: "Brother",
    },
    {
      id: 5,
      label: "Sister",
      value: "Sister",
    },
    {
      id: 6,
      label: "Spouse",
      value: "Spouse",
    },
  ];
  // BANK DETAILS FORM STRUCTURE

  const backDetailsFormStructure = [
    {
      id: "beneficiaryName",
      label: "Beneficiary Name*",
      placeholder: "Beneficiary Name",
      type: "text",
    },

    {
      id: "accountNumber",
      label: "Account Number*",
      placeholder: "Account Number",
      type: "text",
      pattern: "[0-9]*",
      // inputMode: "numeric",
    },
    {
      id: "confirmAccountNumber",
      label: "Confirm Account Number*",
      placeholder: "Account Number",
      type: "text",
      pattern: "[0-9]*",
    },
    {
      id: "ifscCode",
      label: "IFSC Code*",
      placeholder: "IFSC Code",
      type: "text",
    },
    { id: "relationShipDropDown" },
    {
      id: "emailId",
      label: "Email ID*",
      placeholder: "john@gmail.com",
      pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
      type: "email",
    },
  ];
  const checkAllFields = (bankAccountDetails) => {
    // console.log("sdgsgs", bankAccountDetails);
    const { beneficiaryName, ifscCode, emailId, accountNumber } =
      bankAccountDetails;
    const { confirmAccountError, ifscError, nameError, accountError } = errors;
    // console.log(
    //   "confirmAccountError:",
    //   confirmAccountError,
    //   "ifscError:",
    //   ifscError,
    //   "nameError:",
    //   nameError,
    //   "accountError:",
    //   accountError,
    //   "name:",
    //   beneficiaryName?.length != 0,
    //   "ifsc:",
    //   ifscCode?.length != 0,
    //   "email:",
    //   emailId?.length != 0,
    //   "account:",
    //   accountNumber?.length != 0
    // );
    // console.log("relationShip",relationShip);

    if (
      beneficiaryName?.length != 0 &&
      ifscCode?.length != 0 &&
      emailId?.length != 0 &&
      accountNumber?.length != 0 &&
      !confirmAccountError &&
      !ifscError &&
      !nameError &&
      !accountError &&
      relationShip != null
    ) {
      setFieldsFilled(false);
    } else {
      setFieldsFilled(true);
    }
  };

  const handleInputChange = (id, value) => {
    const beneficiaryNameRegex = new RegExp(/^[a-zA-Z ]*$/);
    const IFSCRegex = new RegExp(/^[a-zA-Z0-9]*$/);
    const accountRegex = new RegExp(/^\\d{8,18}$/);
    if (id === "beneficiaryName") {
      if (!beneficiaryNameRegex.test(value)) return;
    }
    if (id === "ifscCode") {
      if (!IFSCRegex.test(value)) return;
      if (value?.length > 11) return;
    }
    // console.log("detailsBefore", bankAccountDetails, errors.ifscError);

    const details = { ...bankAccountDetails };
    if (id == "confirmAccountNumber") {
      setConfirmAccountNumber(value);
    } else {
      details[id] = value;
    }
    // console.log("detailsAfter", details, errors.ifscError);

    setBankAccountDetails(details);
  };

  // REDUX SELECTORS
  // const userBankDetails = useSelector(
  //   (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.bankDetails
  // );
  const isSubmittingDetails = useSelector(
    (state) => state?.bankDetailsReducer?.loadingStateADD_BANK_DETAILS_POST
  );
  const createGroupData = useSelector((state) => state?.createGroup);
  const createMessageData = useSelector((state) => state?.createMessage);

  React.useEffect(() => {
    ReactGA.event({
      category: EVENTS.HOME.CATEGORY,
      action: EVENTS.HOME.ACTIONS.OPEN_BANK_DETAILS,
    });
    dispatch(
      layoutPageHeadingSet(formEditable ? "Add Bank Details" : "Bank Details")
    );
  }, []);

  // React.useEffect(() => {
  //   if (userBankDetails?.accountNumber) {
  //     setFormEditable(false);
  //     setBankAccountDetails(userBankDetails);
  //   }
  // }, [userBankDetails?.accountNumber]);

  const validateFields = (id, value) => {
    // console.log("validate", value?.length != 11);
    switch (id) {
      case "beneficiaryName":
        return setErrors({
          ...errors,
          nameError: !value?.length,
        });
      case "accountNumber":
        return setErrors({
          ...errors,
          accountError: !value?.length,
        });
      case "ifscCode":
        return setErrors({
          ...errors,
          ifscError: value?.length != 11,
        });
      case "confirmAccountNumber":
        return setErrors({
          ...errors,
          confirmAccountError: value != accountNumber,
        });
      case "emailId":
        return setErrors({
          ...errors,
          emailId: value.length == 0,
        });
    }
  };

  useEffect(() => {
    if (errors?.confirmAccountError != null && !errors?.confirmAccountError) {
      setAccountIcon(true);
      checkAllFields(bankAccountDetails);
    } else if (errors?.confirmAccountError) {
      setAccountIcon(false);
      checkAllFields(bankAccountDetails);
    }
  }, [errors.confirmAccountError, accountNumber]);
  useEffect(() => {
    //  console.log("account:",bankAccountDetails["accountNumber"]);
    checkAllFields(bankAccountDetails);
  }, [bankAccountDetails, relationShip]);
  useEffect(() => {
    // console.log("accountNumber",accountNumber);
    if (confirmAccountNumber && confirmAccountNumber?.length > 0) {
      if (accountNumber !== confirmAccountNumber) {
        setErrors({ ...errors, confirmAccountError: true });
      } else {
        setErrors({ ...errors, confirmAccountError: false });
      }
    }
  }, [accountNumber]);
  const handleSubmitClicked = () => {
    if (errors.ifscError) return toast.error("Invalid Ifsc Code");
    setIsSubmitting(true);
    let updatedPayload = { ...bankAccountDetails };
    updatedPayload.accountNumber = confirmAccountNumber;
    updatedPayload.relationshipWithUser = relationShip.label;
    dispatch(
      addBankAccountDetailsPostAction({
        ...updatedPayload,
        onSuccess: () => {
          if (window?.payload?.name || window?.payload?.title) {
            if (window?.payload?.name) {
              toast.loading("Creating Group");
              dispatch(createGroup({ ...window.payload, navigate }));
            } else {
              toast.loading("Creating Message");
              dispatch(createMessage({ ...window.payload, navigate }));
            }
          }
          window.payload = {};
          toast.success("Bank Details Added Successfully");
          setIsSubmitting(false);
          props.getBankAccountsAction();
          if (
            (disabledEmail !== null && !disabledEmail) ||
            state?.cameFromBankAccounts
          ) {
            navigate(-1);
          }
        },
        onFailure: () => {
          setIsSubmitting(false);
        },
      })
    );
  };

  const handleContinueClicked = () => {
    dispatch(
      addBankAccountDetailsPostAction({
        ...bankAccountDetails,
        onSuccess: () => {
          navigate(`/group/create`);
        },
      })
    );
  };
  const handleBlur = (value, id) => {
    if (id == "accountNumber") {
      setBankAccountDetails({
        ...bankAccountDetails,
        accountNumber: bankAccountDetails["accountNumber"]?.replace(/./g, "*"),
      });
    } else if (id == "ifscCode") {
      if (value?.length != 11) {
        toast.error("Please enter valid IFSC code");
      }
    }
  };
  const handlePreviousValue = () => {
    setBankAccountDetails({
      ...bankAccountDetails,
      accountNumber: accountNumber,
    });
  };
  // const hasGroupToCreate = useSelector((state) => state?.createGroup?.title);

  const { nameError, accountError, ifscError, confirmAccountError, emailId } =
    errors;
  return (
    <div className="bankDetails">
      <div className="bankDetails__form">
        {backDetailsFormStructure?.map?.((input) => {
          if (input?.id == "relationShipDropDown") {
            return (
              <div style={{ marginTop: "15px" }}>
                <div className="label">Relationship with Beneficiary*</div>
                <Dropdown
                  optionsList={relationShipOptions}
                  selectedOption={relationShip}
                  itemClickHandler={(item) => setRelationShip(item)}
                  showNewDropdown={true}
                />
              </div>
            );
          }
          return (
            <div>
              <Input
                key={input?.id}
                label={input?.label}
                placeholder={input?.placeholder}
                pattern={input?.pattern}
                type={input?.type}
                onFocus={
                  input?.id == "accountNumber" ? handlePreviousValue : null
                }
                value={bankAccountDetails[input?.id]}
                onChange={(e) => {
                  validateFields(input?.id, e?.target?.value);
                  if (input?.id === "accountNumber") {
                    setAccountNumber(e?.target?.value);
                  }
                  if (input?.id === "ifscCode")
                    handleInputChange(
                      input?.id,
                      e?.target?.value?.toUpperCase()
                    );
                  else handleInputChange(input?.id, e?.target?.value);
                }}
                disabled={
                  input?.id == "emailId" && disabledEmail ? true : false
                }
                // accountIcon={input?.id==="confirmAccountNumber"?accountIcon:null}
                accountStatus={
                  input?.id === "confirmAccountNumber" ? accountIcon : null
                }
                onBlur={(e) => handleBlur(e?.target?.value, input?.id)}
              />
              {!disabledEmail && input?.id == "emailId" && (
                <div className="emailText">*Enter a new email ID</div>
              )}
            </div>
          );
        })}
      </div>
      {/* {console.log("diabledCheck",isSubmitting,nameError,accountError,confirmAccountError,ifscError,emailId)} */}
      <div className="bankDetails__footer">
        <Button
          onClick={handleSubmitClicked}
          disabled={allFieldsFilled || isSubmitting}
        >
          {isSubmitting ? (
            <>
              <ReactLoading
                type={"spinningBubbles"}
                color={"white"}
                height={20}
                width={20}
              />
              &nbsp;&nbsp;Adding Bank Details
            </>
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
};
const mapDispatchWithProps = (dispatch) => {
  return {
    getBankAccountsAction: (payload) =>
      dispatch(actions.getBankAccountsAction(payload)),
  };
};

export default connect(null, mapDispatchWithProps)(BankDetails);
