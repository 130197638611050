import { call, put, takeLatest } from "redux-saga/effects";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";

function* clearEarningsDataSaga(payload) {
  const loaderAction = yield call(
    getActionObject,
    CONSTANTS.MY_EARNINGS_GET_LOADER
  );
  // yield put({ ...loaderAction });
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.MY_EARNINGS_GET_CLEAR, clearEarningsDataSaga);
}
