import CONSTANTS from "./constants";

export const getAutomatedChannelAction = (payload) => {
  console.log("action: ", payload);
  return {
    type: CONSTANTS.GET_AUTOMATED_CHANNEL,
    payload,
  };
};

export const getTelegramChanelId = (payload) => {
  return {
    type: CONSTANTS.SET_TELEGRAM_ID,
    payload,
  };
};

export const clearTelegramChannelIdAction = (payload) => {
  return {
    type: CONSTANTS.CLEAR_TELEGRAM_ID,
    payload,
  };
};
