import React from "react";
import styles from "./styles.module.scss";

const ContentShimmmer = () => {
  return (
    <div className={`${styles.contentShimmer}`}>
      <div className={`${styles.contentShimmer__listItem}`}>
        <div
          className={`${styles.contentShimmer__listItem__thumbnail} shine`}
        ></div>
        <div className={`${styles.contentShimmer__listItem__content}`}>
          <div
            className={`${styles.contentShimmer__listItem__content__p} shine`}
          ></div>
          <div
            className={`${styles.contentShimmer__listItem__content__p} shine`}
          ></div>
        </div>
      </div>
      <div className={`${styles.contentShimmer__listItem}`}>
        <div
          className={`${styles.contentShimmer__listItem__thumbnail} shine`}
        ></div>
        <div className={`${styles.contentShimmer__listItem__content}`}>
          <div
            className={`${styles.contentShimmer__listItem__content__p} shine`}
          ></div>
          <div
            className={`${styles.contentShimmer__listItem__content__p} shine`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ContentShimmmer;
