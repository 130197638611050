import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/successPage.scss";
import success from "../../../assets/purchase/success.svg";
import joinChannel from "../../../assets/joinChannel.png";
import { useSelector } from "react-redux";
// import Button from "../../../common/Button";
// import Bottomsheet from "../../../common/Bottomsheet";
// import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
// import { COMMON_DEEPLINK_ACTIONS } from "../../../utils/constants";
// import toast from "react-hot-toast";

function SuccessPage(props) {
  let grpName = localStorage.getItem("groupName");
  const purchasedData = useSelector(
    (state) => state?.purchaseReducer?.orderStatus
  );
  const handleThanksSheetClick = (url) => {
    if (url && url?.length) window.open(url, "_blank");
  };
  let paymentSuccessData = window?.paymentSuccessData;

  return (
    <div className="success">
      <img src={success} alt="" />
      <p className="success_title">
        <b>Success!</b>
      </p>
      <p className="success_text">
        {`Congratulations your payment is successful and your subscription for
        ${
          purchasedData?.type === "whatsappGroup"
            ? "Group"
            : purchasedData?.type === "whatsappCommunity"
            ? "Community"
            : "channel"
        } ${grpName} has been extended successfully.`}
      </p>
      {purchasedData?.inviteLink == null &&
        paymentSuccessData?.popupMessage?.inviteLink && (
          <div
            onClick={() =>
              handleThanksSheetClick(
                paymentSuccessData?.popupMessage?.inviteLink
              )
            }
            className="success_CTAContainer"
          >
            <img className="success_CTAIcon" src={joinChannel} />
            <div className="success_joinText">Click here to Join Channel</div>
          </div>
        )}
    </div>
  );
}

export default SuccessPage;
