const namespace = "EARNING_DASHBOARD";
const channelNameSpace = "CHANNEL_EARNING";

const CONSTANTS = {
  MY_EARNINGS_GET: `${namespace}_GET`,
  MY_EARNINGS_GET_LOADER: `${namespace}_GET_LOADER`,
  MY_EARNINGS_SUCCESS: `${namespace}_GET_SUCCESS`,
  MY_EARNINGS_GET_FAILURE: `${namespace}_GET_FAILURE`,
  MY_EARNINGS_GET_CLEAR: `${namespace}_GET_CLEAR`,

  CHANNEL_EARNINGS_GET: `${channelNameSpace}_GET`,
  CHANNEL_EARNINGS_GET_LOADER: `${channelNameSpace}_GET_LOADER`,
  CHANNEL_EARNINGS_SUCCESS: `${channelNameSpace}_GET_SUCCESS`,
  CHANNEL_EARNINGS_GET_FAILURE: `${channelNameSpace}_GET_FAILURE`,
  CHANNEL_EARNINGS_GET_CLEAR: `${channelNameSpace}_GET_CLEAR`,

  SET_VERSION_2: `${namespace}_SET_VERSION_2`,
};

export default CONSTANTS;
