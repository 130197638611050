const EVENTS = {
  HOME: {
    CATEGORY: "HOME",
    ACTIONS: {
      OPEN: "Open_Homescreen",
      LOCKED_MESSAGE_TAB_CLICK: "Click_LockedMsg_Tab",
      TELERGAM_GROUP_TAB_CLICK: "Click_TelegramGrp_Tab",
      LOCKED_MESSAGE_CREATE_CLICK: "Click_LockMssg_Create",
      TELEGRAM_GROUP_CREATE_CLICK: "Click_TelegramGrp_Create",
      MORE_OPTIONS_CLICK: "Click_Homescreen_MoreOption",
      MORE_BANK_DETAILS_CLICKED: "Click_MoreOption_Bank",
      MORE_EARNINGS_CLICKED: "Click_MoreOption_Earning",
      MORE_SIGNOUT_CLICK: "Click_MoreOption_Signout",
      OPEN_EARNINGS: "Open_Earnings",
      OPEN_BANK_DETAILS: "Open_BankDetails",
    },
  },
  CREATE_GROUP: {
    CATEGORY: "CREATE_TELEGRAM_GROUP",
    ACTIONS: {
      OPEN_GROUP_CREATE: "Open_GroupCreation",
      NEXT_CLICK: "Click_GroupCreation_Next",
      OPEN_BANK_INFO_GROUP_CREATE: "Open_BankInfo_GrpCreation",
      SUBMIT_BANK_INFO_GROUP_CREATE: "Click_BankInfo_Submit",
    },
  },
  CREATE_MESSAGE: {
    CATEGORY: "CREATE_LOCKED_MESSAGE",
    ACTIONS: {
      OPEN_LOCKED_MESSAGE_CREATE: "Open_LockMssg_Page",
      ADD_CONTENT_CLICK: "Click_LockedMssg_Add",
      CREATE_LOCKED_MESSAGE_CLICK: "Click_LockedMssg_Create",
    },
  },
};

export default EVENTS;
