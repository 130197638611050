import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/failurePopup.scss";
import failure from "../../../assets/purchase/failure.svg";
import Button from "../../../common/Button";
import * as actions from "../../../store/Purchase/actions";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
function FailurePopup(props) {
  let grpName = localStorage.getItem("groupName");
  let { createOrder } = props;
  const pageData = useSelector(
    (state) => state?.purchaseReducer?.pageData?.data
  );
  const groupData = useSelector(
    (state) => state?.purchaseReducer?.groupData?.data?.group
  );
  const [type, setType] = useState("");
  const [whatsApp, setWhatsApp] = useState(false);
  useEffect(() => {
    if (
      pageData &&
      (pageData?.type == "whatsappGroup" ||
        pageData?.type == "whatsappCommunity")
    ) {
      setType(
        pageData?.type === "whatsappGroup"
          ? "Whatsapp Group"
          : "Whatsapp Community"
      );
      setWhatsApp(true);
    }
    if (
      groupData &&
      (groupData?.type == "whatsappGroup" ||
        groupData?.type == "whatsappCommunity")
    ) {
      setType(
        groupData?.type === "whatsappGroup"
          ? "Whatsapp Group"
          : "Whatsapp Community"
      );
      setWhatsApp(true);
    }
  }, [pageData, groupData]);

  return (
    <div className="failure">
      <img src={failure} alt="" />
      <p className="failure_title">Payment failed!</p>
      {window?.payload?.orderId && (
        <div className="failure_orderid">
          {`Order Id: ${window?.payload?.orderId}`}
        </div>
      )}
      <p className="failure_text">
        {`Sorry, your payment for this ${
          whatsApp ? type : "channel"
        } could not be completed at this
        moment. Please try again.`}
      </p>
      <p className="failure_text">
        If you have any query contact us on: support@fankonnect.com
      </p>
      <div className="Details_ButtonContainer">
        <Button
          onClick={() => {
            // handleRazorPay();
            createOrder();
          }}
        >
          Retry Payment
        </Button>
      </div>
    </div>
  );
}

const mapDispatchWithProps = (dispatch) => {
  return {
    createOrder: (payload) => dispatch(actions.createOrder(payload)),
  };
};
export default connect(null, mapDispatchWithProps)(FailurePopup);
