import React, { useEffect } from "react";
import "./styles.scss";
import Bottomsheet from "../../../../common/Bottomsheet";
// assets
import loadingImg from "../../../../assets/v2Automation/loadingImg.png";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
const WhatsappGroupConfirmation = (props) => {
  const {
    isPhoneVisible = false,
    actionOnBlock = () => {},
    groupNotCreated = false,
    navigateToGroupDetail = () => {},
    data,
  } = props;
  const [apiFail, setApiFail] = useState(false);
  const channelType = useSelector((state) => state?.user?.channelType);
  useEffect(() => {
    if (groupNotCreated) {
      setApiFail(true);
    }
  }, [groupNotCreated]);
  console.log("apifailsafd", apiFail);
  // const platforms = [{name:,imageUrl}]
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const whatsappFlow = () => {
    navigate("/platformAutomation");
  };
  return (
    <Bottomsheet
      showModal={isPhoneVisible}
      hideCross={true}
      onblockBackdrop={() => actionOnBlock()}
    >
      <div className="WhatsappConfirmation">
        <div className="WhatsappConfirmation__tapBlock">
          <div className="WhatsappConfirmation__tapBlock_tap" />
        </div>
        <div className="content">
          <div className="content_loadingImg">
            <img src={loadingImg} style={{ width: "100%", height: "100%" }} />
          </div>
          <div className="content_desc">
            {`We’re creating your paid Whatsapp 
            ${channelType == "whatsappGroup" ? "group" : "community"} shortly!`}
          </div>
          {apiFail && (
            <div className="content_apiFail">
              <div className="WhatsappConfirmation_head">In the meanwhile,</div>
              <div className="content_apiFail_knot1" />
              {/* points */}
              <div className="content_apiFail_points">
                1. Open <b>“Settings”</b> in Whatsapp
              </div>
              <div className="content_apiFail_points">
                2. In Account settings, go to <b>“Privacy”</b>
              </div>
              <div className="content_apiFail_points">
                3. In Groups settings, choose <b>“Everyone”</b> under{" "}
                <b>“who can add me to group”</b>
              </div>
              {/* cta */}
              <div
                onClick={() => navigateToGroupDetail()}
                className="whatsappctaBlock1"
              >
                <div className="whatsappctaBlock1_confirmCTA1">OKAY</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Bottomsheet>
  );
};

export default WhatsappGroupConfirmation;
