import axios from "axios";
import { BASE_URL } from "../../axios-config";
import { getUserToken } from "../../utils/getAxiosConfig";
// const token =
//   "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NTEsIm5hbWUiOiJLZXNodWIiLCJlbWFpbCI6ImFiY0BnbWFpbC5jb20iLCJtb2JpbGUiOiI5MTk2NTQwNjMwMDgiLCJpYXQiOjE2NTI1NDc2NzksImV4cCI6MTY1NDI3NTY3OX0.sRdzQTcyVs11jFYxc8Ayym_M4sdgTMNWfT6X8_Hba78TrBVwekYFUrPo5GOaEzS6";
// "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IlByYXRlZWsgSmFpc3dhbCIsImVtYWlsIjoiIiwibW9iaWxlIjoiOTE4MDkwOTg4OTgwIiwiaWF0IjoxNjUyNDgwNTQyLCJleHAiOjE2NTQyMDg1NDJ9.F4I1yLfZLjEq-l_-qNAEOACM7UsEd7mfaLeWYgoroGllLTSJxqrLPon6RRWdHIFS";

export const getGroupDetailsService = (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/group/${payload?.payload?.id}?v2Enabled=${payload.v2Enabled}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMessageDetailsService = (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/message/${payload?.payload?.id}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getStatementService = (payload) => {
  // console.log(
  //   "getStatementServicePayload",
  //   payload?.payload?.type,
  //   payload?.payload?.id,
  //   payload?.payload?.offset
  // );
  return axios({
    method: "GET",
    url: `${BASE_URL}/${payload?.payload?.type}/${payload?.payload?.id}/members?limit=${payload?.payload?.limit}&offset=${payload?.payload?.offset}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      // console.log("stateServiceResponse", response?.data?.data);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAllCouponsServices = (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/coupons?groupId=${payload}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      // console.log("stateServiceResponse", response?.data?.data);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
