import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import plusIcon from "../../assets/plusIcon.svg";
import hitHapticFeedback from "../../utils/hitHapticFeedback";
import ReactGA from "react-ga";
import EVENTS from "../../utils/gaEvents";
import { useDispatch } from "react-redux";
import { callPlatformBottomSheet } from "../../store/Home/actions";

const LayoutContent = React.forwardRef(
  ({ height, onScroll, children }, ref) => {
    // REACT ROUTER HOOKS
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const logEvent = (tab) => {
      switch (tab) {
        case "message":
          ReactGA.event({
            category: EVENTS.HOME.CATEGORY,
            action: EVENTS.HOME.ACTIONS.LOCKED_MESSAGE_CREATE_CLICK,
          });
          break;
        case "group":
          ReactGA.event({
            category: EVENTS.HOME.CATEGORY,
            action: EVENTS.HOME.ACTIONS.TELEGRAM_GROUP_CREATE_CLICK,
          });
          break;
        default:
          return;
      }
    };

    const handleAddButtonClicked = () => {
      hitHapticFeedback();
      const tab = params?.tabEnum;
      logEvent(tab);
      dispatch(callPlatformBottomSheet(true));
    };

    return (
      <div
        className="layout__content"
        style={{
          height: height,
          overflowY: "scroll",
        }}
        ref={ref}
        onScroll={onScroll}
        id="layoutContent"
      >
        {children}
        {params?.tabEnum && (
          <div
            className="layout__content__addButton"
            onClick={handleAddButtonClicked}
          >
            <img src={plusIcon} alt="plusIcon" width="24px" height="24px" />
          </div>
        )}
      </div>
    );
  }
);

export default LayoutContent;
