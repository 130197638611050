import React, { useEffect } from "react";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
import "./styles/linkExistingChannel.scss";
// assets
import externalLink from "../../../assets/Automation/externalLink.png";
import linkLogo from "../../../assets/Automation/linkLogo.png";
import videoLogo from "../../../assets/Automation/videoLogo.png";
import { useSelector } from "react-redux";
import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
import toast from "react-hot-toast";
import { useState } from "react";

const TelegramLinkExisting = (props) => {
  useLayoutPageHeading("Link Existing Channel");
  const [disabled, setDisabled] = useState(true);
  const botLink = useSelector((state) => state?.createGroup?.botLink);
  useEffect(() => {
    if (botLink) {
      setDisabled(false);
    }
  }, [botLink]);
  const openBotTelegram = () => {
    if (botLink) {
      hitDeeplinkExecutedV2(
        JSON.stringify({
          screen: "UTIL_BROWSER",
          paramOne: botLink,
        }),
        JSON.stringify({
          screen: "UTIL_BROWSER",
          paramOne: botLink,
        })
      );
    } else toast.error("Invalid bot link");
  };
  return (
    <div className="LinkExisting">
      <div className="LinkExisting_block">
        {/* logo */}
        <div className="LinkExisting_linkLogo">
          <div className="LinkExisting_linkLogo_img">
            <img src={linkLogo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        {/* content */}
        <div className="LinkExisting_content">
          <div className="LinkExisting_content_steps">Steps</div>
          <div className="LinkExisting_content_points">
            <span className="LinkExisting_content_bold">1. </span>Click on{" "}
            <span className="LinkExisting_content_bold">
              “Open bot in Telegram”
            </span>{" "}
            button below.
          </div>
          <div className="LinkExisting_content_points">
            <span className="LinkExisting_content_bold">2. </span>
            Send <span className="LinkExisting_content_bold">/start</span> in
            the chat.
          </div>
          <div className="LinkExisting_content_points">
            <span className="LinkExisting_content_bold">3. </span> From
            fankonnect_bot description page, click{" "}
            <span className="LinkExisting_content_bold">
              “Add to Group or Channel”
            </span>
            .
          </div>
          <div className="LinkExisting_content_points">
            <span className="LinkExisting_content_bold">4. </span> Go back to
            fankonnect_bot chats and click on{" "}
            <span className="LinkExisting_content_bold">
              “Open Fankonnect app”
            </span>
            .
          </div>
        </div>
        {/* CTA */}

        <div
          onClick={disabled ? null : openBotTelegram}
          className={
            disabled ? `LinkExisting_CTA_disabled` : `LinkExisting_CTA`
          }
        >
          <div className="LinkExisting_CTA_logoText">
            <div className="LinkExisting_CTA_text">Open bot in Telegram</div>
            <div className="LinkExisting_CTA_img">
              <img
                src={externalLink}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
        {/* breakline */}
        {/* <div style={{ border: "1px solid #E4E6E8", marginTop: "24px" }} /> */}
        {/* video block */}
        {/* <div className="LinkExisting_videoBlock">
          <div className="LinkExisting_videoBlock_columnBlock">
            <div className="LinkExisting_videoBlock_text">Need help ?</div>
            <div className="LinkExisting_videoBlock_text">
              Watch this video to know more
            </div>
          </div>
          <div className="LinkExisting_videoBlock_videoLogo">
            <img src={videoLogo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TelegramLinkExisting;
