import axios from "axios";
import { BASE_URL } from "../../axios-config";
import getAxiosConfig, { getUserToken } from "../../utils/getAxiosConfig";

export const createGroupService = (payload) => {
  console.log("check this: ", payload);
  return axios({
    ...getAxiosConfig(
      "POST",
      () =>
        payload?.type == "whatsappGroup" || payload?.type == "whatsappCommunity"
          ? `/group/whatsapp`
          : `/group`,
      payload
    ),
  });
};
// export const createWhatsAppGroupService = (payload) => {
//   console.log("check this: ", payload);
//   return axios({
//     ...getAxiosConfig("POST", () => `/group`, payload),
//   });
// };

export const editGroupService = (payload) => {
  return axios({
    ...getAxiosConfig(
      "PUT",
      () => `/group/${payload?.data?.payload?.id}`,
      payload?.data?.payload
    ),
  });
};
export const getBotLinkService = (payload) => {
  return axios({
    ...getAxiosConfig("GET", () => `/getAutomateBot`, {}, payload),
  });
};
// export const getBotLinkService = (payload) => {
//   console.log("tokenfs",payload);
//   return axios
//   .get(`${BASE_URL}/getAutomateBot`, {
//     headers: {
//       "x-access-token": payload,
//     },
//   })
//   .then((response) => {
//     return response;
//   })
//   .catch((err) => {
//     return err.response;
//   });
// }
