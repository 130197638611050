import { call, put, takeLatest } from "redux-saga/effects";
import getActionObject from "../../utils/getActionObject";
import CONSTANTS from "./constants";
import { getUserDetailsService } from "./services";
import { transformTabListEnums } from "./transformers";

function* getUserDetailsSaga(data) {
  const loaderAction = yield call(
    getActionObject,
    CONSTANTS.USER_DETAILS_GET_LOADER
  );
  yield put({ ...loaderAction });
  try {
    const apiCall = yield call(getUserDetailsService);
    const getAction = yield call(
      getActionObject,
      CONSTANTS.USER_DETAILS_GET_SUCCESS,
      transformTabListEnums(apiCall?.data?.data)
    );
    yield put({ ...getAction });
  } catch (err) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.USER_DETAILS_GET_FAILURE
    );
    yield put({ ...getAction });
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.USER_DETAILS_GET, getUserDetailsSaga);
}
