import axios from "axios";
import { BASE_URL } from "../../../axios-config";
import getUserToken from "../../../utils/getUserToken";

export const getOtpService = async (payload) => {
  console.log("service: ", payload);
  let groupId = "";
  if (payload.hasOwnProperty("groupId")) {
    groupId = payload.groupId;
    delete payload.groupId;
  }
  return axios({
    url: `${BASE_URL}/v1/otp/get?groupId=${groupId}`,
    method: "POST",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createCoAdminService = async (payload) => {
  return axios({
    url: `${BASE_URL}/createCoAdmin`,
    method: "POST",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAdminList = async (payload) => {
  return axios({
    url: `${BASE_URL}/getCoAdmins/${payload}`,
    method: "GET",
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
