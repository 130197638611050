import React from "react";
import hamMenuIcon from "../../assets/hamMenuIcon.svg";
import backIcon from "../../assets/backIcon.svg";
import editIcon from "../../assets/fi_edit.svg";
import Wallet from "../../assets/Wallet.png";
import { useDispatch, useSelector } from "react-redux";
import { layoutSideBarOpenAction } from "../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import hitHapticFeedback from "../../utils/hitHapticFeedback";
import ReactGA from "react-ga";
import EVENTS from "../../utils/gaEvents";
import { resetPricesPlans, resetState } from "../../store/CreateGroup/actions";
const LayoutHeader = React.forwardRef(({ marginTop }, ref) => {
  // temp props
  let whiteHeader = false;

  // REDUX DISPATCH
  const dispatch = useDispatch();

  // REACT ROUTER HOOKS
  const navigate = useNavigate();
  const params = useParams();

  // edit whatsapptype
  const whatsapp = useSelector((state) => state?.user?.editWhatsappType);

  // FUNCTION TO HANDLE HAM MENU ICON CLICKED
  const handleHamMenuClicked = () => {
    ReactGA.event({
      category: EVENTS.HOME.CATEGORY,
      action: EVENTS.HOME.ACTIONS.MORE_OPTIONS_CLICK,
    });
    dispatch(layoutSideBarOpenAction());
  };

  // FUNCTION TO HANDLE BACK ICON CLICKED
  const handleBackIconClicked = () => {
    hitHapticFeedback();
    navigate(pageBackRoute ? pageBackRoute : -1);
  };

  const handleEditClick = () => {
    if (window.location.href.includes("group")) {
      dispatch(resetPricesPlans());
      dispatch(resetState());
      if (whatsapp) {
        navigate(`/group/whatsapp/${params?.id}/edit`);
      } else {
        navigate(`/group/${params?.id}/edit`);
      }
    } else navigate(`/message/${params?.id}/edit`);
  };

  const handleEarningClick = () => {
    navigate(`/earnings`);
  };

  // REDUX SELECTORS
  const pageHeading = useSelector(
    (state) => state?.layoutReducer?.layoutPageheading
  );
  const pageBackRoute = useSelector(
    (state) => state?.layoutReducer?.layoutPageBackNavigation
  );

  const singleMessageDetails = useSelector(
    (state) => state?.singleContentDetails?.singleMessageDetails
  );

  const singleGroupDetails = useSelector(
    (state) => state?.singleContentDetails?.singleGroupDetails
  );

  const showEdit = () => {
    if (
      params?.id &&
      !window.location.href.includes("edit") &&
      !window.location.href.includes("statement") &&
      !window.location.href.includes("createCoupon") &&
      !window.location.href.includes("coupons") &&
      !window.location.href.includes("adminList") &&
      !window.location.href.includes("addNewAdmin") &&
      singleGroupDetails?.status != "pending"
    )
      return true;
    else return false;
  };

  const showEarning = () => {
    let arr = window.location.href.split("/");
    if (arr[arr.length - 1] == "group") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={`layout__header ${!whiteHeader && "layout__header--accent"}`}
      ref={ref}
      style={{ marginTop: `${marginTop}px` }}
    >
      <div className="layout__header__left">
        <div
          className="layout__header__left__back"
          onClick={
            params?.tabEnum ? handleHamMenuClicked : handleBackIconClicked
          }
        >
          <img
            src={params?.tabEnum ? hamMenuIcon : backIcon}
            alt="hamMenuIcon"
            height="24px"
            width="24px"
          />
        </div>
        <h1>{pageHeading || "fanKonnect"}</h1>
      </div>
      {showEdit() ? (
        <div className="layout__header__right" onClick={handleEditClick}>
          <img src={editIcon} height="16px" style={{ marginRight: "6px" }} />
          Edit
        </div>
      ) : showEarning() ? (
        <div className="layout__header__right" onClick={handleEarningClick}>
          <img
            src={Wallet}
            height="16px"
            style={{ marginRight: "6px", height: 22, width: 22 }}
          />
        </div>
      ) : null}
    </div>
  );
});

export default LayoutHeader;
