import React from "react";
import toast from "react-hot-toast";
import { hitDeeplinkExecutedV2 } from "../../utils/hitDeeplink";

function Badge(props) {
  const { status, inviteLink } = props;

  const badgeTypes = {
    pending: {
      label: "In Progress",
      color: "#FFFFFF",
      background: "rgba(255, 155, 14, 1)",
    },
    failed: {
      label: "Unsuccessful",
      color: "#FFFFFF",
      background: "rgba(250, 67, 54, 1)",
    },
    join: {
      background: "#32A22A",
      color: "#FFFFFF",
      label: "Channel Created",
    },
  };

  // const handleJoinClick = () => {
  //   if (inviteLink?.length) {
  //     hitDeeplinkExecutedV2(
  //       JSON.stringify({
  //         screen: "UTIL_BROWSER",
  //         paramOne: inviteLink,
  //       }),
  //       JSON.stringify({
  //         screen: "UTIL_BROWSER",
  //         paramOne: inviteLink,
  //       })
  //     );
  //   } else toast.error("Invalid invite link");
  // };

  return (
    <div
      style={{
        backgroundColor: badgeTypes[status]
          ? badgeTypes[status]?.background
          : "",
        color: badgeTypes[status] ? badgeTypes[status]?.color : "",
        padding: "4px 8px",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "18px",
        borderRadius: "4px",
        whiteSpace: "nowrap",
        width: "fit-content",
        margin: "5px 0",
      }}
      // onClick={handleJoinClick}
    >
      {badgeTypes[status] ? badgeTypes[status]?.label : ""}
    </div>
  );
}

export default Badge;
