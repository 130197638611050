import CONSTANTS from "./constants";

//REDUX
export const setPurchaseScreen = (payload) => {
  return {
    type: CONSTANTS.SET_PURCHASE_SCREEN,
    payload,
  };
};

export const setFormDetails = (payload) => {
  return {
    type: CONSTANTS.SET_FORM_DETAILS,
    payload,
  };
};
export const resetFormDetails = (payload) => {
  return {
    type: CONSTANTS.RESET_FORM_DETAILS,
    payload,
  };
};

export const generateOtpAction = (payload) => {
  return {
    type: CONSTANTS.GENERATE_OTP,
    payload,
  };
};

export const verifyOtpAction = (payload) => {
  return {
    type: CONSTANTS.VERIFY_OTP,
    payload,
  };
};
export const preAuthAction = (payload) => {
  return {
    type: CONSTANTS.PRE_AUTH,
    payload,
  };
};

export const handlePricingChange = (payload) => {
  return {
    type: CONSTANTS.PRICING_CHANGE,
    payload,
  };
};

export const getPageData = (payload) => {
  return {
    type: CONSTANTS.PAGE_DATA,
    payload,
  };
};
export const getGroupData = (payload) => {
  return {
    type: CONSTANTS.GROUP_DATA,
    payload,
  };
};

export const registerUserAction = (payload) => {
  return {
    type: CONSTANTS.REGISTER_USER,
    payload,
  };
};
export const updateEmailAddress = (payload) => {
  return {
    type: CONSTANTS.UPDATE_EMAIL,
    payload,
  };
};

export const otpValueAction = (payload) => {
  return {
    type: CONSTANTS.OTP_VALUE,
    payload,
  };
};

export const getOrderId = (payload) => {
  return {
    type: CONSTANTS.GET_ORDER_ID,
    payload,
  };
};

export const getOrderStatus = (payload) => {
  return {
    type: CONSTANTS.GET_ORDER_STATUS,
    payload,
  };
};

export const createOrder = (payload) => {
  return {
    type: CONSTANTS.CREATE_ORDER,
    payload,
  };
};

export const WAChecked = (payload) => {
  return {
    type: CONSTANTS.WA_CHECKED,
    payload,
  };
};
export const applyCouponApi = (payload) => {
  return {
    type: CONSTANTS.APPLY_COUPON,
    payload,
  };
};
export const setCouponCodevalue = (payload) => {
  return {
    type: CONSTANTS.COUPON_CODE_VALUE,
    payload,
  };
};
export const resetCoupon = (payload) => {
  return {
    type: CONSTANTS.RESET_APPLY_COUPON,
    payload,
  };
};
export const resetCouponError = (payload) => {
  return {
    type: CONSTANTS.RESET_COUPON_ERROR,
    payload,
  };
};
