import React, { useState } from "react";
import "../../../scss/Purchase/accordian.scss";
import dropDownOpen from "../../../assets/dropDownOpen.png";
import dropDownClose from "../../../assets/dropDownClose.png";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordian_item">
      <div className="accordian_title" onClick={() => setIsActive(!isActive)}>
        <div className="accordian_title_top">
          <div>{title}</div>
          <div>
            {isActive ? (
              <img src={dropDownOpen} alt={"dropDownOpen icon"} height="5px" />
            ) : (
              <img
                src={dropDownClose}
                alt={"dropDownClose icon"}
                height="5px"
              />
            )}
          </div>
        </div>

        {isActive && <div className="accordian_content">{content}</div>}
      </div>
    </div>
  );
};

export default Accordion;
