import axios from "axios";
import { BASE_URL } from "../../../../axios-config";
import getUserToken from "../../../../utils/getUserToken";

export const getChannelListService = async () => {
  return axios({
    url: `${BASE_URL}/group/all`,
    method: "GET",
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const generateReportService = async (payload) => {
  return axios({
    url: `${BASE_URL}/my/report`,
    method: "POST",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
