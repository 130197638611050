import CONSTANTS from "./constants";

export const getUserDetails = (payload) => {
  return {
    type: CONSTANTS.GET_USER_DETAILS,
    payload,
  };
};

export const setUserData = (payload) => {
  return {
    type: CONSTANTS.SET_USER_DATA,
    payload,
  };
};
export const setApiVersion = (payload) => {
  return {
    type: CONSTANTS.API_VERSION,
    payload,
  };
};
export const setOrgIdsData = (payload) => {
  return {
    type: CONSTANTS.ORG_IDS,
    payload,
  };
};
export const setChannelType = (payload) => {
  return {
    type: CONSTANTS.CHANNEL_TYPE,
    payload,
  };
};
export const setEditGroupType = (payload) => {
  return {
    type: CONSTANTS.EDIT_GROUP_TYPE,
    payload,
  };
};
export const setVersion2Action = (payload) => {
  console.log("version2 action: ", payload);
  return {
    type: CONSTANTS.VERSION2,
    payload,
  };
};
