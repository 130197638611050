import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/landingPage.scss";

import logo from "../../../assets/logo-trimmed.png";

import starBanner from "../../../assets/starBanner.png";
import Accordion from "./Accordian";
//social icons assets
import fb from "../../../assets/socialIcons/fb.png";
import twitter from "../../../assets/socialIcons/twitter.png";
import linkedin from "../../../assets/socialIcons/linkedin.png";
import yt from "../../../assets/socialIcons/yt.png";
// icons assets
import monetize from "../../../assets/offeringIcon/monetize.png";
import earning from "../../../assets/offeringIcon/earningD.png";
import paidChannels from "../../../assets/offeringIcon/paidChannels.png";
import pPayment from "../../../assets/offeringIcon/pPayment.png";
// top head banner assets
import backBanner from "../../../assets/back_landing1.png";
import gpplay from "../../../assets/gplay.png";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import LeadCapture from "../../LandingPage/LeadCapture";
function LandingPage(props) {
  const ourOfferingRef = useRef(null);
  const faqRef = useRef(null);
  const navigate = useNavigate();
  const handleClick = (ref) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const redirectToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.fankonnect.app"
    );
  };
  const openPrivacyPolicy = () => {
    // console.log("privacyPolicy");
    navigate("/privacy");
  };
  const openTnc = () => {
    // console.log("privacyPolicy");
    navigate("/tnc");
  };

  const accordionData = [
    {
      title: "How can I join fanKonnect ?",
      content: `Joining FanKonnect is very simple. Go to the playstore and download the fanKonnect App. Register yourself and you are part of this community of countries fastest growing creators.`,
    },
    {
      title: "What category of content creators can join this platform?",
      content: `Anyone who wants to make money from their existing community, by sharing content can join this exclusive club.`,
    },
    {
      title: "How can I make money from fanKonnect?",
      content: `Launch paid telegram channels through fanKonnect and sell it’s subscription plan across your network to earn money.`,
    },
    {
      title:
        "Do my members have to download fanKonnect App to access my paid channels?",
      content: `No, your existing community members do not have to download any other app to access these paid services.`,
    },
    {
      title: "How will my earnings get settled for me?",
      content: `All the money earned through your paid channels will instantly be reflected in your App wallet you can immediately transfer it to your bank account.`,
    },
  ];

  return (
    <div className="landingPage">
      {/* header */}
      <div className="headerContainer">
        <div>
          <img src={logo} alt="logo" height="40px" />
        </div>
        <div onClick={() => redirectToPlayStore()} className="headerDownload">
          <img src={gpplay} style={{ width: "24px", height: "22px" }} />
          <p style={{ color: "white", fontWeight: "bold", marginLeft: 10 }}>
            Download App
          </p>
        </div>
      </div>
      {/* end of header */}

      <div className="topHeadContainer">
        <div className="topHeadTextBtnBlock">
          <div className="topHeadContainer_title">
            Perfect Destination to monetize your Content & Community
          </div>
          <div className="topHeadContainer_description">
            Monetize, grow and manage your community
          </div>
          <img src={backBanner} className="topHeadContainer_backBanner1" />
          <div
            onClick={() => redirectToPlayStore()}
            className="topHeadDownload"
          >
            <img src={gpplay} style={{ width: "24px", height: "22px" }} />
            <p style={{ color: "white", fontWeight: "bold", marginLeft: 10 }}>
              Download App
            </p>
          </div>
        </div>
        <div className="topHeadTextBtnBlock1">
          <img src={backBanner} className="topHeadContainer_backBanner" />
        </div>
      </div>

      {/* 4 icons */}
      <div ref={ourOfferingRef} className="landingPage_FAQs_offering">
        <p className="landingPage_FAQs_title">Our Offerings</p>
        <div className="offeringContainer">
          {/* first row */}
          <div className="offeringMiniContainer">
            <div className="offeringElement">
              <img src={monetize} className="offeringImage" />
              <h4 className="offeringElement_title">Monetize your Community</h4>
              <p className="offeringElement_description">
                Paid Telegram Channel- Time to earn lakhs from Telegram, Create
                Paid Telegram channels for free, using fanKonnect{" "}
              </p>
            </div>
            <div className="offeringElement">
              <img src={paidChannels} className="offeringImage" />
              <h4 className="offeringElement_title">
                Manage your paid channels
              </h4>
              <p className="offeringElement_description">
                Create custom subscription plans, control pricing, and track
                engagement of these channels in one place.{" "}
              </p>
            </div>
          </div>
          {/* second row */}
          <div className="offeringMiniContainer">
            <div className="offeringElement">
              <img src={earning} className="offeringImage" />
              <h4 className="offeringElement_title">
                Personalised Earning Dashboard
              </h4>
              <p className="offeringElement_description">
                {" "}
                Get insights into your earnings in the most organized manner.{" "}
              </p>
            </div>
            <div className="offeringElement">
              <img src={pPayment} className="offeringImage" />
              <h4 className="offeringElement_title">
                Automatic payment settlements
              </h4>
              <p className="offeringElement_description">
                Get your earnings automatically transferred to your bank account
                through our efficient payment mechanism.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* lead capture form */}
      <LeadCapture />
      {/* end of lead capture from */}
      {/* star banner */}
      <div className="landingPage_starBanner">
        <div
          className="landingPage_starBanner_child"
          style={{ backgroundImage: `url(${starBanner})` }}
        >
          <div className="landingPage_head-starText">
            Start monetizing your community and create your paid channel in 5
            mins
          </div>
          <div
            onClick={() => redirectToPlayStore()}
            className="downloadStarButton"
          >
            <img src={gpplay} style={{ width: "24px", height: "22px" }} />
            <p
              style={{
                fontWeight: "bold",
                marginLeft: 10,
                fontSize: 15,
                alignSelf: "center",
              }}
            >
              Download App
            </p>
          </div>
        </div>
      </div>
      {/* FAQs */}
      <div ref={faqRef} className="landingPage_FAQs">
        <p className="landingPage_FAQs_title">FAQs</p>
        {accordionData.map(({ title, content }) => (
          <Accordion title={title} content={content} />
        ))}
      </div>

      {/* <div className="groupDetails_support">
        <div className="groupDetails_support_text">
          If you need any help please write to us at
        </div>
        <div className="groupDetails_support_mail">support@fankonnect.com</div>
      </div> */}

      <div className="landingPage_bottom">
        <div className="landingPage_bottom_top">
          <div className="landingPage_bottom_top_left">
            <img src={logo} alt="logo" height="40px" />
            <div>
              Fankonnect is one stop platform to monetize your audience.
            </div>
            {/* <div className="headerDownload">
         <img src={gpplay} style={{width:"24px",height:"22px"}}/>
          <p style={{color:"white",fontWeight:"bold",marginLeft:10}}>Download App</p>
        </div>   */}
            <div
              onClick={() => redirectToPlayStore()}
              className="footerDownload"
            >
              <img src={gpplay} style={{ width: "24px", height: "22px" }} />
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginLeft: 10,
                  fontSize: 15,
                  alignSelf: "center",
                }}
              >
                Download App
              </p>
            </div>
          </div>
          {/* company */}
          <div className="footerMobile">
            <div style={{ color: "#000000", fontWeight: "bold" }}>Company</div>
            <div style={{ marginTop: "10px" }}>
              <div
                style={{ marginTop: "1px", cursor: "pointer" }}
                onClick={() => handleClick(ourOfferingRef)}
                // onClick={()=>scrollToTargetAdjusted()}
              >
                Our Offerings
              </div>
              {/* <div style={{marginTop:'1px'}}>Testimonials</div>
            <div style={{marginTop:'1px'}}>Pricing</div> */}
              <div
                style={{ marginTop: "1px", cursor: "pointer" }}
                onClick={() => handleClick(faqRef)}
              >
                FAQ's
              </div>
              <div
                style={{ marginTop: "1px", cursor: "pointer" }}
                onClick={() => openPrivacyPolicy(faqRef)}
              >
                Privacy Policy
              </div>
              <div
                style={{ marginTop: "1px", cursor: "pointer" }}
                onClick={() => openTnc(faqRef)}
              >
                Terms and Conditions
              </div>
            </div>
          </div>
          {/* contact us  */}
          <div className="footerMobile">
            <div style={{ color: "#000000", fontWeight: "bold" }}>
              Contact us
            </div>
            <div style={{ marginTop: "10px" }}>support@fankonnect.com</div>
          </div>
        </div>
        {/* <div className="groupDetails_socialIcons">
         <div className="groupDetails_socialIcons_child">
           <img src={fb} style={{width:'8px',height:'14px'}}/>
         </div>
         <div className="groupDetails_socialIcons_child">
           <img src={twitter} style={{width:'15px',height:'12px'}}/>
         </div>
         <div className="groupDetails_socialIcons_child">
           <img src={linkedin} style={{width:'13px',height:'13px'}}/>
         </div>
         <div className="groupDetails_socialIcons_child">
           <img src={yt} style={{width:'14px',height:'10px'}}/>
         </div>
        
        </div> */}
      </div>
    </div>
  );
}

export default LandingPage;
