import { call, put, takeLatest } from "redux-saga/effects";
import { getUserDetailsAction } from "../../../store/userDetailsReducer/actions";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";
import {
  addBankDetailsAccountPostService,
  agreeTermsAndCondtionsService,
  deleteBankAccountService,
  getBankAccountsService,
} from "./services";

function* addBankDetailsAccountPostSaga(data) {
  const loaderAction = yield call(
    getActionObject,
    CONSTANTS.ADD_BANK_DETAILS_POST_LOADER
  );
  yield put({ ...loaderAction });
  try {
    // console.log("P______", data);
    const apiCall = yield call(addBankDetailsAccountPostService, data?.payload);
    const callUserDetailsAction = yield call(getUserDetailsAction);
    yield put({ ...callUserDetailsAction });
    const getAction = yield call(
      getActionObject,
      CONSTANTS.ADD_BANK_DETAILS_POST_SUCCESS
    );
    yield put({ ...getAction });
    if (data?.payload?.onSuccess) {
      data?.payload?.onSuccess?.();
    }
  } catch (err) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.ADD_BANK_DETAILS_POST_FAILURE
    );
    yield put({ ...getAction });
    if (data?.payload?.onFailure) {
      data?.payload?.onFailure?.();
    }
  }
}
function* getBankAccountsSaga(data) {
  yield put({
    type: CONSTANTS.GET_BANK_ACCOUNTS_LOADER,
    payload: true,
  });
  try {
    const bankAccountResp = yield call(getBankAccountsService, data?.payload);
    // console.log("bankAccountsResp", bankAccountResp);
    if (bankAccountResp?.data?.status == "success") {
      yield put({
        type: CONSTANTS.GET_BANK_ACCOUNTS_LOADER,
        payload: false,
      });
      yield put({
        type: CONSTANTS.GET_BANK_ACCOUNTS_SUCCESS,
        payload: bankAccountResp?.data?.data,
      });
    } else {
      yield put({
        type: CONSTANTS.GET_BANK_ACCOUNTS_LOADER,
        payload: false,
      });
      yield put({
        type: CONSTANTS.GET_BANK_ACCOUNTS_FAILURE,
        payload: true,
      });
    }
  } catch (err) {}
}
function* deleteBankAccountSaga(data) {
  yield put({
    type: CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_LOADER,
    payload: true,
  });
  const deleteResp = yield call(deleteBankAccountService, data?.payload);
  if (deleteResp?.data?.status == "success") {
    yield put({
      type: CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_LOADER,
      payload: false,
    });
    yield put({
      type: CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_SUCCESS,
      payload: deleteResp?.data?.data,
    });
  } else {
    yield put({
      type: CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_LOADER,
      payload: false,
    });
    yield put({
      type: CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_FAILURE,
      payload: true,
    });
  }
}

function* agreeTermsAndConditionSaga(data) {
  yield put({
    type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS_LOADER,
    payload: true,
  });
  try {
    const agreeResp = yield call(agreeTermsAndCondtionsService, data?.payload);
    console.log("agreeResps:", agreeResp);
    if (agreeResp?.data?.status == "success") {
      yield put({
        type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS_LOADER,
        payload: false,
      });
      yield put({
        type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS_SUCCESS,
        payload: agreeResp?.data?.data,
      });
    } else {
      console.log("failedState:");
      yield put({
        type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS_FAILURE,
        payload: true,
      });
    }
  } catch (err) {
    yield put({
      type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS_FAILURE,
      payload: true,
    });
  }
}

export default function* actionWatcher() {
  yield takeLatest(
    CONSTANTS.ADD_BANK_DETAILS_POST,
    addBankDetailsAccountPostSaga
  );
  yield takeLatest(CONSTANTS.GET_BANK_ACCOUNTS, getBankAccountsSaga);
  yield takeLatest(
    CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT,
    deleteBankAccountSaga
  );
  yield takeLatest(
    CONSTANTS.AGREE_TERMS_AND_CONDITIONS,
    agreeTermsAndConditionSaga
  );
}
