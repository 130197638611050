import throttle from "lodash/throttle";

const DEFAULT_OFFSET = 100;
const DEFAULT_SCROLL_THROTTLE_INTERVAL = 150;

export default function onScrolledIntoView(el, callback, options = {}) {
  if (el && typeof window !== "undefined") {
    let ignoreAllQueuedCalls = false;
    const {
      offset = DEFAULT_OFFSET,
      throttleInterval = DEFAULT_SCROLL_THROTTLE_INTERVAL,
      triggerOnExit = false,
      scrollParent = window,
    } = options;

    const onScroll = () => {
      if (!ignoreAllQueuedCalls) {
        const elClientRect = el.getBoundingClientRect();
        const bottomLimit = elClientRect.bottom + offset;
        const topLimit = elClientRect.top - offset;
        // const leftLimit = elClientRect.left;
        // const rightLimit = elClientRect.right;

        let inDesiredView = bottomLimit >= 0 && topLimit <= window.innerHeight;
        // leftLimit >= 0 &&
        // rightLimit <= window.innerWidth;
        inDesiredView = triggerOnExit ? !inDesiredView : inDesiredView;

        if (inDesiredView) {
          callback();
        }
      }
    };

    const throttledScrollHandler = throttle(onScroll, throttleInterval, {
      leading: true,
    });

    scrollParent.addEventListener("scroll", throttledScrollHandler);

    return {
      remove: () => {
        scrollParent.removeEventListener("scroll", throttledScrollHandler);
        ignoreAllQueuedCalls = true;
      },
      call: onScroll,
    };
  }
}
