import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as actions from "../../store/Details/actions";
import SingleStatement from "./components/singleStatement";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import "../../scss/Statement/Statement.scss";
import { useParams } from "react-router";
import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import onScrolledIntoView from "./components/LoadOnScrolledIntoView";
const limit = 7;
function Statement(props) {
  const { singleGroupDetails, singleMessageDetails } = props;
  const [page, setPage] = React.useState(0);
  const listInnerRef = useRef();
  const scrollRef = useRef(null);
  const loaderRef = useRef(null);
  const scrollListener = useRef();
  const isFetching = useRef();
  const { id } = useParams();
  const isGroupStatement = window.location.href.includes("group");
  useLayoutPageHeading("View Statement");

  // useEffect(() => {
  //   // console.log('fsdfdsf',props.limit)
  //   props.getStatement({
  //     id: id,
  //     type: isGroupStatement ? "group" : "message",
  //     limit:props.limit,
  //     offset: props,
  //   });
  // }, []);
  useEffect(() => {
    props.resetMemberData(false);
    props.getStatement({
      id: id,
      type: isGroupStatement ? "group" : "message",
      limit: props.limit,
      offset: 0,
    });
    // return()=>{props.resetStatementData(null)}
  }, []);

  // const statementDetails = useSelector(
  //   (state) => state?.singleContentDetails?.statement?.data

  // );
  const statementData = useSelector(
    (state) => state?.singleContentDetails?.statementData
  );
  // const bindScrollListener = () => {
  //   if (loaderRef?.current) {
  //     const fetchMore = () => {
  //       if (!isFetching.current) {
  //         isFetching .current= true;
  //         fetcherCall(false);
  //       }
  //     };
  //     scrollListener.current = onScrolledIntoView(
  //       loaderRef.current,
  //       fetchMore,
  //       {offset: 300, scrollParent:scrollRef.current}
  //     );
  //     scrollListener.current.call();
  //   }
  // }

  useEffect(() => {
    console.log("memberdatacheck", props.dataChecker);
    // bindScrollListener();
    let scrollListener;
    if (loaderRef?.current) {
      const fetchMore = () => {
        // if (!isFetching.current) {
        //   isFetching .current= true;
        //   fetcherCall(false);
        // }
        // api call
        if (!props.dataChecker) {
          props.getStatement({
            id: id,
            type: isGroupStatement ? "group" : "message",
            limit: props.limit,
            offset: props.offset,
          });
        }
      };
      scrollListener = onScrolledIntoView(loaderRef.current, fetchMore, {
        offset: 300,
        scrollParent: scrollRef.current,
      });
      scrollListener.call();
    }
    return () => {
      scrollListener?.remove();
    };
  }, [props.offset]);

  console.log("statementDataaa", statementData);
  return (
    <div ref={scrollRef} className="Statement">
      {/* <InfiniteScroll
        dataLength={100}
        next={() => {
          console.log("next", id, isGroupStatement, props.offset);
          props.getStatement({
            id: id,
            type: isGroupStatement ? "group" : "message",
            limit:props.limit,
            offset: props.offset ,
          });
          // setPage(page + 1);
        }}
        hasMore={true}
        height={"90vh"}
        refreshFunction={() =>
          props.getStatement({
            id: id,
            type: isGroupStatement ? "group" : "message",
            limit:props.limit,
            offset: 0,
          })
        }
        pullDownToRefresh
        pullDownToRefreshThreshold={80}
      > */}
      <div>
        <div className="Statement_Top">
          <div className="Statement_Top_Head">Total Earnings</div>
          <div className="Statement_Top_Earnings">
            ₹{statementData?.totalRevenue}
          </div>
        </div>
        <div>
          {/* { console.log("member",statementData?.members)} */}
          {statementData?.members?.map((member, i) => (
            <SingleStatement
              parentRef={
                i === statementData.members.length - 1 ? loaderRef : null
              }
              key={i}
              member={member}
            />
          ))}
        </div>
      </div>
      {/* <div ref={loaderRef}></div> */}
      {/* </InfiniteScroll> */}
    </div>
  );
}

const mapStateToProps = ({ singleContentDetails }) => {
  return {
    //  statementData: singleContentDetails?.statement,
    // statementData: singleContentDetails?.statementData,
    dataChecker: singleContentDetails?.dataChecker,
    limit: singleContentDetails?.limit,
    offset: singleContentDetails?.offset,
    singleMessageDetails: singleContentDetails?.singleMessageDetails,
    singleGroupDetails: singleContentDetails?.singleGroupDetails,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    getStatement: (payload) => dispatch(actions.getStatementData(payload)),
    resetMemberData: (payload) => dispatch(actions.resetMemberData(payload)),
    resetStatementData: (payload) =>
      dispatch(actions.resetStatementData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(Statement);
