import CONSTANTS from "./constants";

export const getOtpAction = (payload) => {
  return {
    type: CONSTANTS.GET_OTP,
    payload,
  };
};

export const setNameAction = (payload) => {
  return {
    type: CONSTANTS.SET_NAME,
    payload,
  };
};

export const setMobileAction = (payload) => {
  return {
    type: CONSTANTS.SET_MOBILE,
    payload,
  };
};

export const getCoAdmins = (payload) => {
  return {
    type: CONSTANTS.GET_COADMINS,
    payload,
  };
};

export const resetStateAction = (payload) => {
  return {
    type: CONSTANTS.RESET_STATE,
    payload,
  };
};
