import React, { useState } from "react";
import "../LeadCapture/leadCapture.scss";
import Input from "../../../common/Input";
// import Dropdown from "../../../components/Dropdown";
import joinChannel from "../../../assets/joinChannel.png";
import thankYouImg from "../../../assets/thankyou.gif";
import success from "../../../assets/purchase/success.svg";
import siocn1 from "../../../assets/siocn1.png";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Select from "react-dropdown-select";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import ReactLoading from "react-loading";
import { useEffect } from "react";
function LeadCapture(props) {
  const { isLoading, leadSuccess, leadFailure, submitLeadCapture } = props;

  const demoData = [
    { labelField: "1", value: "Calls and tips for stock trading" },
    { labelField: "2", value: "Calls and tips for stofddck trading1" },
  ];
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const contentCategoryList = [
    {
      value: "Calls and tips for stock trading",
      label: "Calls and tips for stock trading",
    },
    { value: "Fantasy sports", label: "Fantasy sports" },
    { value: "Astrology", label: "Astrology" },
    { value: "Fitness", label: "Fitness" },
    { value: "Cooking", label: "Cooking" },
    { value: "Other", label: "Other" },
  ];
  const platformList = [
    { value: "Telegram", label: "Telegram" },
    { value: "WhatsApp", label: "WhatsApp" },
    { value: "YouTube", label: "YouTube" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Twitter", label: "Twitter" },
    { value: "Other", label: "Other" },
  ];
  const communitySizeList = [
    { value: "1-1000 users", label: "1-1000 users" },
    { value: "1000-10k users", label: "1000-10k users" },
    { value: "10k - 50k users", label: "10k - 50k users" },
    { value: "50k - 100k users", label: "50k - 100k users" },
    { value: "100k + users", label: "100k + users" },
  ];
  const [fullName, setFullName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [platform, setPlatform] = useState("");
  const [communitySize, setCommunitySize] = useState("");
  const [thankYou, setThankYou] = useState(false);

  useEffect(() => {
    console.log("leadStatus", leadFailure, leadSuccess);
    if (leadSuccess) {
      setThankYou(true);
    } else if (leadFailure) {
      setThankYou(false);
    }
  }, [leadFailure, leadSuccess]);

  const validation = () => {
    let flag = true;
    if (!fullName) {
      flag = false;
      toast.error("Please enter the name");
    } else if (!phone) {
      flag = false;
      toast.error("Please enter the phone");
    } else if (selectedCategory == "") {
      flag = false;
      toast.error("Please select the Content Category");
    } else if (platform == "") {
      flag = false;
      toast.error("Please select the Platform");
    } else if (communitySize == "") {
      flag = false;
      toast.error("Please select the Community Size");
    }
    return flag;
  };
  const onSubmit = () => {
    if (validation()) {
      let payload = {
        name: fullName,
        mobile: "91" + phone,
        email: email,
        contentCategory: selectedCategory,
        communitySize: communitySize,
        sourcePlatform: platform,
      };
      console.log("leadPayloda", payload);
      props.submitLeadCapture(payload);
    }
  };
  return (
    <div className="LeadCapture">
      {thankYou ? (
        <div className="Thankyou">
          <div className="Thankyou_imgBlock">
            <img src={siocn1} className="Thankyou_img" />
          </div>
          <div className="Thankyou_headText">Thank you !</div>
          <p className="Thankyou_description">
            Thank you for sharing the details. Our team will reach out to you
            soon to explain our product offerings. Meanwhile you can download
            the FanKonnect app from Playstore
          </p>
        </div>
      ) : (
        <div className="LeadCapture_container">
          {/* left */}
          <div className="LeadCapture_leftBlock">
            <div className="LeadCapture_leftBlock_headTxt">
              Join Our Creator Exclusive Clan
            </div>
            <p className="LeadCapture_leftBlock_description">
              Monetize, grow and manage your community
            </p>
          </div>
          {/* right */}
          <div className="LeadCapture_rightBlock">
            {/* 1st row */}
            <div className="LeadCapture_rightBlock_form">
              <div className="LeadCapture_rightBlock_form_formElement">
                <div className="formLabel">Full name</div>
                <Input
                  value={fullName}
                  // label="FullName"
                  type="text"
                  placeholder="Enter your full name"
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  // onEnter={() => {
                  //   if (!disabled) submit();
                  // }}
                />
              </div>
              <div className="LeadCapture_rightBlock_form_formElement">
                <div className="formLabel">Phone</div>
                <Input
                  value={phone}
                  // label="FullName"
                  type="phone"
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  // onEnter={() => {
                  //   if (!disabled) submit();
                  // }}
                />
              </div>
            </div>
            {/* 2nd row */}
            <div className="LeadCapture_rightBlock_form">
              <div className="LeadCapture_rightBlock_form_formElement">
                <div className="formLabel">
                  Email{" "}
                  <span style={{ color: "#7D8592", fontSize: "10px" }}>
                    (Optional)
                  </span>
                </div>
                <Input
                  value={email}
                  // label="FullName"
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  // onEnter={() => {
                  //   if (!disabled) submit();
                  // }}
                />
              </div>
              <div className="LeadCapture_rightBlock_form_formElement">
                <div className="formLabel">Content Category</div>
                <Select
                  options={contentCategoryList}
                  dropdownGap={0}
                  onChange={(item) => {
                    setSelectedCategory(item[0].value);
                  }}
                  placeholder="Select Category"
                  style={{
                    backgroundColor: "white",
                    padding: "13px",
                    marginTop: "10px",
                    borderColor: "white",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
            {/* 3rd row */}
            <div className="LeadCapture_rightBlock_form">
              <div className="LeadCapture_rightBlock_form_formElement">
                <div className="formLabel1">
                  Your community is on which platform?
                </div>
                <Select
                  options={platformList}
                  dropdownGap={0}
                  onChange={(item) => setPlatform(item[0].value)}
                  style={{
                    backgroundColor: "white",
                    padding: "13px",
                    marginTop: "10px",
                    borderColor: "white",
                    borderRadius: "10px",
                  }}
                  placeholder="Select Platform"
                />
              </div>
              <div className="LeadCapture_rightBlock_form_formElement">
                <div className="formLabel1">Your community size</div>
                <Select
                  options={communitySizeList}
                  dropdownGap={0}
                  onChange={(item) => setCommunitySize(item[0].value)}
                  style={{
                    backgroundColor: "white",
                    padding: "13px",
                    marginTop: "10px",
                    borderColor: "white",
                    borderRadius: "10px",
                  }}
                  placeholder="Select Size"
                />
              </div>
            </div>
            {/* button submit */}
            {isLoading ? (
              <div className="Loader">
                <ReactLoading
                  type={"spin"}
                  color={"#2278FF"}
                  height={30}
                  width={30}
                />
              </div>
            ) : (
              <div onClick={() => onSubmit()} className="ctaBlock">
                <div className="ctaBlock_CTAContainer">
                  <div className="ctaBlock_joinText">Submit</div>
                  <img className="ctaBlock_CTAIcon" src={joinChannel} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isLoading: state?.leadCaptureRedeucer?.isLoading,
    leadSuccess: state?.leadCaptureRedeucer?.leadSuccess,
    leadFailure: state?.leadCaptureRedeucer?.leadFailure,
  };
};
const mapDispatchWithProps = (dispatch) => {
  return {
    submitLeadCapture: (payload) =>
      dispatch(actions.submitLeadCapture(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(LeadCapture);
