const namespace = "CREATE_GROUP_";

export default {
  SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
  SET_TITLE: `${namespace}SET_TITLE`,
  SET_DESCRIPTION: `${namespace}SET_DESCRIPTION`,
  SET_CATEGORY: `${namespace}SET_CATEGORY`,
  ADD_PRICE_PLAN: `${namespace}ADD_PRICE_PLAN`,
  SET_ONE_TIME_PRICE: `${namespace}SET_ONE_TIME_PRICE`,
  SET_SUBSCRIPTION_PERIOD: `${namespace}SET_SUBSCRIPTION_PERIOD`,
  SET_SUBSCRIPTION_PRICE: `${namespace}SET_SUBSCRIPTION_PRICE`,
  SET_PRICING_DATA: `${namespace}SET_PRICING_DATA`,
  SET_IMAGE: `${namespace}SET_IMAGE`,

  SET_DISCOUNT: `${namespace}SET_DISCOUNT`,
  SET_OFFER_TITLE: `${namespace}SET_OFFER_TITLE`,
  SET_SELECTED_PLAN: `${namespace}SET_SELECTED_PLAN`,
  DELETE_PLAN: `${namespace}DELETE_PLAN`,
  RESET_PRICES_PLANS: `${namespace}RESET_PRICES_PLANS`,

  CREATE_GROUP: `${namespace}CREATE_GROUP`,
  CREATE_GROUP_CLEAR: `${namespace}CREATE_GROUP_CLEAR`,
  CREATE_GROUP_SUCCESS: `${namespace}CREATE_GROUP_SUCCESS`,

  EDIT_GROUP: `${namespace}EDIT_GROUP`,
  EDIT_GROUP_SUCCESS: `${namespace}EDIT_GROUP_SUCCESS`,

  RESET_STATE: `${namespace}RESET_STATE`,
  // custom period action names
  CUSTOM_PERIOD: `${namespace}CUSTOM_PERIOD`,
  CUSTOM_DAYS: `${namespace}CUSTOM_DAYS`,

  ADD_FREE_PLAN_DAYS: `${namespace}ADD_FREE_PLAN_DAYS`,
  // conditional access
  SET_CONDITIONAL_TOGGLE: `${namespace}SET_CONDITIONAL_TOGGLE`,
  SET_FORM_LINK_DATA: `${namespace}SET_FORM_LINK_DATA`,
  GET_BOT_LINK: `${namespace}GET_BOT_LINK`,
  BOT_LINK_DATA: `${namespace}BOT_LINK_DATA`,
  PLATFORM_SELECTED: `${namespace}PLATFORM_SELECTED`,
  CHANNEL_TYPE: `${namespace}CHANNEL_TYPE`,
};
