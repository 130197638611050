import { put, call, select, takeLatest } from "redux-saga/effects";
import * as services from "./service";
import CONSTANTS from "./constants";
import getUserToken from "../../utils/getUserToken";

function* createGroup(payload) {
  let v2Enabled = yield select((state) => state?.user?.v2Enabled);
  payload.payload.v2Enabled = v2Enabled;
  try {
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: true,
    });
    const response = yield call(services.createGroupService, payload.payload);
    yield put({
      type: CONSTANTS.CREATE_GROUP_SUCCESS,
      payload: response.data.data,
    });
    // yield put({
    //   type: CONSTANTS.CREATE_GROUP_CLEAR
    // })
    // console.log("navigatee",payload?.payload);

    // payload?.payload?.navigate(`/group`);
    // payload?.payload?.navigate(-1);

    if (payload?.payload?.type == "telegramExisting") {
      payload?.payload?.navigate(-2);
    } else if (
      response?.data?.data?.type !== "whatsappGroup" &&
      response?.data?.data?.type !== "whatsappCommunity"
    ) {
      payload?.payload?.navigate(-3);
    }
    // else {
    //   payload?.payload?.navigate(-3);
    // }

    // payload?.payload?.resetForm()
    //
    // yield put({
    //   type: CONSTANTS.CREATE_GROUP_CLEAR,
    // })
  } catch (err) {
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: false,
    });
  }
}

function* editGroup(payload) {
  try {
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: true,
    });
    const response = yield call(services.editGroupService, { data: payload });
    yield put({
      type: CONSTANTS.CREATE_GROUP_SUCCESS,
      payload: response.data,
    });
    payload?.payload?.navigate("/group");
    // payload?.payload?.resetForm()
  } catch (err) {
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: false,
    });
  }
}
function* getBotLinkSaga() {
  try {
    const token = getUserToken();
    const response = yield call(services.getBotLinkService, token);
    console.log("fdsfdfdf", response);
    yield put({
      type: CONSTANTS.BOT_LINK_DATA,
      payload: response?.data?.data?.botLink,
    });
  } catch (err) {
    console.log("err", err);
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.CREATE_GROUP, createGroup);
  yield takeLatest(CONSTANTS.EDIT_GROUP, editGroup);
  yield takeLatest(CONSTANTS.GET_BOT_LINK, getBotLinkSaga);
}
