import axios from "axios";
import { BASE_URL } from "../../../../axios-config";
import { getUserToken } from "../../../../utils/getAxiosConfig";

export const createCouponService = async (payload) => {
  return await axios({
    url: `${BASE_URL}/coupons`,
    method: "POST",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const editCouponService = async (payload) => {
  return await axios({
    url: `${BASE_URL}/editCouponDetails`,
    method: "PUT",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
