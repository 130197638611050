import React from "react";
import UserAvatar from "react-avatar";
import PropTypes from "prop-types";
// import { stringUtils } from '../../../utils';
// import { userAvatarColors } from '../../../config/constants';
export const userAvatarColors = [
  "#b6816c",
  "#a16592",
  "#dda283",
  "#8f8f75",
  "#9e9fa1",
];

const indexFromName = (name) => {
  let hasher = 0;
  for (let i = 0; i < name.length; i++) {
    hasher = name.charCodeAt(i) + ((hasher << 5) - hasher);
  }
  let h = hasher % 5;
  return h;
};

const getColorFromName = (name) => userAvatarColors[indexFromName(name)];

const CustomUserAvatar = ({
  user = {},
  collection,
  size,
  style,
  clickHandler,
}) => {
  const normalStyle = { display: "inline-block" };
  const collectionStyle = { ...normalStyle, marginLeft: (size || 32) * -0.3 };
  let applyStyle = normalStyle;
  if (collection) {
    applyStyle = collectionStyle;
  }

  const color = user.name && getColorFromName(user.name);

  if (style) {
    style = {
      ...applyStyle,
      ...style,
    };
  }

  return (
    <UserAvatar
      size={size || 32}
      name={user.name}
      color={color}
      src={user.imageUrl || false}
      style={style || applyStyle}
      round
      maxInitials={2}
      onClick={clickHandler}
    />
  );
};

CustomUserAvatar.propTypes = {
  /* eslint-disable */
  collection: PropTypes.bool,
  size: PropTypes.number,
  user: PropTypes.object.isRequired,
  /* eslint-enable */
};

export default CustomUserAvatar;
