import axios from "axios";
import { BASE_URL } from "../../axios-config";
import { razorPayKey } from "../../routes/Purchase/components/Payment/Razorpay";
import { getApiBaseURL } from "../../utils/getAxiosConfig";
import store from "../store";
import { setPurchaseScreen } from "./actions";

const handleRazorpayUtil = (orderId, email, mobile, token) => {
  const options = {
    key: razorPayKey,
    //   image: webLoginLogo,
    // currency: "INR",
    order_id: orderId,
    notes: {
      deviceType: /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? "ANDROID"
        : /iPhone|iPad|iPod/i.test(navigator.userAgent)
        ? "IOS"
        : "WEB",
    },
    async handler(response) {
      console.log(response, "rp+++");
      let payload = {
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: orderId,
      };
      if (Object.keys(response).includes("razorpay_signature")) {
        store.dispatch(setPurchaseScreen("loader"));
      }
      const temp = {
        apiObj: payload,
        token: token,
      };
      window.payload = temp;
      console.log(token, "ppp4");
    },
    prefill: {
      contact: mobile,
      email: email,
    },
    modal: {
      ondismiss: function () {
        store.dispatch(setPurchaseScreen("failure"));
      },
    },
    config: {
      display: {
        hide: [{ method: "paylater" }],
      },
    },
    retry: {
      enabled: false,
      max_count: 1,
    },
  };

  const instance = new window.Razorpay(options);
  instance.on("payment.failed", (data) => {
    console.log(data);
    store.dispatch(setPurchaseScreen("failure"));
  });
  instance.open();
};

export default handleRazorpayUtil;

// const handleRazorPay = (oId,email) => {
//     const options = {
//       key: razorPayKey,
//       //   image: webLoginLogo,
//       // currency: "INR",
//       order_id: oId,
//       notes: {
//         deviceType: /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
//           navigator.userAgent
//         )
//           ? "ANDROID"
//           : /iPhone|iPad|iPod/i.test(navigator.userAgent)
//           ? "IOS"
//           : "WEB",
//       },
//       handler(response) {
//         console.log(response, "rp+++");
//         let payload ={
//           "razorpay_payment_id":response.razorpay_payment_id,
//           "razorpay_order_id":oId
//         }
//         // setLoading(true);
//         axios
//           .put(BASE_URL + "/order/update", payload, {
//               headers: {
//               "x-access-token": verifyOtp?.token,
//             }
//           })
//           .then((res) => {
//             setPurchaseScreen("success");
//           })
//           .catch((err) => {
//             setPurchaseScreen("failure");
//           });
//       },
//       prefill: {
//         contact: formDetails?.mobile,
//         email: formDetails?.email || email,
//       },
//     };
// const instance = new window.Razorpay(options);
// instance.open();
//   };
