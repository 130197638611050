import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Description from "./components/description";
import Earnings from "./components/earnings";

import * as actions from "../../store/Details/actions";
import "../../scss/Details/Details.scss";

import ShareBottomsheet from "../../components/ShareBottomsheet";
import useLayoutPageBackNavigation from "../../customHooks/useLayoutPageBackNavigation";
import {
  layoutPageHeadingSet,
  layoutPageHeadingClear,
} from "../../layout/store/actions";
import useBackListener from "../../customHooks/useBackListener";
import calendarIcon from "../../assets/calendarIcon.svg";
import iIcon from "../../assets/i.svg";
import shareIcon from "../../assets/share.png";
import ReactLoading from "react-loading";
import Badge from "../../common/Badge";

function MessageDetails(props) {
  const {
    singleMessageDetails,
    createMessageData,
    type,
    fromCreate,
    isLoading,
  } = props;
  const [showShareBottomSheet, setShowShareBottomSheet] = useState(false);
  const [messageData, setMessageData] = useState();

  const navigate = useNavigate();
  let { id } = useParams();

  useBackListener(({ location }) => {
    console.log("Navigated Back", { location });
    navigate("/message", { replace: true });
  });

  useEffect(() => {
    !props.formCreate &&
      id &&
      props.getMessageDetails({
        id: id,
      });

    return () => {
      props.layoutPageHeadingClear();
      props.resetSingleMessageDetails();
    };
  }, []);

  useEffect(() => {
    props.layoutPageHeadingSet(singleMessageDetails?.title);
    setMessageData(fromCreate ? createMessageData : singleMessageDetails);
  }, [fromCreate, singleMessageDetails]);

  useLayoutPageBackNavigation("/message");

  const socialLinks = useSelector((state) =>
    fromCreate
      ? state?.createMessage?.shareLinks
      : state?.singleContentDetails?.singleMessageDetails?.shareLinks
  );

  return (
    <>
      {isLoading ? (
        <div className="Loader">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#2278FF"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div className="Details">
          <div className="Details_Name">
            {messageData?.title}&nbsp;
            {messageData?.status ? (
              <Badge status={messageData?.status} />
            ) : null}
          </div>
          {messageData?.status === "pending" ? (
            <div className="Details_Pending">
              <img src={iIcon} alt="i" height={"20px"} />
              <div style={{ marginLeft: "10px" }}>
                Your group creation is in progress, we will notify you once your
                group is ready.
              </div>
            </div>
          ) : null}
          <div className="Details_Date">
            <img
              src={calendarIcon}
              alt="calendar"
              height={"15px"}
              width={"15px"}
              style={{ margin: "0 5px 2px 0" }}
            />
            {moment(messageData?.createdAt).format("MMMM DD, YYYY")}
          </div>

          {!fromCreate ? (
            <Earnings
              totalEarnings={messageData?.revenue}
              purchases={messageData?.members}
              messageId={id}
            />
          ) : null}

          <Description
            description={messageData?.description}
            isMessage={1}
            // pricePlan={singleMessageDetails?.subscriptionPlan}
            price={messageData?.messagePrice}
          />

          <div className="Description_Top">
            <div className="Description_Head">Locked Message</div>
            <div className="Description_Text">{messageData?.lockedMessage}</div>
          </div>

          {/* {messageData?.attachments?.length ? (
        <div className="Description_Files">
          {singleMessageDetails.attachments.map((attachment) => (
            <div className="Description_Files_File">
              <img
                src={attachment?.icon}
                height="20px"
                style={{ marginRight: "6px" }}
              />
              {attachment?.fileName}
            </div>
          ))}
        </div>
      ) : null} */}
          {messageData?.status === "success" ? (
            <div className="Description_ButtonContainer">
              <div
                className="Description_ButtonContainer_Button"
                onClick={() => setShowShareBottomSheet(true)}
              >
                Share Payment Link
                <img
                  src={shareIcon}
                  alt="calendar"
                  height={"15px"}
                  width={"15px"}
                  style={{
                    margin: "0 0 2px 10px",
                    fill: "white",
                    stroke: "white",
                  }}
                />
              </div>
            </div>
          ) : null}

          {showShareBottomSheet ? (
            <ShareBottomsheet
              socialLinks={socialLinks}
              handleClose={() => setShowShareBottomSheet(false)}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ singleContentDetails, createMessage }) => {
  return {
    isLoading: singleContentDetails?.isLoading,
    singleMessageDetails: singleContentDetails?.singleMessageDetails,
    createMessageData: createMessage,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    getMessageDetails: (payload) =>
      dispatch(actions.getMessageDetails(payload)),
    layoutPageHeadingSet: (payload) => dispatch(layoutPageHeadingSet(payload)),
    layoutPageHeadingClear: () => dispatch(layoutPageHeadingClear()),

    resetSingleMessageDetails: () =>
      dispatch(actions.resetSingleMessageDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(MessageDetails);
