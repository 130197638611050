import React, { useState } from "react";
import "./createCoupon.scss";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
import Input from "../../../common/Input";
import Switch from "react-js-switch";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { connect } from "react-redux";
import Dropdown from "../../../components/Dropdown";
import roundCircle from "../../../assets/roundCircle.png";
import * as actions from "./store/actions";
import { createCouponService, editCouponService } from "./store/service";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const CreateCoupon = (props) => {
  const { singleGroupDetails, name, amount, couponType, isEdit } = props;

  useEffect(() => {
    return clearData();
  }, []);
  const clearData = () => {
    if (isEdit) {
    } else {
      props.reset();
      plans = [];
    }
  };
  let plans = isEdit ? window.payload.plans : [];
  let temp = window.location.href.split("/");
  let groupID = temp[temp.length - 2];
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [visibilitySwitch, setVisibilitySwitch] = useState(false);
  const [planBag, setPlanBag] = useState(null);
  // const switch_onChange_handle = () => {
  //   setIsSwitchOn(!isSwitchOn);
  //   if (!isSwitchOn) {
  //     plans = [];
  //     console.log(isSwitchOn);
  //   } else {
  //     plans = [];
  //     singleGroupDetails?.subscriptionPlan?.forEach((plan) => {
  //       plans.push(plan.id);
  //     });
  //     console.log(isSwitchOn);
  //   }
  // };
  useEffect(() => {
    if (isEdit) {
      setPlanBag(window.payload.plans);
    }
  }, [isEdit]);

  const handleVisibilitySwitch = () => {
    setVisibilitySwitch(!visibilitySwitch);
  };
  useLayoutPageHeading(isEdit ? "Edit Coupon" : "Create new Coupon");

  const settingType = (item) => {
    props.setType(item);
  };
  const navigate = useNavigate();

  const CheckBoxComponent = ({ plan, checkbox = false }) => {
    const [checked, setChecked] = useState(checkbox);
    // setChecked(checkbox);
    return (
      <Checkbox
        checked={checked}
        disabled={
          !(couponType.label == "Fixed"
            ? plan.discount
              ? plan.price - plan.discount - amount >= 2
              : plan.price - amount >= 2
            : plan.discount
            ? plan.price -
                plan.discount -
                ((plan.price - plan.discount) * amount) / 100 >=
                2 &&
              amount > 5 &&
              amount < 95
            : plan.price - (plan.price * amount) / 100 >= 2 &&
              amount > 5 &&
              amount < 95)
        }
        onChange={(event) => {
          if (event.target.checked) {
            setChecked(true);
            if (!plans.includes(plan.id)) {
              plans.push(plan?.id);
            }
            console.log(plans);
          } else {
            setChecked(false);
            let temp = plans.filter((obj) => plan?.id != obj);
            console.log(temp);
            plans = temp;
          }
        }}
      />
    );
  };

  const handleCreateCoupon = async () => {
    let payload = {
      name: name,
      type: couponType.label == "Fixed" ? "discount" : "percentage",
      value: amount,
      isVisible: visibilitySwitch, //change here
      plans: planBag ? planBag : plans,
      groupId: groupID,
    };
    console.log("payloaddd", { payload });
    // navigate(`/group/${groupID}/coupons`);

    if (couponType) {
      if (name && name.length < 25) {
        await createCouponService(payload)
          .then((res) => {
            if (res.data.status == "success") {
              props.reset();
              navigate(`/group/${groupID}/coupons`, { replace: true });
            }
          })
          .catch((error) => {
            console.log("error in create coupon: ", error);
          });
      } else {
        toast.error("Coupon Name must be between 0 to 25 characters");
      }
    } else {
      toast.error("Please select coupon type");
    }
  };

  const handleEditCoupon = async () => {
    let editedPlans = [];
    console.log("there: ", plans);
    await singleGroupDetails.subscriptionPlan.forEach((plan) => {
      console.log("pid: ", plan.id);
      if (planBag.includes(plan.id)) {
        editedPlans.push({
          plan: plan.id,
          isActive: true,
        });
      } else {
        editedPlans.push({
          plan: plan.id,
          isActive: false,
        });
      }
    });

    console.log(editedPlans);

    let payload = {
      couponId: window.payload.couponId,
      groupId: groupID,
      type: couponType.label == "Fixed" ? "discount" : "percentage",
      value: amount,
      isVisible: visibilitySwitch, //change here
      plans: editedPlans,
    };
    console.log({ payload });

    await editCouponService(payload)
      .then((res) => {
        console.log(res);
        if (res?.data?.status == "success") {
          props.reset();
          navigate(`/group/${groupID}/coupons`, { replace: true });
        }
      })
      .catch((err) => {
        toast.error(err);
        console.log("err : ", err);
      });

    //   navigate(`/group/${groupID}/coupons`)
  };

  const enableSwitch = () => {
    console.log("amount: ", typeof amount);
    let status = false;
    singleGroupDetails?.subscriptionPlan.forEach((plan) => {
      if (plan.price > 0) {
        let discountedPrice;
        if (couponType.label == "Fixed") {
          if (plan.discount) {
            discountedPrice = plan.price - plan.discount;
          } else {
            discountedPrice = plan.price;
          }
          if (discountedPrice - parseFloat(amount) < 2) {
            console.log(discountedPrice);
            console.log("1", discountedPrice - parseFloat(amount));
            status = true;
          }
        } else {
          if (plan.discount) {
            discountedPrice =
              plan.price -
              plan.discount -
              (parseFloat(amount) * 100) / (plan.price - plan.discount);
            if (discountedPrice - parseFloat(amount) < 2) {
              console.log("2", discountedPrice - parseFloat(amount));
              status = true;
            }
          } else {
            discountedPrice =
              plan.price - (parseFloat(amount) * 100) / plan.price;
            if (discountedPrice - parseFloat(amount) < 2) {
              console.log(`3 ${discountedPrice}  ${parseFloat(amount)}`);
              status = true;
            }
          }
        }
      }
    });
    return status;
  };
  const actionOnPlans = (plan) => {
    console.log("action", plan, planBag);
    if (planBag == null) {
      setPlanBag([plan?.id]);
    } else if (!planBag.includes(plan?.id)) {
      setPlanBag([...planBag, plan?.id]);
    } else {
      let temp = planBag.filter((obj) => plan?.id != obj);
      // console.log(temp);
      setPlanBag(temp);
      console.log("afterPushelse", plans);
    }
    console.log("sfhsfd", planBag);
  };
  useEffect(() => {
    if (planBag && planBag?.length) {
      console.log("plalnss", planBag);
    }
  }, [planBag]);
  return (
    <div className="createCoupon">
      <div className="createCoupon_label">Coupon Name</div>
      <Input
        disabled={isEdit}
        placeholder="Enter Name"
        onChange={(e) => props.setName(e.target.value.toUpperCase())}
        value={name}
        maxLength={25}
      />
      <div className="createCoupon_label">Discount</div>
      <div className="createCoupon_discountAmountDropDownContainer">
        <div className="createCoupon_textInput">
          <Input
            disabled={isEdit}
            placeholder="Enter amount"
            onChange={(e) => props.setAmount(e.target.value)}
            value={amount}
          />
        </div>
        <Dropdown
          editable={!isEdit}
          optionsList={[
            {
              id: 1,
              label: "Fixed",
            },
            {
              id: 2,
              label: "Percentage",
            },
          ]}
          itemClickHandler={settingType}
          selectedOption={couponType}
        />
      </div>
      {singleGroupDetails?.subscriptionPlan?.map((plan) => {
        let temp = plans.includes(plan.id);
        if (plan?.price > 0) {
          return (
            <div
              onClick={() => actionOnPlans(plan)}
              className="createCoupon_SinglePlan"
            >
              <div className="DetailscreateCoupon_SinglePlan_Period">
                <CheckBoxComponent
                  checkbox={
                    planBag
                      ? planBag.includes(plan.id)
                        ? true
                        : false
                      : plans.includes(plan.id)
                      ? true
                      : false
                  }
                  plan={plan}
                />
                {plan?.selectedPeriod == "Custom Period"
                  ? plan?.periodTitle
                  : plan?.selectedPeriod}
              </div>

              <div className="createCoupon_SinglePlan_Right">
                {plan?.discount ? (
                  <div className="createCoupon_SinglePlan_Price">
                    ₹{plan?.price}/-
                  </div>
                ) : null}

                <div className="createCoupon_SinglePlan_FinalPrice">
                  {plan.discount
                    ? `₹${
                        parseFloat(plan?.price) - parseFloat(plan?.discount)
                      }/-`
                    : `₹${parseFloat(plan?.price)}`}
                </div>
              </div>
            </div>
          );
        }
      })}
      <div className="createCoupon_ButtonContainer">
        <div
          onClick={isEdit ? handleEditCoupon : handleCreateCoupon}
          className="createCoupon_ButtonContainer_Button"
        >
          {isEdit ? "Save" : "Save and Create"}
          {!isEdit && (
            <img
              src={roundCircle}
              alt="calendar"
              height={"15px"}
              width={"15px"}
              style={{
                marginLeft: 5,
                fill: "white",
                stroke: "white",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  createGroup,
  singleContentDetails,
  createCouponReducer,
}) => {
  return {
    createGroupData: createGroup,
    singleGroupDetails: singleContentDetails?.singleGroupDetails,
    name: createCouponReducer.name,
    amount: createCouponReducer.amount,
    couponType: createCouponReducer.couponType,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    //   getGroupDetails: (payload) => dispatch(actions.getGrouptDetails(payload)),
    setName: (payload) => dispatch(actions.setName(payload)),
    setAmount: (payload) => dispatch(actions.setAmount(payload)),
    setType: (payload) => dispatch(actions.setType(payload)),
    reset: (payload) => dispatch(actions.resetCoupon(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(CreateCoupon);
