import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/successPopup.scss";
import "../../../scss/Purchase/loader.scss";
import joinChannel from "../../../assets/joinChannel.png";
import success from "../../../assets/purchase/success.svg";
import copy from "../../../assets/copy.png";
import Button from "../../../common/Button";
import Bottomsheet from "../../../common/Bottomsheet";
import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
import { COMMON_DEEPLINK_ACTIONS } from "../../../utils/constants";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { AttentionCustomer } from "./AttentionCustomer";
import { useSelector } from "react-redux";
import { WhatsappBlock } from "./WhatsappBlock";

function SuccessPopup(props) {
  const pageData = useSelector(
    (state) => state?.purchaseReducer?.pageData?.data
  );
  const groupData = useSelector(
    (state) => state?.purchaseReducer?.groupData?.data?.group
  );
  const [type, setType] = useState("");
  const [whatsApp, setWhatsApp] = useState(false);
  useEffect(() => {
    if (
      pageData &&
      (pageData?.type == "whatsappGroup" ||
        pageData?.type == "whatsappCommunity")
    ) {
      setType(
        pageData?.type === "whatsappGroup"
          ? "Whatsapp Group"
          : "Whatsapp Community"
      );
      setWhatsApp(true);
    }
    if (
      groupData &&
      (groupData?.type == "whatsappGroup" ||
        groupData?.type == "whatsappCommunity")
    ) {
      setType(
        groupData?.type === "whatsappGroup"
          ? "Whatsapp Group"
          : "Whatsapp Community"
      );
      setWhatsApp(true);
    }
  }, [pageData, groupData]);
  let grpName = localStorage.getItem("groupName");
  let isAddedToGroup = !!localStorage.getItem("isAddedToGroup");
  let paymentSuccessData = window?.paymentSuccessData;
  let freemiumCreateResponse = window?.payload?.freemiumCreateResponse;
  if (freemiumCreateResponse) {
    grpName = freemiumCreateResponse.groupName;
    paymentSuccessData = freemiumCreateResponse;
  }
  const handleThanksSheetClick = (url) => {
    if (url && url?.length) window.open(url, "_blank");
  };

  const handleCopyURLClicked = async (copyURL) => {
    try {
      await navigator?.clipboard?.writeText(copyURL);
      toast.success("Copied Link To Clipboard");
    } catch (error) {
      console.log("error in copying: ", error);
    }
    hitDeeplinkExecutedV2(
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.UTIL_COPY,
        paramOne: copyURL,
      }),
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.UTIL_COPY,
        paramOne: copyURL,
      })
    );
  };

  return (
    <div className="success">
      <img src={success} alt="" />
      <p className="success_title">Success!</p>
      <p className="success_text">
        {whatsApp
          ? `You’ll be added to the ${grpName} ${type} in maximum 24hrs. We’ll notify you once it’s done!`
          : `Your payment for ${grpName} paid telegram channel is successful.`}
      </p>
      {paymentSuccessData?.orderId && !whatsApp && (
        <p className="success_orderid">
          {`Order Id: ${paymentSuccessData.orderId}`}
        </p>
      )}
      {!isAddedToGroup && (
        <React.Fragment>
          {paymentSuccessData?.popupMessage?.inviteLink && (
            <div>
              <div
                onClick={() =>
                  handleThanksSheetClick(
                    paymentSuccessData?.popupMessage?.inviteLink
                  )
                }
                className="success_CTAContainer"
              >
                <img className="success_CTAIcon" src={joinChannel} />
                <div className="success_joinText">
                  Click here to Join Channel
                </div>
              </div>
              <div className="success_inviteLinkContainer">
                <div className="success_inviteLinkContainer_link">
                  {paymentSuccessData?.popupMessage?.inviteLink}
                </div>
                <img
                  onClick={() =>
                    handleCopyURLClicked(
                      paymentSuccessData?.popupMessage?.inviteLink
                    )
                  }
                  className="success_inviteLinkContainer_copy"
                  src={copy}
                />
              </div>
            </div>
          )}
          {!paymentSuccessData?.conditionalApproval &&
            pageData?.type !== "whatsappGroup" &&
            pageData?.type !== "whatsappCommunity" && (
              <div className="success_footNote">
                <div className="success_footNote_title">Please Note</div>
                <div className="success_footNote_content">
                  1. You will receive a link to join the telegram channel on SMS
                  and Email, within 2 hours
                  <br />
                  <br />
                  {paymentSuccessData?.popupMessage?.inviteLink
                    ? "2. If you are opening this link on desktop or laptop, please ensure you are already logged in on Telegram. After clicking on the above button, choose open on web option to join the channel"
                    : "2. In case you don't get the channel invite link within 2 hours contact us on : support@fankonnect.com"}
                </div>
              </div>
            )}
          {(pageData?.type == "whatsappGroup" ||
            pageData?.type == "whatsappCommunity") && <WhatsappBlock />}
        </React.Fragment>
      )}

      {paymentSuccessData?.conditionalApproval &&
      paymentSuccessData?.formLink ? (
        <div>
          <div
            style={{
              width: "100%",
              marginTop: "16px",
              border: "1px dashed #E5E5E5",
            }}
          />
          <AttentionCustomer data={paymentSuccessData} />
        </div>
      ) : null}
    </div>
  );
}

export default SuccessPopup;
