const namespace = "USER_";

export default {
  SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
  SET_USER_DATA: `${namespace}SET_USER_DATA`,

  GET_USER_DETAILS: `${namespace}GET_USER_DETAILS`,
  GET_USER_DETAILS_SUCCESS: `${namespace}GET_USER_DETAILS_SUCCESS`,

  API_VERSION: `${namespace}API_VERSION`,
  ORG_IDS: `${namespace}ORG_IDS`,
  CHANNEL_TYPE: `${namespace}CHANNEL_TYPE`,
  EDIT_GROUP_TYPE: `${namespace}EDIT_GROUP_TYPE`,
  VERSION2: `${namespace}VERSION2`,
};
