import CONSTANTS from "./constants";

const urlPrefix = "/v1";

const apiEndPoints = {
  [CONSTANTS.ADD_BANK_DETAILS_POST]: {
    url: () => {
      return `${urlPrefix}/add/bank`;
    },
    method: "POST",
  },
  [CONSTANTS.GET_BANK_ACCOUNTS]: {
    url: () => {
      return `/user/getBankDetails`;
    },
    method: "GET",
  },
  [CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT]: {
    url: () => {
      return `/user/changeBankDetails`;
    },
    method: "POST",
  },
  [CONSTANTS.AGREE_TERMS_AND_CONDITIONS]: {
    url: () => {
      return `/user/bankDetailsLogs`;
    },
    method: "POST",
  },
};

export default apiEndPoints;
