// REDUX CONSTANTS
const namespace = "USER_DETAILS";

const CONSTANTS = {
  USER_DETAILS_GET: `${namespace}_GET`,
  USER_DETAILS_GET_LOADER: `${namespace}_GET_LOADER`,
  USER_DETAILS_GET_SUCCESS: `${namespace}_GET_SUCCESS`,
  USER_DETAILS_GET_FAILURE: `${namespace}_GET_FAILURE`,
  USER_DETAILS_RESET: `${namespace}USER_DETAILS_RESET`,
};

export default CONSTANTS;
