import { call, put, takeLatest } from "redux-saga/effects";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";
import {
  createGSTProfileService,
  editGSTProfileService,
  fetchProfileService,
} from "./service";

function* createGSTProfileSaga(payload) {
  try {
    const apiCall = yield call(createGSTProfileService, payload);
    const { data } = apiCall?.data;
    const getAction = yield call(
      getActionObject,
      CONSTANTS.PROFILE_CREATE,
      data
    );
    yield put({ ...getAction });
    payload?.payload?.navigate(-1);
  } catch (error) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.PROFILE_CREATE_FAIULRE
    );
    yield put({ ...getAction });
  }
}

function* editGSTProfileSaga(payload) {
  try {
    const apiCall = yield call(editGSTProfileService, payload);
    const { data } = apiCall?.data;
    const getAction = yield call(getActionObject, CONSTANTS.PROFILE_EDIT, data);
    yield put({ ...getAction });
    payload?.payload?.navigate(-1);
  } catch (error) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.PROFILE_EDIT_FAIULRE
    );
    yield put({ ...getAction });
  }
}

function* fetchProfileSaga(payload) {
  try {
    const apiCall = yield call(fetchProfileService, {});
    const { data } = apiCall?.data;
    const getAction = yield call(
      getActionObject,
      CONSTANTS.PROFILE_FETCH_SUCCESS,
      data
    );
    yield put({ ...getAction });
  } catch (error) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.PROFILE_FETCH_FAIULRE
    );
    yield put({ ...getAction });
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.PROFILE_CREATE_CALL, createGSTProfileSaga);
  yield takeLatest(CONSTANTS.PROFILE_EDIT_CALL, editGSTProfileSaga);
  yield takeLatest(CONSTANTS.PROFILE_FETCH, fetchProfileSaga);
}
