import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import * as actions from "./store/User/action";
import * as EDActions from "./routes/myEarnings/sagaStore/actions";
import ReactGA from "react-ga";
import Layout from "./layout";
import Home from "./routes/Home";
import Statement from "./routes/Statement";
import CreateGroup from "./routes/CreateGroup";
import CreateMessage from "./routes/CreateMessage";
import { connect, useDispatch, useSelector } from "react-redux";
import Purchase from "./routes/Purchase";
import BankDetails from "./routes/bankDetails";
import MyEarnings from "./routes/myEarnings/EarningDash";
import GroupDetails from "./routes/Details/groupDetails";
import MessageDetails from "./routes/Details/messageDetails";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Tnc from "./routes/TnC";
import PreAuth from "./routes/PreAuth";
import { setAppToken } from "./store/persistedData";
import ReactHotToast from "./common/ReactHotToast";
import { GA_MEASUREMENT_ID } from "./utils/constants";
import ChannelEarnings from "./routes/myEarnings/ChannelEarnings";
import MultiBank from "./routes/bankDetails/Multibank";
import CreateCoupon from "./routes/coupons/createCoupon";
import AllCoupons from "./routes/coupons/AllCoupons";
import BankAccountDetails from "./routes/bankDetails/BankAccountDetails";
import ReactLoading from "react-loading";
import Profile from "./routes/Profile";
import AddNewAdmin from "./routes/coadmins/AddNewAdmin";
import AdminList from "./routes/coadmins/AdminList";
import PlatformAutomationPage from "./routes/Automation/Whatsapp";
import TelegramAutomation from "./routes/Automation/TelegramAutomation/index";
import TelegramLinkExisting from "./routes/Automation/TelegramAutomation/LinkExisting";
import EarningsTabs from "./routes/myEarnings";
import ExportReport from "./routes/myEarnings/Report/ExportReport";
import EarningDashSearch from "./routes/myEarnings/EarningDashSearch";
//  whatsapp create group
import WACreateGroup from "./routes/WhatsappFlow/WACreateGroup";
import { getFirebaseRemoteData } from "./utils/getFirebaseRemoteData";
import EditPreview from "./routes/WhatsappFlow/WACreateGroup/EditPreview";
import NotFound from "./routes/NotFound";

function App() {
  const token = useSelector((state) => state?.persistedDataReducer?.token);
  const [v2status, setV2Status] = useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function getRemoteData() {
      const firebaseData = await getFirebaseRemoteData();
      setV2Status(firebaseData.v2);
      dispatch(actions.setApiVersion(firebaseData.v2));
      dispatch(actions.setOrgIdsData(firebaseData.orgIds));
      dispatch(actions.setVersion2Action(firebaseData.version2));
    }
    if (new URLSearchParams(window?.location?.search).get("token")) {
      setAppToken(token);
    }
    ReactGA.initialize(GA_MEASUREMENT_ID);
    getRemoteData();
  }, []);

  useEffect(() => {
    window.updateBuildInfo = (dataObj) => {
      // alert("updateBuildInfo");
    };
  }, []);

  if (v2status !== null) {
    return (
      <div className="App">
        <Routes>
          <Route path="/notfound" element={<NotFound />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/:tabEnum" element={<Home />} />
            <Route
              path="/platformAutomation"
              element={<PlatformAutomationPage />}
            />
            <Route
              path="/telegramAutomation"
              element={<TelegramAutomation />}
            />
            <Route
              path="/telegramExistingChannel"
              element={<TelegramLinkExisting />}
            />
            <Route
              path="/group/whatsapp/create"
              element={<WACreateGroup isEdit={0} />}
            />
            <Route
              path="/group/whatsapp/preview"
              element={<EditPreview fromCreate={1} />}
            />
            <Route
              path="/group/whatsapp/:id"
              element={<EditPreview fromCreate={0} />}
            />
            <Route
              path="group/whatsapp/:id/edit"
              element={<WACreateGroup isEdit={1} />}
            />

            <Route path="/bank-accounts" element={<MultiBank />} />
            <Route path="/bank-details" element={<BankDetails />} />
            <Route
              path="/bank-account-details"
              element={<BankAccountDetails data={null} />}
            />
            <Route path="/my-earnings" element={<MyEarnings />} />
            <Route path="/exportReport" element={<ExportReport />} />
            <Route path="/earnings" element={<EarningsTabs />} />
            <Route path="/ChannelEarnings" element={<ChannelEarnings />} />
            <Route path="/EarningDashSearch" element={<EarningDashSearch />} />

            <Route
              path="/group/:id"
              element={<GroupDetails fromCreate={0} />}
            />
            <Route path="/group/:id/statement" element={<Statement />} />
            <Route path="/coupons" element={<AllCoupons />} />

            <Route path="group/:id/edit" element={<CreateGroup isEdit={1} />} />
            <Route path="group/:id/createcoupon" element={<CreateCoupon />} />
            <Route
              path="group/:id/editCoupon"
              element={<CreateCoupon isEdit={1} />}
            />
            <Route path="group/:id/coupons" element={<AllCoupons />} />

            <Route path="group/:id/addNewAdmin" element={<AddNewAdmin />} />
            <Route path="group/:id/adminList" element={<AdminList />} />

            <Route path="/message/:id" element={<MessageDetails />} />
            <Route path="/message/:id/statement" element={<Statement />} />
            <Route
              path="message/:id/edit"
              element={<CreateMessage isEdit={1} />}
            />

            <Route path="group/create" element={<CreateGroup isEdit={0} />} />

            <Route
              path="group/create/success"
              element={<GroupDetails fromCreate={1} />}
            />

            <Route path="message/create" element={<CreateMessage />} />
            <Route
              path="message/create/success"
              element={<MessageDetails fromCreate={1} />}
            />
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route path="/m/:id" element={<Purchase />} />
          <Route path="/g/:id" element={<Purchase />} />
          <Route path="/pay/:id" element={<PreAuth />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/tnc" element={<Tnc />} />
        </Routes>
        <ReactHotToast />
      </div>
    );
  } else {
    return (
      <div className="Loader">
        <ReactLoading
          type={"spinningBubbles"}
          color={"#2278FF"}
          height={50}
          width={50}
        />
      </div>
    );
  }
}

const mapDispatchWithProps = (dispatch) => {
  return {
    setUserData: (payload) => dispatch(actions.setUserData(payload)),
    getUserDetails: (payload) => dispatch(actions.getUserDetails(payload)),
    setApiVersion: (payload) => dispatch(actions.setApiVersion(payload)),
    setOrgIdsData: (payload) => dispatch(actions.setOrgIdsData(payload)),
    setVersion2: (payload) => dispatch(EDActions.setVersion2Action(payload)),
  };
};

export default connect(null, mapDispatchWithProps)(App);
