const namespace = "DETAILS_";

export default {
  SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
  CHECK_DATA_MEMBER: `${namespace}CHECK_DATA_MEMBER`,
  RESET_MEMEBER_DATA: `${namespace}RESET_MEMEBER_DATA`,
  GET_GROUP_DETAILS: `${namespace}GET_GROUP_DETAILS`,
  GET_MESSAGE_DETAILS: `${namespace}GET_MESSAGE_DETAILS`,
  GET_CONTENT_DETAILS_SUCCESS: `${namespace}GET_CONTENT_DETAILS_SUCCESS`,

  GET_STATEMENT: `${namespace}GET_STATEMENT`,
  GET_STATEMENT_SUCCESS: `${namespace}GET_STATEMENT_SUCCESS`,
  RESET_STATEMENT_DATA: `${namespace}RESET_STATEMENT_DATA`,

  RESET_SINGLE_MESSAGE_DETAILS: `${namespace}RESET_SINGLE_MESSAGE_DETAILS`,
  RESET_SINGLE_GROUP_DETAILS: `${namespace}RESET_SINGLE_GROUP_DETAILS`,
  ALL_COUPONS_GET: `${namespace}_GET`,
  ALL_COUPONS_LOADER: `${namespace}_LOADER`,
  ALL_COUPONS_SUCCESS: `${namespace}_SUCCESS`,
  ALL_COUPONS_FAILURE: `${namespace}_FAILURE`,
};
