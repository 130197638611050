import { put, call, select, takeLatest } from "redux-saga/effects";
import * as services from "../../store/Home/service";
import CONSTANTS from "./constants";
import toast from "react-hot-toast";

function* fetchGroups(payload) {
  if (payload?.payload?.offset === 0)
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: true,
    });
  const response = yield call(services.fetchGroupsService, payload);
  switch (response && response.status) {
    case 200:
      yield put({
        type: CONSTANTS.FETCH_GROUPS_SUCCESS,
        payload: response?.data?.data,
        offset: payload?.payload?.offset,
      });
    case 400:
    default:
      return;
  }
}

function* fetchMessages(payload) {
  if (payload?.payload?.offset === 0)
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: true,
    });
  const response = yield call(services.fetchMessagesService, payload);
  switch (response && response.status) {
    case 200:
      yield put({
        type: CONSTANTS.FETCH_MESSAGES_SUCCESS,
        payload: response?.data?.data,
        offset: payload?.payload?.offset,
      });
    case 400:
    default:
      return;
  }
}

function* setTermsAndConditions({ payload }) {
  console.log("PAYLOAD IS", payload);
  yield put({
    type: CONSTANTS.SET_IS_LOADING,
    payload: true,
  });
  try {
    const response = yield call(services.setTermsAndConditionsService, payload);
    if (response && response.status) {
      toast.success("Successfull");
      yield put({
        type: CONSTANTS.SET_TERMSANDCONDITIONS_SUCCESS,
        payload: response?.data,
      });
      yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: false,
      });
    }
  } catch ({ message }) {
    toast.error(message);
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: false,
    });
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.FETCH_GROUPS, fetchGroups);
  yield takeLatest(CONSTANTS.FETCH_MESSAGES, fetchMessages);
  yield takeLatest(CONSTANTS.SET_TERMSANDCONDITIONS, setTermsAndConditions);
}
