import { call, put, takeLatest } from "redux-saga/effects";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";
import { getAdminList } from "./service";

function* getCoAdminsSaga(payload) {
  try {
    const apiCall = yield call(getAdminList, payload?.payload);
    const { data } = apiCall?.data;
    const getAction = yield call(
      getActionObject,
      CONSTANTS.GET_COADMINS_SUCCESS,
      data
    );
    yield put({ ...getAction });
  } catch (error) {
    console.log(error);
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.GET_COADMINS, getCoAdminsSaga);
}
