import { configureStore, getd } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import rootSaga from "./sagas";
import createSagaMiddleware from "redux-saga";
import layoutReducer from "../layout/store/reducer";
import userDetailsReducer from "./userDetailsReducer/reducer";

import UserReducer from "./User/reducer";
import HomeReducer from "./Home/reducer";
import CreateGroupReducer from "./CreateGroup/reducer";
import CreateMessageReducer from "./CreateMessage/reducer";
import PurchaseReducer from "./Purchase/reducer";
import DetailsReducer from "./Details/reducer";
import bankDetailsReducer from "../routes/bankDetails/store/reducer";
import persistedDataReducer from "./persistedData";
import earningsReducer from "../routes/myEarnings/sagaStore/reducer";
import channelEarningsReducer from "../routes/myEarnings/sagaStore/channelEarningsReducer";
import createCouponReducer from "../routes/coupons/createCoupon/store/reducer";
import allCouponsReducer from "../routes/coupons/store/reducer";
import leadCaptureRedeucer from "../routes/LandingPage/store/reducer";

import gstProfileReducer from "../routes/Profile/store/reducer";

import coAdminReducer from "../routes/coadmins/store/reducer";
import getAutomatedChannelReducer from "../store/automatedGroup/reducer";
import getCountryCodeReducer from "../store/GetCountryCode/reducer";

const rootReducer = combineReducers({
  user: UserReducer,
  home: HomeReducer,
  createGroup: CreateGroupReducer,
  createMessage: CreateMessageReducer,
  layoutReducer: layoutReducer,
  userDetailsReducer: userDetailsReducer,
  purchaseReducer: PurchaseReducer,
  singleContentDetails: DetailsReducer,
  bankDetailsReducer: bankDetailsReducer,
  singleContentDetails: DetailsReducer,
  persistedDataReducer: persistedDataReducer,
  earningsReducer: earningsReducer,
  channelEarningsReducer: channelEarningsReducer,
  createCouponReducer: createCouponReducer,
  allCoupons: allCouponsReducer,
  leadCaptureRedeucer: leadCaptureRedeucer,
  gstProfileReducer: gstProfileReducer,
  coAdminReducer: coAdminReducer,
  getAutomatedChannelReducer: getAutomatedChannelReducer,
  getCountryCodeReducer: getCountryCodeReducer,
});

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: rootReducer,
  middleware: (defaultMiddleware) => defaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
