import { BASE_URL } from "../../axios-config";
import axios from "axios";

export const getUserDetailsService = (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/user/details`,
    headers: {
      "x-access-token": payload?.payload?.token,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
