import React, { useEffect, useRef, useState } from "react";
import "./addNewAdmin.scss";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import Bottomsheet from "../../common/Bottomsheet";
import Button from "../../common/Button";
import Input from "../../common/Input";
import OtpInput from "../../common/OtpInput";
import Timer from "../../common/Timer";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import * as actions from "./store/actions";
import { createCoAdminService } from "./store/service";
import { getOtpService } from "./store/service";
import coAdminSuccess from "../../assets/coAdminSuccess.png";

const AddNewAdmin = (props) => {
  useLayoutPageHeading("Add New Admin");

  const [openOTPBottomSheet, setOpenOTPBottomSheet] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [sessionId, setSessionId] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [promoted, setPromoted] = useState(false);
  const backSpaceRef = useRef();
  const firstOtpElRef = useRef();
  const navigate = useNavigate();
  let { id } = useParams();
  let groupId;
  const arr = window.location.pathname.split("/");
  groupId = arr[2];

  const resendOtpHandler = async () => {
    // let formData = {
    //   mobile: "91" + formDetails?.mobile,
    //   whatsappStatus: waChecked,
    // };
    // generateOtpAction(formData);
    // resendHandler();
    setOpenOTPBottomSheet(true);
    await getOtpService({
      mobile: `91${mobile}`,
      groupId,
      whatsappStatus: true,
    }).then((data) => {
      setSessionId(data.data.data.sessionId);
    });
    setSeconds(30);
  };

  useEffect(() => {
    return () => props.reset();
  }, []);

  const otpHandler = (element, index) => {
    const reg = new RegExp("^[0-9]*$");
    if (
      element.target.value.trim() &&
      element.target.nextSibling &&
      reg.test(element.target.value)
    ) {
      element.target.nextSibling.focus();
    }

    if (element.target.value.length <= 1 && reg.test(element.target.value)) {
      setOtp([
        ...otp.map((data, idx) => {
          return idx === index ? element.target.value : data;
        }),
      ]);
    }
  };

  const backspaceHandler = (e) => {
    if (e.keyCode == 13) {
      // let payload = {
      //   otp: otp.join("").toString(),
      //   sessionId: generateOtp?.sessionId,
      //   mobile: 91 + formDetails?.mobile,
      //   whatsappStatus: waChecked,
      // };
      // otpValueAction(payload.otp);
      // verifyOtpAction(payload);
    }
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
      return;
    }
    // clearTimeout(backSpaceRef.current);
    backSpaceRef.current = setTimeout(() => {
      if (e.keyCode === 8 && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }, 0);
  };

  let otpResponse;

  const { name, mobile } = props;
  return (
    <div className="AddNewAdmin">
      <Popup
        closeOnDocumentClick={false}
        contentStyle={{ width: "85%", borderRadius: 5 }}
        open={successPopup}
      >
        <div className="AddNewAdmin_successPopup">
          <img src={coAdminSuccess} height={"80px"} width={"80px"} />
          <div className="AddNewAdmin_successHeading">
            {promoted ? "Promoted Successfuly" : "Invitation sent successfully"}
          </div>
          <div className="AddNewAdmin_successText">
            {promoted
              ? "User has been promoted to Admin successfully."
              : "Once the person joins the channel they will automatically get promoted to admin."}
          </div>
          <div className="AddNewAdmin_successBTNContainer">
            <Button
              onClick={() => {
                setSuccessPopup(false);
                navigate(`/group/${id}/adminList`, { replace: true });
              }}
            >
              Okay, Thanks
            </Button>
          </div>
        </div>
      </Popup>
      {openOTPBottomSheet && (
        <Bottomsheet
          header={"OTP Verification"}
          handleClose={() => setOpenOTPBottomSheet(false)}
        >
          <div>
            <div className="AddNewAdmin_OTPSheetHeading">
              We've sent an OTP to this mobile number
            </div>
            <div className="AddNewAdmin_OTPNumber">
              <p className="AddNewAdmin_OTPNumber-code">+91</p>
              <div className="AddNewAdmin_OTPNumber-input">{mobile}</div>
              <button
                className="AddNewAdmin_OTPNumber-button"
                onClick={() => {
                  setOpenOTPBottomSheet(false);
                }}
              >
                Edit
              </button>
            </div>

            <p className="AddNewAdmin_content">Enter OTP here</p>
            <div className="AddNewAdmin_field">
              {otp.map((data, index) => {
                return (
                  <OtpInput
                    additionalClasses="AddNewAdmin_inputStyle"
                    type="number"
                    id="otp2"
                    key={index}
                    value={data}
                    handleChange={(e) => otpHandler(e, index)}
                    onKeyDown={(e) => backspaceHandler(e, index)}
                    refEl={index === 0 ? firstOtpElRef : null}
                    onFocus={(e) => e.target.select()}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                );
              })}
            </div>
            {seconds ? (
              <div className="otp_waiting">
                Resend in &nbsp;
                <Timer
                  minutes={minutes}
                  seconds={seconds}
                  setMinutes={setMinutes}
                  setSeconds={setSeconds}
                />{" "}
                &nbsp;seconds
              </div>
            ) : (
              <p className="otp_waiting" onClick={resendOtpHandler}>
                Send OTP again
              </p>
            )}

            <div className="AddNewAdmin_gotItBTNContainer">
              <Button
                onClick={() => {
                  setOpenOTPBottomSheet(false);
                  const payload = {
                    groupId: id,
                    mobile: `91${mobile}`,
                    name: name,
                    otp: otp.join(""),
                    sessionId: sessionId,
                  };
                  createCoAdminService(payload)
                    .then((response) => {
                      setOtp(new Array(4).fill(""));
                      if (response && response.status == 200) {
                        console.log(response);
                        setPromoted(response?.data?.data?.upgradedToAdmin);
                        setSuccessPopup(true);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {"Verify"}
              </Button>
            </div>
          </div>
        </Bottomsheet>
      )}
      <div className="AddNewAdmin_enterAdminHead">Enter new admin details</div>
      <div className="AddNewAdmin_enterAdminSubHead">
        Enter details of user you want to add as admin.
      </div>
      <div className="AddNewAdmin_Form">
        <Input
          label={"Name"}
          placeholder="Enter Name Here"
          onChange={(e) => props.setName(e.target.value)}
          value={name}
        />
        <Input
          label={"Phone Number"}
          placeholder={"Enter Phone Number Here"}
          onChange={(e) => props.setMobile(e.target.value)}
          value={mobile}
          maxLength={10}
        />
        <div className="AddNewAdmin_footerText">
          *OTP will be sent to the above mobile number & you will be asked to
          verify it in the next step
        </div>
      </div>
      <div className="AddNewAdmin_footerBTNContainer">
        {/* <Button onClick={() => navigate(`/group/${id}/adminList`)}> */}
        <Button
          disabled={!(name && name.length > 0 && mobile && mobile.length == 10)}
          onClick={async () => {
            setSeconds(30);
            setOpenOTPBottomSheet(true);
            await getOtpService({
              mobile: `91${mobile}`,
              whatsappStatus: true,
              groupId,
            }).then((data) => {
              setSessionId(data.data.data.sessionId);
            });
          }}
        >
          {"Proceed"}
        </Button>
      </div>
    </div>
  );
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setName: (payload) => dispatch(actions.setNameAction(payload)),
    setMobile: (payload) => dispatch(actions.setMobileAction(payload)),
    reset: (payload) => dispatch(actions.resetStateAction(payload)),
  };
};

const mapStateToProps = ({ coAdminReducer }) => {
  return {
    name: coAdminReducer.name,
    mobile: coAdminReducer.mobile,
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(AddNewAdmin);
