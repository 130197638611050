import CONSTANTS from "./constants";

export const setBrandName = (payload) => {
  return {
    type: CONSTANTS.SET_BRAND_NAME,
    payload,
  };
};

export const setAddress = (payload) => {
  return {
    type: CONSTANTS.SET_ADDRESS,
    payload,
  };
};

export const setPinCode = (payload) => {
  return {
    type: CONSTANTS.SET_PINCODE,
    payload,
  };
};

export const setGSTNumber = (payload) => {
  return {
    type: CONSTANTS.SET_GST_NUMBER,
    payload,
  };
};

export const setState = (payload) => {
  return {
    type: CONSTANTS.SET_STATE,
    payload,
  };
};

export const createGSTProfile = (payload) => {
  return {
    type: CONSTANTS.PROFILE_CREATE_CALL,
    payload,
  };
};

export const editGSTProfile = (payload) => {
  return {
    type: CONSTANTS.PROFILE_EDIT_CALL,
    payload,
  };
};

export const fetchProfileAction = (payload) => {
  return {
    type: CONSTANTS.PROFILE_FETCH,
    payload,
  };
};
