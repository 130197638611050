import CONSTANTS from "./constants";

export const setIsLoading = (payload) => {
  return {
    type: CONSTANTS.SET_IS_LOADING,
    payload,
  };
};

export const setTitle = (payload) => {
  return {
    type: CONSTANTS.SET_TITLE,
    payload,
  };
};

export const setDescription = (payload) => {
  return {
    type: CONSTANTS.SET_DESCRIPTION,
    payload,
  };
};

export const setAccessPrice = (payload) => {
  return {
    type: CONSTANTS.SET_ONE_TIME_PRICE,
    payload,
  };
};

export const setCategory = (payload) => {
  return {
    type: CONSTANTS.SET_CATEGORY,
    payload,
  };
};

export const addPaymentPlan = (payload) => {
  return {
    type: CONSTANTS.ADD_PRICE_PLAN,
    payload,
  };
};

export const setDiscount = (payload) => {
  return {
    type: CONSTANTS.SET_DISCOUNT,
    payload,
  };
};

export const setOfferTitle = (payload) => {
  return {
    type: CONSTANTS.SET_OFFER_TITLE,
    payload,
  };
};

export const setSelectedPlan = (payload) => {
  return {
    type: CONSTANTS.SET_SELECTED_PLAN,
    payload,
  };
};

export const deletePlan = (payload) => {
  return {
    type: CONSTANTS.DELETE_PLAN,
    payload,
  };
};

export const setPricingData = (payload) => {
  return {
    type: CONSTANTS.SET_PRICING_DATA,
    payload,
  };
};

export const setSelectedSubscriptionPeriod = (payload) => {
  return {
    type: CONSTANTS.SET_SUBSCRIPTION_PERIOD,
    payload,
  };
};
export const setCustomPeriod = (payload) => {
  return {
    type: CONSTANTS.CUSTOM_PERIOD,
    payload,
  };
};
export const setCustomNumber = (payload) => {
  return {
    type: CONSTANTS.CUSTOM_DAYS,
    payload,
  };
};

export const setSubscriptionPrice = (payload) => {
  return {
    type: CONSTANTS.SET_SUBSCRIPTION_PRICE,
    payload,
  };
};

export const createGroup = (payload) => {
  return {
    type: CONSTANTS.CREATE_GROUP,
    payload,
  };
};

export const editGroup = (payload) => {
  return {
    type: CONSTANTS.EDIT_GROUP,
    payload,
  };
};

export const resetState = () => {
  return {
    type: CONSTANTS.RESET_STATE,
  };
};
export const addFreePlanDays = (payload) => {
  return {
    type: CONSTANTS.ADD_FREE_PLAN_DAYS,
    payload,
  };
};
export const resetPricesPlans = () => {
  return {
    type: CONSTANTS.RESET_PRICES_PLANS,
  };
};
export const setCAToggle = (payload) => {
  return {
    type: CONSTANTS.SET_CONDITIONAL_TOGGLE,
    payload,
  };
};
export const setFormLinkData = (payload) => {
  return {
    type: CONSTANTS.SET_FORM_LINK_DATA,
    payload,
  };
};
export const getAutomationBotLink = (payload) => {
  console.log("actions");
  return {
    type: CONSTANTS.GET_BOT_LINK,
    payload,
  };
};

export const setPlatformSelection = (payload) => {
  return {
    type: CONSTANTS.PLATFORM_SELECTED,
    payload,
  };
};
export const setChannelType = (payload) => {
  return {
    type: CONSTANTS.CHANNEL_TYPE,
    payload,
  };
};
export const setImage = (payload) => {
  return {
    type: CONSTANTS.SET_IMAGE,
    payload,
  };
};
