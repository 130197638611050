export const accordionTelegramData = [
  {
    title: "What happens after I make the payment?",
    content: `After successful payment, you’ll get the invite link via SMS to join the exclusive channel within 2 hours. You can join the channel through that link.`,
  },
  {
    title:
      "What if I don’t get an invite link to the channel after successful payment?",
    content: `If you don’t get an invite link to the channel even after payment, reach out to us on our support email-  support@fankonnect.com. Our team will help you in getting added to the group`,
  },
  {
    title: "What is the refund policy?",
    content: `Once you have made the payment and joined the channel, there won’t be any refund.`,
  },
  {
    title: "I don’t have a Telegram account, so how can I join this group?",
    content: `First, you will have to create an account on Telegram and then once you have purchased the channel subscription you will be given an invite link to join the channel via SMS.`,
  },
];
export const accordionWhatsAppData = [
  {
    title: "What happens after I make the payment?",
    content: `After successful payment, you’ll get the invite link via WhatsApp to join the exclusive Group/Community within 24 hours.`,
  },
  {
    title:
      "What if I don’t get added to WhatsApp Group/Community after successful payment?",
    content: `If you are not added in Group/Community post payment check your WhatsApp settings , if even after changing the setting you are not added  then contact us on support@fankonnect.com.`,
  },
  {
    title: "What is the refund policy?",
    content: `Once you have made the payment and joined the Group/Community, there won’t be any refund.`,
  },
];
