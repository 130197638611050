import CONSTANTS from "./constants";

const initialState = {
  isLoading: false,
  title: "",
  description: "",
  price: "",
  lockedMessage: "",
  attachments: [],
};

const setIsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.payload,
  };
};

const setTitle = (state, action) => {
  return {
    ...state,
    title: action.payload,
  };
};

const setDescription = (state, action) => {
  return {
    ...state,
    description: action.payload,
  };
};

const setPrice = (state, action) => {
  return {
    ...state,
    price: action.payload,
  };
};

const setLockedMessage = (state, action) => {
  return {
    ...state,
    lockedMessage: action.payload,
  };
};

const setAttachments = (state, action) => {
  return {
    ...state,
    attachments: [...action.payload],
  };
};

const removeAttachment = (state, action) => {
  const indexToRemove = action.payload;
  let currentAttachments = [...state.attachments];
  currentAttachments.splice(indexToRemove, 1);
  return {
    ...state,
    attachments: [...currentAttachments],
  };
};

const createMessageSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    ...action.payload,
  };
};

const resetState = () => {
  return {
    title: "",
    description: "",
    price: "",
    lockedMessage: "",
    attachments: [],
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_IS_LOADING:
      return setIsLoading(state, action);
    case CONSTANTS.RESET_STATE:
      return resetState(state, action);
    case CONSTANTS.SET_TITLE:
      return setTitle(state, action);
    case CONSTANTS.SET_DESCRIPTION:
      return setDescription(state, action);
    case CONSTANTS.SET_PRICE:
      return setPrice(state, action);
    case CONSTANTS.SET_LOCKED_MESSAGE:
      return setLockedMessage(state, action);
    case CONSTANTS.SET_ATTACHMENTS:
      return setAttachments(state, action);
    case CONSTANTS.REMOVE_ATTACHMENT:
      return removeAttachment(state, action);
    case CONSTANTS.CREATE_MESSAGE_SUCCESS:
      return createMessageSuccess(state, action);
    case CONSTANTS.EDIT_MESSAGE_SUCCESS:
    default:
      return state;
  }
};

export default reducer;
