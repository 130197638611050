export const PROD_BASE_URL = "https://api.fankonnect.com/clp-connect";
export const PRE_PROD_BASE_URL = "https://apip-preprod.fankonnect.com/clp-connect";
export const QA_BASE_URL = "https://fankonnect-qa.classplusapp.com/";

const getBaseUrl = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "PROD":
      return PROD_BASE_URL;
    case "PREPROD":
      return PRE_PROD_BASE_URL;
    case "QA":
      return QA_BASE_URL;
    default:
      return PRE_PROD_BASE_URL;
  }
};

export const BASE_URL = getBaseUrl();
