import axios from "axios";
import _getUserToken from "./getUserToken";
import { COMMON_DEEPLINK_ACTIONS } from "./constants";
import { hitDeeplinkExecutedV2 } from "./hitDeeplink";
import { BASE_URL } from "../axios-config";
import parseError from "./parseError";

const getStaticToken = () =>
  "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MTQsIm5hbWUiOiJTYW5kZWVwIiwiZW1haWwiOiJTYW5keUBnbWFpbC5zZGZzIiwibW9iaWxlIjoiODg1MTM1MDA2NiIsImlhdCI6MTY1MjUxOTM4MiwiZXhwIjoxNjU0MjQ3MzgyfQ.T1ET_k_VGKfIEyQ-9lpBXETkaWEKbbcrUVgLq2YH17kCbG_F1fe_D9snuMAQPymV";

// FUNCTION TO GET USER TOKEN
export const getUserToken = _getUserToken;

// FUNCTION TO GET API HEADERS
export const getHeaders = (customToken, region, defaultLanguage) => {
  return {
    "x-access-token": customToken || getUserToken(),
  };
};

// DEFAULT AXIOS CONFIG FOR API CALLS
const getAxiosConfig = (
  method,
  urlCallback,
  payload,
  customToken,
  region,
  defaultLanguage,
  extraHeaders
) => {
  return {
    method: method, // API METHOD ex. GET POST PATCH
    url: `${BASE_URL}${urlCallback(payload)}`, // PASSING WHOLE PAYLOAD OBJECT IN URL CALLBACK (DESTRUCT IN ENDPOINTS)
    ...(method === "GET" ? {} : { data: payload }), // PAYLOAD DATA
    headers: {
      ...getHeaders(customToken, region, defaultLanguage),
      ...extraHeaders,
    }, // API HEADERS // CUSTOM TOKEN IF DON'T WANT TO USE USER TOKEN
  };
};

// AXIOS INTERCEPTOR RESPONSE TO HANDLE REFRESH TOKEN
axios.interceptors.response.use(
  // RETURNING RESPONSE ON SUCCESS
  (res) => res,
  // HANDLING ERROR
  (err) => {
    parseError(err);

    // RUNNING THE REFRESH TOKEN LOGIC IF ERROR RESPONSE CODE IS 401
    if (err?.response?.status === 401) {
      hitDeeplinkExecutedV2(
        JSON.stringify({
          screen: COMMON_DEEPLINK_ACTIONS.UTIL_LOG_OUT,
        }),
        JSON.stringify({
          screen: COMMON_DEEPLINK_ACTIONS.UTIL_LOG_OUT,
        })
      );
      return;
    }
    // RETURNING THE AXIOS ERROR
    return Promise.reject(err);
  }
);

export default getAxiosConfig;
