import CONSTANTS from "./constants";

const initialState = {
  name: "",
  amount: "",
  couponType: "",
};

const setName = (state, action) => {
  return {
    ...state,
    name: action.payload,
  };
};

const setAmount = (state, action) => {
  return {
    ...state,
    amount: action.payload,
  };
};

const setType = (state, action) => {
  return {
    ...state,
    couponType: action.payload,
  };
};

const reset = (state, action) => {
  return {
    ...state,
    name: "",
    amount: "",
    couponType: "",
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_NAME:
      return setName(state, action);
    case CONSTANTS.SET_AMOUNT:
      return setAmount(state, action);
    case CONSTANTS.SET_TYPE:
      return setType(state, action);
    case CONSTANTS.RESET:
      return reset();
    default:
      return state;
  }
};

export default reducer;
