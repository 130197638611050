import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/groupDetails.scss";
import Input from "../../../common/Input";
import "../../../scss/Purchase/userDetails.scss";
import Button from "../../../common/Button";
import lock from "../../../assets/purchase/lock.svg";
import * as actions from "../../../store/Purchase/actions";
import * as Ipactions from "../../../store/GetCountryCode/actions";
import ReactLoading from "react-loading";
import arrowLeft from "../../../assets/purchase/arrow-left.svg";
import whatsapp from "../../../assets/userDetail/whatsapp.png";
import { connect, useSelector, useDispatch } from "react-redux";
import Bottomsheet from "../../../common/Bottomsheet";

import SelectCountry from "../../../components/SelectCountry";
function UserDetails(props) {
  let {
    setPurchaseScreen,
    getCountryCode,
    setCountry,
    setFormDetails,
    formDetails,
    generateOtp,
    generateOtpAction,
    generateOtpLoading,
    verifyOtpLoading,
    waChecked,
    WAChecked,
  } = props;
  let [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = React.useState(true);

  const pageData = useSelector(
    (state) => state?.purchaseReducer?.pageData?.data
  );

  const ipData = useSelector((state) => state.getCountryCodeReducer.data);
  const isLoading = useSelector(
    (state) => state.getCountryCodeReducer.isLoading
  );

  const [loading, setLoading] = useState(false);

  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  //CONDITION TO CHECK VALID EMAIL AND EMPTY MOBILE NUMBER
  useEffect(() => {
    if (ipData !== null && ipData?.countryISO === "91") {
      if (formDetails?.mobile?.length == 10) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (ipData !== null && ipData?.countryISO !== "91") {
      if (formDetails?.email !== "" && formDetails?.email.includes("@")) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    //THIS IS THE CASE WHEN IP API doesn't work or throws error
    else {
      if (formDetails?.mobile?.length == 10) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [formDetails?.mobile, formDetails?.email]);

  useEffect(() => {
    WAChecked(true);
    console.log("pageData: ", pageData);
  }, []);

  //CALL THE IP API ON INITIAL RENDER OF USER DETAILS PAGE
  useEffect(() => {
    getCountryCode();
  }, []);

  //SET THE COUNTRY IN REDUCER ON THE BASIS OF ISO CODE COMING FROM IP API
  useEffect(() => {
    if (ipData !== null) {
      setCountry(ipData?.countryISO);
    }
  }, [ipData]);

  //A LOADING INDICATOR TILL THE TIME IP API GETS CALLED
  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [isLoading]);

  return (
    <>
      {!loading && (
        <div className="userDetails">
          <div className="userDetails_heading">
            <div
              onClick={() => {
                setPurchaseScreen("pageDetails");
              }}
            >
              <img
                src={arrowLeft}
                alt=""
                className="userDetails_heading-image"
              />
            </div>
            <p className="userDetails_heading-text">Login or Signup</p>
          </div>
          {ipData?.countryISO === "91" && (
            <div className="userDetails_mobile">
              <div className="userDetails_mobile-countryCode">
                +{ipData !== null ? ipData?.countryISO : "91"}
              </div>
              <Input
                value={formDetails?.mobile}
                label={
                  pageData?.type == "whatsappGroup" ||
                  pageData?.type == "whatsappCommunity"
                    ? "WhatsApp Number*"
                    : "Mobile Number*"
                }
                type="number"
                placeholder={
                  pageData?.type == "whatsappGroup" ||
                  pageData?.type == "whatsappCommunity"
                    ? "Add WhatsApp Number"
                    : "Add Mobile Number"
                }
                style={{ paddingLeft: "72px" }}
                onChange={(e) => {
                  setFormDetails({ ...formDetails, mobile: e.target.value });
                }}
                onEnter={() => {
                  let formDetailsPayload = {
                    mobile: formDetails?.mobile,
                    countryCode: "91",
                    whatsappStatus: waChecked,
                    type: pageData?.type,
                    groupId: pageData?.id,
                  };
                  generateOtpAction(formDetailsPayload);
                }}
              />
            </div>
          )}

          {ipData?.countryISO !== "91" && (
            <div className="userDetails_mobile">
              <Input
                value={formDetails?.email}
                label="Email Address*"
                type="email"
                placeholder="Enter Your Email Address"
                onChange={(e) => {
                  setFormDetails({ ...formDetails, email: e.target.value });
                }}
                onEnter={() => {
                  let formDetailsPayload = {
                    email: formDetails?.email,
                    whatsappStatus: waChecked,
                    type: pageData?.type,
                    groupId: pageData?.id,
                    // countryCode: ipData?.countryISO,
                  };
                  generateOtpAction(formDetailsPayload);
                }}
              />
            </div>
          )}

          {/* WA communication checkbox */}

          {(ipData?.countryISO === "91" || ipData === null) && (
            <>
              {pageData?.type != "whatsappGroup" &&
              pageData?.type != "whatsappCommunity" ? (
                <div className="WA_Container">
                  <input
                    type="checkbox"
                    defaultChecked={waChecked}
                    onChange={() => WAChecked(!waChecked)}
                  />
                  <div className="WA_Container_text">
                    Get updates via WhatsApp
                  </div>
                  <div className="WA_Container_img">
                    <img
                      src={whatsapp}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              ) : (
                <span className="userDetails_description">
                  *You will receive OTP on your WhatsApp
                </span>
              )}
            </>
          )}

          {/* end of WA */}

          {/* please note */}

          {(ipData?.countryISO === "91" || ipData === null) && (
            <>
              {pageData?.type == "whatsappGroup" ||
              pageData?.type == "whatsappCommunity" ? (
                <div className="content_apiFail">
                  <div className="content_apiFail_head">Please note:</div>
                  <div className="content_apiFail_points">
                    In order to get added successfully post payment, please
                    follow the given steps.
                  </div>
                  <br />
                  <div className="content_apiFail_points">
                    1. Open “Settings” in Whatsapp.
                  </div>
                  <div className="content_apiFail_points">
                    2. In Account settings, go to “Privacy”.
                  </div>
                  <div className="content_apiFail_points">
                    3. In Groups settings, choose “Everyone” under “who can add
                    me to group”
                  </div>
                </div>
              ) : null}
            </>
          )}

          {/* end of please note */}

          <div className="userDetails_footer">
            <div className="userDetails_footer-container">
              <img src={lock} alt="" className="userDetails_footer-img" />
              <p className="userDetails_footer-text">
                Secure payment with Razorpay
              </p>
            </div>
            <div className="userDetails_footer-button">
              <Button
                disabled={disabled || generateOtpLoading || verifyOtpLoading}
                onClick={() => {
                  let formDetailsPayload = () => {
                    if (ipData?.countryISO === "91") {
                      return {
                        mobile: formDetails?.mobile,
                        whatsappStatus: waChecked,
                        type: pageData?.type,
                        countryCode: "91",
                        groupId: pageData?.id,
                      };
                    } else if (ipData?.countryISO !== "91") {
                      return {
                        email: formDetails?.email,
                        whatsappStatus: waChecked,
                        type: pageData?.type,
                        groupId: pageData?.id,
                        // countryCode: ipData?.countryISO,
                      };
                    } else {
                      return {
                        mobile: formDetails?.mobile,
                        whatsappStatus: waChecked,
                        type: pageData?.type,
                        countryCode: "91",
                        groupId: pageData?.id,
                      };
                    }
                  };

                  console.log(formDetailsPayload());
                  generateOtpAction(formDetailsPayload());
                  // setPurchaseScreen("otp");
                }}
              >
                {generateOtpLoading || verifyOtpLoading ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"white"}
                    height={20}
                    width={20}
                  />
                ) : (
                  "Verify via OTP"
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="Loader">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#2278FF"}
            height={50}
            width={50}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    formDetails: state?.purchaseReducer?.formDetails,
    generateOtpLoading: state?.purchaseReducer?.generateOtpLoading,
    verifyOtpLoading: state?.purchaseReducer?.verifyOtpLoading,
    waChecked: state?.purchaseReducer?.waChecked,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setPurchaseScreen: (payload) =>
      dispatch(actions.setPurchaseScreen(payload)),
    getCountryCode: () => dispatch(Ipactions.getCountryCode()),
    setCountry: (payload) => dispatch(Ipactions.setCountry(payload)),
    setFormDetails: (payload) => dispatch(actions.setFormDetails(payload)),
    generateOtpAction: (payload) =>
      dispatch(actions.generateOtpAction(payload)),
    WAChecked: (payload) => dispatch(actions.WAChecked(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(UserDetails);
