import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useSwipeable } from "react-swipeable";
import ReactGA from "react-ga";
import TabBar from "./components/TabBar";
import * as actions from "../../store/Home/actions";
import "../../scss/Home/Home.scss";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import SwitchTabs from "./components/SwitchTabs";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";
import { layoutPageHeadingSet } from "../../layout/store/actions";
import EVENTS from "../../utils/gaEvents";
import { termsAndConditionsText } from "../../utils/constants";

function Home(props) {
  // REACT ROUTER HOOKS
  const navigate = useNavigate();
  const params = useParams();

  const tabList = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.tabs
  );
  const tcAccepted = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.TnC_accepted
  );

  const isLoading = useSelector((state) => state?.home?.isLoading);

  const [activeTab, setActiveTab] = React.useState(
    tabList?.find((tab) => tab?.enum === params?.tabEnum)?.id || 1
  );
  const [isTcModalOpen, setIsTCModalOpen] = useState(false);
  const [tcChecked, setTcChecked] = useState(false);

  const handleButtonClick = () => {
    setIsTCModalOpen(false);
    props.setTermsAndConditions({
      TnC_accepted: true,
    });
  };

  React.useEffect(() => {
    props.layoutPageHeadingSet("fanKonnect");
    ReactGA.event({
      category: EVENTS.HOME.CATEGORY,
      action: EVENTS.HOME.ACTIONS.OPEN,
    });
  }, []);

  React.useEffect(() => {
    if (!params?.tabEnum && tabList?.length) {
      navigate(`/${tabList[0]?.enum}`);
    }
  }, [tabList?.length]);

  React.useEffect(() => {
    if (tabList?.length) {
      const currentTab = params?.tabEnum;
      const redirectTo = tabList?.find((tab) => tab?.id === activeTab)?.enum;
      if (currentTab !== redirectTo) {
        navigate(`/${redirectTo}`);
      }
    }
  }, [activeTab, tabList]);

  const changeTab = (value) => {
    if (value > 0 && activeTab === 1) {
      setActiveTab(2);
    } else if (value < 0 && activeTab === 2) {
      setActiveTab(1);
    }
  };

  const swipeActions = useSwipeable({
    onSwipedLeft: (e) => {
      // let flag = true;
      // const path =
      //   e?.event?.path || (e?.event.composedPath && e?.event.composedPath()); // COMPOSED PATH FOR iOS
      // path?.forEach((ele) => {
      //   if (ele?.className?.includes("blockSlide")) flag = false;
      // });
      // if (flag) changeTab(1);
    },
    onSwipedRight: (e) => {
      // let flag = true;
      // const path =
      //   e?.event?.path || (e?.event.composedPath && e?.event.composedPath()); // COMPOSED PATH FOR iOS
      // path?.forEach((ele) => {
      //   if (ele?.className?.includes("blockSlide")) flag = false;
      // });
      // if (flag) changeTab(-1);
    },
    delta: 50,
  });

  useEffect(() => {
    if (tcAccepted === 1 || tcAccepted === -1) {
      setIsTCModalOpen(false);
    } else if (tcAccepted === 0) {
      setIsTCModalOpen(true);
    }
  }, [tcAccepted]);

  return (
    <>
      <div className="swipingPage">
        <TabBar
          activeTabId={activeTab}
          list={tabList}
          setTab={(id) => setActiveTab(id)}
        />
        <div style={{ minHeight: "80vh" }} {...swipeActions}>
          <SwitchTabs activeTabId={activeTab} />
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#2278FF"}
                height={50}
                width={50}
              />
            </div>
          )}
        </div>
        {isTcModalOpen && (
          <TermsAndConditionsModal
            text={termsAndConditionsText}
            checked={tcChecked}
            handleButtonClick={() => handleButtonClick()}
            handleChecked={() => setTcChecked(!tcChecked)}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ home }) => {
  return {
    isLoading: home?.isLoading,
    selectedTab: home?.selectedTab,
    messages: home?.messages,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setSelectedTab: (payload) => dispatch(actions.setSelectedTab(payload)),
    fetchGroups: (payload) => dispatch(actions.fetchGroups(payload)),
    layoutPageHeadingSet: (payload) => dispatch(layoutPageHeadingSet(payload)),
    setTermsAndConditions: (payload) =>
      dispatch(actions.setTermsAndConditions(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(Home);
