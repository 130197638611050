import React from "react";
import EarningDash from "./EarningDash";
import ExportReport from "./Report/ExportReport";

const SwitchTabs = ({ activeTabId }) => {
  switch (activeTabId) {
    case 1:
      return <EarningDash />;
    case 2:
      return <ExportReport />;
    default:
      return <h1>Loading...</h1>;
  }
};

export default SwitchTabs;
