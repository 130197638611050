import React, { useState, useEffect } from "react";

const SearchInput = ({
  icon,
  accountStatus = null,
  label,
  disabled,
  style,
  inputType = "input",
  onEnter,
  ...inputProps
}) => {
  const Component = inputType;

  return (
    <>
      <Component
        disabled={disabled}
        {...inputProps}
        {...(inputProps?.type === "number"
          ? {
              onKeyDown: (e) => {
                if (e.keyCode == 13 && onEnter) {
                  onEnter();
                }
                if (
                  e.key === "ArrowUp" ||
                  e.key === "ArrowDown" ||
                  e.key === "-" ||
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "+" ||
                  e.key === "."
                )
                  e.preventDefault();
              },
            }
          : {
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  onEnter();
                }
              },
            })}
      />
    </>
  );
};

export default SearchInput;
