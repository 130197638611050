import * as services from "./service";
import CONSTANTS from "./constants";
import { call, put, takeLatest } from "redux-saga/effects";

function* getAutoMatedChannelID(payload) {
  const response = yield call(services.getAutomatedChannelIDService, {
    ...payload,
  });
  if (response && response.status == 200) {
    const payloadToBeSet = {
      channelName: response?.data?.data?.groupName,
      channelID: payload?.payload?.telegramChannelId,
    };
    console.log("payloadToBeSet: ", payloadToBeSet);
    yield put({
      type: CONSTANTS.SET_AUTOMATED_CHANNEL,
      payload: payloadToBeSet,
    });
    if (response?.data?.data?.groupName) {
      payload?.payload?.navigate("/group/create");
    }
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.GET_AUTOMATED_CHANNEL, getAutoMatedChannelID);
}
