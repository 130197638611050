import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "100px",
      }}
    >
      <h1>404!</h1>
      <br></br>
      <br></br>
      <h2>Not Found</h2>
    </div>
  );
};

export default NotFound;
