const namespace = "HOME_";

export default {
  SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
  SET_SELECTED_TAB: `${namespace}SET_SELECTED_TAB`,

  FETCH_GROUPS: `${namespace}FETCH_GROUPS`,
  FETCH_GROUPS_SUCCESS: `${namespace}FETCH_GROUPS_SUCCESS`,

  FETCH_MESSAGES: `${namespace}FETCH_MESSAGES`,
  FETCH_MESSAGES_SUCCESS: `${namespace}FETCH_MESSAGES_SUCCESS`,

  RESET_STATE: `${namespace}RESET_STATE`,
  CREATE_BOTTOM_SHEET: `${namespace}CREATE_BOTTOM_SHEET`,
  WHATSAPP_BOTTOM_SHEET: `${namespace}WHATSAPP_BOTTOM_SHEET`,

  SET_TERMSANDCONDITIONS: `${namespace}SET_TERMSANDCONDITIONS`,
  SET_TERMSANDCONDITIONS_SUCCESS: `${namespace}SET_TERMSANDCONDITIONS_SUCCESS`,
};

export const homeTabTypes = {
  TELEGRAM_TAB: `TELEGRAM_TAB`,
  LOCKED_MESSAGE_TAB: `LOCKED_MESSAGE_TAB`,
};
