import CONSTANTS from "./constants";

export const setIsLoading = (payload) => {
  return {
    type: CONSTANTS.SET_LOADING,
    payload,
  };
};

export const getCountryCode = () => {
  return {
    type: CONSTANTS.GET_COUNTRY_CODE,
  };
};

export const setCountry = (isoCode) => {
  return {
    type: CONSTANTS.SET_COUNTRY,
    payload: isoCode,
  };
};

export const setCountryListBottomSheet = (payload) => {
  return {
    type: CONSTANTS.SET_COUNTRY_LIST_BOTTOMSHEET,
    payload: payload,
  };
};
