import React from "react";
import "../../../scss/Purchase/alreadyDone.scss";
import alreadyDone from "../../../assets/purchase/alreadyExist.svg";
import Button from "../../../common/Button";
import joinChannel from "../../../assets/joinChannel.png";
import { useSelector } from "react-redux";
import { AttentionCustomer } from "./AttentionCustomer";
function AlreadyDone(props) {
  const data = useSelector((state) => state?.purchaseReducer?.orderStatus);
  const pageData = useSelector(
    (state) => state?.purchaseReducer?.pageData?.data
  );
  const { inviteLink, formLink, status, conditionalApproval } = data;
  // states
  let helpingText1 =
    "If you haven't joined the channel yet, join the channel through the button given below.";
  let helpingText2 =
    "Your joining request is still under review. Please contact the admin.";
  let helpingText3 =
    "If you haven't joined the channel yet.Please join from here.";
  let helpingText4 =
    "Your joining request is still under review. Please fill the form if you haven't filled it yet or contact the admin.";
  let normalHelpingText = `You’re already added to the ${
    pageData?.type == "telegramChannel"
      ? "telegram channel"
      : pageData?.type == "whatsappGroup"
      ? "Whatsapp Group"
      : "Whatsapp Community"
  }`;

  const handleUrl = () => {
    window.open(formLink);
  };
  const handleThanksSheetClick = (url) => {
    if (url && url?.length) window.open(url, "_blank");
  };
  return (
    <div className="failure">
      <img src={alreadyDone} alt="" />
      <p className="failure_title">Already Purchased!</p>
      <p className="failure_text">
        {conditionalApproval && status == "pending" && formLink
          ? helpingText1
          : null}
        {conditionalApproval && status == "requested" && formLink
          ? helpingText4
          : null}
        {conditionalApproval &&
        status == "pending" &&
        (!formLink || formLink == "")
          ? helpingText3
          : null}
        {conditionalApproval &&
        status == "requested" &&
        (!formLink || formLink == "")
          ? helpingText2
          : null}
        {!conditionalApproval ? normalHelpingText : null}
      </p>

      {conditionalApproval && status == "requested" && formLink ? (
        <div onClick={() => handleUrl()} className="success1_CTAContainer">
          <img className="success1_CTAIcon" src={joinChannel} />
          <div className="success1_joinText">Fill form</div>
        </div>
      ) : null}
      {pageData?.type !== "whatsappGroup" &&
      pageData?.type !== "whatsappCommunity"
        ? (conditionalApproval
            ? status == "pending" && inviteLink && inviteLink != ""
            : inviteLink && inviteLink != "") && (
            <div
              onClick={() => handleThanksSheetClick(inviteLink)}
              className="success1_CTAContainer"
            >
              <img className="success1_CTAIcon" src={joinChannel} />
              <div className="success1_joinText">
                Click here to Join Channel
              </div>
            </div>
          )
        : null}
      {conditionalApproval && status == "pending" && formLink ? (
        <div>
          <div
            style={{
              width: "100%",
              marginTop: "16px",
              border: "1px dashed #E5E5E5",
            }}
          />
          <AttentionCustomer data={data} />
        </div>
      ) : null}
    </div>
  );
}

export default AlreadyDone;
