import CONSTANTS from "./constants";

const urlPrefix = "/user";

const apiEndPoints = {
  [CONSTANTS.USER_DETAILS_GET]: {
    url: () => {
      return `${urlPrefix}/details`;
    },
    method: "GET",
  },
};

export default apiEndPoints;
