import CONSTANTS from "./constants";

const initialState = {
  brandName: "",
  address: "",
  pincode: "",
  state: "",
  gstNumber: "",
  gstCertificate: "",
};

const setBrandName = (state, action) => {
  return {
    ...state,
    brandName: action.payload,
  };
};

const setAddress = (state, action) => {
  return {
    ...state,
    address: action.payload,
  };
};

const setPinCode = (state, action) => {
  return {
    ...state,
    pincode: action.payload,
  };
};

const setGSTNumber = (state, action) => {
  return {
    ...state,
    gstNumber: action.payload,
  };
};

const settingState = (state, action) => {
  return {
    ...state,
    state: action.payload,
  };
};

const setProfile = (state, action) => {
  console.log(action.payload);
  return {
    ...state,
    brandName: action?.payload?.orgName,
    address: action?.payload?.address,
    pincode: action?.payload?.pinCode,
    gstNumber: action?.payload?.gstNumber,
    state: action?.payload?.state,
  };
};

const reset = (state) => {
  return {
    ...state,
    brandName: "",
    address: "",
    pincode: "",
    gstNumber: "",
    state: "",
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_BRAND_NAME:
      return setBrandName(state, action);
    case CONSTANTS.SET_ADDRESS:
      return setAddress(state, action);
    case CONSTANTS.SET_PINCODE:
      return setPinCode(state, action);
    case CONSTANTS.SET_GST_NUMBER:
      return setGSTNumber(state, action);
    case CONSTANTS.SET_STATE:
      return settingState(state, action);
    case CONSTANTS.PROFILE_FETCH_SUCCESS:
      return setProfile(state, action);
    case CONSTANTS.RESET:
      return reset(state);
    default:
      return state;
  }
};

export default reducer;
