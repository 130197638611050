import CONSTANTS from "./constants";

// INITIAL STATE
const layoutReducerInitialState = {
  layoutPageheading: "fanKonnect",
  layoutPageBackNavigation: "",
  isLayoutSideBarOpen: false,
};

// REDUCER
const layoutReducer = (state = layoutReducerInitialState, action) => {
  switch (action.type) {
    case CONSTANTS.LAYOUT_SIDEBAR_OPEN: {
      return {
        ...state,
        isLayoutSideBarOpen: true,
      };
    }
    case CONSTANTS.LAYOUT_SIDEBAR_CLOSE: {
      return {
        ...state,
        isLayoutSideBarOpen: false,
      };
    }
    case CONSTANTS.LAYOUT_PAGE_HEADING_SET: {
      return {
        ...state,
        layoutPageheading: action?.payload,
      };
    }
    case CONSTANTS.LAYOUT_PAGE_HEADING_CLEAR: {
      return {
        ...state,
        layoutPageheading: "fanKonnect",
      };
    }
    case CONSTANTS.LAYOUT_PAGE_BACK_NAVIGATION_SET: {
      return {
        ...state,
        layoutPageBackNavigation: action?.payload,
      };
    }
    case CONSTANTS.LAYOUT_PAGE_BACK_NAVIGATION_CLEAR: {
      return {
        ...state,
        layoutPageBackNavigation: "",
      };
    }
    default:
      return state;
  }
};

export default layoutReducer;
