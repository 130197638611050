import CONSTANTS from "./constants";

const urlPrefix = "/v1";

const apiEndPoints = {
  [CONSTANTS.ALL_COUPONS_GET]: {
    url: () => {
      return `/coupons?groupId=1412`;
    },
    method: "GET",
  },
  [CONSTANTS.COUPON_ACTIVE_INACTIVE_DELETE]: {
    url: () => {
      return `/changeCouponStatus`;
    },
    method: "POST",
  },
};

export default apiEndPoints;
