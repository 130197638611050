// import { dispatch } from "../store";
// const store = require("../store");

// ACTION TYPES
export const ACTION_TYPES = {
  SET_USER_TOKEN: `SET_USER_TOKEN`,
};

// FUNCTION TO SET APP TOKEN AND COLOR
export const setAppToken = () => {
  const token =
    new URLSearchParams(window?.location?.search).get("token") || "";

  // SETTING USER TOKEN AND APP ACCENT COLOR IN LOCAL STORAGE
  // token && localStorage.clear();
  token && localStorage.setItem("token", token);

  // DISPATCHING SET APP CONFIG ACTION IF URL HAS TOKEN OR COLOR
  if (token) {
    // store.default.dispatch(
    //     setPersistedDataAction({
    //         token: token,
    //     })
    // );
  }
};

// ACTIONS
export const setPersistedDataAction = (payload) => {
  return {
    type: ACTION_TYPES.SET_PERSISTED_DATA,
    payload,
  };
};
export const setUserTokenAction = (payload) => {
  return {
    type: ACTION_TYPES.SET_USER_TOKEN,
    payload,
  };
};

// INITIAL STATE
const persistedDataInitialState = {
  token: "",
};

// STATE ALTERING FUNCTIONS
const setUserToken = (userToken) => {
  return {
    userToken: userToken,
  };
};

// REDUCER
const persistedDataReducer = (state = persistedDataInitialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_USER_TOKEN: {
      return { ...state, ...setUserToken(action?.payload) };
    }
    default:
      return state;
  }
};

export default persistedDataReducer;
