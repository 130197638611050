import React from "react";
import { useNavigate } from "react-router";
import shareIcon from "../../../assets/share.svg";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
import "../../../scss/Home/SingleMessage.scss";
import CustomUserAvatar from "../../../common/CustomUserAvator";
import Badge from "../../../common/Badge";
import { useDispatch } from "react-redux";
import { setEditGroupType } from "../../../store/User/action";

function SingleMessage(props) {
  const { data, type, setShowShare, setSocialLinks } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const singleMessageClick = () => {
    if (
      type === 1 &&
      (data?.type === "whatsappGroup" || data?.type === "whatsappCommunity")
    ) {
      dispatch(setEditGroupType(true));
      navigate(`/group/whatsapp/${data?.id}`, {
        state: { socialLinks: data?.shareLinks },
      });
    } else if (type === 1) {
      dispatch(setEditGroupType(false));
      navigate(`/group/${data?.id}`, {
        state: { socialLinks: data?.shareLinks },
      });
    } else {
      navigate(`/message/${data?.id}`);
    }
  };

  return (
    <div
      className="SingleMessage"
      style={{ padding: type === 1 ? "" : "25px" }}
    >
      <div className="SingleMessage_Left" onClick={singleMessageClick}>
        {type === 1 ? (
          // <img
          //   src={
          //     data?.img ||
          //     "https://storage.googleapis.com/cp-prod-whitelabel-assets-as-sth1-gcs-dgte34/production/single/learn/6b796325-8e3f-4ca9-91cd-fe8da55fbfcb.png"
          //   }
          //   height="50px"
          //   width="50px"
          // />
          data?.logoUrl ? (
            <img
              src={data?.logoUrl}
              height={"44px"}
              width={"44px"}
              style={{ borderRadius: "22px" }}
            />
          ) : (
            <CustomUserAvatar
              user={{
                name: data?.groupName,

                // imageUrl: students[0].imageUrl || false
              }}
              size={"44px"}
            />
          )
        ) : null}

        <div
          className="SingleMessage_Left_NameDate"
          style={{ flexDirection: "column" }}
        >
          <div className="SingleMessage_Left_NameDate-name">
            {data?.groupName || data?.title}
          </div>
          <div
            style={{ fontWeight: "400px", fontSize: "14px", paddingTop: "8px" }}
          >
            Total Earnings: ₹{data?.revenue}/-
          </div>
          {/* <div className="SingleMessage_Left_NameDate-date">
            <img
              src="https://storage.googleapis.com/cp-prod-whitelabel-assets-as-sth1-gcs-dgte34/production/single/learn/1a2b2220-1589-44c7-b5e4-8f886bafd6cd.svg"
              height="18px"
              width="18px"
              style={{ marginRight: "6px" }}
            />
            {data?.createdOn}
          </div> */}
        </div>
      </div>
      {data?.status !== "success" ? (
        <div className="SingleMessage_Status">
          <Badge
            status={data?.inviteLink?.length ? "join" : data?.status}
            inviteLink={data?.inviteLink}
          />
        </div>
      ) : (
        <div
          className="SingleMessage_Right"
          onClick={(e) => {
            setSocialLinks(data?.shareLinks);
            setShowShare(true);
          }}
        >
          Share
          <img src={shareIcon} height="16px" style={{ marginLeft: "6px" }} />
        </div>
      )}
    </div>
  );
}

export default SingleMessage;
