import CONSTANTS from "./constants";

const inititalState = {
  isLoading: false,
  leadSuccess: null,
  leadFailure: null,
};
const setIsLoading = (state) => {
  return { ...state, isLoading: true };
};
const setLeadSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    leadSuccess: action.payload,
  };
};
const setLeadFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    leadFailure: action.payload,
  };
};

const reducer = (state = inititalState, action) => {
  switch (action.type) {
    case CONSTANTS.LEAD_LOADER:
      return setIsLoading(state, action);
    case CONSTANTS.LEAD_SUCCESS:
      return setLeadSuccess(state, action);
    case CONSTANTS.LEAD_FAILURE:
      return setLeadFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
