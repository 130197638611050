import React, { useRef, useState } from "react";
import bottomAngleIcon from "../../assets/bottomAngleIcon.svg";
import "./styles.scss";

const Dropdown = ({
  customClass,
  customProps,
  label,
  optionsList,
  selectedOption,
  itemClickHandler,
  editable = true,
  showNewDropdown = true,
  placeholder,
  countryList,
}) => {
  const [showDetails, updateShowDetails] = useState(false);
  const optionsRef = useRef();

  return (
    <React.Fragment>
      {!!label && <label className="dropdownLabel">{label}</label>}
      <div
        ref={optionsRef}
        className={customClass ? `Dropdown ${customClass}` : "Dropdown"}
        {...customProps}
        onClick={() => {
          if (editable) {
            optionsRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            updateShowDetails(!showDetails);
          }
        }}
      >
        <div className="Dropdown__Main">
          {selectedOption?.label
            ? selectedOption?.label
            : placeholder || "Select"}
          <div
            className={
              showDetails
                ? "Dropdown__Main--Toggle Dropdown__Main--Toggle--Show"
                : "Dropdown__Main--Toggle Dropdown__Main--Toggle--Hide"
            }
          >
            <img
              className={
                showDetails
                  ? "Dropdown__Main--Toggle--Verticle Dropdown__Main--Toggle--Verticle--Show"
                  : "Dropdown__Main--Toggle--Verticle Dropdown__Main--Toggle--Verticle--Hide"
              }
              src={bottomAngleIcon}
              alt="bottomAngleIcon"
              width="24px"
              height="24px"
            />
          </div>
        </div>
        <div
          className={
            showDetails
              ? `Dropdown__Extended Dropdown__Extended--Show ${
                  showNewDropdown ? "Dropdown__Extended__showNew" : ""
                }`
              : "Dropdown__Extended Dropdown__Extended--Hide"
          }
          style={countryList ? { width: "58%" } : { width: "92%" }}
        >
          {!countryList &&
            optionsList?.map((item, index) => (
              <div
                onClick={() => {
                  itemClickHandler(item);
                }}
                key={item?.id || index}
                className="Dropdown__Extended__item"
              >
                {item?.label}
              </div>
            ))}

          {countryList &&
            optionsList?.map((item, index) => (
              <div
                onClick={() => {
                  itemClickHandler(item);
                }}
                key={item?.countryISO}
                className="Dropdown__Extended__item"
              >
                <span style={{ marginRight: "5px" }}>{item?.countryFlag}</span>
                {item?.countryName} (+{item?.countryISO}){" "}
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
