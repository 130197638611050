import { call, put, takeLatest } from "redux-saga/effects";
import { getUserDetailsAction } from "../../../store/userDetailsReducer/actions";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";
import { couponActionServices, getAllCouponsServices } from "./services";

function* getAllCouponDataSaga(data) {
  const loaderAction = yield call(
    getActionObject,
    CONSTANTS.ALL_COUPONS_LOADER
  );
  yield put({ ...loaderAction });
  try {
    const apiCall = yield call(getAllCouponsServices, data?.payload);
    // console.log("apiDataa", apiCall);
    if (apiCall.status == 200) {
      yield put({
        type: CONSTANTS.ALL_COUPONS_SUCCESS,
        payload: apiCall?.data?.data,
      });
    } else {
      yield put({
        type: CONSTANTS.ALL_COUPONS_FAILURE,
        payload: "Data Not Found",
      });
    }
    // const callUserDetailsAction = yield call(getUserDetailsAction);
    // yield put({ ...callUserDetailsAction });
    // const getAction = yield call(
    //   getActionObject,
    //   CONSTANTS.ADD_BANK_DETAILS_POST_SUCCESS
    // );
    // yield put({ ...getAction });
    // if (data?.payload?.onSuccess) {
    //   data?.payload?.onSuccess?.();
    // }
  } catch (err) {
    // const getAction = yield call(
    //   getActionObject,
    //   CONSTANTS.ADD_BANK_DETAILS_POST_FAILURE
    // );
    // yield put({ ...getAction });
  }
}
function* couponActionSaga(data) {
  // console.log("couponActiondataPa", data);
  yield put({
    type: CONSTANTS.COUPON_ACTION_LOADER,
    payload: true,
  });
  const couponActionData = yield call(couponActionServices, data.payload);
  // console.log("couponActiondata", couponActionData);
  if (couponActionData?.data?.status == "success") {
    yield put({
      type: CONSTANTS.COUPON_ACTION_LOADER,
      payload: false,
    });
  } else if (couponActionData?.data?.status == "failure") {
    yield put({
      type: CONSTANTS.COUPON_ACTION_LOADER,
      payload: false,
    });
  } else {
    yield put({
      type: CONSTANTS.COUPON_ACTION_LOADER,
      payload: false,
    });
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.ALL_COUPONS_GET, getAllCouponDataSaga);
  yield takeLatest(CONSTANTS.COUPON_ACTIVE_INACTIVE_DELETE, couponActionSaga);
}
