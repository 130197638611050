import React from "react";

function Tnc() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Privacy">
      <div
        className="Privacy_Top"
        style={{
          padding: "30px 20px",
          background: "#062847",
          color: "white",
          fontWeight: "bold",
        }}
      >
        Terms and Conditions
      </div>
      <div className="Privacy_Bottom" style={{ padding: "20px 20px" }}>
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          TERMS AND CONDITIONS OF USE AND ACCESS
        </div>
        The website – ‘https://web.fankonnect.com/’, mobile application -
        ‘FanKonnect’ and the other associated/ancillary applications and
        websites (collectively “FanKonnect Platform” or “Platform”) are property
        of and managed by Bunch Microtechnologies Private Limited (hereinafter
        referred to as “Bunch”, “we”, “us” and “our”), a private limited company
        incorporated under the Companies Act, 2013, and having its registered
        office at Mez & 1st Floor, A-83 Okhla Industrial Area, Okhla Ph-II, New
        Delhi – 110020, India.
        <br />
        Bunch requests the User (as defined below) to carefully go through these
        terms and conditions of use and access (“Terms of Service” or “TOS” or
        “Terms”) prior to accessing the FanKonnect Platform or availing Services
        (as defined below) using the FanKonnect Platform. If User continues to
        browse and use the FanKonnect Platform or to avail Services, the User
        irrevocably and unconditionally is agreeing to comply with, abide by and
        be bound by all these obligations as stipulated in this TOS, which
        together read with our privacy policy available at [insert link]
        (“Privacy Policy”) and any other applicable policies referred to herein
        or made available on the FanKonnect Platform (collectively referred as
        “Terms and Conditions”). The Terms and Conditions shall govern Bunch’s
        relationship with the User in relation to the usage of the FanKonnect
        Platform. These Terms and Conditions supersede all previous oral,
        written terms and conditions (if any) communicated to the User and shall
        act as a binding agreement between Bunch and the User.
        <br />
        As used herein, “you", “user” or “Users” shall mean the following:
        <br />
        a.such persons who are creating User Content (as define below); or
        <br />
        b. anyone who uses or accesses/views the FanKonnect Platform and/or
        avails the Services on any computer, mobile phone, tablet, console or
        other device (collectively, “Device”).
        <br />
        IF YOU DO NOT AGREE TO BE BOUND BY ALL TERMS AND CONDITIONS CAPTURED IN
        THE TERMS AND CONDITIONS, PLEASE DO NOT USE THE FANKONNECT PLATFORM OR
        SERVICES. <br />
        {/* This Policy shall, at all times be read and construed in consonance and
        along with the terms of use and access of the Website (“T&C”).
        <br />
        fanKonnect will not differentiate between who is using the device to
        access the Website, so long as the log in/access credentials match with
        yours. In order to make the best use of the Website and enable your
        Information to be captured accurately on the Website, it is essential
        that you have logged in using your own credentials.
        <br />
        This Policy highlights inter alia the type of data shared/collected from
        a User in the course of the User’s usage of the Website. The Policy
        further intends to apprise the User of the purposes for which the data
        of the User is collected and the Website’s policy with regard to sharing
        such personal information with third party entities.
        <br /> */}
        {/* The terms “We”/ “Us”/ “Our” individually and collectively refer to and are synonymous with the term " Bunch " and the terms “You” / “Your” / “Yourself” are to be construed to be synonymous with the term ‘User’.
        <br />
        All defined terms used within this Policy but not specifically defined herein shall draw their meaning from the definition ascribed to such term under the T&C. */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          1. MODIFICATIONS TO TERMS OF USE
        </div>
        We reserve the right, at our sole discretion, to change or modify
        portions of these Terms at any time. If we do this, depending on the
        nature of the change, we will post the changes on this page and indicate
        at the top of this page the date these terms were last revised. Any
        changes addressing new functions of the FanKonnect Platform or changes
        made for legal reasons will be effective immediately. Your continued use
        of the FanKonnect Platform after the date any such changes become
        effective constitutes your acceptance of the new Terms.
        <br />
        {/* < br/>
        1.1 Personal Information: 
        (i) Name of the User; (ii) Phone number (mobile and/or
        residence and/or alternative) of the User; (iii) Gender of the User; (iv) Date of birth of the User; (v) Address (official/residence/other) of the User; (vi) Email Id (primary/alternative) of the User; (vii) Personal information received from social networking sites through which the User has registered to the Website including name, profile picture, email address or friends list, and any information made public in connection with that social media service; (viii) Personal information from the mobile device of the User such as their contact list including the name, phone number and the email address of the contact; (ix) Internet protocol (IP) address of the User; and (x) Sensitive personal data such as passwords and payment details.
        < br/>
        < br/>
        1.2 Non-personal information: (i) Details of internet or telecom service provider of the User; (ii) Location of a User; (iii) Type of internet browser being used by the User; and (iv) Such other information that may be required to access and operate the Website.
        <br/>
        < br/>
        1.3 Please note that in addition to the above, the duration of use of the Website by the User may also be logged and stored by the Website.
        <br/>
        < br/>
        1.4 The Information may be collected and/or stored in electronic form, however, Bunch is hereby authorized by the User to collect/store such information in physical form as well. 
        <br/>
        < br/>
        1.5 The Website may share the Information of a User with any third-party entities subject to such entities adopting reasonable safety standards with respect to the use of such Information.  */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          2. PRIVACY
        </div>
        Bunch respects the privacy of our users. For details, please see our
        Privacy Policy. By using the FanKonnect Platform, you consent to our
        collection and use of personal data as outlined therein.
        {/* 1.1  Every User hereby represents and warrants to Bunch that: (a) all Information provided by the User is true, correct, current and updated; (b) all Information provided by the User and the provision of such Information by the User does  not  in  any  manner  violate  any  third  party  agreement,  law,  decree,  order  or  judgement;  (c)  all  Information provided  by  the  User  does  not  belong  to  any  third  party,  and  if  it  does  belong  to  a  third  party,  the  User  is  duly authorized  by  such  Third  Party  to  use,  access  and  disseminate  such  Information;  (d)  the  officers,  directors, contractors or agents of Bunch shall not be responsible in any manner whatsoever with regard to the authenticity or veracity of the Information that a User may provide to the Website; and (e) the User shall indemnify and hold harmless  Bunch  and  each  of  its  officers,  directors,  contracts  or  agents  and  any  third  party  relying  on  the Information provided by the User in the event the User is in breach of this Policy. 
        <br/>
        <br/>
        1.2  Bunch represents and warrants to every User that: (a) it shall not collect the User’s sensitive personal data unless such sensitive personal data is collected for a lawful purpose for which such collection of data is necessary; (b) it shall  not  retain  any  sensitive  personal  data  for  longer  than  such  sensitive  personal  data  is  required  or  can  be lawfully  used;  (c)  in  the  event  Bunch  collects  Information  directly  from  the  User,  Bunch  shall  make  reasonable effort  to  apprise  the  User  of  the  purpose  of  such  collection  of  Information,  the  intended  recipient  of  the Information  and  the  details  of  the  agencies  collecting  and  retaining  the  Information;  and  (d)  it  has  in  place  the security  practices  and  procedures  prescribed  under  the  Information  Technology  (Reasonable  Security  Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (“IT Rules”). */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          3. ELIGIBILITY
        </div>
        <br />
        By using Platform, you affirm that you are at least 18 years of age and
        are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations, warranties set forth in the
        TOS, and to abide by and comply with the terms stated therein. You shall
        not access and avail the Services if you are not competent to contract
        under the applicable laws, rules and regulations.
        <br />
        <br />
        As a parent or guardian of a minor, you may create an account for such
        minor and consent to the minor availing Services on the FanKonnect
        Platform. By using the FanKonnect Platform as a minor, you affirm that
        your account was created for you by your parent or legal guardian and
        the consent of the parent or legal guardian has been duly obtained
        before you participate in the activities available on the FanKonnect
        Platform. By accessing the FanKonnect Platform or by accessing any of
        the Services, it is deemed that the User has read and understood and
        accepted these Terms.
        <br />
        <br />
        If you will be using the FanKonnect Platform on behalf of an
        organization, you agree to these Terms on behalf of that organization
        and you represent that you have the authority to do so.
        {/* All  Information  collected/stored  by  the  Website  shall  be  used  for:  (a)  providing  information  about  new  educational products and services to the Users; (b) to continually improve the existing Website and service offerings; (c) to conduct research and surveys; (d) to implement the necessary security practices to ensure that all personal data are protected; 
        (e) to administer the User accounts in normal course of business; (f) to contact the Users in case where fraud, illegal activities  or  breach  of  privacy  is  recorded;  (g)  to  enable  the  employees  of  or  persons  acting  on  behalf  of  Bunch  to communicate with the User, as and when necessary, in order to provide the services requested by such User; (h) such other purposes that Bunch, at its sole discretion, however subject to the principles contained in this Policy, may deem fit. */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          4. SERVICE(S) PROVIDED TO USERS
        </div>
        FanKonnect is a technology provider that helps creators automate and
        manage paid channels on social media platforms such as Telegram,
        Whatsapp etc. It also helps you create premium locked messages which the
        end users can access after making the relevant payment. (“Services”){" "}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          5. USER ACCOUNT, PASSWORD & SECURITY
        </div>
        You are required to create an account (“Account”) on the FanKonnect
        Platform to have access to the Services. You are responsible for
        maintaining the confidentiality of your login credentials and your
        Account and are fully responsible for all activities that occur under
        your password or Account. You agree to: <br />
        <br />
        a.immediately notify us of any unauthorized use of your password or
        Account or any other breach of security; and <br />
        <br />
        b. ensure that you exit from your Account at the end of each session. We
        cannot and will not be liable for any loss or damage arising from your
        failure to comply with this Clause 5.
        <br />
        <br />
        You hereby acknowledge that the deletion of the FanKonnect Platform from
        the Device does not constitute termination of your Account and agree to
        undertake the process detailed herein to complete de-registration. If
        you are desirous of having your name and other details removed from the
        records of Bunch, immediately upon receiving your written request to
        that effect Bunch shall remove and/delete all such information.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          6. FEES AND PAYMENT TERMS
        </div>
        6.1 Fees: Services are based on a subscription model (“Subscription”).
        This means that when you choose to avail our Services, you will be
        billed on a periodic basis (“Billing Cycle”). You agree to pay to us any
        fees for the Services availed by you, in accordance with the pricing
        charges and the payment terms as presented to you on the FanKonnect
        Platform (“Subscription Fees”). Payment of Subscription Fees can be made
        online through credit card/internet banking/UPI gateway etc. or any
        other mode of payment which is made available to the User on the
        FanKonnect Platform. The Fees paid by you are non-refundable, except as
        provided in these Terms. Your payments to Bunch shall be subject to
        applicable taxes including without limitation Goods and Service Taxes
        (GST) and Value Added Taxes (VAT) or other similar taxes as may be
        applicable in your country of residence/from where you have created your
        Account on the FanKonnect Platform/ purchased the Subscriptions.
        <br />
        <br />
        6.2 Payments through gateway:
        <br />
        <br />
        We use third-party payment processors to process payments for the
        Services availed through the FanKonnect Platform. We use Razorpay
        Software Private Limited (“Razorpay”) for Users situated in India. As
        You may know, payment gateways such as Razorpay are third-party service
        providers, and third-party service providers (like Razorpay) may also
        charge you fees to use or access their services and require your
        personal data to complete any transaction for the Platform. Hence, prior
        to using Razorpay or any other third-party payment gateway, we suggest
        that you read such service provider’s terms and conditions, privacy
        policy and other policies, that may apply, to understand as to how Your
        data is being processed. Bunch is not affiliated with Razorpay or any
        other payment gateway service provider, and neither Bunch nor Razorpay
        such third party service provider, are agents or employees of the other.
        <br />
        <br />
        In availing Services through the FanKonnect Platform, a User may be
        redirected to an external website of such third-party service provider,
        such as one operated by Razorpay (as applicable). We cannot and do not
        (i) guarantee the adequacy of the privacy and security practices
        employed by or the content and media provided by the third-party service
        provider, including Razorpay or its respective websites or (ii) control
        collection or use of Your personal information by the third-party
        service provider, including Razorpay. You agree that you are solely
        responsible for all charges that occur through Razorpay / the
        third-party service provider, and acknowledge and agree to indemnify,
        defend, and hold harmless Bunch, its licensors, their corporate
        affiliates, and their respective officers, directors, employees, and
        agents from any loss arising out of or related to the use of the
        Platform or any purchases made through the Fankonnect Platform. This
        obligation will survive your use of the FanKonnect Platform.
        <br />
        <br />
        For purposes of these Terms, “loss” means all losses, liabilities,
        damages, awards, settlements, claims, suits, proceedings, obligations,
        costs and expenses (including legal fees and disbursements and costs of
        investigation, litigation, settlement, judgment, interest, and
        penalties). Bunch shall not be liable to you for any claims arising out
        of any act or omission on the part of such third-party service provider
        / Razorpay (as the case may be) including, but not limited to, any lost,
        stolen, or incorrectly processed payments. Bunch expressly disclaims any
        responsibility and liability for all services provided by such
        third-party service provider / Razorpay. <br />
        <br />
        {/* 6.3  Bunch shall ensure that the Website shall adopt appropriate encryption and security measures to prevent any hacking of the information of the Users and third parties and shall ensure that the User shall not be required or asked to disclose any Information, which may be prejudicial to the interests of the User. Currently, the content available on the Website is encrypted with AES 256 encryption where the data transfers are secured with HTTPS secured protocols and video content is delivered through HLS protocols.
        <br/>
        <br/>
        6.4  Bunch shall use the Information collected from the Users in accordance with applicable laws including but not limited to the IT Act and the rules made thereunder and use the Information only for the purpose for which it was furnished. 
        <br/>
        <br/>
        6.5  Bunch has appropriate physical, electronic and managerial procedures in relation to the Website. The servers of the  Website  are  accessible  only  to  the  authorized  personnel  and any  Information  of  the  User shall  be shared with  the  authorized  personnel  only  on  a  need-to-know  basis  to  facilitate  the  services  requested  by  the  User. Bunch shall endeavor to safeguard the confidentiality of a User’s personally identifiable information, however, the transmissions made by means of the Internet cannot be made absolutely secure by the Website. The User agrees  and  acknowledges  that  Bunch  shall  not  be  liable  for  disclosure  of  any  information  due  to  errors  in transmission or any unauthorized acts of third parties.
        <br/>
        <br/>
        6.6  The User agrees and acknowledges that Bunch shall be entitled to share the Information where such sharing is necessary for the lawful performance of the contractual obligations existing between Bunch and the User and for such purposes as it may deem fit, however, the disclosure of Information shall be in accordance with this Policy, the IT Act and the rules made thereunder. */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          7. REFUND POLICY
        </div>
        Please note that once you subscribe and make the required payment, it
        shall be final and there cannot be any changes or modifications to the
        subscription plan and no refund shall be provided whatsoever.
        <br />
        {/* <br/>
        7.2  Further, Information provided and/or collected by Bunch may be updated, modified, rectified or deleted at any time  during  or  pursuant  to  usage  of  the  Website  by  a  User  by  writing  to  Bunch  at support@fankonnect.com. However, the User must note that, in case the Information is critical for continuation of services to be provided to the User, the deletion of such Information may lead to discontinuation of Bunch services to the User.
        <br/>
        <br/>
        7.3  Mere  deletion  of  the  application,  website  or  expiry  of  the  User  subscription  with  Bunch  will  not  automatically erase the personal data of the User. The User must submit a written request to the above email if the User desires to delete all personal data from the Bunch system/platform. */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          8.USER CONTENT AND CONDUCT
        </div>
        8.1 User Content:The User is solely responsible for all video, images,
        information, data, text, software, music, sound, photographs, graphics,
        messages or other materials that he/she uploads, posts, publishes or
        displays, or emails or otherwise and any content uploaded or published
        or submitted or posted by the User on the Platform, including without
        limitation, the content published by the User and/or any text, image,
        media, written statements or other content posted or published by a User
        anywhere on the Platform including without limitations in the comments
        section (“Content” or "User Content") uses via the FanKonnect Platform.
        If Bunch suffers any loss or damages on account of the User Content
        belonging to another party or such Content violating or infringing the
        rights of any party, the User shall be liable to indemnify, defend and
        hold Bunch harmless from such loss and damages. Bunch reserves the right
        to investigate and take appropriate legal action against anyone who, in
        Bunch’s sole discretion, violates this provision, including without
        limitation, removing the offending Content from the FanKonnect Platform,
        suspending, or terminating the Account of such violators and reporting
        the User to the law enforcement authorities. The User shall not post,
        upload, display or otherwise make available on the Platform any Content
        that:a.is grossly, harmful, harassing, blasphemous, defamatory, obscene,
        pornographic, paedophilic, libellous, invasive of another’s privacy,
        hateful, or racially, ethnically objectionable, disparaging, relating or
        encouraging money laundering or gambling, or otherwise unlawful in any
        manner whatsoever; b. advocates harassment or intimidation of another
        person; c.belongs to another person and to which the User does not have
        any right; d. harm minors in any way; e. infringes any patent,
        trademark, copyright or other proprietary rights of Bunch and/ or any
        third-party; f.violates any law for the time being in force; g.deceives
        or misleads the addressee about the origin of such messages or
        communicates any information which is grossly offensive or menacing in
        nature; h. contains software viruses or any other computer code, files
        or programs designed to interrupt, destroy or limit the functionality of
        any computer resource; i.threatens the unity, integrity, defence,
        security or sovereignty of India, friendly relations with foreign
        states, or public order or causes incitement to the commission of any
        cognisable offence or prevents investigation of any offence or is
        insulting any other nation; j.is intended to defraud, or otherwise
        defrauds, Bunch and/or other users of the FanKonnect Platform;
        k.promotes information that is false or misleading, or promotes illegal
        activities or conduct that is defamatory, libellous or otherwise
        objectionable; l.contains video, audio photographs, or images of another
        person without his or her permission (or in the case of a minor, the
        minor’s legal guardian); m. provides material that exploits people in a
        sexual, violent or other illegal manner, or solicits personal
        information from anyone under the age of 18 (eighteen) without the
        consent of the guardian; n. provides instructional information about
        illegal activities such as making or buying illegal weapons or drugs,
        violating someone’s privacy, or providing, disseminating or creating
        computer viruses; o. contains viruses, time bombs, trojan horses,
        cancelbots, worms or other harmful, or disruptive codes, components or
        devices; and/or; p. impersonates, or otherwise misrepresents
        affiliation, connection or association with, any person or entity.{" "}
        <br />
        <br />
        8.2 User Guidelines:In consideration of Bunch granting you the rights
        hereunder, you hereby agree not to use FanKonnect Platform for any
        purpose that is unlawful under any applicable laws and/or in violation
        of the Terms and Conditions. You shall not use the FanKonnect Platform
        in any manner that could damage, disable, overburden, or impair any
        network(s) connected to any server, or interfere with any other party's
        use and enjoyment of the FanKonnect Platform and. You shall not attempt
        to gain unauthorized access to any functions and features, other user
        accounts, computer systems or networks connected to any other server, in
        any manner, including, through hacking, data or password mining or any
        other means. You shall not obtain or attempt to obtain or extract any
        materials, data or information through robots or any means which is not
        intentionally made available to your Account or through the FanKonnect
        Platform The FanKonnect Platforms are made available to you on
        non-exclusive, non-transferable, non-sublicensable and on limited
        license basis for your own commercial use alone and grants you a limited
        right to temporarily download one copy of the material (information or
        software) on the FanKonnect Platform. You shall not allow third parties
        to: a.make and/or distribute copies of the FanKonnect Platform or any
        deliverable generated by the FanKonnect Platform; b. attempt to copy,
        reproduce, alter, modify and/or reverse engineer the FanKonnect Platform
        and/orc.create derivative works of the FanKonnect Platform.You accept
        that all operations emanating from your Device shall be assumed to have
        been initiated by you.You shall not use the FanKonnect Platform to
        transmit, distribute, send, etc. viruses, worms, or any other code that
        may be deemed dangerous, harmful, or destructive in any manner.You shall
        not copy, reproduce, distribute, or create derivative works or modify
        the Content that is available on the FanKonnect Platform. Also, you
        shall not attempt to decompile or reverse engineer or reverse compile
        our technology/ software that is available on the FanKonnect Platform or
        transfer the material to another person or “mirror” the material on any
        other server, including, without limitation, such Java applet, as may be
        associated with the FanKonnect Platform from time to time.You shall
        request Bunch to block the Account and change the password immediately
        for the Account, if your Device has been lost or stolen.You are
        responsible for any and all activities that occur in your Account. You
        agree to notify Bunch immediately of any unauthorized use of the Account
        or any other breach of security. Bunch shall not be liable for any loss
        to you or your organization owing to negligent actions or a failure on
        your part to inform Bunch within a reasonable time, about loss or theft
        of your Device and/or any unauthorized access in your Account, either
        with or without your knowledge.You shall be liable for losses incurred
        by Bunch or any other party due to a third party’s use of the Account.
        You shall not use any other person’s account at any time, without the
        permission of the Account holder and Bunch.Bunch shall make all
        reasonable efforts to ensure that your information is kept confidential.
        However, Bunch shall not be responsible for any disclosure or leakage of
        confidential information and/or loss or damage of the Device due to
        theft, negligence or failure on your part to practice safe computing.
        You shall ensure that while using the functions and features, all
        prevailing and applicable laws, rules and regulations, shall at all
        times, be strictly complied with by you and Bunch shall not be liable in
        any manner whatsoever for default of any nature, by you, regarding the
        same.You understand and acknowledge that upon using the FanKonnect
        Platform, you authorize us to access third-party sites designated by
        you, on your behalf, to retrieve such information requested by you, and
        to register for accounts requested by you. For all purposes hereof, you
        hereby grant us a limited powers and hereby authorize us with the full
        power of substitution and re-substitution, for you and in your name,
        place and stead, in any and all capacities, to access third party
        internet sites, servers or documents, retrieve information, and use your
        information, all as described above, with the full power and authority
        to do and perform each and every act and thing requisite and necessary
        to be done in connection with services and facilities available on the
        FanKonnect Platform, as fully to all intents and purposes as you might
        or could do in person. You acknowledge and agree that when we access and
        retrieve information from third party sites, we are acting as your
        agent, and not the agent or on behalf of the third party. You agree that
        such third parties shall be entitled to rely on the foregoing
        authorization, agency granted by you.
        <br />
        {/* Email ID: support@fankonnect.com
        <br/>
        Address: 1st Floor, Building No, D8, Block D, Sector 3, Noida, Uttar Pradesh 201301 */}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          9.ADDITIONAL TERMS AND CONDITIONS FOR CREATORS
        </div>
        Bunch Microtechnologies Private Limited’s (“Company”) terms and
        conditions (“Additional Terms”) are designed to keep our community of
        creators (“Creator”) and users (Creators and their content users being
        collectively referred to as “you” or “your”, as the context may require)
        protected. These Additional Terms are in addition to any other terms and
        conditions that govern the Creator’s and/or user’s access or use of our
        app / web-portal / the other associated/ancillary applications or
        services (such as “FanKonnect”) and websites of the Company
        (collectively “Platform”) which are available on our Platform.
        <ul style={{ margin: "20px 16px" }}>
          <li>INVESTMENT INFORMATION AND FINANCIAL PRODUCTS</li>
          <div style={{ margin: "20px 0" }}>
            The content where the Creator gives out advise or information about
            investments and of an array of financial instruments such as
            dematerialized accounts, stock market trading, personal finance and
            mutual funds using the Platform, the Creator undertakes and agrees
            to comply with, and at all times remain in compliance with,
            applicable laws and regulations and obtain necessary
            approvals/registrations/licenses to gives out any advise or
            information about investments and of an array of financial
            instruments.
          </div>
          <p dir="ltr">
            <span>
              Without limiting the generality of the foregoing, the Creator
              undertakes and agrees to ensure the following while creating the
              content and engaging its users:
            </span>
          </p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that it or anyone on its behalf shall not promote or market
                  any financial information or product in a manner that
                  misguides the retail investors;
                </span>
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that the Creator shall not provide false, unauthorized or
                  exaggerated information about any products;
                </span>
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that the Creator shall not solicit the prospective leads to
                  invest or subscribe or purchase the FIPs through coercion,
                  duress or undue influence;
                </span>
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that the Creator shall at all times ensure that it does not
                  directly or indirectly demand or accept money, commissions,
                  kickbacks from the retail investors or prospective lead;
                </span>
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that the Creator has the necessary training, skill and
                  experience for such promotional, marketing or other
                  distribution activities;
                </span>
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that the Creator shall not access, use, respond to requests or
                  messages publish content on behalf of the anyone, without
                  obtaining the prior written authorization from such
                  person/organization;
                </span>
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <span>
                  that the views and recommendations provided by the Creators on
                  the Platform are their own, and not that of the Company or its
                  management.
                </span>
              </p>
            </li>
          </ul>
          <p dir="ltr">
            <span>&nbsp;</span>
          </p>
          <p dir="ltr">
            <span>
              Further, in the event the Creator engages in any investment
              education services and is not a registered SEBI investment
              advisor, the Creator shall undertake necessary actions and provide
              the following disclaimer to its users, as may be appropriate:
            </span>
          </p>
          <p dir="ltr">
            <span>&nbsp;</span>
          </p>
          <p dir="ltr">
            <span>"</span>
            <span>
              The Creator is not a SEBI registered or qualified investment
              advisor. The Creator compiles data, content and information from
              various sources with due care and caution. The channel/group is
              for informational, educational and discussion purposes only. Even
              though topics may be discussed on the channel/group that involve
              investment issues, nothing on the channel shall be deemed to
              constitute the practice of investment advice. However, it is
              important to note that the advice may vary based on individual
              circumstances, and the information provided should not be
              considered as a substitute for advice from a qualified
              professional. Neither the Creator, and where applicable, nor its
              directors, principals, agents, associates nor employees, are
              licensed to provide investment advice. You are encouraged to
              consult with a qualified expert or professional to address your
              specific needs and circumstances
            </span>
            <span>.&rdquo;</span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <li>SPAM, DECEPTIVE PRACTICES AND SCAM CONTENT</li>
          <p dir="ltr">
            <span>
              The Company does not allow spam, scams, or other deceptive
              practices that take advantage of the users. The Creator is
              requested not to post or promote content if it fits any of the
              descriptions noted below:
            </span>
          </p>
          <p dir="ltr">
            <span>&nbsp;</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Video Spam: Content that is excessively posted,
              repetitive, or untargeted and does one or more of the following:
            </span>
          </p>
          <p dir="ltr">
            <span>
              i.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Promises viewers
              they'll see something but instead directs them off site.
            </span>
          </p>
          <p dir="ltr">
            <span>
              ii. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Directs them off site to
              get clicks, views, or traffic off YouTube or such other social
              media sites including for reasons of promising users that
              they&rsquo;ll make money fast.
            </span>
          </p>
          <p dir="ltr">
            <span>
              iii.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Sends audiences to sites
              that spread harmful software, try to gather personal info, or
              other sites that have a negative impact.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Misleading Metadata or Thumbnails: Using the
              title, thumbnails, description to trick users into believing the
              content is something it is not.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Scams: Content offering cash gifts, &ldquo;get
              rich quick&rdquo; schemes, or pyramid schemes (sending money
              without a tangible product in a pyramid structure).
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Incentivisation Spam: Content that sells
              engagement metrics such as views, likes, comments, or any other
              metric.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <li>IMPERSONATION</li>
          Content intended to impersonate a person is not allowed on the Company
          developed Platform. The Company also enforces intellectual property
          holder rights. When a Creator, or Creator’s content, causes confusion
          about the source of goods and services advertised, it may not be
          allowed.
          <br></br>
          <br></br>
          <li>EXTERNAL LINKS</li>
          <p dir="ltr">
            <span>
              Links that send users to websites featuring content that violates
              our terms and conditions are not allowed on the Platform. Do not
              post links in your content if they direct users to content that
              violates our terms and conditions. This includes links that fit
              any of the descriptions noted below:
            </span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Links to pornography</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to websites or application that install
              malware
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to websites or application phishing for a
              user&rsquo;s personal or sensitive information.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to websites that seek to raise funds or
              recruit for terrorist organizations
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to sites selling items noted in our
              regulated goods guidelines
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to content that would violate hate or
              harassment rules
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to content encouraging others to commit
              violent acts
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to content that spread misinformation
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to websites or application that spread
              misleading or deceptive content
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <li>FAKE ENGAGEMENT</li>
          <p dir="ltr">
            <span>
              The Company doesn&rsquo;t allow anything that artificially
              increases the number of users engaging with the Company developed
              Platform for the Creator. Also, content that solely exists to
              incentivize viewers for engagement is prohibited. This should
              include:
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Links to or promotes third-party services that
              artificially inflate metrics.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Content linking to or promoting third-party
              websites or services.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Offering to subscribe to another Creator&rsquo;s
              app only if they subscribe to your app.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <br></br>
          <li>SENSITIVE CONTENT</li>
          We cater to users of all age groups, including minors with parental /
          guardian consent. That's why we've got rules around keeping children
          safe, sex and nudity, and self-harm. Explicit content meant to be
          sexually gratifying is not allowed on the Company. Sexually explicit
          content featuring minors and content that sexually exploits minors is
          not allowed on the Platform.
          <br></br>
          <br></br>
          <li>VULGAR LANGUAGE</li>
          <p dir="ltr">
            <span>
              Some language may not be appropriate for users. The Company does
              not allow{" "}
            </span>
            <span>posting content that:</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Use of sexually explicit language or narratives
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Use of excessive profanity in the content&nbsp;
            </span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; </span>
            <span>Violent or dangerous content</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; </span>
            <span>Hate speech</span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <br></br>
          <li>HARMFUL OR DANGEROUS CONTENT</li>
          <p dir="ltr">
            <span>The Company </span>
            <span>
              doesn&rsquo;t allow content that encourages dangerous or illegal
              activities that risk serious physical harm or death. Hence,
              don&rsquo;t post such content, especially if it fits any of the
              descriptions noted below.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Extremely dangerous challenges: Challenges that
              pose an imminent risk of physical injury.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Instructional theft or cheating: Showing viewers
              how to steal tangible goods or promoting dishonest behavior.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Hacking: Demonstrating how to use computers or
              information technology with the intent to steal credentials,
              compromise personal data or cause serious harm to others such as
              (but not limited to) hacking into social media accounts.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Bypassing payment for digital content or services:
              Showing viewers how to use application, websites, or other
              information technology to gain unauthorized access to content.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <br></br>
          <br></br>
          <li>SALE OF ILLEGAL OR REGULATED GOODS OR SERVICES</li>
          <p dir="ltr">
            <span>
              Content intended to sell certain regulated goods and services is
              not allowed by the Company. Don't post content on the{" "}
            </span>
            <span>Platform</span>
            <span>
              {" "}
              if it aims to directly sell, link to, or facilitate access to any
              prohibited goods, or regulated goods and services listed
              below.&nbsp;
            </span>
          </p>
          <p dir="ltr">
            <span>&nbsp;</span>
          </p>
          <p dir="ltr">
            <span>
              Making the sale of these items or facilitating the use of these
              services possible by posting links, email, phone number or other
              means to contact a seller directly is not allowed:
            </span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Alcohol</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Bank account passwords, stolen credit cards, or
              other sensitive financial information
            </span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Counterfeit documents or currency</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Controlled narcotics and other drugs</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Explosives</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Organs</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Endangered species or parts of endangered species
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Firearms and certain firearms accessories
            </span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Nicotine, including vaping products</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Online gambling sites not yet reviewed by Google
              or YouTube
            </span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Pharmaceuticals without a prescription</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Sex or escort services</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Unlicensed medical services</span>
          </p>
          <p dir="ltr">
            <span>&Oslash; &nbsp; Human smuggling</span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <br></br>
          <br></br>
          <li>MISINFORMATION</li>
          <p dir="ltr">
            <span>
              Certain types of misleading or deceptive content with serious risk
              of egregious harm are not allowed on Company. This includes
              certain types of misinformation that can cause real-world harm,
              like promoting harmful remedies or treatments, certain types of
              technically manipulated content, or content interfering with
              democratic processes.
            </span>
          </p>
          <p dir="ltr">
            <span>&nbsp;</span>
          </p>
          <p dir="ltr">
            <span>Don&rsquo;t post content on the </span>
            <span>Platform</span>
            <span> if it fits any of the descriptions below:</span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Manipulated content: Content that has been
              technically manipulated or doctored in a way that misleads users
              and may pose a serious risk of egregious harm.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Misattributed content: Content that may pose a
              serious risk of egregious harm by falsely claiming that old
              footage from a past event is from a current event.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Promoting dangerous remedies, cures, or
              substances: Content that promotes harmful substances, treatments,
              or substances that present an inherent risk of severe bodily harm
              or death.
            </span>
          </p>
          <p dir="ltr">
            <span>
              &Oslash; &nbsp; Contradicting expert consensus on certain safe
              medical practices: Content that contradicts local health
              authorities&rsquo; or WHO guidance on certain safe medical
              practices.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
        </ul>
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          10. INTELLECTUAL PROPERTY RIGHTS
        </div>
        10.1 Bunch Content:All of the content on the FanKonnect Platform,
        including, without limitation, all of the page headers, images,
        illustrations, graphics, audio clips, video clips or text, interface,
        reports generated, trademarks, tradenames, trade secrets (“Services
        Content”), constitute our and our partners’ intellectual property.
        Copyright laws in all applicable jurisdictions protect the FanKonnect
        Platform and the Services Content.You may access the FanKonnect
        Platform, avail of the features and facilities and utilize the Services
        Content for your personal or internal requirements only. You agree not
        to duplicate, distribute, create derivative works of, display, or
        commercially exploit the Services Content, features or facilities,
        directly or indirectly, except as expressly authorized herein. In case
        you want to request permission to commercially exploit any Services
        Content, you could contact us and seek our explicit written
        consent.Bunch is the sole owner of the underlying software and source
        code associated with the FanKonnect Platform and all the trademarks,
        copyright and any other intellectual property rights of any nature in
        the Services.You confirm and undertake not to utilize any data mining
        tools, robots, or similar data gathering and extraction tools to extract
        for re-utilization of any substantial parts of the FanKonnect Platform,
        without Bunch’s express prior written consent.
        <br />
        <br />
        10.2 User Content:You shall remain the sole owner of the User Content
        and Bunch does not claim any ownership over any User Content
        uploaded/published by any User on the FanKonnect Platform.By submitting
        the User Content on the FanKonnect Platform, you hereby grant Bunch a
        limited, worldwide, non-exclusive, royalty-free, sub-licensable and
        transferable license to use, reproduce, distribute, share, display,
        publish, retain, make available online and/or electronically transmit
        such User-generated Content as well as technical information collected
        via the FanKonnect Platform to the extent necessary (a) to provide the
        FanKonnect Platform and our Services; (b) to perform our obligations
        under the FanKonnect Platform Terms and other written agreement with the
        User; (c) to provide, monitor, correct, and improve the FanKonnect
        Platform and Services related thereto; (d) to de-identify User data such
        that there is no reasonable basis to believe that the information can be
        used, alone, or in combination with other reasonably available
        information, to identify any individual or to identify User as the
        source of such data; (e) to aggregate User data with other data; and (f)
        to comply with applicable laws. However, please note that we will never
        disclose aggregated or de- identified information in a manner that could
        identify you as an individual, and all your information shall be
        processed in accordance with our Privacy Policy.You also hereby grant
        each User of the Platform a limited, non-exclusive license to access
        your User Content through the Platform.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          11. THIRD PARTY SERVICES, PROMOTIONS AND ADVERTISEMENTS
        </div>
        While availing Services, Users may connect with or at times, run
        promotions and advertisements for third-party service providers.
        However, Bunch is not responsible for, and does not endorse, any
        third-party services mentioned on the FanKonnect Platform. It is hereby
        stated Bunch shall in no way be responsible for any acts or omissions of
        third parties. Any transaction, dealings, or communication otherwise
        that the User may have with such third parties are at the User’s own
        risk and we make no warranties, express or implied regarding the quality
        or suitability of the services or products of such third-party vendors.
        You may be redirected to a third-party website upon clicking on such
        links, these websites will be governed by its privacy policy and terms
        of use. Bunch shall not be responsible for any transaction or
        dissemination of information that may take place while accessing these
        third-party websites. You acknowledge and agree that Bunch is not
        responsible or liable for any loss or damage of any kind incurred
        because of any such dealings with such third parties. Any use you make
        of any site or service linked to by the Platform is entirely at your own
        risk. <br />
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          12. TERMINATION
        </div>
        Bunch may block, restrict, disable, suspend or terminate the use of the
        Platform at any time without giving any notice to you in case you are
        inter alia found to:a.be in breach of the terms of the Terms and
        Conditions;b. violate any applicable laws;c.be infringing the
        intellectual property rights of any third party; ord. be acting in a
        manner which may result into any claim against Bunch.Upon termination,
        the rights and license granted to you herein shall terminate
        immediately, and you must immediately cease all use of and access to the
        FanKonnect Platform and Services. You must also immediately destroy any
        downloaded materials (not being User Content) in your possession whether
        in electronic or printed format.Any termination of your right to use or
        access the FanKonnect Platform and/ or avail the Services shall not
        affect liability incurred by you prior to such termination.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          13. DISPUTES BETWEEN USERS
        </div>
        You agree that you are solely responsible for your interactions with any
        other user in connection with the FanKonnect Platform and Services, and
        Bunch will have no liability or responsibility with respect thereto.
        Bunch reserves the right, but has no obligation, to become involved in
        any way with disputes between you and any other user of the FanKonnect
        Platform.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          14. INDEMNITY AND RELEASE
        </div>
        To the extent permitted by applicable law, you agree to defend,
        indemnify and hold harmless Bunch , its affiliates, their respective
        officers, directors, employees and agents, from and against any and all
        losses arising from: (i) your use of and access to the FanKonnect
        Platform; (ii) your violation of any of the Terms; (iii) your violation
        of any third party right, including without limitation any copyright,
        property, or privacy right; (iv) any claim that your User Content causes
        or caused damage to a third party; or (v) violation of any applicable
        laws. This defense and indemnification obligation will survive these
        Terms and Conditions and your use of the FanKonnect Platform.You hereby
        expressly release Bunch, its affiliates and any of their respective
        officers, directors, employees and agents from any loss, liability or
        other consequence of any of the actions/inactions of any third-party
        vendors or service providers and specifically waive any claims or
        demands that you may have in this behalf against any of Bunch, its
        affiliates and any of their respective officers, directors, employees
        and agents under any statute, contract or otherwise.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          15. DISCLAIMER
        </div>
        The Platform and Services (including, without limitation, any Services
        Content) are provided “AS IS” and “AS AVAILABLE” and are without
        warranty of any kind, express or implied, including, but not limited to,
        the implied warranties of title, non-infringement, merchantability and
        fitness for a particular purpose, and any warranties implied by any
        course of performance or usage of trade, all of which are expressly
        disclaimed. Bunch (including its affiliates and their respective
        directors, employees, representatives, agents, suppliers, sponsors and
        partners) do not warrant that: (a) the Platform and/or Services will be
        secure or available at any particular time or location; (b) the quality
        of any products, services, information, or other material purchased or
        obtained by you through the Platform and/or Services will meet your
        expectations; (c) any defects or errors will be corrected; (d) any
        content or software available at or through the Platform and/or Services
        is free of viruses or other harmful components; or (e) the results of
        using the Platform and/or Services will meet your requirements or the
        use of the Platform and/or Services will be accurate or reliable. Your
        use of the Platform and/or Services is solely at your own risk.Bunch
        does not promise to store or keep showing any information and User
        Content that you’ve posted. You agree that we have no obligation to
        store, maintain or provide you a copy of any content or information that
        you or others provide, except to the extent required by applicable law
        and as noted in our Privacy Policy. The cost of procurement of the
        services resulting from any data, content or information purchased or
        obtained or messages received or not obtained or received from any paid
        group/channel or any host or other user is solely and entirely at your
        own risk. You hereby acknowledge and agree that we provide no guarantee
        or surety of engagement between you and the host/other user upon payment
        and take no responsibility of ensuring full scale interaction between
        you and the host and the other users.You hereby acknowledge and agree
        that you shall only provide true and correct information and in the case
        of creating a profile you undertake to always keep the information up to
        date. Under no circumstances will Bunch be liable on account of any
        inaccuracy of information provided by you on this platform. Any material
        downloaded or otherwise obtained through the FanKonnect Platform is done
        at your own discretion and risk and you are solely responsible for any
        damage to your Device or loss of data that results from the download of
        any such material. No advice or information, whether oral or written,
        obtained by you from us through or from the FanKonnect Platform will
        create any warranty not expressly stated in these Terms.Bunch shall not
        be liable for the loss and/or damage of the confidential information or
        data of the User arising because of an event or a series of related
        events, that is beyond its control including failures of or problems
        with the internet or part of the internet, attempted hacker attacks,
        hacker attacks, denial of service attacks and/or viruses or other
        malicious software attacks or infections.The FanKonnect Platform is
        controlled and offered by Bunch and/or its affiliates depending upon
        your jurisdiction. Bunch makes no representations that the FanKonnect
        Platform is appropriate or available for use in other locations. Those
        who access or use the FanKonnect Platform from other jurisdictions do so
        at their own volition and are responsible for compliance with local law.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          16. LIMITATION OF LIABILITY
        </div>
        EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW OR TO THE EXTENT THAT
        ANY EXCLUSION OR LIMITATION OF ITS LIABILITY IS VOID, PROHIBITED OR
        UNENFORCEABLE, IN NO EVENT SHALL BUNCH, ITS AFFILIATES OR THEIR
        RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES OR AGENTS, BE
        LIABLE UNDER ANY EQUITY, COMMON LAW, CONTRACT, ESTOPPEL, NEGLIGENCE,
        TORT, STRICT LIABILITY OR OTHER THEORY (REGARDLESS OF THE FORM OF
        ACTION) (A) FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
        PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING LOSS OF PROFITS, REVENUES,
        SAVINGS, CUSTOMERS, OPPORTUNITIES, PROPERTY OR GOODWILL); (B) FOR ANY
        MATTER BEYOND ITS REASONABLE CONTROL; (C) FOR ANY LOSS, CORRUPTION OR
        INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE OR COST OF PROCURING
        SUBSTITUTE TECHNOLOGY, EVEN IF BUNCH HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES; (D) ERRORS, MISTAKES, OR INACCURACIES OF USER CONTENT
        OR ANY OTHER CONTENT AVAILABLE ON THE PLATFORM OR WITH BUNCH OR (E) ANY
        BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO
        OR THROUGH THE PLATFORM BY ANY THIRD PARTY.IN NO EVENT WILL BUNCH'S
        CUMULATIVE AGGREGATE LIABILITY ARISING OUT OF THESE TERMS OF SERVICE
        FROM ANY CAUSE OF ACTION WHATSOEVER EXCEED THE TOTAL FEES PAID BY THE
        USER TO BUNCH PURSUANT TO THESE TERMS OF SERVICE DURING THE SIX (6)
        MONTHS PRIOR TO THE DATE THE CAUSE OF ACTION AROSE. THESE LIMITATIONS
        ARE INDEPENDENT FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL
        APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN.We
        understand that, in some jurisdictions, warranties, disclaimers and
        conditions may apply that cannot be legally excluded. If that is true in
        your jurisdiction, then to the extent permitted by law, Bunch, its
        affiliates, and their respective officers, directors, employees,
        representatives or agents, limit their liability for any claims under
        those warranties or conditions to supplying you the FanKonnect Platform
        again. You specifically acknowledge that Bunch shall not be liable for
        User Content or the defamatory, offensive, or illegal conduct of any
        third party, and that the risk of harm or damage from the foregoing
        rests entirely with you.You further agree and confirm Bunch shall not be
        responsible, in any manner whatsoever, for any delay/unavailability of
        Services or failure to meet its obligations under the Terms, which may
        be caused, directly or indirectly, due to:a.your failure to cooperate;b.
        your unavailability and/or unresponsiveness;c.your failure to provide
        accurate and complete information;d. your failure to provide or
        facilitate the submission of required documents/materials in timely
        manner; and/ore. any event beyond Bunch’s reasonable control.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          17. FORCE MAJEURE
        </div>
        Bunch shall not be liable for failing to perform, or delay in
        performance of, any of its obligations if, and to the extent that, such
        failure or fire, flood, hurricane, pandemic, epidemic or other natural
        catastrophe, terrorist actions, laws, orders, regulations, directions,
        or actions of Government authorities having jurisdiction over the
        subject matter hereof, or any civil or military authority, national
        emergency, insurrection, riot or war, labor strikes (not involving
        either party’s employees), general failure of telecommunication or
        digital transmission links, general failure of the internet, power
        outage or grid failure, failure of third party applications, failure of
        any third party operating systems, platforms, applications or networks
        not under reasonable control of Bunch, or other similar occurrence.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          18. WAIVER
        </div>
        Any failure on the part of Bunch to require performance of any provision
        of these Terms shall not affect its right to full performance thereof at
        any time thereafter, and any waiver by Bunch of a breach of any
        provision hereof shall not constitute a waiver of a similar breach in
        the future or of any other breach.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          19. ASSIGNMENT
        </div>
        You shall not assign or transfer any rights, obligations, or privileges
        that you have under these Terms, without the prior written consent of
        Bunch. Subject to the foregoing, these Terms will be binding on your
        legal heirs, successors and permitted assignees. Any assignment or
        transfer in violation of this clause will be deemed null and void.
        Bunch’s rights under the Terms are freely transferable by Bunch to any
        third parties without the requirement of intimating you or seeking your
        consent.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          20. UPDATES
        </div>
        Bunch reserves the right to update the Services and the FanKonnect
        Platform to, inter alia, increase efficiency, optimize user interface,
        and add new facilities from time to time. Update packages will be sent
        to you on your Device for download and installation.You hereby agree to
        install the updates from time to time and acknowledge that Bunch will
        only be able to provide account support for the Services and the
        FanKonnect Platform if you ensure to install all updates upon receiving
        notifications thereof when using the FanKonnect Platform. Bunch shall
        not be liable for, and the User shall indemnify and hold Bunch harmless
        for, losses due to the User’s failure to take the foregoing steps with
        respect to a critical update.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          21. VALIDITY OF TERMS
        </div>
        These Terms shall apply when you complete the authentication process and
        create an Account and shall remain valid and binding on you for so long
        as you maintain the Account.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          22. SEVERABILITY
        </div>
        If any provision of these Terms are held to be illegal, invalid or
        unenforceable under any present or future applicable laws:a.such
        provision will be replaced with another, which is not prohibited or
        unenforceable and has, as far as possible, the same legal and commercial
        effect as that which it replaces; andb. the remaining provisions of the
        Terms will remain in full force and effect and will not be affected by
        the illegal, invalid, or unenforceable provision or by its severance
        here from.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          23. GOVERNING LAW AND JURISDICTION
        </div>
        These Terms (and by extension, the Privacy Policy) are governed and
        construed in accordance with laws of India. By using the FanKonnect
        Platform, you hereby irrevocably consent to the exclusive jurisdiction
        and venue of courts in New Delhi, India, in the event of any disputes
        arising out of or in relation to your access to and use of the
        FanKonnect Platform.
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          24. GRIEVANCE REDRESSAL MECHANISM
        </div>
        In accordance with the Information Technology Act, 2000 and rules made
        there under, and the Consumer Protection (E-Commerce) Rules 2020, the
        name and contact details of the Grievance Officer/Nodal Officer is
        provided below:Attention: Mr. Saransh RanjanEmail ID:
        support@fankonnect.comAddress: 1st Floor, Building No, D8, Block D,
        Sector 3, Noida, Uttar Pradesh 201301
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          25.ANTI ABUSE AND ANTI CORRUPTION
        </div>
        Bunch prohibits sending unsolicited messages or endorsing illegal
        activities using the FanKonnect Platform. You may not engage in
        commercial activity on non-commercial properties or applications or in
        high-volume activity on the FanKonnect Platform without our prior
        written consent. You may not engage in or conduct any activity that is
        disruptive to the Service or the experience of other users. User agrees
        to comply with all applicable anti-bribery and anti-corruption laws and
        agrees to not use the FanKonnect Platform for any corrupt practices
        including but not limited to collecting unlawful payments from anyone
        for a corrupt purpose.{" "}
        <div
          className="Privacy_Bottom_Head"
          style={{ fontWeight: "bold", margin: "20px 0" }}
        >
          26. CONTACT
        </div>
        If you have any questions regarding the Services or usage of the
        FanKonnect Platform, please contact Bunch at support@fankonnect.com.
        Please note that for the purpose of validation, you shall be required to
        provide information (including, but not limited to contact number or
        registered mobile number, etc.) for the purpose of validation and taking
        your service request.
      </div>
    </div>
  );
}

export default Tnc;
