import CONSTANTS from "./constants";

const initialState = {
  isLoading: false,
  limit: 10,
  offset: 0,
  singleMessageDetails: null,
  singleGroupDetails: null,
  statement: {
    revenue: 500,
    data: [],
  },
  statementData: {},

  dataChecker: false,
  allCouponsData: [],
  isLoadings: false,
  error: null,
};

const setIsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.payload,
  };
};
const checkMemberData = (state, action) => {
  return {
    ...state,
    dataChecker: action.payload,
  };
};
const resetMemberData = (state, action) => {
  console.log("actionResetMemberData", action);
  return {
    ...state,
    dataChecker: action.payload,
  };
};

const getContentDetailsSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    ...(action.content === 1
      ? {
          singleGroupDetails: action.payload,
        }
      : {
          singleMessageDetails: action.payload[0],
        }),
  };
};

const getStatementDataSuccess = (state, action) => {
  // console.log("reducerdataaaaa",action);

  const temp = {
    ...state,
    statement: {
      ...state.statement,
      data: {
        ...action.payload,
      },
    },
    statementData: {
      ...(action.offset === 0
        ? action.payload
        : {
            ...state.statementData,
            members: [
              ...state.statementData.members,
              ...action.payload.members,
            ],
          }),
    },
    offset: state.limit + action.offset,
  };
  // console.log("temp",temp);
  return temp;
};
const resetStatementData = (state) => {
  return {
    ...state,
    statementData: {},
  };
};

const resetSingleGroupDetails = (state) => {
  return {
    ...state,
    singleGroupDetails: null,
  };
};

const resetSingleMessageDetails = (state) => {
  return {
    ...state,
    singleMessageDetails: null,
  };
};
const allCouponSuccessfully = (state, action) => {
  // console.log("actionAll",action)
  return {
    ...state,
    allCouponsData: action.payload,
    isLoading: false,
    error: null,
  };
};
const allCouponFailure = (state, action) => {
  return {
    ...state,
    error: action.payload,
    isLoading: false,
    allCouponsData: [],
  };
};
const allCouponLoader = (state) => {
  return {
    ...state,
    isLoading: true,
    // allCouponsData: [],
    // toggleLoading:false,
    error: null,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_IS_LOADING:
      return setIsLoading(state, action);
    case CONSTANTS.CHECK_DATA_MEMBER:
      return checkMemberData(state, action);
    case CONSTANTS.RESET_MEMEBER_DATA:
      return resetMemberData(state, action);
    case CONSTANTS.GET_CONTENT_DETAILS_SUCCESS:
      return getContentDetailsSuccess(state, action);
    case CONSTANTS.GET_STATEMENT_SUCCESS:
      return getStatementDataSuccess(state, action);
    case CONSTANTS.RESET_SINGLE_GROUP_DETAILS:
      return resetSingleGroupDetails(state, action);
    case CONSTANTS.RESET_SINGLE_MESSAGE_DETAILS:
      return resetSingleMessageDetails(state, action);
    case CONSTANTS.RESET_STATEMENT_DATA:
      return resetStatementData(state, action);
    case CONSTANTS.ALL_COUPONS_SUCCESS:
      return allCouponSuccessfully(state, action);
    case CONSTANTS.ALL_COUPONS_FAILURE:
      return allCouponFailure(state, action);
    case CONSTANTS.ALL_COUPONS_LOADER:
      return allCouponLoader(state, action);
    default:
      return state;
  }
};

export default reducer;
