import CONSTANTS from "./constants";

//INITIAL STATE
const earningsInitialState = {
  loadingStateGetEarnings: false,
  apiResponseGetEarnings: [],
  totalEarnings: 0,
  offset: 0,
  version2: false,
};

//REDUCER
const earningsReducer = (state = earningsInitialState, action) => {
  const temp = state.apiResponseGetEarnings;
  switch (action.type) {
    case CONSTANTS.MY_EARNINGS_GET_CLEAR: {
      return {
        ...state,
        apiResponseGetEarnings: [],
        totalEarnings: 0,
        offset: 0,
      };
    }
    case CONSTANTS.MY_EARNINGS_GET_LOADER: {
      return {
        ...state,
        loadingStateGetEarnings: true,
        offset: 0,
      };
    }
    case CONSTANTS.MY_EARNINGS_SUCCESS: {
      return {
        ...state,
        loadingStateGetEarnings: false,
        apiResponseGetEarnings: [...temp, ...action?.payload.earnings],
        totalEarnings: action?.payload?.totalEarnings,
        offset: action.payload.offset,
      };
    }
    case CONSTANTS.MY_EARNINGS_GET_FAILURE: {
      return {
        ...state,
        loadingStateGetEarnings: false,
        apiResponseGetEarnings: [],
        totalEarnings: 0,
        offset: 0,
      };
    }
    case CONSTANTS.SET_VERSION_2: {
      return {
        ...state,
        version2: action.payload,
      };
    }
    default:
      return state;
  }
};

export default earningsReducer;
