import { put, call, select, takeLatest } from "redux-saga/effects";
import * as services from "./service";
import CONSTANTS from "./constants";

function* getCountryCode() {
  yield put({
    type: CONSTANTS.SET_LOADING,
    payload: true,
  });
  const response = yield call(services.getCountryCodeService);
  console.log("RESPONSE IN SAGA IS", response);
  if (response.status === "success") {
    yield put({
      type: CONSTANTS.GET_COUNTRY_CODE_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: CONSTANTS.SET_LOADING,
      payload: false,
    });
  } else {
    yield put({
      type: CONSTANTS.SET_LOADING,
      payload: false,
    });
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.GET_COUNTRY_CODE, getCountryCode);
}
