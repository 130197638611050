import React from "react";
import "../../../../scss/Purchase/groupDetails.scss";
import CustomUserAvatar from "../../../../common/CustomUserAvator";
import ChannelBadge from "../../../../common/ChannelBadge";
import CategoryBadge from "../../../../common/CategoryBadge";

const PayHeader = (props) => {
  const { pageData } = props;
  return (
    <div>
      <div className="groupDetails_head">
        {/* <img src={grpPic} alt="" className="groupDetails_head-img" /> */}
        {pageData?.logoUrl ? (
          <img
            src={pageData?.logoUrl}
            height={"100px"}
            width={"100px"}
            style={{ borderRadius: "50px" }}
          />
        ) : (
          <CustomUserAvatar
            user={{
              name: pageData?.title || pageData?.groupName,
            }}
            size={"116px"}
          />
        )}
        <p className="groupDetails_head-text">
          {pageData?.title || pageData?.groupName}
        </p>
      </div>
      {/* tags */}

      <div className="groupDetails_Badges">
        <ChannelBadge data={pageData} />
        <CategoryBadge categoryName={pageData?.category} />
      </div>
    </div>
  );
};

export default PayHeader;
