import React from "react";
import { useSelector } from "react-redux";
import tele from "../../assets/tele.svg";
import wa from "../../assets/v2Automation/walogo.png";
import "./styles.scss";

function ChannelBadge(props) {
  const { data } = props;
  const selectedPlatform = useSelector(
    (state) => state?.createGroup?.selectedPlatform
  );
  const channelType = useSelector((state) => state?.user?.channelType);
  console.log("selectedPlatform", selectedPlatform, data?.type);
  switch (data?.type || channelType || selectedPlatform) {
    case "whatsappCommunity":
      return (
        <div className="WhatsAppBadge">
          <img
            src={wa}
            alt="cats"
            height="10px"
            style={{ marginRight: "5px" }}
          />
          Whatsapp Community
        </div>
      );
    case "whatsappGroup":
      return (
        <div className="WhatsAppBadge">
          <img
            src={wa}
            alt="cats"
            height="10px"
            style={{ marginRight: "5px" }}
          />
          Whatsapp Group
        </div>
      );
    case "Telegram":
      return (
        <div className="ChannelBadge">
          <img
            src={tele}
            alt="cats"
            height="10px"
            style={{ marginRight: "5px" }}
          />
          Telegram Channel
        </div>
      );
    default:
      return (
        <div className="ChannelBadge">
          <img
            src={tele}
            alt="cats"
            height="10px"
            style={{ marginRight: "5px" }}
          />
          Telegram Channel
        </div>
      );
  }
}

export default ChannelBadge;
