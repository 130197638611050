import axios from "axios";
import { BASE_URL } from "../../axios-config";
import { getUserToken } from "../../utils/getAxiosConfig";

export const getAutomatedChannelIDService = async (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/getAutomatedChannel?channelId=${payload?.payload?.telegramChannelId}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      console.log("response automated Channel : ", response);
      return response;
    })
    .catch((error) => {
      console.log("error in automated Channel : ", error);
      return error;
    });
};
