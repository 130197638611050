import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../axios-config";
import "../../../scss/Purchase/loader.scss";
import { setPurchaseScreen } from "../../../store/Purchase/actions";
import store from "../../../store/store";

const Loading = () => {
  const [opDone, setOpDone] = useState(false);
  const [callAPI, setCallAPI] = useState(true);
  let counter = 1000;
  let callingCount = 1;
  let count = 1;
  let status = "pending";

  const purchasedData = useSelector(
    (state) => state?.purchaseReducer?.orderStatus
  );
  // console.log("purchasedDataaa", purchasedData);
  const timeOut = async () => {
    console.log("delay 2 seconds");
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const callOrderUpdate = async (payload, token) => {
    // console.log("hittingApiOrderUpdate");
    await axios
      .put(BASE_URL + "/order/update", payload, {
        headers: {
          ...(token ? { "x-access-token": token } : {}),
        },
      })
      .then((res) => {
        // console.log("pppp11", res?.data?.data);
        localStorage.setItem("groupName", res?.data?.data?.groupName);
        localStorage.setItem(
          "isAddedToGroup",
          res?.data?.data?.isAddedToGroup ? "1" : ""
        );
        //   if (status == "pending") {
        //     if(count){
        //       count=0;
        //      async function actionPerform(){
        //          await timeOut();
        //         await callOrderUpdate(payload,token);
        //       }
        //      actionPerform();
        //     }
        //     else{
        //      setOpDone(true);
        //      store.dispatch(setPurchaseScreen("inprogress"));
        //     }
        //    return;
        //  }
        if (res?.data?.data.status == "failed") {
          setCallAPI(false);
          setOpDone(true);
          window.payload = { orderId: res?.data?.data.orderId };
          store.dispatch(setPurchaseScreen("failure"));
          return;
        }
        if (res?.data?.data.status == "pending") {
          if (counter == 64000) {
            setOpDone(true);
            window.payload = { orderId: res?.data?.data.orderId };
            store.dispatch(setPurchaseScreen("inprogress"));
          } else {
            handleApiCall();
          }
          // if (count) {
          //   count = 0;
          //   async function actionPerform() {
          //     await timeOut();
          //     await callOrderUpdate(payload, token);
          //   }
          //   actionPerform();
          // } else {
          //   setOpDone(true);
          //   window.payload = { orderId: res?.data?.data.orderId };
          //   store.dispatch(setPurchaseScreen("inprogress"));
          // }
          return;
        }
        const paymentSuccess = {
          data: [...res?.data?.data?.paymentSuccessMessage],
        };
        window.paymentSuccessData = res?.data?.data;
        localStorage.setItem("successMessage", JSON.stringify(paymentSuccess));
        setOpDone(true);
        setCallAPI(false);
        if (purchasedData?.memberStatus == "renewed") {
          store.dispatch(setPurchaseScreen("renewedSuccess"));
        } else {
          store.dispatch(setPurchaseScreen("success"));
        }
      })
      .catch((err) => {
        console.log(err);
        setOpDone(true);
        store.dispatch(setPurchaseScreen("inprogress"));
      });
  };

  let timer;

  const handleApiCall = async () => {
    const payload = window?.payload?.apiObj;
    const token = window?.payload?.token;
    let temp = counter;
    counter = counter * 2;
    if (temp == 64000) {
      clearTimeout(timer);
      return;
    } else {
      timer = setTimeout(async () => {
        await callOrderUpdate(payload, token);
      }, counter - temp);
    }
  };

  useEffect(() => {
    handleApiCall();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!opDone) {
        setPurchaseScreen("inprogress");
      }
    }, 64000);
  }, []);

  return (
    <div className="loaderContainer">
      <ReactLoading color="black" />
      <div className="loaderContainer_loaderHeading">Processing...</div>
      <div className="loaderContainer_loaderDescription">
        Please do not refresh the page and wait while we are processing your
        request. This can take upto few seconds.
      </div>
      {/* </div> */}
    </div>
  );
};

export default Loading;
