import React, { useEffect, useState } from "react";
import Button from "../../../common/Button";
import Dropdown from "../../../components/Dropdown";
import exportMail from "../../../assets/exportMail.png";
import reportSucess from "../../../assets/reportSuccess.png";
import "./exportReport.scss";
import { generateReportService, getChannelListService } from "./store/service";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";

const ExportReport = (props) => {
  let [channels, setChannels] = useState([]);
  let [type, setType] = useState(null);
  let [channel, setChannel] = useState(null);
  let [period, setPeriod] = useState(null);
  let [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    getChannels();
  }, []);

  const userDetails = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.userDetails
  );

  const getChannels = () => {
    getChannelListService()
      .then((response) => {
        let temp = [];
        response?.data?.data.forEach((obj) => {
          temp.push({
            id: obj.id,
            label: obj.groupName,
          });
        });
        setChannels(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportReport = () => {
    const payload = {
      ...(type
        ? type.label == "Active User"
          ? { type: "active" }
          : type.label == "Expired User"
          ? { type: "expired" }
          : { type: "settlement" }
        : {}),
      ...(channel ? { channel: channel.id } : {}),
      ...(period
        ? period.label == "Last 30 Days"
          ? { period: 1 }
          : period.label == "Last 3 months"
          ? { period: 3 }
          : { period: 6 }
        : {}),
    };
    generateReportService(payload)
      .then((response) => {
        console.log(response.status);
        if (response && response.status == 200) {
          setShowSuccessPopup(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reportType = [
    {
      id: "Active User",
      label: "Active User",
    },
    {
      id: "Expired User",
      label: "Expired User",
    },
    // {
    //   id: "Settlement",
    //   label: "Settlement",
    // },
  ];

  const periodList = [
    {
      id: "Last 30 Days",
      label: "Last 30 Days",
    },
    {
      id: "Last 3 months",
      label: "Last 3 months",
    },
    {
      id: "Last 6 months",
      label: "Last 6 months",
    },
  ];

  const settingType = (item) => {
    setType(item);
    if (item.label == "Settlement") {
      setChannel(null);
    }
    if (item.label == "Active User") {
      setPeriod(null);
    }
  };

  const setBtnDisabled = () => {
    if (type && type.label == "Settlement") {
      if (period) {
        return true;
      }
    } else if (type && type.label == "Active User") {
      if (channel) {
        return true;
      }
    } else if (type) {
      if (channel && period) {
        return true;
      }
    }
    return false;
  };

  const settingChannel = (item) => {
    setChannel(item);
  };

  const settingPeriod = (item) => {
    setPeriod(item);
  };

  return (
    <div className="ExportReport">
      <div className="ExportReport_form">
        <Popup
          closeOnDocumentClick={false}
          contentStyle={{ width: "85%", borderRadius: 5 }}
          open={showSuccessPopup}
        >
          <div className="ExportReport_successPopup">
            <img src={reportSucess} height={"80px"} width={"80px"} />
            <div className="ExportReport_successHeading">Report Generated</div>
            <div className="ExportReport_successText">
              {`Report will be sent to ${userDetails?.email} within next 10 min.`}
            </div>
            <div className="ExportReport_successBTNContainer">
              <Button
                onClick={() => {
                  setType(null);
                  setChannel(null);
                  setPeriod(null);
                  setShowSuccessPopup(false);
                }}
              >
                Okay, Thanks
              </Button>
            </div>
          </div>
        </Popup>
        <Dropdown
          placeholder={"Select Report Type"}
          optionsList={reportType}
          label={"Report type"}
          itemClickHandler={settingType}
          selectedOption={type}
        />
        {type?.label != "Settlement" && (
          <Dropdown
            placeholder={"Select Channel"}
            optionsList={channels}
            label={"Select Channel"}
            itemClickHandler={settingChannel}
            selectedOption={channel}
          />
        )}
        {type?.label != "Active User" && (
          <Dropdown
            placeholder={"Select Period"}
            optionsList={periodList}
            label={"Period"}
            itemClickHandler={settingPeriod}
            selectedOption={period}
          />
        )}
        <div className="ExportReport_ButtonContainer">
          <Button disabled={!setBtnDisabled()} onClick={exportReport}>
            {`Export Now`}
            <img
              style={{ marginLeft: 10 }}
              height={"24px"}
              width={"24px"}
              src={exportMail}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExportReport;
