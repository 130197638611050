import { initializeApp } from "@firebase/app";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "@firebase/remote-config";
import { firebaseConfig } from "./constants";

const app = initializeApp(firebaseConfig);

const getValueFromFirebase = (remoteConfig) => {
  const v2 = getValue(remoteConfig, "v2Enabled");
  const orgIds = getValue(remoteConfig, "orgIds");
  const featureReleaseControl = getValue(remoteConfig, "FeatureReleaseControl");
  const version2 = getValue(remoteConfig, "version2");

  const data = {
    v2: v2._value === "false" ? false : true,
    orgIds: orgIds._value.split(","),
    featureReleaseControl,
    version2: version2._value,
  };

  console.log("firebase data: ", data);
  return data;
};

export const getFirebaseRemoteData = async () => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 15000;
  await fetchAndActivate(remoteConfig);
  return getValueFromFirebase(remoteConfig);
};
