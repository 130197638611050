import { BASE_URL } from "../../../axios-config";
import axios from "axios";

export async function submitLeadCaptureForm(payload) {
  return axios
    .post(`${BASE_URL}/lead/capture`, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}
