import CONSTANTS from "./constants";

export const layoutSideBarOpenAction = (payload) => {
  return {
    type: CONSTANTS.LAYOUT_SIDEBAR_OPEN,
    payload,
  };
};

export const layoutSideBarCloseAction = (payload) => {
  return {
    type: CONSTANTS.LAYOUT_SIDEBAR_CLOSE,
    payload,
  };
};

export const layoutPageHeadingSet = (payload = "") => {
  return {
    type: CONSTANTS.LAYOUT_PAGE_HEADING_SET,
    payload,
  };
};

export const layoutPageHeadingClear = (payload = "") => {
  return {
    type: CONSTANTS.LAYOUT_PAGE_HEADING_CLEAR,
  };
};

export const layoutPageBackNavigationSet = (payload = "") => {
  return {
    type: CONSTANTS.LAYOUT_PAGE_BACK_NAVIGATION_SET,
    payload,
  };
};

export const layoutPageBackNavigationClear = (payload = "") => {
  return {
    type: CONSTANTS.LAYOUT_PAGE_BACK_NAVIGATION_CLEAR,
  };
};
