import CONSTANTS from "./constants";
import apiEndpoints from "./apiEndpoints";
import axios from "axios";
import getAxiosConfig from "../../../utils/getAxiosConfig";

// AXIOS SERVICE TO GET COURSE CONTENT
export const addBankDetailsAccountPostService = (payload) => {
  return axios({
    ...getAxiosConfig(
      apiEndpoints[CONSTANTS.ADD_BANK_DETAILS_POST]?.method,
      apiEndpoints[CONSTANTS.ADD_BANK_DETAILS_POST]?.url,
      payload
    ),
  });
};
// get bank accounts service
export const getBankAccountsService = (payload) => {
  return axios({
    ...getAxiosConfig(
      apiEndpoints[CONSTANTS.GET_BANK_ACCOUNTS]?.method,
      apiEndpoints[CONSTANTS.GET_BANK_ACCOUNTS]?.url,
      payload
    ),
  });
};
//delete bank account service
export const deleteBankAccountService = (payload) => {
  return axios({
    ...getAxiosConfig(
      apiEndpoints[CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT]?.method,
      apiEndpoints[CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT]?.url,
      payload
    ),
  });
};
export const agreeTermsAndCondtionsService = async (payload) => {
  try {
    return axios({
      ...getAxiosConfig(
        apiEndpoints[CONSTANTS.AGREE_TERMS_AND_CONDITIONS]?.method,
        apiEndpoints[CONSTANTS.AGREE_TERMS_AND_CONDITIONS]?.url,
        payload
      ),
    });
  } catch (err) {
    console.log("error:", err);
    return err;
  }
};
