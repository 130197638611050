import CONSTANTS from "./constants";

// INITIAL STATE
const bankDetailsReducerInitialState = {
  loadingStateADD_BANK_DETAILS_POST: false,
  bankAccounts: null,
  isLoading: false,
  bankAccountsError: null,
  deletePrimaryAccounts: null,
  deletePrimaryAccountsError: null,
  isDeleteLoading: false,
  agreeSuccessData: null,
  agreeFailureData: null,
  isAgreeLoading: false,
};

// REDUCER
const bankDetailsReducer = (state = bankDetailsReducerInitialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADD_BANK_DETAILS_POST_LOADER: {
      return {
        ...state,
        loadingStateADD_BANK_DETAILS_POST: true,
      };
    }
    case CONSTANTS.ADD_BANK_DETAILS_POST_SUCCESS: {
      return {
        ...state,
        loadingStateADD_BANK_DETAILS_POST: false,
      };
    }
    case CONSTANTS.ADD_BANK_DETAILS_POST_FAILURE: {
      return {
        ...state,
        loadingStateADD_BANK_DETAILS_POST: false,
      };
    }
    case CONSTANTS.GET_BANK_ACCOUNTS_LOADER: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case CONSTANTS.GET_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccounts: action.payload,
      };
    }
    case CONSTANTS.GET_BANK_ACCOUNTS_FAILURE: {
      return {
        ...state,
        bankAccountsError: true,
      };
    }
    case CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_LOADER: {
      return {
        ...state,
        isDeleteLoading: action.payload,
      };
    }
    case CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deletePrimaryAccounts: action.payload,
      };
    }
    case CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT_FAILURE: {
      return {
        ...state,
        deletePrimaryAccountsError: action.payload,
      };
    }
    case CONSTANTS.AGREE_TERMS_AND_CONDITIONS_LOADER: {
      return {
        ...state,
        isAgreeLoading: action.payload,
      };
    }
    case CONSTANTS.AGREE_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        agreeSuccessData: action.payload,
      };
    }
    case CONSTANTS.AGREE_TERMS_AND_CONDITIONS_FAILURE: {
      return {
        ...state,
        isAgreeLoading: false,
        // agreeSuccessData: null,
        agreeFailureData: true,
      };
    }
    case CONSTANTS.AGREE_TERMS_AND_CONDITIONS_RESET: {
      return {
        ...state,
        agreeSuccessData: null,
        agreeFailureData: null,
        isAgreeLoading: false,
      };
    }
    default:
      return state;
  }
};

export default bankDetailsReducer;
