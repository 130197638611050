import React from "react";
import "./styles.scss";
import verifyTick from "../../assets/multibank/verifyTick.png";
import information from "../../assets/multibank/information.png";
import { countryListJson } from "../../utils/countryExtensions";

const Input = React.forwardRef(({ icon, accountStatus = null, label, disabled, style, inputType = "input", onEnter, ...inputProps }, ref) => {
  const InputComponent = inputType; // Renamed to InputComponent to avoid conflicts

  return (
    <React.Fragment>
      {!!label && <label className="inputLabel">{label}</label>}
      <div
        className={
          accountStatus !== null && !accountStatus
            ? "accountInputContainer"
            : "inputContainer"
        }
        style={disabled ? { background: `#e8e8e8` } : {}}
      >
        {icon && (
          <div className="inputContainer__icon">
            <img src={verifyTick} alt="inputIcon" width="18px" height="18px" />
          </div>
        )}

        <InputComponent // Changed to InputComponent here
          ref={ref}
          style={style}
          disabled={disabled}
          {...inputProps}
          {...(inputProps?.type === "number"
            ? {
                onKeyDown: (e) => {
                  if (e.keyCode == 13 && onEnter) {
                    onEnter();
                  }
                  if (
                    e.key === "ArrowUp" ||
                    e.key === "ArrowDown" ||
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "."
                  )
                    e.preventDefault();
                },
              }
            : {
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    onEnter();
                  }
                },
              })}
        />
        {accountStatus !== null && (
          <div className="inputContainer__icon">
            <img
              src={!accountStatus ? information : verifyTick}
              alt="inputIcon"
              width="18px"
              height="18px"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default Input;
