import React from "react";
import "../../../scss/Purchase/inprogress.scss";
import processing_time from "../../../assets/processing_time.png";

const Inprogress = () => {
  return (
    <div className="inprogress">
      <img className="inprogress_img" src={processing_time} />
      <div className="inprogress_title">
        We are trying to process your payment
      </div>
      {window?.payload?.orderId && (
        <p className="inprogress_orderid">
          {`Order Id: ${window?.payload?.orderId}`}
        </p>
      )}
      <div className="inprogress_point">
        1. If the payment is successful, we will share the link to join the
        channel via sms and email within 2 hours.
      </div>
      <div className="inprogress_point">
        2. In case the payment fails, the amount will be refunded to the source
        with 2-3 business days.
      </div>
      <div className="inprogress_point">
        3. In case you don't get the channel invite link within 2 hours contact
        us on : support@fankonnect.com
      </div>
    </div>
  );
};

export default Inprogress;
