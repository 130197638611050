import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ReactLoading from "react-loading";
import * as actions from "../../../store/CreateGroup/actions";
import { layoutPageHeadingSet } from "../../../layout/store/actions";
import { validateCurrentPlan } from "../../../store/CreateGroup/transformer";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import toast from "react-hot-toast";
import plus from "../../../assets/plus.png";
import trash from "../../../assets/trash.svg";
import x from "../../../assets/x.png";
import createChannelDP from "../../../assets/createChannelDP.png";
import "../scss/CreateGroup/index.scss";
import { useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../../../axios-config";
import { getUserToken } from "../../../utils/getAxiosConfig";

function WhatsAppCreateGroup(props) {
  const {
    isEdit,
    fromCreate,
    isLoading,
    groupName,
    about,
    selectedCategory,
    freeplanDays,
    storeImage,
  } = props;

  const navigate = useNavigate();

  const pricingPeriod = [...props.pricingperiods];
  const periodUnit = [
    { id: 1, label: "Days", value: "Days" },
    { id: 2, label: "Months", value: "Months" },
  ];

  const [freePlanActive, setFreePlanActive] = React.useState(false);
  // const [freeplanDays, setFreePlanDays] = React.useState(null);
  const [freePlanPreExist, setFreePlanPreExist] = useState(false);
  const [image, setImage] = useState();
  const [url, setUrl] = useState();
  const [mainUrl, setMainUrl] = useState();
  // conditional Access states
  const fileRef = useRef();
  const userHasBankDetails = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.bankDetails
  );
  const userHasEmailDetails = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET
  );
  const channelType = useSelector((state) => state?.user?.channelType);
  console.log("channnelTyoe", channelType);
  useEffect(() => {
    props.layoutPageHeadingSet(
      isEdit
        ? "Edit Group"
        : `Whatsapp ${
            channelType === "whatsappGroup" ? `Group` : `Community`
          } Creation`
    );
  }, []);

  useEffect(() => {
    if (mainUrl) uploadImage(image);
  }, [mainUrl]);

  useEffect(() => {
    if (freeplanDays) {
      setFreePlanActive(true);
    }
  }, [freeplanDays]);

  useEffect(() => {
    const { singleGroupDetails, prices } = props;
    if (isEdit) {
      props.setTitle(groupName ? groupName : singleGroupDetails?.groupName);
      props.setDescription(about ? about : singleGroupDetails?.about);
      props.setImage(storeImage ? storeImage : singleGroupDetails?.logoUrl);

      let categoryItem = props?.categories.filter(
        (cat) => cat?.value === singleGroupDetails?.category
      );
      props.selectCategory(categoryItem?.length ? categoryItem[0] : null);
      let freeExistence = false;
      let subscriptionPlans = singleGroupDetails?.subscriptionPlan?.map(
        (plan) => {
          let periodItem = props?.pricingperiods?.filter(
            (period) => period?.value === plan?.selectedPeriod
          );
          let offerItem = props?.offers?.filter(
            (offer) => offer?.value === plan?.selectedOffer
          );
          let customTypeItem = periodUnit.filter(
            (item) => item.value === plan?.customType
          );
          if (plan?.selectedOffer == "Free Trial") {
            freeExistence = true;
          }
          return {
            price: `${plan?.price}`,
            discount: `${plan?.discount}`,
            selectedPeriod: periodItem?.length ? periodItem[0] : "",
            selectedOffer:
              plan?.selectedOffer == "Free Trial"
                ? { id: 9, label: "Free Trial", value: "Free Trial" }
                : offerItem?.length
                ? offerItem[0]
                : "",
            customType:
              plan?.selectedOffer == "Free Trial"
                ? { id: 2, label: "Days", value: "Days" }
                : customTypeItem.length
                ? customTypeItem[0]
                : null,
            customValue: plan?.customValue ? plan?.customValue : null,
            periodTitle: plan?.periodTitle ? plan?.periodTitle : null,
            id: plan?.id ? plan?.id : null,
          };
        }
      );

      setFreePlanPreExist(freeExistence);
      // console.log("creayee",prices);
      if (prices[0].price != "") {
        props.setPricingData(prices);
      } else {
        props.setPricingData(subscriptionPlans);
      }
    }
  }, [isEdit, fromCreate]);

  const createGroup = () => {
    let temp = true;

    //checking in the prices
    let arry = [];
    for (let i = 0; i < props.prices.length; i++) {
      if (props.prices[i].selectedPeriod.value == "Custom Period") {
        arry.push(
          props.prices[i].customValue + " " + props.prices[i].customType.value
        );
      } else {
        arry.push(props.prices[i].selectedPeriod.value);
      }
    }
    //  console.log("strings array : ",arry);
    if (arry.filter((item, index) => arry.indexOf(item) !== index).length > 0) {
      temp = false;
      toast.error(
        `You can't create 2 subscription plans with the same time period.`
      );
    }

    props.prices?.forEach((plan) => {
      // console.log("propsssss", plan);
      if (parseFloat(plan?.discount) > parseFloat(plan?.price)) {
        temp = false;
        toast.error("Discount should not be greater than Price");
      }
      if (parseFloat(plan?.price <= 0)) {
        temp = false;
        toast.error("Price should not be empty");
      }
      if (
        plan &&
        plan?.selectedPeriod.value == "Custom Period" &&
        plan?.customType &&
        plan?.selectedOffer?.value !== "Free Trial"
      ) {
        if (plan?.customType.value == "Days") {
          if (
            plan?.selectedOffer?.value !== "Free Trial" &&
            plan?.customValue < 1
          ) {
            toast.error("Custom plan can’t be less than 1 days");
            temp = false;
          }
        } else if (plan?.customType?.value == "Months") {
          if (plan?.customValue == 0 || plan?.customValue == undefined) {
            toast.error("Custom plan can’t be less than 1 days");
            temp = false;
          }
        }
      }
    });
    if (freePlanActive && freeplanDays < 2) {
      temp = false;
      toast.error("Please add valid days to free plan");
    }
    window.payload = { freeplanDays };
    if (temp) {
      navigate("/group/whatsapp/preview", {
        state: { isEdit: isEdit },
      });
    }
  };

  const setBtnDisabled = () => {
    var flag = true;
    if (groupName?.length > 0 && selectedCategory?.value?.length > 0) {
    } else {
      return false;
    }
    props.prices.forEach((plan) => {
      if (plan?.price?.length > 0 && plan?.selectedPeriod?.value?.length > 0) {
        flag = true;
      } else {
        flag = false;
        return flag;
      }
    });
    if (freeplanDays && (freeplanDays > 15 || freeplanDays <= 1)) {
      flag = false;
      toast.error(`Free plan should be between 2-15 days.`);
    } else {
      flag = true;
    }
    return flag;
  };

  const selectCategory = (item) => {
    props.selectCategory(item);
  };

  const handleNextButtonClicked = () => {
    let temp = true;
    props.prices?.forEach((plan) => {
      if (parseFloat(plan?.discount) > parseFloat(plan?.price)) {
        temp = false;
        toast.error("Discount should not be greater than Price");
      }
    });
    // validateFields(props);
    const payload = {
      name: groupName,
      about: about,
      category: selectedCategory.value,
      ...(telegramChanelId ? { channelId: telegramChanelId } : {}),
      ...(telegramChanelId ? { type: "telegramExisting" } : {}),
      pricing: props.prices.map((plan) => {
        if (plan?.customType) {
          return {
            price: parseFloat(plan.price),
            selectedPeriod: plan.selectedPeriod.label,
            discount:
              plan?.discount?.length > 0 ? parseFloat(plan.discount) : 0,
            selectedOffer: plan.selectedOffer?.value,
            customType: plan.customType.value,
            customValue: plan.customValue,
          };
        }
        return {
          price: parseFloat(plan.price),
          selectedPeriod: plan.selectedPeriod.value,
          discount: plan?.discount?.length > 0 ? parseFloat(plan.discount) : 0,
          selectedOffer: plan.selectedOffer?.value,
        };
      }),
    };
    if (freeplanDays > 0) {
      let obj = {
        customType: "Days",
        customValue: freeplanDays,
        discount: 0,
        price: 0,
        selectedOffer: "Free Trial",
        selectedPeriod: "Custom Period",
      };
      payload.pricing.push(obj);
    }
    if (mainUrl && image) {
      payload["logoUrl"] = mainUrl;
    }
    window.payload = payload;
    if (temp)
      navigate(`/bank-details`, {
        state: {
          email: userHasEmailDetails?.userDetails?.email,
        },
      });
  };

  const expandPlan = (index) => {
    if (validateCurrentPlan(props.prices[props.selectedPlanIndex])) {
      props.setSelectedPlan(index);
    }
  };

  const collapsePlan = (index) => {
    if (validateCurrentPlan(props.prices[props.selectedPlanIndex]))
      props.setSelectedPlan(null);
  };

  const addPlan = () => {
    if (validateCurrentPlan(props.prices[props.selectedPlanIndex])) {
      props.addPaymentPlan();
    }
  };
  const addFreePlan = () => {
    setFreePlanActive(!freePlanActive);
  };
  const inActiveFreePlan = () => {
    setFreePlanActive(false);
    props.addFreePlanDays(null);
  };
  const deletePlan = (index) => {
    if (props.prices[index].periodTitle !== null) {
      setFreePlanPreExist(false);
    }
    props.deletePlan(index);
  };
  const onChangeValue = (value) => {
    if (value.charAt(value.length - 1) === ".") {
      toast.error("Please enter 0-9");
    } else {
      if (value) {
        props.addFreePlanDays(value);
      } else {
        props.addFreePlanDays(null);
      }
    }
  };

  const onFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      getSignedUrl(event?.target?.files[0]);
    }
  };

  const getSignedUrl = (file) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/uploadImage`,
      data: {
        fileExtension: `${file.type}`,
        destination: "group",
      },
      headers: {
        "x-access-token": getUserToken(),
      },
    })
      .then((response) => {
        console.log(response.data.data, "response.data.data");
        response && setUrl(response.data.data.url);
        response && setMainUrl(response.data.data.mainUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadImage = async (file) => {
    console.log(url);
    axios({
      url: url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": `${file.type}`,
      },
    })
      .then((response) => {
        console.log("response of image upload: ", response, mainUrl);
        props.setImage(mainUrl);
      })
      .catch((error) => {
        console.log("error in image upload: ", error);
        toast.error("Please upload the image again");
      });
  };
  const telegramGroupName = useSelector(
    (state) => state?.getAutomatedChannelReducer.groupName
  );

  const telegramChanelId = useSelector(
    (state) => state?.getAutomatedChannelReducer.telegramGroupId
  );

  useEffect(() => {
    if (telegramGroupName) props.setTitle(telegramGroupName);
  }, [telegramGroupName]);

  return (
    <div className="CreateGroup">
      <div
        onClick={() => {
          fileRef.current.click();
        }}
        className="CreateGroup_DPContainer"
      >
        <img
          className={image ? "CreateGroup_image" : null}
          src={storeImage ? storeImage : createChannelDP}
          height={"100px"}
          width={"100px"}
          style={storeImage ? { borderRadius: "50px" } : null}
        />
        <div className="CreateGroup_UploadChannelText">
          {storeImage ? "Edit Channel Logo" : "Upload Channel Logo"}
        </div>
        <input
          accept="image/*"
          // disabled={GSTDisabled}
          type="file"
          onChange={onFileChange}
          ref={fileRef}
          hidden
        />
      </div>
      <div className="CreateGroup_Label">
        {" "}
        {channelType == "whatsappGroup" ? `Group Name*` : `Community Name*`}
      </div>
      {!!isEdit && (
        <div className="CreateGroup_desc">
          Any changes to channel name will reflect on the payment page. If you
          wish to edit name on whatsapp, edit channel name from whatsapp app
        </div>
      )}
      <Input
        placeholder={`Add ${
          channelType == "whatsappGroup" ? `Group` : `Community`
        } Name*`}
        value={groupName}
        onChange={(e) => props.setTitle(e.target.value)}
        maxLength={25}
        // disabled={isEdit}
      />

      <div className="CreateGroup_Label">Description</div>
      <div className="CreateGroup_desc">
        {` Describe the value / service you provide in this ${
          channelType == "whatsappGroup" ? ` group` : ` community`
        } , which will
        help users decide & purchase your subscription.`}
      </div>
      <Input
        inputType="textarea"
        placeholder="Add Description"
        value={about}
        onChange={(e) => props.setDescription(e.target.value)}
        height="100px"
        // disabled={isEdit}
        maxLength={1500}
        // onEnter={() => {
        //   props.setDescription(about + "\n");
        // }}
      />

      <div className="CreateGroup_Label">Category*</div>
      <Dropdown
        editable={!isEdit}
        optionsList={props?.categories}
        selectedOption={selectedCategory}
        itemClickHandler={selectCategory}
      />

      <div className="CreateGroup_Label">Create Subscription Plan(s)</div>

      {props?.prices.map((plan, index) => {
        // console.log("plans:",props.prices);
        if (
          props.selectedPlanIndex != index &&
          props.selectedPlanIndex != undefined
        ) {
          return (
            <div className="CollapsedPlan CreateGroup_Card">
              <div className="CollapsedPlan_Top">
                Subscription Plan {index + 1}
                <div
                  className="CollapsedPlan_Top_Edit"
                  onClick={() => expandPlan(index)}
                >
                  Edit
                </div>
              </div>

              <div className="CollapsedPlan_Mid">
                <div className="CollapsedPlan_Mid_Period">
                  {plan?.selectedPeriod.label == "Custom Period" ||
                  plan?.selectedPeriod == "Custom Period"
                    ? plan.periodTitle
                    : plan.selectedPeriod.label}
                </div>
                <div className="CollapsedPlan_Mid_NetPrice">
                  {plan?.price != 0 ? (
                    plan?.discount?.length ? (
                      <span>
                        ₹{parseFloat(plan.price) - parseFloat(plan.discount)}/-
                      </span>
                    ) : (
                      <span>
                        ₹{parseFloat(plan.price?.length ? plan.price : 0)}/-
                      </span>
                    )
                  ) : (
                    <p>Free!</p>
                  )}
                </div>
              </div>

              <div className="CollapsedPlan_Foot">
                {plan.selectedOffer ? (
                  <div className="CollapsedPlan_Foot_Offer">
                    {plan.selectedOffer.label}
                  </div>
                ) : null}
                <div className="CollapsedPlan_Foot_Price">
                  {plan?.discount != 0
                    ? plan?.discount?.length
                      ? "₹" + plan?.price + "/-"
                      : null
                    : null}
                </div>
              </div>
            </div>
          );
        } else {
          if (props.selectedPlanIndex == undefined)
            props.setSelectedPlan(index);
          return (
            <div className="CreateGroup_Card" key={index}>
              <div
                className="CreateGroup_Heading"
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div onClick={() => collapsePlan(index)}>
                  Subscription Plan {index + 1}
                </div>

                {props?.prices?.length > 1 ? (
                  <img
                    src={trash}
                    alt="trash"
                    height="20px"
                    onClick={() => deletePlan(index)}
                  />
                ) : null}
              </div>

              <div className="CreateGroup_Heading">Period*</div>

              {plan?.selectedOffer &&
              plan?.selectedOffer?.value == "Free Trial" ? (
                <div className="freeGroup">
                  <div className="freeGroup_Input1">
                    <input
                      placeholder="Enter period"
                      //  onChange={(e)=>onChangeValue(e.target.value)}
                      onChange={(e) => {
                        if (new RegExp(/^[0-9.]*$/).test(e.target.value)) {
                          props.addFreePlanDays(e.target.value);
                          props.setCustomNumber({
                            index,
                            value: e.target.value,
                          });
                        }
                      }}
                      value={plan?.customValue}
                      // autoFocus
                      style={{
                        width: "90%",
                        border: "none",
                        height: "30px",
                        alignContent: "center",
                        outline: "none",
                        padding: "10px",
                      }}
                    />
                    <div
                      style={{
                        borderLeft: "1px solid #e5e5e5",
                        height: "30px",
                        paddingRight: "10px",
                      }}
                    />
                    <div className="freeGroup_Days1">{"Days"}</div>
                  </div>
                </div>
              ) : (
                <div>
                  <p style={{ color: "#7A8B94", marginTop: 10, fontSize: 14 }}>
                    *Plan should not be less than 7 days
                  </p>
                  <Dropdown
                    optionsList={pricingPeriod}
                    selectedOption={plan?.selectedPeriod}
                    itemClickHandler={(item) =>
                      props.setSelectedSubscriptionPeriod({
                        index,
                        value: item,
                      })
                    }
                  />
                  {/* custom period fields */}
                  {plan?.selectedPeriod.value == "Custom Period" && (
                    <div className="CreateGroup_CustomPeriod">
                      <div className="CreateGroup_CustomInput">
                        <Input
                          type="number"
                          pattern="[0-9]*"
                          value={plan?.customValue}
                          onChange={(e) => {
                            if (new RegExp(/^[0-9.]*$/).test(e.target.value))
                              props.setCustomNumber({
                                index,
                                value: e.target.value,
                              });
                          }}
                          style={{ height: "23px" }}
                        />
                      </div>
                      <div className="CreateGroup_CustomPeriod_DropDown">
                        {console.log("plan?.customType", plan?.customType)}
                        <Dropdown
                          optionsList={periodUnit}
                          selectedOption={plan?.customType}
                          itemClickHandler={(item) => {
                            console.log("dropDownitem", item);
                            props.setCustomPeriod({ index, value: item });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/* end of custom perriod fields */}

                  <div className="CreateGroup_Heading">Price*</div>
                  <Input
                    placeholder="Add Price"
                    type="number"
                    pattern="[0-9]*"
                    value={plan.price}
                    onChange={(e) => {
                      if (new RegExp(/^[0-9.]*$/).test(e.target.value))
                        props.setAccessPrice({ index, value: e.target.value });
                    }}
                  />

                  <div className="CreateGroup_Heading">Discount</div>
                  <Input
                    placeholder="Add Price"
                    type="number"
                    pattern="[0-9]*"
                    value={plan.discount}
                    onChange={(e) => {
                      if (new RegExp(/^[0-9.]*$/).test(e.target.value))
                        props.setDiscount({ index, value: e.target.value });
                    }}
                  />

                  <div
                    className="CreateGroup_Heading"
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Effective Price After Discount
                    {plan?.price?.length ? (
                      <span>
                        ₹
                        {parseFloat(plan.price) -
                          parseFloat(plan.discount?.length ? plan.discount : 0)}
                        /-
                      </span>
                    ) : null}
                  </div>

                  <div className="CreateGroup_Heading">Offer Title</div>
                  <Dropdown
                    optionsList={props?.offers}
                    selectedOption={plan.selectedOffer}
                    itemClickHandler={(item) =>
                      props.setOffer({ index, value: item })
                    }
                  />
                </div>
              )}
            </div>
          );
        }
      })}

      {/* free plan block */}
      {freePlanActive && !freePlanPreExist && (
        <div className="CreateGroup_Card">
          <div
            className="CreateGroup_Heading"
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Free Plan</div>

            <img
              src={x}
              alt="trash"
              height="20px"
              onClick={() => inActiveFreePlan()}
            />
          </div>
          <div className="CreateGroup_Heading">Period*</div>
          <div className="freeGroup">
            <div className="freeGroup_Input1">
              <input
                placeholder="Enter period"
                onChange={(e) => onChangeValue(e.target.value)}
                autoFocus
                style={{
                  width: "90%",
                  border: "none",
                  height: "30px",
                  alignContent: "center",
                  outline: "none",
                  padding: "10px",
                }}
                value={freeplanDays}
              />
              <div
                style={{
                  borderLeft: "1px solid #e5e5e5",
                  height: "30px",
                  paddingRight: "10px",
                }}
              />
              <div className="freeGroup_Days1">{"Days"}</div>
            </div>
          </div>
        </div>
      )}
      {/* end of free plan block */}

      <div className="CreateGroup_Addplan" onClick={addPlan}>
        <img src={plus} alt="plus" height="20px" />
        Add Subscription Plan(s)
      </div>
      <br />
      {!freePlanActive && !freePlanPreExist && (
        <div className="CreateGroup_Addplan" onClick={addFreePlan}>
          <img src={plus} alt="plus" height="20px" />
          Add Free Plan
        </div>
      )}

      {/* //BOTTOM BUTTON */}
      <div className="CreateGroup_ButtonContainer">
        {userHasBankDetails ? (
          <Button disabled={!setBtnDisabled()} onClick={createGroup}>
            {isLoading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"white"}
                height={24}
                width={20}
              />
            ) : isEdit ? (
              "Save"
            ) : (
              `Preview ${
                channelType == "whatsappGroup" ? `Group` : `Community`
              }`
            )}
          </Button>
        ) : (
          <Button
            disabled={!setBtnDisabled()}
            onClick={handleNextButtonClicked}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  createGroup,
  singleContentDetails,
  userDetailsReducer,
  home,
}) => {
  return {
    isLoading: createGroup?.isLoading,
    groupName: createGroup?.groupName,
    about: createGroup?.about,
    selectedCategory: createGroup?.selectedCategory,
    categoryOptions: createGroup?.categoryOptions,
    accessPrice: createGroup?.pricing?.accessPrice,
    storeImage: createGroup?.image,
    pricingperiods: userDetailsReducer?.apiResUSER_DETAILS_GET?.pricingperiods,
    offers: userDetailsReducer?.apiResUSER_DETAILS_GET?.offers,
    categories: userDetailsReducer?.apiResUSER_DETAILS_GET?.categories,

    selectedPlanIndex: createGroup?.selectedPlanIndex,
    prices: createGroup?.prices,
    subscriptionPrice: createGroup?.pricing?.paymentPeriod?.price,
    subscriptionPeriod: createGroup?.pricing?.paymentPeriod?.period,
    freeplanDays: createGroup?.freeplanDays,
    selectedSubscriptionPeriod: createGroup?.selectedSubscriptionPeriod,
    subscriptionPeriodOptions: createGroup?.subscriptionPeriodOptions,
    singleGroupDetails: singleContentDetails?.singleGroupDetails,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setTitle: (payload) => dispatch(actions.setTitle(payload)),
    setDescription: (payload) => dispatch(actions.setDescription(payload)),
    setAccessPrice: (payload) => dispatch(actions.setAccessPrice(payload)),
    selectCategory: (payload) => dispatch(actions.setCategory(payload)),

    setDiscount: (payload) => dispatch(actions.setDiscount(payload)),
    setOffer: (payload) => dispatch(actions.setOfferTitle(payload)),
    addPaymentPlan: () => dispatch(actions.addPaymentPlan()),
    deletePlan: (payload) => dispatch(actions.deletePlan(payload)),

    setSelectedSubscriptionPeriod: (payload) =>
      dispatch(actions.setSelectedSubscriptionPeriod(payload)),
    setSubscriptionPrice: (payload) =>
      dispatch(actions.setSubscriptionPrice(payload)),
    setPricingData: (payload) => dispatch(actions.setPricingData(payload)),
    createGroup: (payload) => dispatch(actions.createGroup(payload)),
    editGroup: (payload) => dispatch(actions.editGroup(payload)),

    setSelectedPlan: (payload) => dispatch(actions.setSelectedPlan(payload)),
    resetState: () => dispatch(actions.resetState()),
    layoutPageHeadingSet: (payload) => dispatch(layoutPageHeadingSet(payload)),
    setCustomPeriod: (payload) => dispatch(actions.setCustomPeriod(payload)),
    setCustomNumber: (payload) => dispatch(actions.setCustomNumber(payload)),
    // setImage: (payload) => dispatch(actions.setImage(payload)),
    setImage: (payload) => dispatch(actions.setImage(payload)),
    addFreePlanDays: (payload) => dispatch(actions.addFreePlanDays(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchWithProps
)(WhatsAppCreateGroup);
