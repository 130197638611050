import React, { useState, useEffect } from "react";
import "../../../scss/Purchase/groupDetails.scss";
import Input from "../../../common/Input";
import "../../../scss/Purchase/register.scss";
import Button from "../../../common/Button";
import lock from "../../../assets/purchase/lock.svg";
import * as actions from "../../../store/Purchase/actions";
import { connect, useSelector } from "react-redux";
import arrowLeft from "../../../assets/purchase/arrow-left.svg";
import whatsapp from "../../../assets/userDetail/whatsapp.png";
import ReactLoading from "react-loading";
import Dropdown from "../../../components/Dropdown";
import axios from "axios";
import { BASE_URL } from "../../../axios-config";
import Bottomsheet from "../../../common/Bottomsheet";
import SelectCountry from "../../../components/SelectCountry";
import { countryListJson } from "../../../utils/countryExtensions";
function Register(props) {
  let {
    setPurchaseScreen,
    setFormDetails,
    formDetails,
    registerUserAction,
    sessionId,
    otp,
    registerUserLoading,
    updateEmailAddress,
    updateEmailLoading,
    waChecked,
    WAChecked,
    pageData,
    indianState,
    isPreauth = false,
    groupData,
    ipData,
  } = props;

  let [disabled, setDisabled] = useState(true);
  let [states, setStates] = useState([]);

  const emailExistData = useSelector(
    (state) => state?.purchaseReducer?.emailExistData
  );
  const stateExistData = useSelector(
    (state) => state?.purchaseReducer?.stateExistData
  );
  const { name, email } = emailExistData;

  const selectedCountry = useSelector(
    (state) => state.getCountryCodeReducer.country
  );
  const [countrySelected, setCountrySelected] = useState(null);

  let invoiceEnabled;

  if (isPreauth) {
    invoiceEnabled = groupData.invoiceEnabled;
  } else {
    invoiceEnabled = pageData.invoiceEnabled;
  }

  //SELECTED COUNTRY LOGIC
  useEffect(() => {
    if (selectedCountry.length !== 0) {
      setCountrySelected(selectedCountry[0]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    //IF USER IS NOT INTERNATIONAL
    if (ipData?.countryExt === "91") {
      if (
        formDetails?.email?.length > 0 &&
        (formDetails?.name?.length || name.length) > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      if ((formDetails?.name?.length || name.length) > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [formDetails?.email, formDetails?.name]);

  useEffect(() => {
    if (
      stateExistData?.name.length > 0 &&
      ((stateExistData?.email && stateExistData?.email.length > 0) ||
        (formDetails?.email && formDetails?.email.length > 0))
    ) {
      setDisabled(false);
    }
  }, [stateExistData, formDetails]);

  useEffect(() => {
    WAChecked(true);
  }, []);

  useEffect(() => {
    if (invoiceEnabled) {
      getStates();
    }
  }, [invoiceEnabled]);

  const submit = () => {
    if (stateExistData?.name?.length > 0) {
      let payload = {
        email: stateExistData?.email
          ? stateExistData?.email
          : formDetails?.email,
        state: formDetails?.indianState,
      };
      updateEmailAddress(payload);
    } else if (!invoiceEnabled && !email) {
      let payload = {
        email: formDetails?.email,
      };
      updateEmailAddress(payload);
    } else if (name) {
      let payload = {
        email: formDetails?.email,
        state: formDetails?.indianState,
      };
      updateEmailAddress(payload);
    } else {
      let payload = () => {
        //PAYLOAD FOR INDIAN USER
        if (ipData?.countryExt === "91") {
          return {
            contact: {
              email: formDetails?.email,
              mobile: formDetails?.mobile,
            },
            name: formDetails?.name,
            sessionId: sessionId,
            otp: otp,
            whatsappStatus: waChecked,
            countryCode: "91",
          };
        }
        //PAYLOAD FOR INTERNATIONAL USER
        else {
          //IF USER HAS FILLED MOBILE NUMBER
          if (formDetails?.mobile !== "") {
            //IF SOMEHOW USER ENTERS AN INDIAN NUMBER THEN DON'T SEND THE COUNTRY CODE OTHERWISE SEND THE COUNTRY CODE
            if (
              countrySelected?.countryISO === "91" ||
              ipData?.countryISO === "91"
            ) {
              return {
                contact: {
                  email: formDetails?.email,
                  mobile: formDetails?.mobile,
                },
                name: formDetails?.name,
                sessionId: sessionId,
                otp: otp,
                whatsappStatus: waChecked,
              };
            } else {
              return {
                contact: {
                  email: formDetails?.email,
                  mobile: formDetails?.mobile,
                },
                name: formDetails?.name,
                sessionId: sessionId,
                otp: otp,
                whatsappStatus: waChecked,
                countryCode: countrySelected?.countryISO || ipData?.countryISO,
              };
            }
          }

          //IF USER HAS NOT FILLED MOBILE NUMBER
          else {
            return {
              contact: {
                email: formDetails?.email,
              },
              name: formDetails?.name,
              sessionId: sessionId,
              otp: otp,
              whatsappStatus: waChecked,
            };
          }
        }
      };

      const finalpayload = payload();
      if (invoiceEnabled) {
        finalpayload.state = formDetails?.indianState;
      }
      console.log("PAYLOAD IS", finalpayload);
      registerUserAction(finalpayload);
    }
  };

  const getStates = async () => {
    await axios({
      method: "GET",
      url: `${BASE_URL}/states`,
    })
      .then(async (res) => {
        let temp = [];
        await res.data.data.forEach((obj) => {
          temp.push({
            id: obj.id,
            label: obj.name,
          });
        });
        setStates(temp);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  const settingState = (item) => {
    setFormDetails({ ...formDetails, indianState: item?.label });
  };

  useEffect(() => {
    console.log("IP DATA IS", ipData);
  }, []);

  return (
    <>
      <div className="register">
        <div className="register_heading">
          <div
            onClick={() => {
              setPurchaseScreen("userDetails");
            }}
          >
            <img src={arrowLeft} alt="" className="register_heading-image" />
          </div>
          <p className="register_heading-text">
            {email ? "Sign Up" : "Add your Email"}
          </p>
        </div>

        <Input
          disabled={!email}
          value={formDetails?.name || name || stateExistData?.name}
          label="Name*"
          type="text"
          placeholder="Enter Your Name"
          onChange={(e) => {
            setFormDetails({ ...formDetails, name: e.target.value });
          }}
          onEnter={() => {
            if (!disabled) submit();
          }}
        />

        {
          //THIS CASE HANDLES WHEN IP API country code doesn't match any of our country codes in the list
          selectedCountry.length !== 0 && !isPreauth && (
            <>
              {ipData?.countryExt !== "91" && (
                <SelectCountry
                  disabled={false}
                  value={formDetails?.mobile}
                  label="Phone Number"
                  type="number"
                  placeholder="Phone Number"
                  countryListInput={true}
                  onChange={(e) => {
                    setFormDetails({ ...formDetails, mobile: e.target.value });
                  }}
                />
              )}
            </>
          )
        }

        {/* SHOW EMAIL OPTION WHEN NOT AN INTERNATIONAl USER */}

        {ipData?.countryExt === "91" && !isPreauth && (
          <>
            <Input
              value={formDetails?.email || stateExistData?.email}
              label="Email Address*"
              type="email"
              placeholder="Enter Your Email Address"
              onChange={(e) => {
                setFormDetails({ ...formDetails, email: e.target.value });
              }}
              onEnter={() => {
                if (!disabled) submit();
              }}
            />
          </>
        )}

        {!groupData?.user?.email && isPreauth && (
          <>
            <Input
              value={formDetails?.email || stateExistData?.email}
              label="Email Address*"
              type="email"
              placeholder="Enter Your Email Address"
              onChange={(e) => {
                setFormDetails({ ...formDetails, email: e.target.value });
              }}
              onEnter={() => {
                if (!disabled) submit();
              }}
            />
          </>
        )}
        {!!invoiceEnabled && (
          <div className="groupDetails_selectState">Select State</div>
        )}
        {!!invoiceEnabled && (
          <Dropdown
            itemClickHandler={settingState}
            optionsList={states}
            selectedOption={{ label: indianState }}
          />
        )}
        {/* <Input
        value={formDetails?.telegramHandle}
        label="Telegram Handle*"
        type="text"
        placeholder="Enter Your Telegram Handle"
        onChange={(e) => {
          setFormDetails({ ...formDetails, telegramHandle: e.target.value });
        }}
        onEnter={() => {
          if (!disabled) submit();
        }}
      /> */}
        {ipData?.countryExt === "91" && (
          <p className="register_note">
            Note:- You may have restricted who can add you to channels. Please
            change the setting to Everybody from Privacy and Security in
            Telegram.
          </p>
        )}
        {/* WA communication checkbox */}
        {ipData?.countryExt === "91" && (
          <div className="WA_Container">
            <input
              type="checkbox"
              defaultChecked={waChecked}
              onChange={() => WAChecked(!waChecked)}
            />
            <div className="WA_Container_text">Get updates via WhatsApp</div>
            <div className="WA_Container_img">
              <img src={whatsapp} style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
        )}
        {/* end of WA */}
        <div className="register_footer">
          {email && (
            <div className="register_footer-container">
              <img src={lock} alt="" className="register_footer-img" />
              <p className="register_footer-text">
                Secure payment with Razorpay
              </p>
            </div>
          )}
          <div className="register_footer-button">
            {registerUserLoading || updateEmailLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <ReactLoading
                  type={"spin"}
                  color={"#2278ff"}
                  height={30}
                  width={30}
                />
              </div>
            ) : (
              <Button
                disabled={disabled || registerUserLoading}
                onClick={submit}
              >
                {email ? "Proceed to Payment" : "Update"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    formDetails: state?.purchaseReducer?.formDetails,
    indianState: state?.purchaseReducer?.formDetails?.indianState,
    sessionId: state?.purchaseReducer?.generateOtp?.sessionId,
    otp: state?.purchaseReducer?.otp,
    registerUserLoading: state?.purchaseReducer?.registerUserLoading,
    updateEmailLoading: state?.purchaseReducer?.updateEmailLoading,
    waChecked: state?.purchaseReducer?.waChecked,
    pageData: state?.purchaseReducer?.pageData,
    groupData: state?.purchaseReducer?.groupData?.data,
    ipData: state?.getCountryCodeReducer?.data,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setPurchaseScreen: (payload) =>
      dispatch(actions.setPurchaseScreen(payload)),
    setFormDetails: (payload) => dispatch(actions.setFormDetails(payload)),
    registerUserAction: (payload) =>
      dispatch(actions.registerUserAction(payload)),
    updateEmailAddress: (payload) =>
      dispatch(actions.updateEmailAddress(payload)),
    WAChecked: (payload) => dispatch(actions.WAChecked(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(Register);
