import React, { useEffect } from "react";
import "../../../scss/Coupons/AllCoupons/CouponCard.scss";
import Switch from "react-switch";

import threeDots from "../../../assets/allCoupons/CouponCard/threeDots.png";
import sharebtn from "../../../assets/allCoupons/CouponCard/sharebtn.png";
import discountShape from "../../../assets/allCoupons/CouponCard/discountShape.png";
import halfCircle from "../../../assets/allCoupons/CouponCard/halfCircle.svg";
import rightHalfCircle from "../../../assets/allCoupons/CouponCard/rightHalfCircle.svg";
import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import edit from "../../../assets/allCoupons/CouponCard/edit.png";
import dlt from "../../../assets/allCoupons/CouponCard/dlt.png";
import ShareBottomsheet from "../../../components/ShareBottomsheet";
import { useNavigate } from "react-router-dom";
import * as actions from "../createCoupon/store/actions";
import { connect } from "react-redux";

function CouponCard(props) {
  const {
    data,
    toggleChecked = true,
    toggleSelection = () => {},
    deleteCoupon = () => {},
  } = props;
  const [toggleValue, setToggleValue] = useState(data.isActive);
  const [optionMenu, setOptionMenu] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false);
  const [timeused, setTimeused] = useState(data?.memberCount);
  const [earning, setEarning] = useState(data?.totalEarnings);
  const [showShareBottomSheet, setShowShareBottomSheet] = useState(false);

  useEffect(() => {
    if (data) {
      setEarning(data.totalEarnings);
      setTimeused(data.memberCount);
    }
  }, [data]);
  const toggleAction = () => {
    setToggleValue(!toggleValue);
    toggleSelection(data);
  };
  const confirmDelete = () => {
    deleteCoupon(data);
  };
  const navigate = useNavigate();
  const LeftStripe = () => {
    return (
      <div className="CouponCard_Container_CouponStripe">
        <div className="center">
          <img src={halfCircle} />
        </div>
        <div className="text">Coupon</div>
      </div>
    );
  };
  const closeModal = () => {
    setOptionOpen(false);
  };
  const closeModalMenu = () => {
    setOptionMenu(false);
    setOptionOpen(false);
  };
  return (
    <div className="CouponCard">
      <div className="CouponCard_Container">
        <LeftStripe />

        <div className="mainContent">
          <div className="mainContent_data">
            {/* coupon Name  */}
            <div className="mainContent_head">
              <div className="mainContent_head_img">
                <img
                  src={discountShape}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="mainContent_head_text">{data.name}</div>
            </div>
            {/* discount rs */}
            <div className="mainContent_discountAmount">
              {data.type == "discount"
                ? `₹ ${data.value} off`
                : `${data.value} % off`}
            </div>
            {/* time used and earning */}
            <div className="mainContent_timeAndEarning">
              {/* time used */}
              <div className="mainContent_timeAndEarning_item">
                <div className="mainContent_timeAndEarning_item_text">
                  {timeused}
                </div>
                <div className="mainContent_timeAndEarning_item_tag">
                  Time Used
                </div>
              </div>
              {/* earning */}
              <div className="mainContent_timeAndEarning_itemEarning">
                <div className="mainContent_timeAndEarning_item_text">
                  ₹{earning}
                </div>
                <div className="mainContent_timeAndEarning_item_tag">
                  Earnings
                </div>
              </div>
            </div>
          </div>
          {/* toggle and three dots */}
          <div className="mainContent_Options">
            <div className="mainContent_Options_toggle">
              <Switch
                //   handleDiameter={1}
                checked={toggleValue}
                onChange={() => {
                  toggleAction();
                }}
                offColor="#CED5E0"
                onColor="#2278FF"
                offHandleColor="#ffffff"
                onHandleColor="#ffffff"
                handleDiameter={15}
                height={20}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
              />
            </div>

            {/* options */}
            <div className="mainContent_Options_shareAndThreeDots">
              {data.isActive ? (
                <img
                  onClick={() => {
                    setShowShareBottomSheet(true);
                  }}
                  src={sharebtn}
                  className="mainContent_Options_shareAndThreeDots_shareBtn"
                />
              ) : null}
              <Popup
                position={"bottom right"}
                contentStyle={{ width: "120px" }}
                arrow={false}
                open={optionOpen}
                closeOnDocumentClick
                trigger={
                  <img
                    src={threeDots}
                    className="mainContent_Options_shareAndThreeDots_threeDots"
                  />
                }
                nested
              >
                <div
                  className="optionMenu"
                  onClick={async () => {
                    props.setName(data.name);
                    props.setAmount(data.value);
                    if (data.type == "discount") {
                      props.setType({ id: 1, label: "Fixed" });
                    } else {
                      props.setType({ id: 2, label: "Percentage" });
                    }
                    let tempPlans = [];
                    await data.couponsMapping.forEach((obj) => {
                      tempPlans.push(obj.plan);
                    });
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    let tempData = {
                      type: data.type,
                      plans: tempPlans,
                      couponId: data.id,
                    };
                    window.payload = tempData;
                    let urlArr = window.location.pathname.split("/");
                    let groupId = urlArr[2];
                    navigate(`/group/${groupId}/editCoupon`);
                  }}
                >
                  <div className="optionMenu_optionItem">
                    <div className="optionMenu_optionItem_optionIcon">
                      <img
                        src={edit}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div className="optionMenu_text">Edit</div>
                  </div>
                  <Popup
                    open={optionMenu}
                    trigger={
                      <div className="optionMenu_optionItem">
                        <div className="optionMenu_optionItem_optionIcon">
                          <img
                            src={dlt}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div
                          onClick={() => {
                            setOptionMenu(true);
                          }}
                          className="optionMenu_text"
                        >
                          Delete
                        </div>
                      </div>
                    }
                    modal
                    contentStyle={{ borderRadius: "6px", width: "300px" }}
                  >
                    <div className="deleteModal">
                      <div className="deleteModal_text">
                        Are you sure you want to delete this coupon?
                      </div>
                      <div className="deleteModal_breakLine" />
                      <div className="deleteButton">
                        <div
                          onClick={() => closeModalMenu()}
                          className="deleteButton_cancel"
                        >
                          Cancel
                        </div>
                        <div
                          onClick={() => {
                            confirmDelete();
                          }}
                          className="deleteButton_Delete"
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
              </Popup>
            </div>
          </div>
          {/* half circle  */}
          <div className="rightCenter">
            <img src={rightHalfCircle} />
          </div>
        </div>
      </div>
      {showShareBottomSheet ? (
        <ShareBottomsheet
          socialLinks={data.shareLinks}
          handleClose={() => setShowShareBottomSheet(false)}
        />
      ) : null}
    </div>
  );
}
const mapStateToProps = ({
  createGroup,
  singleContentDetails,
  createCouponReducer,
}) => {
  return {
    createGroupData: createGroup,
    singleGroupDetails: singleContentDetails?.singleGroupDetails,
    name: createCouponReducer.name,
    amount: createCouponReducer.amount,
    couponType: createCouponReducer.couponType,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    //   getGroupDetails: (payload) => dispatch(actions.getGrouptDetails(payload)),
    setName: (payload) => dispatch(actions.setName(payload)),
    setAmount: (payload) => dispatch(actions.setAmount(payload)),
    setType: (payload) => dispatch(actions.setType(payload)),
    reset: (payload) => dispatch(actions.resetCoupon(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(CouponCard);
