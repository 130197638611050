import CONSTANTS from "./constants";

export const setTitle = (payload) => {
  return {
    type: CONSTANTS.SET_TITLE,
    payload,
  };
};

export const getGrouptDetails = (payload) => {
  return {
    type: CONSTANTS.GET_GROUP_DETAILS,
    payload,
  };
};

export const getMessageDetails = (payload) => {
  return {
    type: CONSTANTS.GET_MESSAGE_DETAILS,
    payload,
  };
};

export const getStatementData = (payload) => {
  console.log("actionPayload", payload);
  return {
    type: CONSTANTS.GET_STATEMENT,
    payload,
  };
};
export const resetMemberData = (payload) => {
  console.log("resetMemberData", payload);
  return {
    type: CONSTANTS.RESET_MEMEBER_DATA,
    payload,
  };
};
export const resetStatementData = (payload) => {
  console.log("resetStatementData");
  return {
    type: CONSTANTS.RESET_STATEMENT_DATA,
    payload,
  };
};

export const resetSingleMessageDetails = () => {
  return {
    type: CONSTANTS.RESET_SINGLE_MESSAGE_DETAILS,
  };
};

export const resetSingleGroupDetails = () => {
  return {
    type: CONSTANTS.RESET_SINGLE_GROUP_DETAILS,
  };
};
export const getAllCoupons = (payload) => {
  return {
    type: CONSTANTS.ALL_COUPONS_GET,
    payload,
  };
};
