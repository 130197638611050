import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router";
import Button from "../../common/Button";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import ShareBottomsheet from "../../components/ShareBottomsheet";
import CustomUserAvatar from "../../common/CustomUserAvator";
import Description from "./components/description";
import Earnings from "./components/earnings";
import * as actions from "../../store/Details/actions";
import * as groupActions from "../../store/CreateGroup/actions";
import * as IpActions from "../../store/GetCountryCode/actions";
import {
  layoutPageHeadingSet,
  layoutPageHeadingClear,
} from "../../layout/store/actions";
import "../../scss/Details/Details.scss";
import iIcon from "../../assets/i.svg";
import iSuccess from "../../assets/i2.svg";
import telegramIcon from "../../assets/i3.svg";
import shareIcon from "../../assets/share.png";
import ReactLoading from "react-loading";
import Badge from "../../common/Badge";
import CategoryBadge from "../../common/CategoryBadge";
import { hitDeeplinkExecutedV2 } from "../../utils/hitDeeplink";
import toast from "react-hot-toast";
import CouponListing from "./components/couponListing";
import blueAdd from "../../assets/blueAdd.png";
import blueArrow from "../../assets/blueArrow.png";
import Bottomsheet from "../../common/Bottomsheet";
import greenOne from "../../assets/greenOne.png";
import greenTwo from "../../assets/greenTwo.png";
import greenThree from "../../assets/greenThree.png";
import greenFour from "../../assets/greenFour.png";
import ChannelBadge from "../../common/ChannelBadge";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";

function GroupDetails(props) {
  let {
    singleGroupDetails,
    createGroupData,
    type,
    fromCreate,
    isLoading,
    allCouponsData,
    isLoadings,
    getAllCoupons,
    v2Enabled,
    channelType,
  } = props;
  const dispatch = useDispatch();
  const temp = window.location.pathname.split("/");
  const groupId = temp[temp.length - 1];
  const location = useLocation();
  let finalDataVar = null;
  const isEdit = location.state.isEdit;
  useLayoutPageHeading(
    singleGroupDetails?.groupName || createGroupData?.groupName
  );
  const navigate = useNavigate();
  let { id } = useParams();
  let { prices } = createGroupData;

  const [showShareBottomSheet, setShowShareBottomSheet] = useState(false);
  const [groupData, setGroupData] = useState();
  const [finalData, setFinalData] = useState(null);
  const [couponData, setCouponsData] = useState(null);
  const [ctaStatus, setCtaStatus] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);

  const [hide, setHide] = useState(false);

  const socialLinks = useSelector((state) =>
    fromCreate
      ? state?.createGroup?.shareLinks
      : // : state?.singleContentDetails?.singleGroupDetails?.shareLinks
        location.state.socialLinks
  );
  const telegramChanelId = useSelector(
    (state) => state?.getAutomatedChannelReducer.telegramGroupId
  );

  useEffect(() => {
    dispatch(IpActions.getCountryCode());
  }, []);

  useEffect(() => {
    console.log("paramss", props.formCreate, id);
    !props.formCreate &&
      id &&
      props.getGroupDetails({
        id: id,
      });

    return () => {
      props.layoutPageHeadingClear();
      // props.resetSingleGroupDetails();
    };
  }, [id]);
  useEffect(() => {
    if (!fromCreate) getAllCoupons(id);
  }, []);
  useEffect(() => {
    if (allCouponsData) {
      if (allCouponsData.length > 2) {
        let coupons = allCouponsData.slice(0, 3);
        let view = { ctaStatus: true };
        coupons = [...coupons, view];
        setCouponsData(coupons);
        setCtaStatus(true);
      } else {
        setCouponsData(allCouponsData);
        setCtaStatus(false);
      }
    }
  }, [allCouponsData]);

  useEffect(() => {
    let count = 0;
    prices.filter((item) => {
      if (item?.selectedOffer && item?.selectedOffer?.value == "Free Trial") {
        count++;
      }
    });
    if (!count && window?.payload?.freeplanDays > 0) {
      let obj = {
        customType: {
          value: "Days",
        },
        customValue: window.payload.freeplanDays,
        discount: 0,
        price: 0,
        selectedOffer: { id: 9, label: "Free Trial", value: "Free Trial" },
        selectedPeriod: {
          id: 9,
          label: "Custom Period",
          value: "Custom Period",
        },
      };
      if (prices.length == 1 && prices[0].price.length != 0) {
        finalDataVar = [obj, ...prices];
      } else if (prices.length > 1) {
        finalDataVar = [obj, ...prices];
      } else {
        finalDataVar = [obj];
      }
      setFinalData(finalDataVar);
    } else {
      finalDataVar = prices;
      setFinalData(finalDataVar);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("success")) {
      props.layoutPageHeadingSet(createGroupData?.groupName);
    } else {
      props.layoutPageHeadingSet(singleGroupDetails?.groupName);
    }
    setGroupData(fromCreate ? createGroupData : singleGroupDetails);
  }, [fromCreate, singleGroupDetails, createGroupData]);

  const handleCreate = () => {
    setHide(true);
    let finalPayload = {};
    const payload = {
      ...(isEdit
        ? {
            name: createGroupData?.groupName,
            description: createGroupData?.about,
            id: parseInt(singleGroupDetails?.id),
            type: channelType,
            v2Enabled: v2Enabled,
            pricing: (finalData ? finalData : createGroupData?.prices)?.map(
              (plan) => {
                console.log("mapped plan", plan);
                if (plan.customType) {
                  finalPayload = {
                    price: parseFloat(plan.price),
                    selectedPeriod: plan.selectedPeriod?.value,
                    discount:
                      plan?.discount?.length > 0
                        ? parseFloat(plan.discount)
                        : 0,
                    selectedOffer: plan.selectedOffer?.value,
                    customType: plan?.customType.value
                      ? plan.customType.value
                      : "",
                    customValue: plan?.customValue ? plan.customValue : "",
                  };
                  if (v2Enabled) {
                    finalPayload["id"] = plan.id;
                  }
                  console.log("finalPayload: ", finalPayload);
                  return finalPayload;
                }
                finalPayload = {
                  price: parseFloat(plan.price),
                  selectedPeriod: plan.selectedPeriod?.value,
                  discount:
                    plan?.discount?.length > 0 ? parseFloat(plan.discount) : 0,
                  selectedOffer: plan.selectedOffer?.value,
                };
                if (v2Enabled) {
                  finalPayload["id"] = plan.id;
                }
                console.log("finalPayload: ", finalPayload);
                return finalPayload;
              }
            ),
          }
        : {
            name: createGroupData?.groupName,
            about: createGroupData?.about,
            category: createGroupData?.selectedCategory.value,
            type: channelType,
            v2Enabled: v2Enabled,
            ...(telegramChanelId ? { channelId: telegramChanelId } : {}),
            ...(singleGroupDetails?.id ? { id: singleGroupDetails?.id } : {}),
            ...(telegramChanelId ? { type: "telegramExisting" } : {}),
            pricing: (finalData ? finalData : createGroupData?.prices).map(
              (plan) => {
                if (plan?.customType) {
                  return {
                    price: parseFloat(plan.price),
                    selectedPeriod: plan.selectedPeriod.label,
                    discount:
                      plan?.discount?.length > 0
                        ? parseFloat(plan.discount)
                        : 0,
                    selectedOffer: plan.selectedOffer?.value,
                    customType: plan.customType.value,
                    customValue: plan.customValue,
                  };
                }
                return {
                  price: parseFloat(plan.price),
                  selectedPeriod: plan.selectedPeriod.value,
                  discount:
                    plan?.discount?.length > 0 ? parseFloat(plan.discount) : 0,
                  selectedOffer: plan.selectedOffer?.value,
                };
              }
            ),
          }),
      navigate,
      resetForm: () => {},
    };

    if (createGroupData?.image) {
      console.log("image sent");
      payload["logoUrl"] = createGroupData?.image;
    }
    if (isEdit) {
      if (createGroupData?.conditonalAccessToggle) {
        payload["conditionalApproval"] = true;
        if (createGroupData?.formLinkData !== null) {
          payload["formLink"] = createGroupData?.formLinkData;
        }
      }
      props.editGroup(payload);
    } else {
      if (createGroupData?.conditonalAccessToggle) {
        payload["conditionalApproval"] = true;
        if (createGroupData?.formLinkData !== "") {
          payload["formLink"] = createGroupData?.formLinkData;
        }
      }

      console.log("ca:", payload);
      props.createGroup(payload);
    }
  };

  const handleJoinClick = () => {
    if (groupData?.inviteLink?.length) {
      hitDeeplinkExecutedV2(
        JSON.stringify({
          screen: "UTIL_BROWSER",
          paramOne: groupData?.inviteLink,
        }),
        JSON.stringify({
          screen: "UTIL_BROWSER",
          paramOne: groupData?.inviteLink,
        })
      );
    } else toast.error("Invalid invite link");
  };

  return (
    <>
      {isLoading ? (
        <div className="Loader">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#2278FF"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div className="Details">
          <div className="Details_UpperBlock">
            <div className="Details_Top">
              {groupData?.image || singleGroupDetails?.logoUrl ? (
                <img
                  className="Details_image"
                  src={groupData?.image || singleGroupDetails?.logoUrl}
                  style={{ borderRadius: "50px" }}
                />
              ) : (
                <CustomUserAvatar
                  user={{
                    name: groupData?.groupName,
                  }}
                  size={"116px"}
                />
              )}
              <div className="Details_Name">
                <div className="Details_Name_txt">{groupData?.groupName}</div>{" "}
                &nbsp;
                {groupData?.status || groupData?.inviteLink ? (
                  <Badge
                    status={
                      groupData?.inviteLink && groupData?.status != "success"
                        ? "join"
                        : groupData?.status
                    }
                    inviteLink={groupData?.inviteLink}
                  />
                ) : null}
              </div>
            </div>

            {groupData?.status === "pending" && !groupData?.inviteLink ? (
              <div className="Details_Pending">
                <img
                  src={iIcon}
                  alt="i"
                  height={"15px"}
                  style={{ marginTop: "1px" }}
                />
                <div style={{ marginLeft: "10px" }}>
                  Your group creation is in progress, we will notify you once
                  your group is ready.
                </div>
              </div>
            ) : null}

            {groupData?.inviteLink && groupData?.status != "success" ? (
              <div className="Details_ChannelCreated">
                <img
                  src={iSuccess}
                  alt="i"
                  height={"15px"}
                  style={{ marginTop: "1px" }}
                />
                <div style={{ marginLeft: "10px" }}>
                  Join your telegram channel from here, soon you will be able to
                  share payments link across your network.
                </div>
              </div>
            ) : null}
          </div>

          <div className="Details_Badges">
            <ChannelBadge data={groupData} />
            <CategoryBadge
              categoryName={
                fromCreate
                  ? groupData?.selectedCategory?.label
                  : singleGroupDetails?.category
              }
            />
          </div>

          {!props.fromCreate ? (
            <Earnings
              data={groupData}
              totalEarnings={groupData?.totalRevenue}
              purchases={groupData?.memberCount}
              groupId={id}
            />
          ) : null}
          <Description
            description={groupData?.about}
            // pricePlan={
            //   fromCreate ? groupData?.pricing : groupData?.subscriptionPlan
            // }
          />
          {groupData?.status != "pending" &&
            couponData &&
            !fromCreate &&
            v2Enabled && (
              <CouponListing
                data={couponData}
                viewAll={ctaStatus}
                groupId={groupId}
              />
            )}

          {groupData?.type !== "whatsappGroup" &&
            groupData?.type !== "whatsappCommunity" &&
            groupData?.status != "pending" &&
            !fromCreate && (
              <div
                onClick={() => {
                  if (groupData?.adminCount && groupData?.adminCount > 0) {
                    navigate(`/group/${id}/adminList`);
                  }
                }}
                className="Details_coAdminState0Container"
              >
                <div className="Details_wantToAddAdmin">
                  {groupData?.adminCount && groupData?.adminCount > 0
                    ? "Your Admins"
                    : "Want to add Admin?"}
                </div>
                {groupData?.adminCount && groupData?.adminCount > 0 ? (
                  <img
                    // onClick={() => {
                    //   navigate(`/group/${id}/adminList`);
                    // }}
                    height={"12px"}
                    width={"10px"}
                    src={blueArrow}
                  />
                ) : (
                  <div
                    onClick={() => setOpenBottomSheet(true)}
                    className="Details_AddBTNContainer"
                  >
                    <img className="Details_blueAdd" src={blueAdd} />
                    <div className="Details_addTxt">Add</div>
                  </div>
                )}
              </div>
            )}

          {openBottomSheet && (
            <Bottomsheet
              header={"Steps to add new admin"}
              handleClose={() => setOpenBottomSheet(false)}
            >
              <div>
                <div className="Details_btSheetListItem">
                  <img className="Details_btSheetStepper" src={greenOne} />
                  <div className="Details_stepperText">
                    Enter details of user you want add as admin.
                  </div>
                </div>
                <div className="Details_btSheetListItem">
                  <img className="Details_btSheetStepper" src={greenTwo} />
                  <div className="Details_stepperText">
                    Enter and verify the OTP sent on users mobile number.
                  </div>
                </div>
                <div className="Details_btSheetListItem">
                  <img className="Details_btSheetStepper" src={greenThree} />
                  <div className="Details_stepperText">
                    Invite link to join the channel will be sent on the given
                    mobile number via SMS and Whatsapp.
                  </div>
                </div>
                <div className="Details_btSheetListItem">
                  <img className="Details_btSheetStepper" src={greenFour} />
                  <div className="Details_stepperText">
                    Once the person joins the channel they will automatically
                    get promoted to admin.
                  </div>
                </div>
                <div className="Details_gotItBTNContainer">
                  <Button
                    onClick={() => {
                      setOpenBottomSheet(false);
                      navigate(`/group/${id}/addNewAdmin`);
                    }}
                  >
                    {"Got It"}
                  </Button>
                </div>
              </div>
            </Bottomsheet>
          )}

          <div className="Details_Planheading">
            {window.location.href.includes("success")
              ? " Choose Payment Plans"
              : "Payment Plans"}
          </div>

          {(fromCreate
            ? window?.payload?.freeplanDays > 0
              ? finalData
              : createGroupData?.prices
            : singleGroupDetails?.subscriptionPlan
          )?.map((plan) => (
            <div className="Details_SinglePlan">
              {(fromCreate ? plan?.selectedOffer?.label : plan?.selectedOffer)
                ?.length ? (
                <div className="Details_SinglePlan_Offer">
                  {fromCreate
                    ? plan?.selectedOffer?.label
                    : plan?.selectedOffer}
                </div>
              ) : null}
              <div className="Details_SinglePlan_Period">
                {fromCreate
                  ? plan?.selectedPeriod?.label == "Custom Period" ||
                    plan?.selectedPeriod == "Custom Period"
                    ? plan?.customValue + " " + plan?.customType.value
                    : plan?.selectedPeriod?.label
                  : plan?.periodTitle &&
                    (plan?.selectedPeriod?.label == "Custom Period" ||
                      plan?.selectedPeriod == "Custom Period")
                  ? plan?.periodTitle
                  : plan?.selectedPeriod}
              </div>

              <div className="Details_SinglePlan_Right">
                {plan?.price != 0 ? (
                  (fromCreate ? plan?.discount?.length : plan?.discount) ? (
                    <div className="Details_SinglePlan_Price">
                      ₹{plan?.price}/-
                    </div>
                  ) : null
                ) : null}

                <div className="Details_SinglePlan_FinalPrice">
                  {plan.selectedOffer == "Free Trial" ||
                  plan.selectedOffer?.label == "Free Trial" ? (
                    <p>Free!</p>
                  ) : plan.discount ? (
                    `₹${parseFloat(plan?.price) - parseFloat(plan?.discount)}/-`
                  ) : (
                    `₹${parseFloat(plan?.price)}/-`
                  )}
                </div>
              </div>
            </div>
          ))}

          {groupData?.status == "success" ? (
            <div className="Description_ButtonContainer">
              <div
                className="Description_ButtonContainer_Button"
                onClick={() => {
                  setShowShareBottomSheet(true);
                }}
              >
                Share Payment Link
                <img
                  src={shareIcon}
                  alt="calendar"
                  height={"15px"}
                  width={"15px"}
                  style={{
                    margin: "0 0 2px 10px",
                    fill: "white",
                    stroke: "white",
                  }}
                />
              </div>
            </div>
          ) : null}

          {fromCreate ? (
            <div className="Details_ButtonContainer">
              <Button onClick={handleCreate} disabled={hide}>
                {singleGroupDetails?.id ? "Launch Channel" : "Launch Channel"}
              </Button>
            </div>
          ) : null}

          {groupData?.inviteLink && groupData?.status != "success" ? (
            <div className="Details_ButtonContainer">
              <div className="Details_btn_img">
                <Button onClick={handleJoinClick}>
                  <img
                    src={telegramIcon}
                    height={"18px"}
                    style={{ marginRight: "8px" }}
                  />
                  {"Join Your Channel"}
                </Button>
              </div>
            </div>
          ) : null}
          {showShareBottomSheet ? (
            <ShareBottomsheet
              socialLinks={socialLinks}
              handleClose={() => setShowShareBottomSheet(false)}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ createGroup, singleContentDetails, user }) => {
  return {
    createGroupData: createGroup,
    isLoading: singleContentDetails?.isLoading,
    singleGroupDetails: singleContentDetails?.singleGroupDetails,
    isLoadings: singleContentDetails.isLoadings,
    error: singleContentDetails.error,
    allCouponsData: singleContentDetails.allCouponsData,
    v2Enabled: user.v2Enabled,
    channelType: user?.channelType,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    getGroupDetails: (payload) => dispatch(actions.getGrouptDetails(payload)),
    layoutPageHeadingSet: (payload) => dispatch(layoutPageHeadingSet(payload)),
    layoutPageHeadingClear: () => dispatch(layoutPageHeadingClear()),
    resetState: () => dispatch(groupActions.resetState()),

    createGroup: (payload) => dispatch(groupActions.createGroup(payload)),
    editGroup: (payload) => dispatch(groupActions.editGroup(payload)),
    resetSingleGroupDetails: () => dispatch(actions.resetSingleGroupDetails()),
    getAllCoupons: (payload) => dispatch(actions.getAllCoupons(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(GroupDetails);
