import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { useEffect } from "react";
import CouponCard from "./CouponCard";
import "../../../scss/Coupons/AllCoupons/AllCoupons.scss";
import { layoutPageHeadingSet } from "../../../layout/store/actions";
import Button from "../../../common/Button";
import addCircle from "../../../assets/allCoupons/CouponCard/addCircle.png";
import LoadingOverlay, { LoadingOverLayProps } from "react-loading-overlay-ts";
import { useLayoutEffect } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import * as actions from "../store/actions";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
import { useNavigate } from "react-router-dom";
function AllCoupons(props) {
  const { isLoading, error, allCouponsData, toggleLoading } = props;
  // const [toggleLoading, setToggleloading] = useState(false);
  let activeCouponsData = [];
  let inActiveCouponsData = [];
  const [activeCoupons, setActiveCoupons] = useState([]);
  const [inActiveCoupons, setInActiveCoupons] = useState([]);
  // const [screenLoading,setScreenLoading] = useState(isLoading);

  const navigate = useNavigate();

  const addMore = () => {
    let temp = window.location.pathname.split("/");
    let groupId = temp[temp.length - 2];
    navigate(`/group/${groupId}/createCoupon`);
  };
  // useLayoutPageHeading("Create new Coupon");
  useEffect(() => {
    props.layoutPageHeadingSet(
      `All Coupons ${
        allCouponsData?.length ? `(${allCouponsData.length})` : ""
      }`
    );
  }, [allCouponsData]);
  // useEffect(() => {
  //   let temp = window.location.href.split("/");
  //   let groupId = temp[temp.length - 2];
  //   props.getAllCoupons(groupId);
  // }, []);
  useEffect(() => {
    // let groupId = "1412";
    let temp = window.location.pathname.split("/");
    // console.log(temp);
    let groupId = temp[temp.length - 2];
    // console.log("gid: ", groupId);
    props.getAllCoupons(groupId);
  }, [toggleLoading]);
  useEffect(() => {
    // console.log("dataa", allCouponsData, allCouponsData.length);
    if (allCouponsData) {
      activeCouponsData = allCouponsData.filter((item) => {
        return item.isActive ? item : null;
      });
      inActiveCouponsData = allCouponsData.filter((item) => {
        return !item.isActive ? item : null;
      });
      // console.log("dataActiveInactie",activeCouponsData,inActiveCouponsData)
      setActiveCoupons(activeCouponsData);
      setInActiveCoupons(inActiveCouponsData);
    }
  }, [allCouponsData]);
  useLayoutEffect(() => {
    if (toggleLoading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!toggleLoading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [toggleLoading]);
  const toggleActiveAction = (data) => {
    // console.log("itemselected", data);
    let payload = {
      couponId: data.id,
      isActive: false,
    };
    props.couponAction(payload);
  };
  const toggleInActiveAction = (data) => {
    // console.log("itemselected", data);
    let payload = {
      couponId: data.id,
      isActive: true,
    };
    props.couponAction(payload);
  };
  const confirmDelete = (data) => {
    // console.log("itemselected", data);
    let payload = {
      couponId: data.id,
      deleted: true,
    };
    props.couponAction(payload);
  };
  const LoaderComponent = () => {
    return (
      <div className="Loader">
        <ReactLoading
          type={"spinningBubbles"}
          color={"#2278FF"}
          height={50}
          width={50}
        />
      </div>
    );
  };

  return (
    <div className="AllCoupons">
      <LoadingOverlay
        active={toggleLoading}
        spinner
        text="Please wait"
        styles={{
          overlay: (base: any, props: LoadingOverLayProps) => {
            // console.log(base, props);
            return {
              ...base,
              position: "fixed",
            };
          },
          content: (base: any) => {
            return {
              ...base,
              // display: "flex",
              // flexDirection: "row",
              // backgroundColor: "white",
              color: "#ffffff",
              // borderRadius: "56px",
              fontSize: "16px",
              fontWeight: "bold",
              // marginBottom: "72px",
              // padding:'5px',
              textAlign: "center",
              alignContent: "center",

              // textAlign:"center",
            };
          },
          // spinner: (base: any) => {
          //   return {
          //     ...base,
          //     // backgroundColor:"#000000",
          //     // fontSize: "2px",
          //     // stroke: "#000",
          //     // color:"#000000",
          //   };
          // },
        }}
        // className="LoaderOverlay"
        // classNamePrefix="MyLoader_LoaderOverlay"
      >
        {isLoading && !toggleLoading ? (
          <LoaderComponent />
        ) : (
          <div className="AllCoupons">
            {allCouponsData.length == 0 && (
              <div className="NoCoupons">NO COUPONS CREATED</div>
            )}
            {/* {console.log("activeCoupons:",activeCoupons)} */}
            {activeCoupons.length > 0 && (
              <div className="ActiveCoupons_Container">
                <p className="ActiveCoupons_headText">Active Coupons</p>
                <div className="ActiveCoupons_CouponCard">
                  {activeCoupons.map((item) => {
                    return (
                      <CouponCard
                        toggleSelection={(data) => {
                          toggleActiveAction(data);
                        }}
                        deleteCoupon={(data) => {
                          confirmDelete(data);
                        }}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {/* Inactive Coupons */}
            {/* {console.log("InactiveCoupons:",inActiveCoupons)} */}
            {inActiveCoupons.length > 0 ? (
              <div className="ActiveCoupons_InactiveContainer">
                <p className="ActiveCoupons_headText">Inactive Coupons</p>
                <div className="ActiveCoupons_CouponCard">
                  {/* {console.log("sda", inActiveCoupons)} */}
                  {inActiveCoupons.map((item) => {
                    // console.log("inactive", item);
                    return (
                      <CouponCard
                        data={item}
                        deleteCoupon={(data) => {
                          confirmDelete(data);
                        }}
                        toggleSelection={(data) => {
                          toggleInActiveAction(data);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div style={{ paddingBottom: "70px" }}></div>
            )}
            {/* bottom button */}
            <div className="ButtonContainer">
              <Button onClick={addMore}>
                {allCouponsData.length > 0 ? `Add More` : `Create Coupon`}
                <div className="ButtonContainer_icon">
                  <img
                    src={addCircle}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Button>
            </div>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
}
const mapStateToProps = ({ allCoupons }) => {
  return {
    isLoading: allCoupons?.isLoading,
    error: allCoupons?.error,
    allCouponsData: allCoupons?.allCouponsData,
    toggleLoading: allCoupons?.toggleLoading,
  };
};
const mapDispatchWithProps = (dispatch) => {
  return {
    layoutPageHeadingSet: (payload) => dispatch(layoutPageHeadingSet(payload)),
    getAllCoupons: (payload) => dispatch(actions.getAllCoupons(payload)),
    couponAction: (payload) => dispatch(actions.couponAction(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(AllCoupons);
