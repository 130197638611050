import React, { useEffect } from "react";
import "./EarningDashSearch.scss";
import backArrow from "../../assets/backArrow.png";
import graySearch from "../../assets/graySearch.png";
import whiteSearch from "../../assets/whiteSearch.png";
import { searchRecord } from "./sagaStore/services";
import { useState } from "react";
import phoneED from "../../assets/phoneED.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useRef } from "react";

const ListItem = ({ item, index }) => {
  const [show, setShow] = useState();
  const freeTrial = item?.currentPlan
    ? JSON.parse(item.currentPlan).selectedOffer == "Free Trial"
      ? true
      : false
    : false;
  return (
    <div>
      <div key={index} className="searchScreenContainer_itemContainer">
        <div className="searchScreenContainer_itemContainer_left">
          <div className="searchScreenContainer_name">{item?.buyer?.name}</div>
          {item.buyer.mobile && (
            <div className="searchScreenContainer_date">
              <img
                height={"14px"}
                width={"14px"}
                src={phoneED}
                style={{ marginRight: 9 }}
              />
              <div className="searchScreenContainer_mobile">
                {`+${item.buyer.countryCode} ${item.buyer.mobile}`}
              </div>
            </div>
          )}
          <div className="searchScreenContainer_tagContainer">
            {item.migrated ? (
              <div className="searchScreenContainer_subscription">Migrated</div>
            ) : null}
            {item.currentPlan ? (
              item.currentPlan.selectedPeriod == "Lifetime" ? (
                <div className="searchScreenContainer_lifetime">Lifetime</div>
              ) : (
                <div className="searchScreenContainer_subscription">
                  {JSON.parse(item.currentPlan)?.periodTitle
                    ? JSON.parse(item.currentPlan)?.periodTitle
                    : JSON.parse(item.currentPlan)?.selectedPeriod}{" "}
                </div>
              )
            ) : null}
            {item.joinedBy && !item.isLeft ? (
              <div className="searchScreenContainer_joined">Joined</div>
            ) : item.isLeft ? (
              <div className="searchScreenContainer_notJoined">Left</div>
            ) : (
              <div className="searchScreenContainer_notJoined">Not Joined</div>
            )}

            {item.renewed && (
              <div className="searchScreenContainer_subscription">Renewed</div>
            )}
            {freeTrial && (
              <div className="searchScreenContainer_subscription">Free</div>
            )}
            {item.couponName && (
              <div className="searchScreenContainer_coupon">
                {item.couponName.length < 10
                  ? item.couponName
                  : `${item.couponName.slice(0, 10)}...`}
              </div>
            )}
          </div>
        </div>
        <div className="searchScreenContainer_itemContainer_right">
          <div className="searchScreenContainer_dateText">
            {moment(item.createdAt).format("MMM DD, YYYY")}
          </div>
          {
            <div className="searchScreenContainer_amount">
              {item.migrated ? `₹0/-` : `₹${item.amount}/-`}
            </div>
          }
          {!freeTrial && !item.migrated && (
            <div
              className="searchScreenContainer_details"
              onClick={() => setShow(!show)}
            >
              {show ? "Hide details" : "View details"}
            </div>
          )}
        </div>
      </div>
      {show && (
        <div className="searchScreenContainer_detailsContainer">
          <div className="searchScreenContainer_detailsLeft">
            <div className="searchScreenContainer_detailsText">
              Original Price
            </div>
            {item.planDiscount > 0 && (
              <div className="searchScreenContainer_detailsText">Discount</div>
            )}
            {item.couponName && (
              <div className="searchScreenContainer_detailsText">
                {`Coupon (${item.couponName})`}
              </div>
            )}
            <div className="searchScreenContainer_detailsText">
              Internet Handling Charges
            </div>
          </div>
          <div>
            <div className="searchScreenContainer_detailsAmount">
              {`₹${item.orignalPrice}/-`}
            </div>
            {item.planDiscount > 0 && (
              <div className="searchScreenContainer_detailsAmount">
                ₹{item?.planDiscount}/-
              </div>
            )}
            {item.couponName && (
              <div className="searchScreenContainer_detailsAmount">
                ₹{item?.couponDiscount}/-
              </div>
            )}
            <div className="searchScreenContainer_detailsAmount">
              ₹{item?.fankonnectCommission}/-
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const EarningDashSearch = () => {
  const input = useRef();
  const [mobile, setMobile] = useState();
  const [searchResults, setSearchResults] = useState();
  const navigate = useNavigate();
  const fetchSearchResults = () => {
    const payload = {
      mobile,
      groupId: `${window.payload.groupId}`,
    };
    searchRecord(payload)
      .then((response) => {
        if (response.length > 0) {
          setSearchResults(response);
        } else {
          toast.error("Mobile number not found");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    input.current.focus();
  }, []);
  return (
    <>
      <div className="searchScreenContainer">
        <div className="searchScreenContainer_searchBoxContainer">
          <img
            onClick={() => {
              navigate(-1);
            }}
            height={"16px"}
            width={"16px"}
            src={backArrow}
          />
          <div className="searchScreenContainer_searchBox">
            <input
              ref={input}
              onKeyDown={(e) => {
                if (mobile && mobile?.length > 4 && e.keyCode == 13) {
                  console.log("hello");
                  fetchSearchResults();
                }
              }}
              inputMode="numeric"
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Search"
              className="searchScreenContainer_search"
            />
            <div
              style={
                mobile && mobile.length > 4 ? { backgroundColor: "blue" } : null
              }
              className="searchScreenContainer_iconContainer"
            >
              <img
                onClick={
                  mobile && mobile?.length > 4 ? fetchSearchResults : null
                }
                height={"16px"}
                width={"16px"}
                src={mobile && mobile?.length > 4 ? whiteSearch : graySearch}
              />
            </div>
          </div>
        </div>
        <div>
          {searchResults &&
            searchResults.map((item, index) => {
              return <ListItem item={item} index={index} key={index} />;
            })}
        </div>
      </div>
    </>
  );
};

export default EarningDashSearch;
