import CONSTANTS from "./constants";

const inititalState = {
  purchaseScreen: "",

  formDetails: {
    name: "",
    mobile: "",
    email: "",
    indianState: "",
  },

  verifyOtpToken: "",
  generateOtp: {},
  generateOtpLoading: false,
  generateOtpError: false,

  verifyOtp: {},
  verifyOtpLoading: false,
  verifyOtpError: false,

  pageData: {},
  pageDataLoading: false,
  pageDataError: false,

  groupData: {},
  groupDataLoading: false,
  groupDataError: false,

  registerUser: {},
  registerUserLoading: false,
  registerUserError: false,

  orderStatus: {},
  orderStatusLoading: false,
  orderStatusError: false,
  groupPricingPayload: {},
  pricingPayload: {},
  otp: "",
  inviteLink: "",
  emailExistData: {
    name: "",
    email: true,
  },
  stateExistData: {
    name: "",
    email: "",
    state: true,
  },
  updateEmailLoading: false,
  updateEmailError: false,
  waChecked: true,
  applyCouponLoader: false,
  applyCouponSuccess: null,
  applyCouponFailure: null,
  couponCode: null,
  resetCouponError: false,
};
const setApplyCouponLoader = (state, action) => {
  return {
    ...state,
    applyCouponLoader: action.payload,
  };
};
const resetApplyCoupon = (state, action) => {
  return {
    ...state,
    applyCouponFailure: null,
    applyCouponSuccess: null,
    applyCouponLoader: false,
  };
};
const setCouponCodeReducer = (state, action) => {
  return {
    ...state,
    couponCode: action.payload,
  };
};
const setUpdateEmailLoader = (state, action) => {
  return {
    ...state,
    updateEmailLoading: action.payload,
  };
};
const setPurchaseScreen = (state, action) => {
  return {
    ...state,
    purchaseScreen: action.payload,
  };
};
const setInviteLink = (state, action) => {
  return {
    ...state,
    inviteLink: action.payload,
  };
};
const setEmailExistence = (state, action) => {
  return {
    ...state,
    emailExistData: {
      name: action.payload.name,
      email: false,
    },
  };
};

const setStateExistence = (state, action) => {
  return {
    ...state,
    stateExistData: {
      name: action.payload.name,
      email: action.payload.email,
      state: false,
    },
  };
};
const setVerifyOtpToken = (state, action) => {
  return {
    ...state,
    verifyOtpToken: action.payload,
  };
};

const setFormDetails = (state, action) => {
  return { ...state, formDetails: action.payload };
};
const resetFormDetails = (state, action) => {
  console.log("123");
  return {
    ...state,
    generateOtpLoading: false,
    verifyOtpLoading: false,
  };
};
const generateOtpLoader = (state, action) => {
  return { ...state, generateOtpLoading: true };
};

const generateOtpSuccess = (state, action) => {
  return {
    ...state,
    generateOtp: action.generateOtp,
    generateOtpLoading: action.generateOtpLoading,
  };
};

const generateOtpFailure = (state, action) => {
  return {
    ...state,
    generateOtp: action.generateOtp,
    generateOtpLoading: action.generateOtpLoading,
  };
};

const verifyOtpLoader = (state, action) => {
  return { ...state, verifyOtpLoading: true };
};

const verifyOtpSuccess = (state, action) => {
  return {
    ...state,
    verifyOtp: action.verifyOtp,
    verifyOtpError: action.verifyOtpError,
  };
};

const verifyOtpFailure = (state, action) => {
  return {
    ...state,
    verifyOtpLoading: action.verifyOtpLoading,
    verifyOtp: action.verifyOtp,
    verifyOtpError: action.verifyOtpError,
  };
};

const registerUserLoader = (state, action) => {
  return { ...state, registerUserLoading: true };
};

const registerUserSuccess = (state, action) => {
  return {
    ...state,
    registerUser: action.registerUser,
    // registerUserLoading: action.registerUserLoading,
    registerUserError: action.registerUserError,
  };
};

const registerUserFailure = (state, action) => {
  return {
    ...state,
    registerUserLoading: action.registerUserLoading,
    registerUser: action.registerUser,
    registerUserError: action.registerUserError,
  };
};

const pricingChange = (state, action) => {
  return {
    ...state,
    pricingPayload: action.payload,
  };
};

const otpValue = (state, action) => {
  return {
    ...state,
    otp: action.payload,
  };
};

const pageDataLoader = (state, action) => {
  return { ...state, pageDataLoading: true };
};

const pageDataSuccess = (state, action) => {
  return {
    ...state,
    pageData: action.pageData,
    pageDataLoading: action.pageDataLoading,
    pageDataError: action.pageDataError,
    pricingPayload: action.pageData?.data?.subscriptionPlan?.paymentPeriod
      ? action.pageData?.data?.subscriptionPlan?.paymentPeriod
      : {
          price: action.pageData?.data?.subscriptionPlan?.accessPrice,
        },
  };
};

const pageDataFailure = (state, action) => {
  return {
    ...state,
    pageDataLoading: action.pageDataLoading,
    pageData: action.pageData,
    pageDataError: action.pageDataError,
  };
};

const groupDataLoader = (state, action) => {
  return { ...state, groupDataLoading: true };
};

const groupDataSuccess = (state, action) => {
  // console.log("groupDataSuccess", action);
  return {
    ...state,
    groupData: action.groupData,
    groupDataLoading: action.groupDataLoading,
    groupDataError: action.groupDataError,
    groupPricingPayload: action.groupData?.data?.subscriptionPlan?.paymentPeriod
      ? action.groupData?.data?.subscriptionPlan?.paymentPeriod
      : {
          price: action.groupData?.data?.subscriptionPlan?.accessPrice,
        },
  };
};

const groupDataFailure = (state, action) => {
  return {
    ...state,
    groupDataLoading: action.groupDataLoading,
    groupData: action.groupData,
    groupDataError: action.groupDataError,
  };
};

const orderStatusLoader = (state, action) => {
  return { ...state, orderStatusLoading: true };
};

const orderStatusSuccess = (state, action) => {
  return {
    ...state,
    orderStatus: action.orderStatus,
    orderStatusLoading: action.orderStatusLoading,
    orderStatusError: action.orderStatusError,
  };
};

const orderStatusFailure = (state, action) => {
  return {
    ...state,
    orderStatusLoading: action.orderStatusLoading,
    orderStatus: action.orderStatus,
    orderStatusError: action.orderStatusError,
  };
};

const createOrderLoader = (state, action) => {
  return { ...state, createOrderLoading: true };
};

const createOrderSuccess = (state, action) => {
  return {
    ...state,
    createOrder: action.createOrder,
    createOrderLoading: action.createOrderLoading,
    createOrderError: action.createOrderError,
  };
};

const createOrderFailure = (state, action) => {
  return {
    ...state,
    createOrderLoading: action.createOrderLoading,
    createOrder: action.createOrder,
    createOrderError: action.createOrderError,
  };
};
const setWACommunicationState = (state, action) => {
  return {
    ...state,
    waChecked: action.payload,
  };
};
const setApplyCouponSuccess = (state, action) => {
  return {
    ...state,
    applyCouponFailure: null,
    applyCouponSuccess: action.payload,
  };
};
const setApplyCouponFailure = (state, action) => {
  return {
    ...state,
    applyCouponSuccess: null,
    applyCouponFailure: action.payload,
  };
};
const resetCouponReducer = (state, action) => {
  return {
    ...state,
    resetCouponError: true,
  };
};
const reducer = (state = inititalState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_PURCHASE_SCREEN:
      return setPurchaseScreen(state, action);
    case CONSTANTS.SET_FORM_DETAILS:
      return setFormDetails(state, action);
    case CONSTANTS.RESET_FORM_DETAILS:
      return resetFormDetails(state, action);
    case CONSTANTS.GENERATE_OTP_LOADER:
      return generateOtpLoader(state, action);
    case CONSTANTS.GENERATE_OTP_SUCCESS:
      return generateOtpSuccess(state, action);
    case CONSTANTS.GENERATE_OTP_FAILURE:
      return generateOtpFailure(state, action);
    case CONSTANTS.VERIFY_OTP_LOADER:
      return verifyOtpLoader(state, action);
    case CONSTANTS.VERIFY_OTP_SUCCESS:
      return verifyOtpSuccess(state, action);
    case CONSTANTS.VERIFY_OTP_FAILURE:
      return verifyOtpFailure(state, action);
    case CONSTANTS.REGISTER_USER_LOADER:
      return registerUserLoader(state, action);
    case CONSTANTS.REGISTER_USER_SUCCESS:
      return registerUserSuccess(state, action);
    case CONSTANTS.REGISTER_USER_FAILURE:
      return registerUserFailure(state, action);
    case CONSTANTS.PRICING_CHANGE:
      return pricingChange(state, action);
    case CONSTANTS.PAGE_DATA_LOADER:
      return pageDataLoader(state, action);
    case CONSTANTS.PAGE_DATA_SUCCESS:
      return pageDataSuccess(state, action);
    case CONSTANTS.PAGE_DATA_FAILURE:
      return pageDataFailure(state, action);
    //
    case CONSTANTS.GROUP_DATA_LOADER:
      return groupDataLoader(state, action);
    case CONSTANTS.GROUP_DATA_SUCCESS:
      return groupDataSuccess(state, action);
    case CONSTANTS.GROUP_DATA_FAILURE:
      return groupDataFailure(state, action);
    //

    case CONSTANTS.ORDER_STATUS_LOADER:
      return orderStatusLoader(state, action);
    case CONSTANTS.ORDER_STATUS_SUCCESS:
      return orderStatusSuccess(state, action);
    case CONSTANTS.ORDER_STATUS_FAILURE:
      return orderStatusFailure(state, action);
    case CONSTANTS.OTP_VALUE:
      return otpValue(state, action);
    case CONSTANTS.SET_VERIFY_OTP:
      return setVerifyOtpToken(state, action);
    case CONSTANTS.CREATE_ORDER_LOADER:
      return createOrderLoader(state, action);
    case CONSTANTS.CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, action);
    case CONSTANTS.CREATE_ORDER_FAILURE:
      return createOrderFailure(state, action);
    case CONSTANTS.SET_INVITE_LINK:
      return setInviteLink(state, action);
    case CONSTANTS.EMAIL_EXISTS:
      return setEmailExistence(state, action);
    case CONSTANTS.STATE_EXISTS:
      return setStateExistence(state, action);
    case CONSTANTS.UPDATE_EMAIL_LOADER:
      return setUpdateEmailLoader(state, action);
    case CONSTANTS.WA_CHECKED:
      return setWACommunicationState(state, action);
    case CONSTANTS.APPLY_COUPON_SUCCESS:
      return setApplyCouponSuccess(state, action);
    case CONSTANTS.APPLY_COUPON_FAILURE:
      return setApplyCouponFailure(state, action);
    case CONSTANTS.COUPON_CODE_VALUE:
      return setCouponCodeReducer(state, action);
    case CONSTANTS.APPLY_COUPON_LOADER:
      return setApplyCouponLoader(state, action);
    case CONSTANTS.RESET_APPLY_COUPON:
      return resetApplyCoupon(state, action);
    case CONSTANTS.RESET_COUPON_ERROR:
      return resetCouponReducer(state, action);
    default:
      return state;
  }
};

export default reducer;
