export const countryListJson = [
  {
    countryCode: "IN",
    countryFlag: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    countryName: "India",
    description: "flag for India",
    countryISO: "91",
  },
  {
    countryCode: "AD",
    countryFlag: "🇦🇩",
    unicode: "U+1F1E6 U+1F1E9",
    countryName: "Andorra",
    description: "flag for Andorra",
    countryISO: "376",
  },
  {
    countryCode: "AE",
    countryFlag: "🇦🇪",
    unicode: "U+1F1E6 U+1F1EA",
    countryName: "United Arab Emirates",
    description: "flag for United Arab Emirates",
    countryISO: "971",
  },
  {
    countryCode: "AF",
    countryFlag: "🇦🇫",
    unicode: "U+1F1E6 U+1F1EB",
    countryName: "Afghanistan",
    description: "flag for Afghanistan",
    countryISO: "93",
  },
  {
    countryCode: "AG",
    countryFlag: "🇦🇬",
    unicode: "U+1F1E6 U+1F1EC",
    countryName: "Antigua and Barbuda",
    description: "flag for Antigua and Barbuda",
    countryISO: "1268",
  },
  {
    countryCode: "AI",
    countryFlag: "🇦🇮",
    unicode: "U+1F1E6 U+1F1EE",
    countryName: "Anguilla",
    description: "flag for Anguilla",
    countryISO: "1 264",
  },
  {
    countryCode: "AL",
    countryFlag: "🇦🇱",
    unicode: "U+1F1E6 U+1F1F1",
    countryName: "Albania",
    description: "flag for Albania",
    countryISO: "355",
  },
  {
    countryCode: "AM",
    countryFlag: "🇦🇲",
    unicode: "U+1F1E6 U+1F1F2",
    countryName: "Armenia",
    description: "flag for Armenia",
    countryISO: "374",
  },
  {
    countryCode: "AO",
    countryFlag: "🇦🇴",
    unicode: "U+1F1E6 U+1F1F4",
    countryName: "Angola",
    description: "flag for Angola",
    countryISO: "244",
  },
  {
    countryCode: "AR",
    countryFlag: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
    countryName: "Argentina",
    description: "flag for Argentina",
    countryISO: "54",
  },
  {
    countryCode: "AS",
    countryFlag: "🇦🇸",
    unicode: "U+1F1E6 U+1F1F8",
    countryName: "American Samoa",
    description: "flag for American Samoa",
    countryISO: "1 684",
  },
  {
    countryCode: "AT",
    countryFlag: "🇦🇹",
    unicode: "U+1F1E6 U+1F1F9",
    countryName: "Austria",
    description: "flag for Austria",
    countryISO: "43",
  },
  {
    countryCode: "AU",
    countryFlag: "🇦🇺",
    unicode: "U+1F1E6 U+1F1FA",
    countryName: "Australia",
    description: "flag for Australia",
    countryISO: "61",
  },
  {
    countryCode: "AW",
    countryFlag: "🇦🇼",
    unicode: "U+1F1E6 U+1F1FC",
    countryName: "Aruba",
    description: "flag for Aruba",
    countryISO: "297",
  },
  {
    countryCode: "AZ",
    countryFlag: "🇦🇿",
    unicode: "U+1F1E6 U+1F1FF",
    countryName: "Azerbaijan",
    description: "flag for Azerbaijan",
    countryISO: "994",
  },
  {
    countryCode: "BA",
    countryFlag: "🇧🇦",
    unicode: "U+1F1E7 U+1F1E6",
    countryName: "Bosnia and Herzegovina",
    description: "flag for Bosnia and Herzegovina",
    countryISO: "387",
  },
  {
    countryCode: "BB",
    countryFlag: "🇧🇧",
    unicode: "U+1F1E7 U+1F1E7",
    countryName: "Barbados",
    description: "flag for Barbados",
    countryISO: "1 246",
  },
  {
    countryCode: "BD",
    countryFlag: "🇧🇩",
    unicode: "U+1F1E7 U+1F1E9",
    countryName: "Bangladesh",
    description: "flag for Bangladesh",
    countryISO: "880",
  },
  {
    countryCode: "BE",
    countryFlag: "🇧🇪",
    unicode: "U+1F1E7 U+1F1EA",
    countryName: "Belgium",
    description: "flag for Belgium",
    countryISO: "32",
  },
  {
    countryCode: "BF",
    countryFlag: "🇧🇫",
    unicode: "U+1F1E7 U+1F1EB",
    countryName: "Burkina Faso",
    description: "flag for Burkina Faso",
    countryISO: "226",
  },
  {
    countryCode: "BG",
    countryFlag: "🇧🇬",
    unicode: "U+1F1E7 U+1F1EC",
    countryName: "Bulgaria",
    description: "flag for Bulgaria",
    countryISO: "359",
  },
  {
    countryCode: "BH",
    countryFlag: "🇧🇭",
    unicode: "U+1F1E7 U+1F1ED",
    countryName: "Bahrain",
    description: "flag for Bahrain",
    countryISO: "973",
  },
  {
    countryCode: "BI",
    countryFlag: "🇧🇮",
    unicode: "U+1F1E7 U+1F1EE",
    countryName: "Burundi",
    description: "flag for Burundi",
    countryISO: "257",
  },
  {
    countryCode: "BJ",
    countryFlag: "🇧🇯",
    unicode: "U+1F1E7 U+1F1EF",
    countryName: "Benin",
    description: "flag for Benin",
    countryISO: "229",
  },
  {
    countryCode: "BL",
    countryFlag: "🇧🇱",
    unicode: "U+1F1E7 U+1F1F1",
    countryName: "Saint Barthélemy",
    description: "flag for Saint Barthélemy",
    countryISO: "590",
  },
  {
    countryCode: "BM",
    countryFlag: "🇧🇲",
    unicode: "U+1F1E7 U+1F1F2",
    countryName: "Bermuda",
    description: "flag for Bermuda",
    countryISO: "1 441",
  },
  {
    countryCode: "BN",
    countryFlag: "🇧🇳",
    unicode: "U+1F1E7 U+1F1F3",
    countryName: "Brunei Darussalam",
    description: "flag for Brunei Darussalam",
    countryISO: "673",
  },
  {
    countryCode: "BO",
    countryFlag: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
    countryName: "Bolivia",
    description: "flag for Bolivia",
    countryISO: "591",
  },
  {
    countryCode: "BQ",
    countryFlag: "🇧🇶",
    unicode: "U+1F1E7 U+1F1F6",
    countryName: "Bonaire, Sint Eustatius and Saba",
    description: "flag for Bonaire, Sint Eustatius and Saba",
    countryISO: "535",
  },
  {
    countryCode: "BR",
    countryFlag: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
    countryName: "Brazil",
    description: "flag for Brazil",
    countryISO: "55",
  },
  {
    countryCode: "BS",
    countryFlag: "🇧🇸",
    unicode: "U+1F1E7 U+1F1F8",
    countryName: "Bahamas",
    description: "flag for Bahamas",
    countryISO: "1 242",
  },
  {
    countryCode: "BT",
    countryFlag: "🇧🇹",
    unicode: "U+1F1E7 U+1F1F9",
    countryName: "Bhutan",
    description: "flag for Bhutan",
    countryISO: "975",
  },
  {
    countryCode: "BV",
    countryFlag: "🇧🇻",
    unicode: "U+1F1E7 U+1F1FB",
    countryName: "Bouvet Island",
    description: "flag for Bouvet Island",
    countryISO: "74",
  },
  {
    countryCode: "BW",
    countryFlag: "🇧🇼",
    unicode: "U+1F1E7 U+1F1FC",
    countryName: "Botswana",
    description: "flag for Botswana",
    countryISO: "267",
  },
  {
    countryCode: "BY",
    countryFlag: "🇧🇾",
    unicode: "U+1F1E7 U+1F1FE",
    countryName: "Belarus",
    description: "flag for Belarus",
    countryISO: "375",
  },
  {
    countryCode: "BZ",
    countryFlag: "🇧🇿",
    unicode: "U+1F1E7 U+1F1FF",
    countryName: "Belize",
    description: "flag for Belize",
    countryISO: "501",
  },
  {
    countryCode: "CA",
    countryFlag: "🇨🇦",
    unicode: "U+1F1E8 U+1F1E6",
    countryName: "Canada",
    description: "flag for Canada",
    countryISO: "1",
  },
  {
    countryCode: "CC",
    countryFlag: "🇨🇨",
    unicode: "U+1F1E8 U+1F1E8",
    countryName: "Cocos (Keeling) Islands",
    description: "flag for Cocos (Keeling) Islands",
    countryISO: "61",
  },
  {
    countryCode: "CD",
    countryFlag: "🇨🇩",
    unicode: "U+1F1E8 U+1F1E9",
    countryName: "Congo",
    description: "flag for Congo",
    countryISO: "243",
  },
  {
    countryCode: "CF",
    countryFlag: "🇨🇫",
    unicode: "U+1F1E8 U+1F1EB",
    countryName: "Central African Republic",
    description: "flag for Central African Republic",
    countryISO: "236",
  },
  {
    countryCode: "CG",
    countryFlag: "🇨🇬",
    unicode: "U+1F1E8 U+1F1EC",
    countryName: "Congo",
    description: "flag for Congo",
    countryISO: "242",
  },
  {
    countryCode: "CH",
    countryFlag: "🇨🇭",
    unicode: "U+1F1E8 U+1F1ED",
    countryName: "Switzerland",
    description: "flag for Switzerland",
    countryISO: "41",
  },
  {
    countryCode: "CI",
    countryFlag: "🇨🇮",
    unicode: "U+1F1E8 U+1F1EE",
    countryName: "Côte D'Ivoire",
    description: "flag for Côte D'Ivoire",
    countryISO: "225",
  },
  {
    countryCode: "CK",
    countryFlag: "🇨🇰",
    unicode: "U+1F1E8 U+1F1F0",
    countryName: "Cook Islands",
    description: "flag for Cook Islands",
    countryISO: "682",
  },
  {
    countryCode: "CL",
    countryFlag: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
    countryName: "Chile",
    description: "flag for Chile",
    countryISO: "56",
  },
  {
    countryCode: "CM",
    countryFlag: "🇨🇲",
    unicode: "U+1F1E8 U+1F1F2",
    countryName: "Cameroon",
    description: "flag for Cameroon",
    countryISO: "237",
  },
  {
    countryCode: "CN",
    countryFlag: "🇨🇳",
    unicode: "U+1F1E8 U+1F1F3",
    countryName: "China",
    description: "flag for China",
    countryISO: "86",
  },
  {
    countryCode: "CO",
    countryFlag: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
    countryName: "Colombia",
    description: "flag for Colombia",
    countryISO: "57",
  },
  {
    countryCode: "CR",
    countryFlag: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
    countryName: "Costa Rica",
    description: "flag for Costa Rica",
    countryISO: "506",
  },
  {
    countryCode: "CU",
    countryFlag: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
    countryName: "Cuba",
    description: "flag for Cuba",
    countryISO: "53",
  },
  {
    countryCode: "CV",
    countryFlag: "🇨🇻",
    unicode: "U+1F1E8 U+1F1FB",
    countryName: "Cape Verde",
    description: "flag for Cape Verde",
    countryISO: "238",
  },
  {
    countryCode: "CW",
    countryFlag: "🇨🇼",
    unicode: "U+1F1E8 U+1F1FC",
    countryName: "Curaçao",
    description: "flag for Curaçao",
    countryISO: "599",
  },
  {
    countryCode: "CX",
    countryFlag: "🇨🇽",
    unicode: "U+1F1E8 U+1F1FD",
    countryName: "Christmas Island",
    description: "flag for Christmas Island",
    countryISO: "61",
  },
  {
    countryCode: "CY",
    countryFlag: "🇨🇾",
    unicode: "U+1F1E8 U+1F1FE",
    countryName: "Cyprus",
    description: "flag for Cyprus",
    countryISO: "537",
  },
  {
    countryCode: "CZ",
    countryFlag: "🇨🇿",
    unicode: "U+1F1E8 U+1F1FF",
    countryName: "Czech Republic",
    description: "flag for Czech Republic",
    countryISO: "420",
  },
  {
    countryCode: "DE",
    countryFlag: "🇩🇪",
    unicode: "U+1F1E9 U+1F1EA",
    countryName: "Germany",
    description: "flag for Germany",
    countryISO: "49",
  },
  {
    countryCode: "DJ",
    countryFlag: "🇩🇯",
    unicode: "U+1F1E9 U+1F1EF",
    countryName: "Djibouti",
    description: "flag for Djibouti",
    countryISO: "253",
  },
  {
    countryCode: "DK",
    countryFlag: "🇩🇰",
    unicode: "U+1F1E9 U+1F1F0",
    countryName: "Denmark",
    description: "flag for Denmark",
    countryISO: "45",
  },
  {
    countryCode: "DM",
    countryFlag: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
    countryName: "Dominica",
    description: "flag for Dominica",
    countryISO: "1 767",
  },
  {
    countryCode: "DO",
    countryFlag: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
    countryName: "Dominican Republic",
    description: "flag for Dominican Republic",
    countryISO: "1 849",
  },
  {
    countryCode: "DZ",
    countryFlag: "🇩🇿",
    unicode: "U+1F1E9 U+1F1FF",
    countryName: "Algeria",
    description: "flag for Algeria",
    countryISO: "213",
  },
  {
    countryCode: "EC",
    countryFlag: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
    countryName: "Ecuador",
    description: "flag for Ecuador",
    countryISO: "593",
  },
  {
    countryCode: "EE",
    countryFlag: "🇪🇪",
    unicode: "U+1F1EA U+1F1EA",
    countryName: "Estonia",
    description: "flag for Estonia",
    countryISO: "372",
  },
  {
    countryCode: "EG",
    countryFlag: "🇪🇬",
    unicode: "U+1F1EA U+1F1EC",
    countryName: "Egypt",
    description: "flag for Egypt",
    countryISO: "20",
  },
  {
    countryCode: "EH",
    countryFlag: "🇪🇭",
    unicode: "U+1F1EA U+1F1ED",
    countryName: "Western Sahara",
    description: "flag for Western Sahara",
    countryISO: "212",
  },
  {
    countryCode: "ER",
    countryFlag: "🇪🇷",
    unicode: "U+1F1EA U+1F1F7",
    countryName: "Eritrea",
    description: "flag for Eritrea",
    countryISO: "291",
  },
  {
    countryCode: "ES",
    countryFlag: "🇪🇸",
    unicode: "U+1F1EA U+1F1F8",
    countryName: "Spain",
    description: "flag for Spain",
    countryISO: "34",
  },
  {
    countryCode: "ET",
    countryFlag: "🇪🇹",
    unicode: "U+1F1EA U+1F1F9",
    countryName: "Ethiopia",
    description: "flag for Ethiopia",
    countryISO: "251",
  },
  {
    countryCode: "EU",
    countryFlag: "🇪🇺",
    unicode: "U+1F1EA U+1F1FA",
    countryName: "European Union",
    description: "flag for European Union",
  },
  {
    countryCode: "FI",
    countryFlag: "🇫🇮",
    unicode: "U+1F1EB U+1F1EE",
    countryName: "Finland",
    description: "flag for Finland",
    countryISO: "358",
  },
  {
    countryCode: "FJ",
    countryFlag: "🇫🇯",
    unicode: "U+1F1EB U+1F1EF",
    countryName: "Fiji",
    description: "flag for Fiji",
    countryISO: "679",
  },
  {
    countryCode: "FK",
    countryFlag: "🇫🇰",
    unicode: "U+1F1EB U+1F1F0",
    countryName: "Falkland Islands (Malvinas)",
    description: "flag for Falkland Islands (Malvinas)",
    countryISO: "500",
  },
  {
    countryCode: "FM",
    countryFlag: "🇫🇲",
    unicode: "U+1F1EB U+1F1F2",
    countryName: "Micronesia",
    description: "flag for Micronesia",
    countryISO: "691",
  },
  {
    countryCode: "FO",
    countryFlag: "🇫🇴",
    unicode: "U+1F1EB U+1F1F4",
    countryName: "Faroe Islands",
    description: "flag for Faroe Islands",
    countryISO: "298",
  },
  {
    countryCode: "FR",
    countryFlag: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
    countryName: "France",
    description: "flag for France",
    countryISO: "33",
  },
  {
    countryCode: "GA",
    countryFlag: "🇬🇦",
    unicode: "U+1F1EC U+1F1E6",
    countryName: "Gabon",
    description: "flag for Gabon",
    countryISO: "241",
  },
  {
    countryCode: "GB",
    countryFlag: "🇬🇧",
    unicode: "U+1F1EC U+1F1E7",
    countryName: "United Kingdom",
    description: "flag for United Kingdom",
    countryISO: "44",
  },
  {
    countryCode: "GD",
    countryFlag: "🇬🇩",
    unicode: "U+1F1EC U+1F1E9",
    countryName: "Grenada",
    description: "flag for Grenada",
    countryISO: "1 473",
  },
  {
    countryCode: "GE",
    countryFlag: "🇬🇪",
    unicode: "U+1F1EC U+1F1EA",
    countryName: "Georgia",
    description: "flag for Georgia",
    countryISO: "995",
  },
  {
    countryCode: "GF",
    countryFlag: "🇬🇫",
    unicode: "U+1F1EC U+1F1EB",
    countryName: "French Guiana",
    description: "flag for French Guiana",
    countryISO: "594",
  },
  {
    countryCode: "GG",
    countryFlag: "🇬🇬",
    unicode: "U+1F1EC U+1F1EC",
    countryName: "Guernsey",
    description: "flag for Guernsey",
    countryISO: "44",
  },
  {
    countryCode: "GH",
    countryFlag: "🇬🇭",
    unicode: "U+1F1EC U+1F1ED",
    countryName: "Ghana",
    description: "flag for Ghana",
    countryISO: "233",
  },
  {
    countryCode: "GI",
    countryFlag: "🇬🇮",
    unicode: "U+1F1EC U+1F1EE",
    countryName: "Gibraltar",
    description: "flag for Gibraltar",
    countryISO: "350",
  },
  {
    countryCode: "GL",
    countryFlag: "🇬🇱",
    unicode: "U+1F1EC U+1F1F1",
    countryName: "Greenland",
    description: "flag for Greenland",
    countryISO: "299",
  },
  {
    countryCode: "GM",
    countryFlag: "🇬🇲",
    unicode: "U+1F1EC U+1F1F2",
    countryName: "Gambia",
    description: "flag for Gambia",
    countryISO: "220",
  },
  {
    countryCode: "GN",
    countryFlag: "🇬🇳",
    unicode: "U+1F1EC U+1F1F3",
    countryName: "Guinea",
    description: "flag for Guinea",
    countryISO: "224",
  },
  {
    countryCode: "GP",
    countryFlag: "🇬🇵",
    unicode: "U+1F1EC U+1F1F5",
    countryName: "Guadeloupe",
    description: "flag for Guadeloupe",
    countryISO: "590",
  },
  {
    countryCode: "GQ",
    countryFlag: "🇬🇶",
    unicode: "U+1F1EC U+1F1F6",
    countryName: "Equatorial Guinea",
    description: "flag for Equatorial Guinea",
    countryISO: "240",
  },
  {
    countryCode: "GR",
    countryFlag: "🇬🇷",
    unicode: "U+1F1EC U+1F1F7",
    countryName: "Greece",
    description: "flag for Greece",
    countryISO: "30",
  },
  {
    countryCode: "GS",
    countryFlag: "🇬🇸",
    unicode: "U+1F1EC U+1F1F8",
    countryName: "South Georgia",
    description: "flag for South Georgia",
    countryISO: "500",
  },
  {
    countryCode: "GT",
    countryFlag: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
    countryName: "Guatemala",
    description: "flag for Guatemala",
    countryISO: "502",
  },
  {
    countryCode: "GU",
    countryFlag: "🇬🇺",
    unicode: "U+1F1EC U+1F1FA",
    countryName: "Guam",
    description: "flag for Guam",
    countryISO: "1 671",
  },
  {
    countryCode: "GW",
    countryFlag: "🇬🇼",
    unicode: "U+1F1EC U+1F1FC",
    countryName: "Guinea-Bissau",
    description: "flag for Guinea-Bissau",
    countryISO: "245",
  },
  {
    countryCode: "GY",
    countryFlag: "🇬🇾",
    unicode: "U+1F1EC U+1F1FE",
    countryName: "Guyana",
    description: "flag for Guyana",
    countryISO: "595",
  },
  {
    countryCode: "HK",
    countryFlag: "🇭🇰",
    unicode: "U+1F1ED U+1F1F0",
    countryName: "Hong Kong",
    description: "flag for Hong Kong",
    countryISO: "852",
  },
  {
    countryCode: "HM",
    countryFlag: "🇭🇲",
    unicode: "U+1F1ED U+1F1F2",
    countryName: "Heard Island and Mcdonald Islands",
    description: "flag for Heard Island and Mcdonald Islands",
  },
  {
    countryCode: "HN",
    countryFlag: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
    countryName: "Honduras",
    description: "flag for Honduras",
    countryISO: "504",
  },
  {
    countryCode: "HR",
    countryFlag: "🇭🇷",
    unicode: "U+1F1ED U+1F1F7",
    countryName: "Croatia",
    description: "flag for Croatia",
    countryISO: "385",
  },
  {
    countryCode: "HT",
    countryFlag: "🇭🇹",
    unicode: "U+1F1ED U+1F1F9",
    countryName: "Haiti",
    description: "flag for Haiti",
    countryISO: "509",
  },
  {
    countryCode: "HU",
    countryFlag: "🇭🇺",
    unicode: "U+1F1ED U+1F1FA",
    countryName: "Hungary",
    description: "flag for Hungary",
    countryISO: "36",
  },
  {
    countryCode: "ID",
    countryFlag: "🇮🇩",
    unicode: "U+1F1EE U+1F1E9",
    countryName: "Indonesia",
    description: "flag for Indonesia",
    countryISO: "62",
  },
  {
    countryCode: "IE",
    countryFlag: "🇮🇪",
    unicode: "U+1F1EE U+1F1EA",
    countryName: "Ireland",
    description: "flag for Ireland",
    countryISO: "353",
  },
  {
    countryCode: "IL",
    countryFlag: "🇮🇱",
    unicode: "U+1F1EE U+1F1F1",
    countryName: "Israel",
    description: "flag for Israel",
    countryISO: "972",
  },
  {
    countryCode: "IM",
    countryFlag: "🇮🇲",
    unicode: "U+1F1EE U+1F1F2",
    countryName: "Isle of Man",
    description: "flag for Isle of Man",
    countryISO: "44",
  },
  {
    countryCode: "IO",
    countryFlag: "🇮🇴",
    unicode: "U+1F1EE U+1F1F4",
    countryName: "British Indian Ocean Territory",
    description: "flag for British Indian Ocean Territory",
    countryISO: "246",
  },
  {
    countryCode: "IQ",
    countryFlag: "🇮🇶",
    unicode: "U+1F1EE U+1F1F6",
    countryName: "Iraq",
    description: "flag for Iraq",
    countryISO: "964",
  },
  {
    countryCode: "IR",
    countryFlag: "🇮🇷",
    unicode: "U+1F1EE U+1F1F7",
    countryName: "Iran",
    description: "flag for Iran",
    countryISO: "98",
  },
  {
    countryCode: "IS",
    countryFlag: "🇮🇸",
    unicode: "U+1F1EE U+1F1F8",
    countryName: "Iceland",
    description: "flag for Iceland",
    countryISO: "354",
  },
  {
    countryCode: "IT",
    countryFlag: "🇮🇹",
    unicode: "U+1F1EE U+1F1F9",
    countryName: "Italy",
    description: "flag for Italy",
    countryISO: "39",
  },
  {
    countryCode: "JE",
    countryFlag: "🇯🇪",
    unicode: "U+1F1EF U+1F1EA",
    countryName: "Jersey",
    description: "flag for Jersey",
    countryISO: "44",
  },
  {
    countryCode: "JM",
    countryFlag: "🇯🇲",
    unicode: "U+1F1EF U+1F1F2",
    countryName: "Jamaica",
    description: "flag for Jamaica",
    countryISO: "1 876",
  },
  {
    countryCode: "JO",
    countryFlag: "🇯🇴",
    unicode: "U+1F1EF U+1F1F4",
    countryName: "Jordan",
    description: "flag for Jordan",
    countryISO: "962",
  },
  {
    countryCode: "JP",
    countryFlag: "🇯🇵",
    unicode: "U+1F1EF U+1F1F5",
    countryName: "Japan",
    description: "flag for Japan",
    countryISO: "81",
  },
  {
    countryCode: "KE",
    countryFlag: "🇰🇪",
    unicode: "U+1F1F0 U+1F1EA",
    countryName: "Kenya",
    description: "flag for Kenya",
    countryISO: "254",
  },
  {
    countryCode: "KG",
    countryFlag: "🇰🇬",
    unicode: "U+1F1F0 U+1F1EC",
    countryName: "Kyrgyzstan",
    description: "flag for Kyrgyzstan",
    countryISO: "996",
  },
  {
    countryCode: "KH",
    countryFlag: "🇰🇭",
    unicode: "U+1F1F0 U+1F1ED",
    countryName: "Cambodia",
    description: "flag for Cambodia",
    countryISO: "855",
  },
  {
    countryCode: "KI",
    countryFlag: "🇰🇮",
    unicode: "U+1F1F0 U+1F1EE",
    countryName: "Kiribati",
    description: "flag for Kiribati",
    countryISO: "686",
  },
  {
    countryCode: "KM",
    countryFlag: "🇰🇲",
    unicode: "U+1F1F0 U+1F1F2",
    countryName: "Comoros",
    description: "flag for Comoros",
    countryISO: "269",
  },
  {
    countryCode: "KN",
    countryFlag: "🇰🇳",
    unicode: "U+1F1F0 U+1F1F3",
    countryName: "Saint Kitts and Nevis",
    description: "flag for Saint Kitts and Nevis",
    countryISO: "1 869",
  },
  {
    countryCode: "KP",
    countryFlag: "🇰🇵",
    unicode: "U+1F1F0 U+1F1F5",
    countryName: "North Korea",
    description: "flag for North Korea",
    countryISO: "850",
  },
  {
    countryCode: "KR",
    countryFlag: "🇰🇷",
    unicode: "U+1F1F0 U+1F1F7",
    countryName: "South Korea",
    description: "flag for South Korea",
    countryISO: "82",
  },
  {
    countryCode: "KW",
    countryFlag: "🇰🇼",
    unicode: "U+1F1F0 U+1F1FC",
    countryName: "Kuwait",
    description: "flag for Kuwait",
    countryISO: "965",
  },
  {
    countryCode: "KY",
    countryFlag: "🇰🇾",
    unicode: "U+1F1F0 U+1F1FE",
    countryName: "Cayman Islands",
    description: "flag for Cayman Islands",
    countryISO: " 345",
  },
  {
    countryCode: "KZ",
    countryFlag: "🇰🇿",
    unicode: "U+1F1F0 U+1F1FF",
    countryName: "Kazakhstan",
    description: "flag for Kazakhstan",
    countryISO: "7 7",
  },
  {
    countryCode: "LA",
    countryFlag: "🇱🇦",
    unicode: "U+1F1F1 U+1F1E6",
    countryName: "Lao People's Democratic Republic",
    description: "flag for Lao People's Democratic Republic",
    countryISO: "856",
  },
  {
    countryCode: "LB",
    countryFlag: "🇱🇧",
    unicode: "U+1F1F1 U+1F1E7",
    countryName: "Lebanon",
    description: "flag for Lebanon",
    countryISO: "961",
  },
  {
    countryCode: "LC",
    countryFlag: "🇱🇨",
    unicode: "U+1F1F1 U+1F1E8",
    countryName: "Saint Lucia",
    description: "flag for Saint Lucia",
    countryISO: "1 758",
  },
  {
    countryCode: "LI",
    countryFlag: "🇱🇮",
    unicode: "U+1F1F1 U+1F1EE",
    countryName: "Liechtenstein",
    description: "flag for Liechtenstein",
    countryISO: "423",
  },
  {
    countryCode: "LK",
    countryFlag: "🇱🇰",
    unicode: "U+1F1F1 U+1F1F0",
    countryName: "Sri Lanka",
    description: "flag for Sri Lanka",
    countryISO: "94",
  },
  {
    countryCode: "LR",
    countryFlag: "🇱🇷",
    unicode: "U+1F1F1 U+1F1F7",
    countryName: "Liberia",
    description: "flag for Liberia",
    countryISO: "231",
  },
  {
    countryCode: "LS",
    countryFlag: "🇱🇸",
    unicode: "U+1F1F1 U+1F1F8",
    countryName: "Lesotho",
    description: "flag for Lesotho",
    countryISO: "266",
  },
  {
    countryCode: "LT",
    countryFlag: "🇱🇹",
    unicode: "U+1F1F1 U+1F1F9",
    countryName: "Lithuania",
    description: "flag for Lithuania",
    countryISO: "370",
  },
  {
    countryCode: "LU",
    countryFlag: "🇱🇺",
    unicode: "U+1F1F1 U+1F1FA",
    countryName: "Luxembourg",
    description: "flag for Luxembourg",
    countryISO: "352",
  },
  {
    countryCode: "LV",
    countryFlag: "🇱🇻",
    unicode: "U+1F1F1 U+1F1FB",
    countryName: "Latvia",
    description: "flag for Latvia",
    countryISO: "371",
  },
  {
    countryCode: "LY",
    countryFlag: "🇱🇾",
    unicode: "U+1F1F1 U+1F1FE",
    countryName: "Libya",
    description: "flag for Libya",
    countryISO: "218",
  },
  {
    countryCode: "MA",
    countryFlag: "🇲🇦",
    unicode: "U+1F1F2 U+1F1E6",
    countryName: "Morocco",
    description: "flag for Morocco",
    countryISO: "212",
  },
  {
    countryCode: "MC",
    countryFlag: "🇲🇨",
    unicode: "U+1F1F2 U+1F1E8",
    countryName: "Monaco",
    description: "flag for Monaco",
    countryISO: "377",
  },
  {
    countryCode: "MD",
    countryFlag: "🇲🇩",
    unicode: "U+1F1F2 U+1F1E9",
    countryName: "Moldova",
    description: "flag for Moldova",
    countryISO: "373",
  },
  {
    countryCode: "ME",
    countryFlag: "🇲🇪",
    unicode: "U+1F1F2 U+1F1EA",
    countryName: "Montenegro",
    description: "flag for Montenegro",
    countryISO: "382",
  },
  {
    countryCode: "MF",
    countryFlag: "🇲🇫",
    unicode: "U+1F1F2 U+1F1EB",
    countryName: "Saint Martin (French Part)",
    description: "flag for Saint Martin (French Part)",
    countryISO: "590",
  },
  {
    countryCode: "MG",
    countryFlag: "🇲🇬",
    unicode: "U+1F1F2 U+1F1EC",
    countryName: "Madagascar",
    description: "flag for Madagascar",
    countryISO: "261",
  },
  {
    countryCode: "MH",
    countryFlag: "🇲🇭",
    unicode: "U+1F1F2 U+1F1ED",
    countryName: "Marshall Islands",
    description: "flag for Marshall Islands",
    countryISO: "692",
  },
  {
    countryCode: "MK",
    countryFlag: "🇲🇰",
    unicode: "U+1F1F2 U+1F1F0",
    countryName: "Macedonia",
    description: "flag for Macedonia",
    countryISO: "389",
  },
  {
    countryCode: "ML",
    countryFlag: "🇲🇱",
    unicode: "U+1F1F2 U+1F1F1",
    countryName: "Mali",
    description: "flag for Mali",
    countryISO: "223",
  },
  {
    countryCode: "MM",
    countryFlag: "🇲🇲",
    unicode: "U+1F1F2 U+1F1F2",
    countryName: "Myanmar",
    description: "flag for Myanmar",
    countryISO: "95",
  },
  {
    countryCode: "MN",
    countryFlag: "🇲🇳",
    unicode: "U+1F1F2 U+1F1F3",
    countryName: "Mongolia",
    description: "flag for Mongolia",
    countryISO: "976",
  },
  {
    countryCode: "MO",
    countryFlag: "🇲🇴",
    unicode: "U+1F1F2 U+1F1F4",
    countryName: "Macao",
    description: "flag for Macao",
    countryISO: "853",
  },
  {
    countryCode: "MP",
    countryFlag: "🇲🇵",
    unicode: "U+1F1F2 U+1F1F5",
    countryName: "Northern Mariana Islands",
    description: "flag for Northern Mariana Islands",
    countryISO: "1 670",
  },
  {
    countryCode: "MQ",
    countryFlag: "🇲🇶",
    unicode: "U+1F1F2 U+1F1F6",
    countryName: "Martinique",
    description: "flag for Martinique",
    countryISO: "596",
  },
  {
    countryCode: "MR",
    countryFlag: "🇲🇷",
    unicode: "U+1F1F2 U+1F1F7",
    countryName: "Mauritania",
    description: "flag for Mauritania",
    countryISO: "222",
  },
  {
    countryCode: "MS",
    countryFlag: "🇲🇸",
    unicode: "U+1F1F2 U+1F1F8",
    countryName: "Montserrat",
    description: "flag for Montserrat",
    countryISO: "1664",
  },
  {
    countryCode: "MT",
    countryFlag: "🇲🇹",
    unicode: "U+1F1F2 U+1F1F9",
    countryName: "Malta",
    description: "flag for Malta",
    countryISO: "356",
  },
  {
    countryCode: "MU",
    countryFlag: "🇲🇺",
    unicode: "U+1F1F2 U+1F1FA",
    countryName: "Mauritius",
    description: "flag for Mauritius",
    countryISO: "230",
  },
  {
    countryCode: "MV",
    countryFlag: "🇲🇻",
    unicode: "U+1F1F2 U+1F1FB",
    countryName: "Maldives",
    description: "flag for Maldives",
    countryISO: "960",
  },
  {
    countryCode: "MW",
    countryFlag: "🇲🇼",
    unicode: "U+1F1F2 U+1F1FC",
    countryName: "Malawi",
    description: "flag for Malawi",
    countryISO: "265",
  },
  {
    countryCode: "MX",
    countryFlag: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
    countryName: "Mexico",
    description: "flag for Mexico",
    countryISO: "52",
  },
  {
    countryCode: "MY",
    countryFlag: "🇲🇾",
    unicode: "U+1F1F2 U+1F1FE",
    countryName: "Malaysia",
    description: "flag for Malaysia",
    countryISO: "60",
  },
  {
    countryCode: "MZ",
    countryFlag: "🇲🇿",
    unicode: "U+1F1F2 U+1F1FF",
    countryName: "Mozambique",
    description: "flag for Mozambique",
    countryISO: "258",
  },
  {
    countryCode: "NA",
    countryFlag: "🇳🇦",
    unicode: "U+1F1F3 U+1F1E6",
    countryName: "Namibia",
    description: "flag for Namibia",
    countryISO: "264",
  },
  {
    countryCode: "NC",
    countryFlag: "🇳🇨",
    unicode: "U+1F1F3 U+1F1E8",
    countryName: "New Caledonia",
    description: "flag for New Caledonia",
    countryISO: "687",
  },
  {
    countryCode: "NE",
    countryFlag: "🇳🇪",
    unicode: "U+1F1F3 U+1F1EA",
    countryName: "Niger",
    description: "flag for Niger",
    countryISO: "227",
  },
  {
    countryCode: "NF",
    countryFlag: "🇳🇫",
    unicode: "U+1F1F3 U+1F1EB",
    countryName: "Norfolk Island",
    description: "flag for Norfolk Island",
    countryISO: "672",
  },
  {
    countryCode: "NG",
    countryFlag: "🇳🇬",
    unicode: "U+1F1F3 U+1F1EC",
    countryName: "Nigeria",
    description: "flag for Nigeria",
    countryISO: "234",
  },
  {
    countryCode: "NI",
    countryFlag: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
    countryName: "Nicaragua",
    description: "flag for Nicaragua",
    countryISO: "505",
  },
  {
    countryCode: "NL",
    countryFlag: "🇳🇱",
    unicode: "U+1F1F3 U+1F1F1",
    countryName: "Netherlands",
    description: "flag for Netherlands",
    countryISO: "31",
  },
  {
    countryCode: "NO",
    countryFlag: "🇳🇴",
    unicode: "U+1F1F3 U+1F1F4",
    countryName: "Norway",
    description: "flag for Norway",
    countryISO: "47",
  },
  {
    countryCode: "NP",
    countryFlag: "🇳🇵",
    unicode: "U+1F1F3 U+1F1F5",
    countryName: "Nepal",
    description: "flag for Nepal",
    countryISO: "977",
  },
  {
    countryCode: "NR",
    countryFlag: "🇳🇷",
    unicode: "U+1F1F3 U+1F1F7",
    countryName: "Nauru",
    description: "flag for Nauru",
    countryISO: "674",
  },
  {
    countryCode: "NU",
    countryFlag: "🇳🇺",
    unicode: "U+1F1F3 U+1F1FA",
    countryName: "Niue",
    description: "flag for Niue",
    countryISO: "683",
  },
  {
    countryCode: "NZ",
    countryFlag: "🇳🇿",
    unicode: "U+1F1F3 U+1F1FF",
    countryName: "New Zealand",
    description: "flag for New Zealand",
    countryISO: "64",
  },
  {
    countryCode: "OM",
    countryFlag: "🇴🇲",
    unicode: "U+1F1F4 U+1F1F2",
    countryName: "Oman",
    description: "flag for Oman",
    countryISO: "968",
  },
  {
    countryCode: "PA",
    countryFlag: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
    countryName: "Panama",
    description: "flag for Panama",
    countryISO: "507",
  },
  {
    countryCode: "PE",
    countryFlag: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
    countryName: "Peru",
    description: "flag for Peru",
    countryISO: "51",
  },
  {
    countryCode: "PF",
    countryFlag: "🇵🇫",
    unicode: "U+1F1F5 U+1F1EB",
    countryName: "French Polynesia",
    description: "flag for French Polynesia",
    countryISO: "689",
  },
  {
    countryCode: "PG",
    countryFlag: "🇵🇬",
    unicode: "U+1F1F5 U+1F1EC",
    countryName: "Papua New Guinea",
    description: "flag for Papua New Guinea",
    countryISO: "675",
  },
  {
    countryCode: "PH",
    countryFlag: "🇵🇭",
    unicode: "U+1F1F5 U+1F1ED",
    countryName: "Philippines",
    description: "flag for Philippines",
    countryISO: "63",
  },
  {
    countryCode: "PK",
    countryFlag: "🇵🇰",
    unicode: "U+1F1F5 U+1F1F0",
    countryName: "Pakistan",
    description: "flag for Pakistan",
    countryISO: "92",
  },
  {
    countryCode: "PL",
    countryFlag: "🇵🇱",
    unicode: "U+1F1F5 U+1F1F1",
    countryName: "Poland",
    description: "flag for Poland",
    countryISO: "48",
  },
  {
    countryCode: "PM",
    countryFlag: "🇵🇲",
    unicode: "U+1F1F5 U+1F1F2",
    countryName: "Saint Pierre and Miquelon",
    description: "flag for Saint Pierre and Miquelon",
    countryISO: "508",
  },
  {
    countryCode: "PN",
    countryFlag: "🇵🇳",
    unicode: "U+1F1F5 U+1F1F3",
    countryName: "Pitcairn",
    description: "flag for Pitcairn",
    countryISO: "872",
  },
  {
    countryCode: "PR",
    countryFlag: "🇵🇷",
    unicode: "U+1F1F5 U+1F1F7",
    countryName: "Puerto Rico",
    description: "flag for Puerto Rico",
    countryISO: "1 939",
  },
  {
    countryCode: "PS",
    countryFlag: "🇵🇸",
    unicode: "U+1F1F5 U+1F1F8",
    countryName: "Palestinian Territory",
    description: "flag for Palestinian Territory",
    countryISO: "970",
  },
  {
    countryCode: "PT",
    countryFlag: "🇵🇹",
    unicode: "U+1F1F5 U+1F1F9",
    countryName: "Portugal",
    description: "flag for Portugal",
    countryISO: "351",
  },
  {
    countryCode: "PW",
    countryFlag: "🇵🇼",
    unicode: "U+1F1F5 U+1F1FC",
    countryName: "Palau",
    description: "flag for Palau",
    countryISO: "680",
  },
  {
    countryCode: "PY",
    countryFlag: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
    countryName: "Paraguay",
    description: "flag for Paraguay",
    countryISO: "595",
  },
  {
    countryCode: "QA",
    countryFlag: "🇶🇦",
    unicode: "U+1F1F6 U+1F1E6",
    countryName: "Qatar",
    description: "flag for Qatar",
    countryISO: "974",
  },
  {
    countryCode: "RE",
    countryFlag: "🇷🇪",
    unicode: "U+1F1F7 U+1F1EA",
    countryName: "Réunion",
    description: "flag for Réunion",
    countryISO: "262",
  },
  {
    countryCode: "RO",
    countryFlag: "🇷🇴",
    unicode: "U+1F1F7 U+1F1F4",
    countryName: "Romania",
    description: "flag for Romania",
    countryISO: "40",
  },
  {
    countryCode: "RS",
    countryFlag: "🇷🇸",
    unicode: "U+1F1F7 U+1F1F8",
    countryName: "Serbia",
    description: "flag for Serbia",
    countryISO: "381",
  },
  {
    countryCode: "RU",
    countryFlag: "🇷🇺",
    unicode: "U+1F1F7 U+1F1FA",
    countryName: "Russia",
    description: "flag for Russia",
    countryISO: "7",
  },
  {
    countryCode: "RW",
    countryFlag: "🇷🇼",
    unicode: "U+1F1F7 U+1F1FC",
    countryName: "Rwanda",
    description: "flag for Rwanda",
    countryISO: "250",
  },
  {
    countryCode: "SA",
    countryFlag: "🇸🇦",
    unicode: "U+1F1F8 U+1F1E6",
    countryName: "Saudi Arabia",
    description: "flag for Saudi Arabia",
    countryISO: "966",
  },
  {
    countryCode: "SB",
    countryFlag: "🇸🇧",
    unicode: "U+1F1F8 U+1F1E7",
    countryName: "Solomon Islands",
    description: "flag for Solomon Islands",
    countryISO: "677",
  },
  {
    countryCode: "SC",
    countryFlag: "🇸🇨",
    unicode: "U+1F1F8 U+1F1E8",
    countryName: "Seychelles",
    description: "flag for Seychelles",
    countryISO: "248",
  },
  {
    countryCode: "SD",
    countryFlag: "🇸🇩",
    unicode: "U+1F1F8 U+1F1E9",
    countryName: "Sudan",
    description: "flag for Sudan",
    countryISO: "249",
  },
  {
    countryCode: "SE",
    countryFlag: "🇸🇪",
    unicode: "U+1F1F8 U+1F1EA",
    countryName: "Sweden",
    description: "flag for Sweden",
    countryISO: "46",
  },
  {
    countryCode: "SG",
    countryFlag: "🇸🇬",
    unicode: "U+1F1F8 U+1F1EC",
    countryName: "Singapore",
    description: "flag for Singapore",
    countryISO: "65",
  },
  {
    countryCode: "SH",
    countryFlag: "🇸🇭",
    unicode: "U+1F1F8 U+1F1ED",
    countryName: "Saint Helena, Ascension and Tristan Da Cunha",
    description: "flag for Saint Helena, Ascension and Tristan Da Cunha",
    countryISO: "290",
  },
  {
    countryCode: "SI",
    countryFlag: "🇸🇮",
    unicode: "U+1F1F8 U+1F1EE",
    countryName: "Slovenia",
    description: "flag for Slovenia",
    countryISO: "386",
  },
  {
    countryCode: "SJ",
    countryFlag: "🇸🇯",
    unicode: "U+1F1F8 U+1F1EF",
    countryName: "Svalbard and Jan Mayen",
    description: "flag for Svalbard and Jan Mayen",
    countryISO: "47",
  },
  {
    countryCode: "SK",
    countryFlag: "🇸🇰",
    unicode: "U+1F1F8 U+1F1F0",
    countryName: "Slovakia",
    description: "flag for Slovakia",
    countryISO: "421",
  },
  {
    countryCode: "SL",
    countryFlag: "🇸🇱",
    unicode: "U+1F1F8 U+1F1F1",
    countryName: "Sierra Leone",
    description: "flag for Sierra Leone",
    countryISO: "232",
  },
  {
    countryCode: "SM",
    countryFlag: "🇸🇲",
    unicode: "U+1F1F8 U+1F1F2",
    countryName: "San Marino",
    description: "flag for San Marino",
    countryISO: "378",
  },
  {
    countryCode: "SN",
    countryFlag: "🇸🇳",
    unicode: "U+1F1F8 U+1F1F3",
    countryName: "Senegal",
    description: "flag for Senegal",
    countryISO: "221",
  },
  {
    countryCode: "SO",
    countryFlag: "🇸🇴",
    unicode: "U+1F1F8 U+1F1F4",
    countryName: "Somalia",
    description: "flag for Somalia",
    countryISO: "252",
  },
  {
    countryCode: "SR",
    countryFlag: "🇸🇷",
    unicode: "U+1F1F8 U+1F1F7",
    countryName: "Suriname",
    description: "flag for Suriname",
    countryISO: "597",
  },
  {
    countryCode: "SS",
    countryFlag: "🇸🇸",
    unicode: "U+1F1F8 U+1F1F8",
    countryName: "South Sudan",
    description: "flag for South Sudan",
    countryISO: "211",
  },
  {
    countryCode: "ST",
    countryFlag: "🇸🇹",
    unicode: "U+1F1F8 U+1F1F9",
    countryName: "Sao Tome and Principe",
    description: "flag for Sao Tome and Principe",
    countryISO: "239",
  },
  {
    countryCode: "SV",
    countryFlag: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
    countryName: "El Salvador",
    description: "flag for El Salvador",
    countryISO: "503",
  },
  {
    countryCode: "SX",
    countryFlag: "🇸🇽",
    unicode: "U+1F1F8 U+1F1FD",
    countryName: "Sint Maarten (Dutch Part)",
    description: "flag for Sint Maarten (Dutch Part)",
    countryISO: "721",
  },
  {
    countryCode: "SY",
    countryFlag: "🇸🇾",
    unicode: "U+1F1F8 U+1F1FE",
    countryName: "Syrian Arab Republic",
    description: "flag for Syrian Arab Republic",
    countryISO: "963",
  },
  {
    countryCode: "SZ",
    countryFlag: "🇸🇿",
    unicode: "U+1F1F8 U+1F1FF",
    countryName: "Swaziland",
    description: "flag for Swaziland",
    countryISO: "268",
  },
  {
    countryCode: "TC",
    countryFlag: "🇹🇨",
    unicode: "U+1F1F9 U+1F1E8",
    countryName: "Turks and Caicos Islands",
    description: "flag for Turks and Caicos Islands",
    countryISO: "1 649",
  },
  {
    countryCode: "TD",
    countryFlag: "🇹🇩",
    unicode: "U+1F1F9 U+1F1E9",
    countryName: "Chad",
    description: "flag for Chad",
    countryISO: "235",
  },
  {
    countryCode: "TF",
    countryFlag: "🇹🇫",
    unicode: "U+1F1F9 U+1F1EB",
    countryName: "French Southern Territories",
    description: "flag for French Southern Territories",
    countryCode: "262",
  },
  {
    countryCode: "TG",
    countryFlag: "🇹🇬",
    unicode: "U+1F1F9 U+1F1EC",
    countryName: "Togo",
    description: "flag for Togo",
    countryISO: "228",
  },
  {
    countryCode: "TH",
    countryFlag: "🇹🇭",
    unicode: "U+1F1F9 U+1F1ED",
    countryName: "Thailand",
    description: "flag for Thailand",
    countryISO: "66",
  },
  {
    countryCode: "TJ",
    countryFlag: "🇹🇯",
    unicode: "U+1F1F9 U+1F1EF",
    countryName: "Tajikistan",
    description: "flag for Tajikistan",
    countryISO: "992",
  },
  {
    countryCode: "TK",
    countryFlag: "🇹🇰",
    unicode: "U+1F1F9 U+1F1F0",
    countryName: "Tokelau",
    description: "flag for Tokelau",
    countryISO: "690",
  },
  {
    countryCode: "TL",
    countryFlag: "🇹🇱",
    unicode: "U+1F1F9 U+1F1F1",
    countryName: "Timor-Leste",
    description: "flag for Timor-Leste",
    countryISO: "670",
  },
  {
    countryCode: "TM",
    countryFlag: "🇹🇲",
    unicode: "U+1F1F9 U+1F1F2",
    countryName: "Turkmenistan",
    description: "flag for Turkmenistan",
    countryISO: "993",
  },
  {
    countryCode: "TN",
    countryFlag: "🇹🇳",
    unicode: "U+1F1F9 U+1F1F3",
    countryName: "Tunisia",
    description: "flag for Tunisia",
    countryISO: "216",
  },
  {
    countryCode: "TO",
    countryFlag: "🇹🇴",
    unicode: "U+1F1F9 U+1F1F4",
    countryName: "Tonga",
    description: "flag for Tonga",
    countryISO: "676",
  },
  {
    countryCode: "TR",
    countryFlag: "🇹🇷",
    unicode: "U+1F1F9 U+1F1F7",
    countryName: "Turkey",
    description: "flag for Turkey",
    countryISO: "90",
  },
  {
    countryCode: "TT",
    countryFlag: "🇹🇹",
    unicode: "U+1F1F9 U+1F1F9",
    countryName: "Trinidad and Tobago",
    description: "flag for Trinidad and Tobago",
    countryISO: "1 868",
  },
  {
    countryCode: "TV",
    countryFlag: "🇹🇻",
    unicode: "U+1F1F9 U+1F1FB",
    countryName: "Tuvalu",
    description: "flag for Tuvalu",
    countryISO: "688",
  },
  {
    countryCode: "TW",
    countryFlag: "🇹🇼",
    unicode: "U+1F1F9 U+1F1FC",
    countryName: "Taiwan",
    description: "flag for Taiwan",
    countryISO: "886",
  },
  {
    countryCode: "TZ",
    countryFlag: "🇹🇿",
    unicode: "U+1F1F9 U+1F1FF",
    countryName: "Tanzania",
    description: "flag for Tanzania",
    countryISO: "255",
  },
  {
    countryCode: "UA",
    countryFlag: "🇺🇦",
    unicode: "U+1F1FA U+1F1E6",
    countryName: "Ukraine",
    description: "flag for Ukraine",
    countryISO: "380",
  },
  {
    countryCode: "UG",
    countryFlag: "🇺🇬",
    unicode: "U+1F1FA U+1F1EC",
    countryName: "Uganda",
    description: "flag for Uganda",
    countryISO: "256",
  },
  {
    countryCode: "UM",
    countryFlag: "🇺🇲",
    unicode: "U+1F1FA U+1F1F2",
    countryName: "United States Minor Outlying Islands",
    description: "flag for United States Minor Outlying Islands",
    countryISO: "581",
  },
  {
    countryCode: "US",
    countryFlag: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
    countryName: "United States",
    description: "flag for United States",
    countryISO: "1",
  },
  {
    countryCode: "UY",
    countryFlag: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
    countryName: "Uruguay",
    description: "flag for Uruguay",
    countryISO: "598",
  },
  {
    countryCode: "UZ",
    countryFlag: "🇺🇿",
    unicode: "U+1F1FA U+1F1FF",
    countryName: "Uzbekistan",
    description: "flag for Uzbekistan",
    countryISO: "998",
  },
  {
    countryCode: "VA",
    countryFlag: "🇻🇦",
    unicode: "U+1F1FB U+1F1E6",
    countryName: "Vatican City",
    description: "flag for Vatican City",
    countryISO: "379",
  },
  {
    countryCode: "VC",
    countryFlag: "🇻🇨",
    unicode: "U+1F1FB U+1F1E8",
    countryName: "Saint Vincent and The Grenadines",
    description: "flag for Saint Vincent and The Grenadines",
    countryISO: "1 784",
  },
  {
    countryCode: "VE",
    countryFlag: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
    countryName: "Venezuela",
    description: "flag for Venezuela",
    countryISO: "58",
  },
  {
    countryCode: "VG",
    countryFlag: "🇻🇬",
    unicode: "U+1F1FB U+1F1EC",
    countryName: "Virgin Islands, British",
    description: "flag for Virgin Islands, British",
    countryISO: "1 284",
  },
  {
    countryCode: "VI",
    countryFlag: "🇻🇮",
    unicode: "U+1F1FB U+1F1EE",
    countryName: "Virgin Islands, U.S.",
    description: "flag for Virgin Islands, U.S.",
    countryISO: "1 340",
  },
  {
    countryCode: "VN",
    countryFlag: "🇻🇳",
    unicode: "U+1F1FB U+1F1F3",
    countryName: "Viet Nam",
    description: "flag for Viet Nam",
    countryISO: "84",
  },
  {
    countryCode: "VU",
    countryFlag: "🇻🇺",
    unicode: "U+1F1FB U+1F1FA",
    countryName: "Vanuatu",
    description: "flag for Vanuatu",
    countryISO: "678",
  },
  {
    countryCode: "WF",
    countryFlag: "🇼🇫",
    unicode: "U+1F1FC U+1F1EB",
    countryName: "Wallis and Futuna",
    description: "flag for Wallis and Futuna",
    countryISO: "681",
  },
  {
    countryCode: "WS",
    countryFlag: "🇼🇸",
    unicode: "U+1F1FC U+1F1F8",
    countryName: "Samoa",
    description: "flag for Samoa",
    countryISO: "685",
  },
  {
    countryCode: "XK",
    countryFlag: "🇽🇰",
    unicode: "U+1F1FD U+1F1F0",
    countryName: "Kosovo",
    description: "flag for Kosovo",
    countryISO: "383",
  },
  {
    countryCode: "YE",
    countryFlag: "🇾🇪",
    unicode: "U+1F1FE U+1F1EA",
    countryName: "Yemen",
    description: "flag for Yemen",
    countryISO: "967",
  },
  {
    countryCode: "YT",
    countryFlag: "🇾🇹",
    unicode: "U+1F1FE U+1F1F9",
    countryName: "Mayotte",
    description: "flag for Mayotte",
    countryISO: "262",
  },
  {
    countryCode: "ZA",
    countryFlag: "🇿🇦",
    unicode: "U+1F1FF U+1F1E6",
    countryName: "South Africa",
    description: "flag for South Africa",
    countryISO: "27",
  },
  {
    countryCode: "ZM",
    countryFlag: "🇿🇲",
    unicode: "U+1F1FF U+1F1F2",
    countryName: "Zambia",
    description: "flag for Zambia",
    countryISO: "260",
  },
  {
    countryCode: "ZW",
    countryFlag: "🇿🇼",
    unicode: "U+1F1FF U+1F1FC",
    countryName: "Zimbabwe",
    description: "flag for Zimbabwe",
    countryISO: "263",
  },
];
