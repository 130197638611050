import React from "react";
import "./styles.scss";
import Bottomsheet from "../../../../common/Bottomsheet";
// assets
import loadingImg from "../../../../assets/v2Automation/loadingImg.png";
import sError from "../../../../assets/v2Automation/sError.png";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
const ServerError = (props) => {
  const { isError = false, actionOnBlock = () => {} } = props;
  const [apiFail, setApiFail] = useState(false);
  // const platforms = [{name:,imageUrl}]
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Bottomsheet
      showModal={isError}
      // handleClose={() => handleClose()}
      hideCross={true}
      onblockBackdrop={() => actionOnBlock()}
    >
      <div className="ServerError">
        <div className="ServerError__tapBlock">
          <div className="ServerError__tapBlock_tap" />
        </div>
        <div className="content">
          <div className="content_loadingImg">
            <img src={sError} style={{ width: "100%", height: "100%" }} />
          </div>
          <div className="content_desc">
            Sorry! Our Server is down for a moment. Please try again later.
          </div>
        </div>
        <div onClick={() => actionOnBlock()} className="ServerErrorctaBlock">
          <div className="ServerErrorctaBlock_confirmCTA">Okay</div>
        </div>
      </div>
    </Bottomsheet>
  );
};

export default ServerError;
