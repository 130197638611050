import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/freeTrial.scss";
import joinChannel from "../../../assets/joinChannel.png";
import alreadyDone from "../../../assets/purchase/alreadyExist.svg";
import success from "../../../assets/purchase/success.svg";
import lifetime from "../../../assets/life.png";
import freeTrial from "../../../assets/freeTrial.png";
import { AttentionCustomer } from "./AttentionCustomer";
function FreeTrialScreen(props) {
  let grpName = localStorage.getItem("groupName");
  let data = window?.payload?.preauthLink;
  let url = data?.preauthLink;

  let helpingText1 =
    "If you haven't joined the channel yet, join the channel through the button given below.";
  let helpingText2 =
    "Your joining request is still under review. Please contact the admin.";
  let helpingText3 =
    "If you haven't joined the channel yet.Please join from here.";
  let helpingText4 =
    "Your joining request is still under review. Please fill the form if you haven't filled it yet or contact the admin.";
  let normalHelpingText = "You’re already added to the Telegram Group";

  const handleUrl = () => {
    console.log("formLink:", data?.formLink);
    window.open(data?.formLink);
  };

  const handleThanksSheetClick = (url) => {
    if (url && url?.length) window.open(url, "_blank");
  };
  if (data?.conditionalApproval) {
    const { conditionalApproval, status, formLink, inviteLink } = data;
    return (
      <div className="failure">
        <img src={alreadyDone} alt="" />
        <p className="failure_title">Already Purchased!</p>
        <p className="failure_text">
          {conditionalApproval && status == "pending" && formLink
            ? helpingText1
            : null}
          {conditionalApproval && status == "requested" && formLink
            ? helpingText4
            : null}
          {conditionalApproval &&
          status == "pending" &&
          (!formLink || formLink == "")
            ? helpingText3
            : null}
          {conditionalApproval &&
          status == "requested" &&
          (!formLink || formLink == "")
            ? helpingText2
            : null}
          {!conditionalApproval ? normalHelpingText : null}
        </p>

        {conditionalApproval && status == "requested" && formLink ? (
          <div onClick={() => handleUrl()} className="success1_CTAContainer">
            <img className="success1_CTAIcon" src={joinChannel} />
            <div className="success1_joinText">Fill form</div>
          </div>
        ) : null}
        {(conditionalApproval
          ? status == "pending" && inviteLink && inviteLink != ""
          : inviteLink && inviteLink != "") && (
          <div
            onClick={() => handleThanksSheetClick(inviteLink)}
            className="success1_CTAContainer"
          >
            <img className="success1_CTAIcon" src={joinChannel} />
            <div className="success1_joinText">Click here to Join Channel</div>
          </div>
        )}
        {conditionalApproval && status == "pending" && formLink ? (
          <div>
            <div
              style={{
                width: "100%",
                marginTop: "16px",
                border: "1px dashed #E5E5E5",
              }}
            />
            <AttentionCustomer data={data} />
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className="success">
      <img src={freeTrial} style={{ width: "98px", height: "105px" }} alt="" />
      <br />
      <p className="success_title">
        <b>Already availed free trial</b>
      </p>
      <p className="success_text">
        Your Free trial is over.Please buy the plan to continue.
      </p>
      <div
        onClick={() => handleThanksSheetClick(url)}
        className="success_CTAContainer"
      >
        <div className="success_joinText">Buy Now</div>
      </div>
    </div>
  );
}

export default FreeTrialScreen;
