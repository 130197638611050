import React from "react";
import tele from "../../assets/tele.svg";
import "./TelegramBadge.scss";

function TelegramBadge(props) {
  return (
    <div className="TelegramBadge">
      <img src={tele} alt="cats" height="10px" style={{ marginRight: "5px" }} />
      Telegram Channel
    </div>
  );
}

export default TelegramBadge;
