import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "../../../scss/Statement/SingleStatement.scss";

function SingleStatement({ member, parentRef }) {
  // const { data, singleGroupDetails, singleMessageDetails } = props;
  // const isGroupStatement = window.location.href.includes("group");
  // const badge = {
  //   bgColor: data?.currentPlan?.accessPrice
  //     ? "rgba(82, 176, 98, 0.1)"
  //     : data?.currentPlan?.paymentPeriod?.price
  //     ? "rgba(255, 173, 59, 0.1)"
  //     : "",
  //   color: data?.currentPlan?.accessPrice
  //     ? "rgba(82, 176, 98, 1)"
  //     : data?.currentPlan?.paymentPeriod?.price
  //     ? "rgba(255, 173, 59, 1)"
  //     : "",
  //   type: data?.currentPlan?.accessPrice
  //     ? "Lifetime"
  //     : data?.currentPlan?.paymentPeriod?.price
  //     ? "Subscription"
  //     : "",
  // };

  const getDate = (date) => {
    const newdate = new Date(date);
    const formattedDate = newdate
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, " ");
    return formattedDate;
  };

  return (
    <div ref={parentRef} className="SingleStatement">
      <div className="SingleStatement_Name">{member?.name}</div>
      <div className="SingleStatement_Footer">
        <div className="SingleStatement_Footer_Left">
          <img
            src="https://storage.googleapis.com/cp-prod-whitelabel-assets-as-sth1-gcs-dgte34/production/single/learn/1a2b2220-1589-44c7-b5e4-8f886bafd6cd.svg"
            height="18px"
            width="18px"
            style={{ marginRight: "6px" }}
          />
          <div className="SingleStatement_Footer_Left_Date">
            {getDate(member?.joinedDate)}
          </div>

          {/*{badge ? (
            <div
              className="SingleStatement_Footer_Left_Badge"
              style={{
                backgroundColor: badge?.bgColor,
                color: badge?.color,
              }}
            >
              {badge?.type}
            </div>
            ) : null}*/}
        </div>
        <div className="SingleStatement_Footer_Right">₹{member?.revenue}</div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ singleContentDetails }) => {
  return {
    singleGroupDetails: singleContentDetails?.singleGroupDetails,
    singleMessageDetails: singleContentDetails?.singleMessageDetails,
  };
};

export default connect(mapStateToProps)(SingleStatement);
