import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import ReactLoading from "react-loading";

import attachIcon from "../../assets/attach.svg";
import imageIcon from "../../assets/image.svg";
import cameraIcon from "../../assets/camera.svg";
import fileIcon from "../../assets/file.svg";
import fileAttachIcon from "../../assets/picture.png";
import docFileIcon from "../../assets/doc.png";
import pdfFileIcon from "../../assets/pdf.png";
import cross from "../../assets/cross.svg";
import * as actions from "../../store/CreateMessage/actions";
import { layoutPageHeadingSet } from "../../layout/store/actions";

import Bottomsheet from "../../common/Bottomsheet";
import Button from "../../common/Button";
import Input from "../../common/Input";
import "../../scss/CreateMessage/CreateMessage.scss";
import toast from "react-hot-toast";
import ReactGA from "react-ga";
import EVENTS from "../../utils/gaEvents";

function CreateMessage(props) {
  const {
    isLoading,
    isEdit,
    title,
    description,
    price,
    lockedMessage,
    singleMessageDetails,
    userHasBankDetails,
  } = props;

  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [imageInput, setImageInput] = useState("");
  const [videoInput, setVideoInput] = useState("");
  const [fileInput, setFileInput] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const inputImg = useRef(null);
  const inputVid = useRef(null);
  const inputFile = useRef(null);

  useEffect(() => {
    ReactGA.event({
      category: EVENTS.CREATE_MESSAGE.CATEGORY,
      action: EVENTS.CREATE_MESSAGE.ACTIONS.OPEN_LOCKED_MESSAGE_CREATE,
    });
    props.layoutPageHeadingSet(
      isEdit ? "Edit Locked Message" : "Locked Message Creation"
    );
    if (isEdit) {
      props.setTitle(singleMessageDetails?.title);
      props.setDescription(singleMessageDetails?.description);
      props.setPrice(singleMessageDetails?.messagePrice);
      props.setLockedMessage(singleMessageDetails?.lockedMessage);
    }
  }, []);

  const resetInputs = () => {
    setImageInput("");
    setVideoInput("");
    setFileInput("");
  };

  const handleAddAttachments = (e) => {
    e.preventDefault();
    const newAttachments = [...attachments, ...e.target.files];
    setAttachments([...newAttachments]);
    resetInputs();
    setShowUploadOptions(false);
  };

  const getFileType = (type) => {
    if (type.includes("image")) return "image";
    else if (type.includes("video")) return "video";
    else return "pdf";
  };

  const getFileIcon = (type) => {
    if (type?.includes("image") || type?.includes("video")) {
      return fileAttachIcon;
    } else if (type?.includes("pdf")) {
      return pdfFileIcon;
    } else if (type?.includes("doc")) {
      return docFileIcon;
    } else {
      return fileAttachIcon;
    }
  };

  const removeAttachment = (index) => {
    let newAttachments = [...attachments];
    newAttachments?.splice(index, 1);

    setAttachments([...newAttachments]);
  };

  const createMessage = () => {
    if (!(title?.length && description?.length))
      return toast.error("Please enter the mandatory details");
    if (title?.length < 3 || title?.length > 100) {
      toast.error("Title should be between 3 - 100 characters");
      return;
    }
    if (description?.length < 10 || description?.length > 200) {
      toast.error("Description should be between 10 - 200 characters");
      return;
    }

    if (price?.length && parseFloat(price) <= 0) {
      return toast.error("Price cannot be 0");
    }

    if (!parseFloat(price)) {
      toast.error("Please enter Price");
      return;
    }

    if (lockedMessage?.length < 1 || lockedMessage?.length > 500) {
      toast.error("Please add Locked Text Message");
      return;
    }
    let formData = new FormData();
    for (const attachment of attachments) {
      formData.append("attachments", attachment);
    }
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("lockedMessage", lockedMessage);
    if (isEdit) {
      formData.append("id", id);
    }
    const payload = {
      // title: title,
      // description: description,
      price: price,
      // lockedMessage: lockedMessage,
      // attachments: attachments,
      ...(isEdit
        ? {
            id: id,
          }
        : {}),
      navigate,
    };
    window.payload = formData;
    if (userHasBankDetails) {
      ReactGA.event({
        category: EVENTS.CREATE_MESSAGE.ACTIONS.CREATE_LOCKED_MESSAGE_CLICK,
        action: EVENTS.CREATE_MESSAGE.ACTIONS.CREATE_LOCKED_MESSAGE_CLICK,
      });
      isEdit
        ? props.editMessage(payload, navigate)
        : props.createMessage(formData, navigate);
    } else {
      navigate(`/bank-details`);
    }
  };

  return (
    <div className="CreateMessage">
      <div className="CreateMessage_Label">Title*</div>
      <Input
        placeholder="Add Title*"
        value={title}
        onChange={(e) => props.setTitle(e.target.value)}
        disabled={isEdit}
      />
      <div className="CreateMessage_Label">Description*</div>
      <Input
        inputType="textarea"
        placeholder="Add Description*"
        value={description}
        onChange={(e) => props.setDescription(e.target.value)}
        disabled={isEdit}
      />
      <div className="CreateMessage_Label">Price*</div>
      <Input
        placeholder="Enter Price*"
        type="number"
        value={price}
        onChange={(e) => {
          if (new RegExp(/^[0-9.]*$/).test(e.target.value))
            props.setPrice(e.target.value);
        }}
      />

      <div className="CreateMessage_Attachments">
        <div className="CreateMessage_Label">Add Locked Text Message</div>
        <Input
          inputType="textarea"
          placeholder="Enter Locked Text Message"
          value={lockedMessage}
          onChange={(e) => props.setLockedMessage(e.target.value)}
          disabled={isEdit}
        />

        {attachments?.length ? (
          <div className="CreateMessage_Files">
            {attachments?.map((attachment, index) => (
              <div className="CreateMessage_Files_File" key={attachment.name}>
                <div className="CreateMessage_Files_File_Left">
                  <img
                    src={getFileIcon(attachment?.type)}
                    height="25px"
                    style={{ marginRight: "10px" }}
                  />
                  {attachment.name}
                </div>
                <div className="CreateMessage_Files_File_Right">
                  {!isEdit ? (
                    <img
                      src={cross}
                      height="15px"
                      onClick={() => removeAttachment(index)}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {!isEdit ? (
          <div
            className="CreateMessage_AttachmentButton"
            onClick={() => {
              ReactGA.event({
                category: EVENTS.CREATE_MESSAGE.CATEGORY,
                action: EVENTS.CREATE_MESSAGE.ACTIONS.ADD_CONTENT_CLICK,
              });
              setShowUploadOptions(true);
            }}
          >
            <img
              src={attachIcon}
              height="20px"
              width="20px"
              style={{ marginRight: "6px" }}
            />
            Add Content(s)
          </div>
        ) : null}
      </div>
      <div className="CreateGroup_ButtonContainer">
        {/* <div
          className={`CreateGroup_ButtonContainer_Button ${
            price?.length && parseFloat(price) >= 0 ? "" : "disabled"
          }`}
          onClick={() => {
            if (price?.length && parseFloat(price) >= 0) createMessage();
          }}
        >
          {isLoading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              height={20}
              width={20}
            />
          ) : (
            <>{userHasBankDetails ? (isEdit ? "Save" : "Create") : "Next"}</>
          )}
        </div> */}

        <Button
          disabled={
            isLoading ||
            !(
              title?.length &&
              description?.length &&
              price?.length &&
              lockedMessage?.length
            )
          }
          onClick={createMessage}
        >
          {isLoading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              height={24}
              width={20}
            />
          ) : (
            <>{userHasBankDetails ? (isEdit ? "Save" : "Create") : "Next"}</>
          )}
        </Button>
      </div>

      {showUploadOptions ? (
        <Bottomsheet
          handleClose={() => setShowUploadOptions(false)}
          header="Upload Content"
        >
          <div
            className="CreateMessage_AttachmentButton_Option"
            onClick={() => inputImg?.current?.click()}
          >
            <img
              src={imageIcon}
              height="30px"
              width="30px"
              style={{ marginRight: "16px" }}
            />
            Upload Image(s)
          </div>

          <div
            className="CreateMessage_AttachmentButton_Option"
            onClick={() => inputVid?.current?.click()}
          >
            <img
              src={cameraIcon}
              height="30px"
              width="30px"
              style={{ marginRight: "16px" }}
            />
            Upload Video(s)
          </div>

          <div
            className="CreateMessage_AttachmentButton_Option"
            onClick={() => inputFile?.current?.click()}
          >
            <img
              src={fileIcon}
              height="30px"
              width="30px"
              style={{ marginRight: "16px" }}
            />
            Upload File(s)
          </div>
        </Bottomsheet>
      ) : null}

      <input
        className="CreateMessage_HiddenInput"
        type="file"
        accept="image/*"
        onChange={handleAddAttachments}
        ref={inputImg}
        multiple
        value={imageInput}
      />
      <input
        className="CreateMessage_HiddenInput"
        type="file"
        accept="video/*"
        onChange={handleAddAttachments}
        ref={inputVid}
        multiple
        value={videoInput}
      />
      <input
        className="CreateMessage_HiddenInput"
        type="file"
        accept=".doc,.docx,.pdf,application/msword,application/pdf"
        onChange={handleAddAttachments}
        ref={inputFile}
        multiple
        value={fileInput}
      />
    </div>
  );
}

const mapStateToProps = ({
  createMessage,
  singleContentDetails,
  userDetailsReducer,
}) => {
  return {
    isLoading: createMessage?.isLoading,
    title: createMessage?.title,
    description: createMessage?.description,
    price: createMessage?.price,
    lockedMessage: createMessage?.lockedMessage,

    singleMessageDetails: singleContentDetails?.singleMessageDetails,
    userHasBankDetails: userDetailsReducer?.apiResUSER_DETAILS_GET?.bankDetails,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setTitle: (payload) => dispatch(actions.setTitle(payload)),
    setDescription: (payload) => dispatch(actions.setDescription(payload)),
    setPrice: (payload) => dispatch(actions.setPrice(payload)),
    setLockedMessage: (payload) => dispatch(actions.setLockedMessage(payload)),

    createMessage: (payload, navigate) =>
      dispatch(actions.createMessage(payload, navigate)),
    editMessage: (payload, navigate) =>
      dispatch(actions.editMessage(payload, navigate)),

    resetState: () => dispatch(actions.resetState()),
    layoutPageHeadingSet: (payload) => dispatch(layoutPageHeadingSet(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(CreateMessage);
