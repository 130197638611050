// REDUX CONSTANTS
const namespace = "ADD_BANK_DETAILS";

const CONSTANTS = {
  ADD_BANK_DETAILS_POST: `${namespace}_POST`,
  ADD_BANK_DETAILS_POST_LOADER: `${namespace}_POST_LOADER`,
  ADD_BANK_DETAILS_POST_SUCCESS: `${namespace}_POST_SUCCESS`,
  ADD_BANK_DETAILS_POST_FAILURE: `${namespace}_POST_FAILURE`,

  // get bank accounts
  GET_BANK_ACCOUNTS: `${namespace}GET_BANK_ACCOUNTS`,
  GET_BANK_ACCOUNTS_LOADER: `${namespace}GET_BANK_ACCOUNTS_LOADER`,
  GET_BANK_ACCOUNTS_SUCCESS: `${namespace}GET_BANK_ACCOUNTS_SUCCESS`,
  GET_BANK_ACCOUNTS_FAILURE: `${namespace}GET_BANK_ACCOUNTS_FAILURE`,
  // delete bank account

  DELETE_PRIMARY_BANK_ACCOUNT: `${namespace}DELETE_PRIMARY_BANK_ACCOUNT`,
  DELETE_PRIMARY_BANK_ACCOUNT_LOADER: `${namespace}DELETE_PRIMARY_BANK_ACCOUNT_LOADER`,
  DELETE_PRIMARY_BANK_ACCOUNT_SUCCESS: `${namespace}DELETE_PRIMARY_BANK_ACCOUNT_SUCCESS`,
  DELETE_PRIMARY_BANK_ACCOUNT_FAILURE: `${namespace}DELETE_PRIMARY_BANK_ACCOUNT_FAILURE`,

  // agree action

  AGREE_TERMS_AND_CONDITIONS: `${namespace}AGREE_TERMS_AND_CONDITIONS`,
  AGREE_TERMS_AND_CONDITIONS_LOADER: `${namespace}AGREE_TERMS_AND_CONDITIONS_LOADER`,
  AGREE_TERMS_AND_CONDITIONS_SUCCESS: `${namespace}AGREE_TERMS_AND_CONDITIONS_SUCCESS`,
  AGREE_TERMS_AND_CONDITIONS_FAILURE: `${namespace}AGREE_TERMS_AND_CONDITIONS_FAILURE`,
  AGREE_TERMS_AND_CONDITIONS_RESET: `${namespace}AGREE_TERMS_AND_CONDITIONS_RESET`,
};

export default CONSTANTS;
