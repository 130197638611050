import React, { useState, useEffect } from "react";
import { countryListJson } from "../../utils/countryExtensions";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import Bottomsheet from "../../common/Bottomsheet";
import check from "../../assets/bluecheck.png";
import * as Ipactions from "../../store/GetCountryCode/actions";
import SearchInput from "../SearchInput";
const SelectCountry = ({
  icon,
  accountStatus = null,
  label,
  disabled,
  style,
  inputType = "input",
  onEnter,
  countryListInput = true,
  onCountryListInputClick,
  ...inputProps
}) => {
  const Component = inputType;
  const dispatch = useDispatch();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const selectedCountry = useSelector(
    (state) => state.getCountryCodeReducer.country
  );
  const closeCountryListBottomSheet = useSelector(
    (state) => state.getCountryCodeReducer.closeCountryListBottomSheet
  );
  const [countrySelected, setCountrySelected] = useState(null);
  const [countryList, setCountryList] = useState(countryListJson);
  const [searchterm, setSearchTerm] = useState("");
  console.log("SELECTED COUNTRY IS", selectedCountry);

  useEffect(() => {
    if (selectedCountry.length !== 0) {
      setCountrySelected(selectedCountry[0]);
    }
  }, [selectedCountry]);

  const clickListItem = (item) => {
    console.log("ITEM CLICKED", item);
    dispatch(Ipactions.setCountry(item.countryISO));
    dispatch(Ipactions.setCountryListBottomSheet(true));
  };

  //LOGIC FOR SEARCHING COUNTRY
  const searchCountry = (value) => {
    setSearchTerm(value);
    const results = countryListJson.filter((item) =>
      item?.countryName.toLowerCase().includes(value.toLowerCase())
    );
    setCountryList(results);
  };

  useEffect(() => {
    setSearchTerm("");
    setCountryList(countryListJson);
  }, [closeCountryListBottomSheet]);

  return (
    <React.Fragment>
      {!!label && <label className="inputLabel">{label}</label>}
      <div
        className={
          accountStatus !== null && !accountStatus
            ? "accountInputContainer"
            : "inputContainer"
        }
        style={disabled ? { background: `#e8e8e8` } : {}}
      >
        {countryListInput && (
          <div
            className="inputContainer__countryicon"
            onClick={() => {
              setShowBottomSheet(true);
              dispatch(Ipactions.setCountryListBottomSheet(false));
            }}
          >
            {countrySelected?.countryFlag}
            <span>{`+${countrySelected?.countryISO}`}</span>
          </div>
        )}
        <Component
          style={style}
          disabled={disabled}
          {...inputProps}
          {...(inputProps?.type === "number"
            ? {
                onKeyDown: (e) => {
                  if (e.keyCode == 13 && onEnter) {
                    onEnter();
                  }
                  if (
                    e.key === "ArrowUp" ||
                    e.key === "ArrowDown" ||
                    e.key === "-" ||
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "."
                  )
                    e.preventDefault();
                },
              }
            : {
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    onEnter();
                  }
                },
              })}
        />
      </div>

      {showBottomSheet && (
        <Bottomsheet
          countryList={true}
          blockBackdrop={true}
          onCloseCountryList={() => setShowBottomSheet(false)}
          header={<div>Select a Country</div>}
        >
          <>
            <div className="register__searchcountrybox">
              <div
                className={
                  accountStatus !== null && !accountStatus
                    ? "accountInputContainer"
                    : "inputContainer"
                }
                style={disabled ? { background: `#e8e8e8` } : {}}
              >
                <SearchInput
                  disabled={disabled}
                  value={searchterm}
                  onChange={(e) => searchCountry(e.target.value)}
                  placeholder="Search Country"
                />
              </div>
            </div>
            <div
              style={{
                // backgroundColor: "white",
                // width: "100%",
                // height: "300px",
                overflow: "scroll",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              className="register__selectcountrybox"
            >
              <ul>
                {countryList.map((item) => (
                  <li onClick={() => clickListItem(item)}>
                    <div
                      className={
                        item?.countryISO === countrySelected?.countryISO
                          ? " register__selectcountrybox__listitem register__selectcountrybox__listitem__active"
                          : "register__selectcountrybox__listitem"
                      }
                    >
                      <div className="register__selectcountrybox__listitem__text">
                        <span className="register__selectcountrybox__listitem__text__flag">
                          {item.countryFlag}
                        </span>
                        <span className="register__selectcountrybox__listitem__text__country">
                          {item.countryName}
                        </span>
                        <span className="register__selectcountrybox__listitem__text__code">
                          {`(+ ${item.countryISO})`}
                        </span>
                      </div>

                      {item?.countryISO === countrySelected?.countryISO && (
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={true}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        </Bottomsheet>
      )}
    </React.Fragment>
  );
};

export default SelectCountry;
