import CONSTANTS from "./constants";

const initialState = {
  groupName: null,
  telegramGroupId: null,
};

const setAutomatedChannel = (state, action) => {
  console.log("reducer: ", action);
  return {
    ...state,
    groupName: action.payload.channelName,
    telegramGroupId: action.payload.channelID,
  };
};

const setTelegramChannelId = (state, action) => {
  return {
    ...state,
    telegramGroupId: action.payload,
  };
};

const clearTelegramChannelId = (state, action) => {
  return {
    ...state,
    telegramGroupId: null,
    groupName: null,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_TELEGRAM_ID:
      return setTelegramChannelId(state, action);
    case CONSTANTS.CLEAR_TELEGRAM_ID:
      return clearTelegramChannelId(state, action);
    case CONSTANTS.SET_AUTOMATED_CHANNEL:
      return setAutomatedChannel(state, action);
    default:
      return state;
  }
};

export default reducer;
