import React from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { layoutPageHeadingSet } from "../../../layout/store/actions";
import Popup from "reactjs-popup";
// assets
import plusBold from "../../../assets/multibank/plusBold.png";
import bank from "../../../assets/multibank/bank.png";
import threeDots from "../../../assets/allCoupons/CouponCard/threeDots.png";
import dlt from "../../../assets/allCoupons/CouponCard/dlt.png";
import primaryTick from "../../../assets/multibank/primaryTick.png";
import x from "../../../assets/x.png";
import agreeTick from "../../../assets/multibank/agreeTick.png";
import { useState } from "react";
import { useRef } from "react";
import * as actions from "../store/actions";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import Button from "../../../common/Button";
import { termsString } from "./terms";
function MultiBank(props) {
  const {
    getBankAccountsAction,
    bankAccounts,
    isLoading,
    bankAccountsError,
    deletePrimaryAccountsError,
    deletePrimaryAccounts,
    isDeleteLoading,
    agreeSuccessData,
    agreeFailureData,
    isAgreeLoading,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const [optionMenu, setOptionMenu] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false);
  const [asPrimary, setAsPrimary] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [bottom, setBottom] = useState(true);
  // bank data state
  const [accountData, setAccountData] = useState(null);
  const [deleteOption, setDeleteOption] = useState(false);
  // refs
  const openTooltip = () => ref?.current?.open();
  const closeTooltip = () => ref?.current?.close();
  const toggleTooltip = () => ref?.current?.toggle();
  const openTerms = () => ref1?.current?.open();
  const closeTerms = () => ref1?.current?.close();
  const toggleTerms = () => ref1?.current?.toggle();
  const openThree = () => ref2?.current?.open();
  const closeThree = () => ref2?.current?.close();

  //
  const userHasEmailDetails = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET
  );

  useEffect(() => {
    dispatch(layoutPageHeadingSet("Bank Accounts"));
  }, []);
  useEffect(() => {
    // props.agreeTermsAndConditonsResetAction();
    if (bankAccounts == null) {
      props.getBankAccountsAction();
    }
  }, []);
  useEffect(() => {
    if (bankAccounts) {
      setAccountData(bankAccounts);
      // console.log("datincomp", bankAccounts);
    } else if (bankAccountsError) {
      setAccountData(null);
    }
  }, [bankAccounts, bankAccountsError]);
  useEffect(() => {
    if (deletePrimaryAccounts) {
      closeTooltip();
      closeTerms();
      setOptionOpen(false);
      props.getBankAccountsAction();
    } else if (deletePrimaryAccountsError) {
      closeTooltip();
      closeThree();
      closeTerms();
      setOptionOpen(false);
      toast.error("Something went wrong. Please try again...");
    }
  }, [deletePrimaryAccounts, deletePrimaryAccountsError]);
  useEffect(() => {
    if (agreeSuccessData && selectedAccount) {
      let payload = {};
      if (deleteOption) {
        payload = {
          isDeleted: 1,
          bankDetailId: selectedAccount?.id,
        };
      } else {
        payload = {
          isPrimary: 1,
          bankDetailId: selectedAccount?.id,
        };
      }

      props.updateBankAccountsAction(payload);
      // props.agreeTermsAndConditonsResetAction("");
    } else if (agreeFailureData) {
      toast.error("Something went wrong");
    }
  }, [agreeSuccessData, agreeFailureData]);
  const addBankAccountPage = () => {
    if (bankAccounts?.length == 0) {
      navigate("/bank-details", {
        state: {
          email: userHasEmailDetails?.userDetails?.email,
          cameFromBankAccounts: true,
        },
      });
    } else {
      navigate("/bank-details");
    }
  };
  const bankDetailPage = (item) => {
    navigate("/bank-account-details", {
      state: {
        data: item,
      },
    });
  };
  const deleteAccount = (item) => {
    setDeleteOption(true);
    setSelectedAccount(item);
    closeThree();
    let payload = {
      accountId: item?.id,
      action: "AccountDeleted",
    };
    props.agreeTermsAndConditonsAction(payload);
  };

  const agreeAction = (item) => {
    // console.log("actionsfje")
    closeThree();
    setDeleteOption(false);
    setSelectedAccount(item);
    let payload = {
      accountId: item?.id,
      action: "T&CAccepted",
    };
    props.agreeTermsAndConditonsAction(payload);
  };

  return (
    <div className="bankAccount">
      {/* {console.log("accountddd", accountData)} */}
      {accountData &&
        accountData.map((item) => {
          return (
            <div className={item?.isPrimary ? "primary" : "accounts"}>
              <div
                onClick={() => bankDetailPage(item)}
                className="accounts_item"
              >
                <div className="accounts_iconBox">
                  <div className="icon">
                    <img src={bank} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
                <div className="accounts_accountDetails">
                  <div className="accounts_accountDetails_accountNumber">
                    {item?.accountNumber
                      ? item?.accountNumber.replace(/.(?=.{4})/g, "x")
                      : null}
                    {item?.isPrimary ? (
                      <div className="primaryTag">
                        <span className="primaryTag_text">Primary</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="accounts_accountDetails_emailText">
                    {item?.emailId ? item?.emailId : null}
                  </div>
                </div>
              </div>
              {/* three dots option */}
              {!item?.isPrimary ? (
                <div className="threeDotsBox">
                  <Popup
                    ref={ref2}
                    position={"bottom right"}
                    contentStyle={{ width: "160px" }}
                    arrow={false}
                    trigger={
                      <div
                        onClick={() => {
                          openThree();
                          setDeleteOption(false);
                        }}
                        className="threeDotsBox_icon"
                      >
                        <img
                          src={threeDots}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    }
                    nested
                  >
                    {/* set as primary */}
                    <Popup
                      ref={ref1}
                      trigger={
                        <div
                          onClick={openTerms}
                          className="optionMenu_optionItem"
                        >
                          <div className="optionMenu_optionItem_optionIcon">
                            <img
                              src={primaryTick}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div className="optionMenu_text">Set as Primary</div>
                        </div>
                      }
                      modal
                      contentStyle={{
                        borderRadius: "6px",
                        width: "320px",
                        height: "600px",
                        borderRadius: "12px 12px 0px 0px",
                        overflowY: "scroll",
                        padding: "0px",
                      }}
                      nested
                    >
                      <div
                        // onScroll={handleScroll}
                        className="termsAndConditions"
                      >
                        <div className="termsAndConditions_header">
                          <div className="termsAndConditions_text">
                            Terms and Conditions
                          </div>
                          <div
                            onClick={closeTerms}
                            className="termsAndConditions_img"
                          >
                            <img
                              src={x}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="termsAndConditions_breakLine" />
                        <div className="termsAndConditions_content">
                          {termsString.map((item) => {
                            return (
                              <div className="termsAndConditions_content_item">
                                <div className="termsAndConditions_content_item_description">
                                  {item}
                                </div>
                              </div>
                            );
                          })}
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                      <div className="termsAndConditions_agreeBox">
                        {isAgreeLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignSelf: "center",
                              // backgroundColor:"red",
                              width: "100%",
                            }}
                          >
                            <ReactLoading
                              type={"spin"}
                              color={"#2278ff"}
                              height={34}
                              width={30}
                            />
                          </div>
                        ) : (
                          <div
                            className="termsAndConditions_agreeBox_cta"
                            onClick={() => agreeAction(item)}
                          >
                            <span style={{ color: "white" }}>I agree</span>
                            <div className="termsAndConditions_agreeBox_img">
                              <img
                                src={agreeTick}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Popup>

                    <div style={{ marginTop: "8px" }} />
                    {/* delete  */}
                    <Popup
                      ref={ref}
                      trigger={
                        <div
                          onClick={openTooltip}
                          className="optionMenu_optionItem"
                        >
                          <div className="optionMenu_optionItem_optionIcon">
                            <img
                              src={dlt}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div className="optionMenu_text">Delete</div>
                        </div>
                      }
                      modal
                      contentStyle={{ borderRadius: "6px", width: "300px" }}
                    >
                      <div className="deleteModal">
                        <div className="deleteModal_text">
                          Are you sure you want to delete this bank account?
                        </div>
                        <div className="deleteModal_breakLine" />
                        <div className="deleteButton">
                          <div
                            onClick={closeTooltip}
                            className="deleteButton_cancel"
                          >
                            Cancel
                          </div>
                          <div
                            onClick={() => deleteAccount(item)}
                            className="deleteButton_Delete"
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    </Popup>
                    {/* end */}
                  </Popup>
                </div>
              ) : null}
            </div>
          );
        })}
      <div onClick={() => addBankAccountPage()} className="addBankAccount">
        <div className="addBankAccount_iconBox">
          <div className="icon">
            <img src={plusBold} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="addBankAccount_headText">Add Bank Account</div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ bankDetailsReducer }) => {
  return {
    bankAccounts: bankDetailsReducer?.bankAccounts,
    isLoading: bankDetailsReducer?.isLoading,
    bankAccountsError: bankDetailsReducer?.bankAccountsError,
    deletePrimaryAccounts: bankDetailsReducer?.deletePrimaryAccounts,
    deletePrimaryAccountsError: bankDetailsReducer?.deletePrimaryAccountsError,
    isDeleteLoading: bankDetailsReducer?.isDeleteLoading,
    agreeSuccessData: bankDetailsReducer?.agreeSuccessData,
    agreeFailureData: bankDetailsReducer?.agreeFailureData,
    isAgreeLoading: bankDetailsReducer?.isAgreeLoading,
  };
};
const mapDispatchWithProps = (dispatch) => {
  return {
    getBankAccountsAction: (payload) =>
      dispatch(actions.getBankAccountsAction(payload)),
    updateBankAccountsAction: (payload) =>
      dispatch(actions.updateBankAccountsAction(payload)),
    agreeTermsAndConditonsAction: (payload) =>
      dispatch(actions.agreeTermsAndConditonsAction(payload)),
    agreeTermsAndConditonsResetAction: (payload) =>
      dispatch(actions.agreeTermsAndConditonsResetAction(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(MultiBank);
