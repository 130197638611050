import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useBodyOverflowHidden from "../../../customHooks/useBodyOverflowHidden";
import useOutsideItemClick from "../../../customHooks/useOutsideItemClick";
import { layoutSideBarCloseAction } from "../../store/actions";
import "./styles.scss";

import sidebarEarningsIcon from "../../../assets/sidebarEarningsIcon.svg";
import sidebarProfileIcon from "../../../assets/sidebarProfileIcon.svg";
import sidebarLogoutIcon from "../../../assets/sidebarLogoutIcon.svg";
import sideBarAccount from "../../../assets/sideBarAccount.png";
import SideBarItem from "./sidebarItem";
import { useNavigate } from "react-router-dom";
import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
import { COMMON_DEEPLINK_ACTIONS } from "../../../utils/constants";
import ReactGA from "react-ga";
import EVENTS from "../../../utils/gaEvents";

const SideBar = () => {
  // REDUX DISPATCH
  const dispatch = useDispatch();

  useBodyOverflowHidden();

  const documentEvent = (e) => {
    if (e.target.className === "sideBarWrapper") {
      dispatch(layoutSideBarCloseAction());
    }
  };

  useOutsideItemClick(documentEvent);

  // REACT ROUTER HOOKS
  const navigate = useNavigate();

  // REDUX SELECTORS
  const userDetails = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.userDetails
  );

  // SIDEBAR ITEMS ARRAY
  const sideBarItems = [
    {
      id: 0,
      icon: sideBarAccount,
      label: "My Profile",
      isHighlighted: 0,
      handleClick: () => {
        ReactGA.event({
          category: EVENTS.HOME.CATEGORY,
          action: EVENTS.HOME.ACTIONS.MORE_BANK_DETAILS_CLICKED,
        });
        dispatch(layoutSideBarCloseAction());
        navigate(`/profile`);
      },
    },
    {
      id: 0,
      icon: sidebarProfileIcon,
      label: "Bank Details",
      isHighlighted: 0,
      handleClick: () => {
        ReactGA.event({
          category: EVENTS.HOME.CATEGORY,
          action: EVENTS.HOME.ACTIONS.MORE_BANK_DETAILS_CLICKED,
        });
        dispatch(layoutSideBarCloseAction());
        navigate(`/bank-accounts`);
      },
    },
    {
      id: 1,
      icon: sidebarEarningsIcon,
      label: "Earnings",
      isHighlighted: 0,
      handleClick: () => {
        ReactGA.event({
          category: EVENTS.HOME.CATEGORY,
          action: EVENTS.HOME.ACTIONS.MORE_EARNINGS_CLICKED,
        });
        dispatch(layoutSideBarCloseAction());
        navigate(`/earnings`);
      },
    },
    {
      id: 2,
      icon: sidebarLogoutIcon,
      label: "Sign Out",
      isHighlighted: 0,
      handleClick: () => {
        ReactGA.event({
          category: EVENTS.HOME.CATEGORY,
          action: EVENTS.HOME.ACTIONS.MORE_SIGNOUT_CLICK,
        });
        dispatch(layoutSideBarCloseAction());
        hitDeeplinkExecutedV2(
          JSON.stringify({
            screen: COMMON_DEEPLINK_ACTIONS.UTIL_LOG_OUT,
          }),
          JSON.stringify({
            screen: COMMON_DEEPLINK_ACTIONS.UTIL_LOG_OUT,
          })
        );
      },
    },
  ];

  return (
    <div className="sideBarWrapper">
      <div className="sideBarWrapper__sideBar">
        <div className="sideBarWrapper__sideBar__top">
          <h3>{userDetails?.name}</h3>
          {userDetails?.telegramUsername && (
            <p>{userDetails?.telegramUsername}</p>
          )}
        </div>
        <div className="sideBarWrapper__sideBar__bottom">
          {sideBarItems?.map?.((item, index) => (
            <SideBarItem
              key={item?.id || index}
              icon={item?.icon}
              label={item?.label}
              isHighlighted={item?.isHighlighted}
              handleClick={item?.handleClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
