import { put, call, select, takeLatest } from "redux-saga/effects";
import * as services from "./service";
import CONSTANTS from "./constants";

function* getGroupDetails(payload) {
  let v2Enabled = yield select((state) => state?.user?.v2Enabled);
  payload.v2Enabled = v2Enabled;
  yield put({
    type: CONSTANTS.SET_IS_LOADING,
    payload: true,
  });
  const response = yield call(services.getGroupDetailsService, payload);
  switch (response && response.status) {
    case 200:
      yield put({
        type: CONSTANTS.GET_CONTENT_DETAILS_SUCCESS,
        content: 1,
        payload: response?.data?.data,
      });
      break;
    case 400:
      yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: false,
      });
    default:
      return;
  }
}

function* getMessageDetails(payload) {
  yield put({
    type: CONSTANTS.SET_IS_LOADING,
    payload: true,
  });
  const response = yield call(services.getMessageDetailsService, payload);
  switch (response && response.status) {
    case 200:
      yield put({
        type: CONSTANTS.GET_CONTENT_DETAILS_SUCCESS,
        content: 2,
        payload: response?.data?.data,
      });
      break;
    case 400:
      yield put({
        type: CONSTANTS.SET_IS_LOADING,
        payload: false,
      });
    default:
      return;
  }
}

function* getStatementData(payload) {
  yield put({
    type: CONSTANTS.SET_IS_LOADING,
    payload: true,
  });

  const response = yield call(services.getStatementService, payload);
  const { members } = response?.data?.data;
  if (!members.length) {
    console.log("repsonsedataChecker", response?.data?.data?.members);
    yield put({
      type: CONSTANTS.CHECK_DATA_MEMBER,
      payload: true,
    });
  }
  switch (response && response.status) {
    case 200:
      yield put({
        type: CONSTANTS.GET_STATEMENT_SUCCESS,
        payload: response?.data?.data,
        offset: payload?.payload?.offset,
      });
      break;
    case 400:
    // yield put({
    //     type: CONSTANTS.SET_IS_LOADING,
    //     payload: false
    // })
    default:
      return;
  }
}
function* getAllCouponDataSaga(data) {
  // const loaderAction = yield call(
  //   getActionObject,
  //   CONSTANTS.ALL_COUPONS_LOADER
  // );
  // yield put({ ...loaderAction });
  try {
    const apiCall = yield call(services.getAllCouponsServices, data?.payload);
    // console.log("apiDataa", apiCall);
    if (apiCall.status == 200) {
      yield put({
        type: CONSTANTS.ALL_COUPONS_SUCCESS,
        payload: apiCall?.data?.data,
      });
    } else {
      yield put({
        type: CONSTANTS.ALL_COUPONS_FAILURE,
        payload: "Data Not Found",
      });
    }
  } catch (err) {
    console.log("catch err", err);
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.GET_GROUP_DETAILS, getGroupDetails);
  yield takeLatest(CONSTANTS.GET_MESSAGE_DETAILS, getMessageDetails);
  yield takeLatest(CONSTANTS.GET_STATEMENT, getStatementData);
  yield takeLatest(CONSTANTS.ALL_COUPONS_GET, getAllCouponDataSaga);
}
