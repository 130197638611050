import React from "react";
import "./styles/telegramAutomation.scss";
import telegramLogo from "../../../assets/Automation/telegramLogo.png";
import add from "../../../assets/Automation/add.png";
import link from "../../../assets/Automation/link.png";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAutomationBotLink } from "../../../store/CreateGroup/actions";
import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
import { setChannelType } from "../../../store/User/action";
const TelegramAutomation = (props) => {
  useLayoutPageHeading("Telegram Automation");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isIOS = () => {
    if (typeof window !== "undefined") {
      return !/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
        window?.navigator?.userAgent
      );
    } else return true;
  };
  const createNew = () => {
    const tabs = params?.tabEnum;
    dispatch(setChannelType("telegramChannel"));
    navigate(`/group/create`);
  };
  const linkExisting = () => {
    // if (isIOS()) {
    //   hitDeeplinkExecutedV2(
    //     JSON.stringify({
    //       screen: "UTIL_BROWSER",
    //       paramOne: "https://forms.gle/vwsmUhcTYFnx2NuR6",
    //     }),
    //     JSON.stringify({
    //       screen: "UTIL_BROWSER",
    //       paramOne: "https://forms.gle/vwsmUhcTYFnx2NuR6",
    //     })
    //   );
    // } else {
    console.log("linkExisting");
    dispatch(setChannelType("telegramExisting"));
    dispatch(getAutomationBotLink(""));
    navigate("/telegramExistingChannel");
  };

  return (
    <div className="TelegramAutomation">
      {/* create new block */}
      <div
        onClick={() => {
          createNew();
        }}
        className="TelegramAutomation_blocks"
      >
        <div className="TelegramAutomation_outerBlock">
          <div className="TelegramAutomation_logoTextBlock">
            <img src={telegramLogo} />
            <div className="TelegramAutomation_headText">Create New</div>
          </div>
          <div className="TelegramAutomation_optionLogo">
            <img src={add} />
          </div>
        </div>
        <div className="TelegramAutomation_description">
          Create new Telegram Channel or group & automate your payments, user
          addition & removal
        </div>
      </div>

      {/* link existing */}
      <div onClick={linkExisting} className="TelegramAutomation_blocks">
        <div className="TelegramAutomation_outerBlock">
          <div className="TelegramAutomation_logoTextBlock">
            <img src={telegramLogo} />
            <div className="TelegramAutomation_headText">Link Existing</div>
            {/* {isIOS() ? (
              <div className="TelegramAutomation_Beta">BETA</div>
            ) : null} */}
          </div>
          <div className="TelegramAutomation_optionLogo">
            <img src={link} />
          </div>
        </div>
        <div className="TelegramAutomation_description">
          Link existing Telegram Channel & automate your payments, user addition
          & removal{" "}
        </div>
      </div>
    </div>
  );
};
export default TelegramAutomation;
