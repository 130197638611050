const namespace = "CREATE_COUPON_";

export default {
  SET_NAME: `${namespace}SET_NAME`,
  SET_AMOUNT: `${namespace}SET_AMOUNT`,
  SET_TYPE: `${namespace}SET_TYPE`,
  CREATE_COUPON: `${namespace}CREATE_COUPON`,
  CREATE_COUPON_FAILURE: `${namespace}CREATE_COUPON_FAILURE`,
  CREATE_COUPON_GET: `${namespace}CREATE_COUPON_GET`,
  RESET: `${namespace}RESET`,
};
