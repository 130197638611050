import CONSTANTS from "./constants";

export const getMyEarningsAction = (payload) => {
  return {
    type: CONSTANTS.MY_EARNINGS_GET,
    payload: payload,
  };
};

export const clearEarningsData = (payload) => {
  return {
    type: CONSTANTS.MY_EARNINGS_GET_CLEAR,
    payload: payload,
  };
};

export const getChannelEarningsAction = (payload) => {
  return {
    type: CONSTANTS.CHANNEL_EARNINGS_GET,
    payload: payload,
  };
};

export const clearChannelEarningsData = (payload) => {
  return {
    type: CONSTANTS.CHANNEL_EARNINGS_GET_CLEAR,
    payload: payload,
  };
};

export const setVersion2Action = (payload) => {
  return {
    type: CONSTANTS.SET_VERSION_2,
    payload,
  };
};
