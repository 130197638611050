import React, { useEffect, useState } from "react";
import "../../scss/PreAuth/PreAuth.scss";
import Button from "../../common/Button";
import * as actions from "../../store/Purchase/actions";
import { connect } from "react-redux";
import CustomUserAvatar from "../../common/CustomUserAvator";
import logo from "../../assets/logo-trimmed.png";
import gplay from "../../assets/gplay.svg";
import shield from "../../assets/shield.png";
import Accordion from "../Purchase/components/Accordian";
import { hitDeeplinkExecutedV2 } from "../../utils/hitDeeplink";
import { useNavigate } from "react-router-dom";
import TelegramBadge from "../../common/TelegramBadge";
import CategoryBadge from "../../common/CategoryBadge";
import calendar from "../../assets/calendar.png";
import LifetimeSubscription from "../Purchase/components/LifetimeSubscription";
import moment from "moment";
import useScript from "../Purchase/components/Payment/useScript";
import SuccessPopup from "../Purchase/components/successPopup";
import FailurePopup from "../Purchase/components/failurePopup";
import Inprogress from "../Purchase/components/inprogress";
import Loading from "../Purchase/components/Loading";
import AlreadyDone from "../Purchase/components/alreadyDone";
import SuccessPage from "../Purchase/components/successPage";
import Register from "../Purchase/components/register";
import Otp from "../Purchase/components/otp";
// assets
import dShape from "../../assets/endUser/dShape.png";
import dShapeBlck from "../../assets/endUser/dShapeBlck.png";
import x from "../../assets/x.png";
import edit from "../../assets/endUser/edit.png";
import info from "../../assets/endUser/info.png";
import ReactLoading from "react-loading";
// import Input from "../../common/Input";
import CouponInput from "../../common/CouponInput";
import ReadMore from "../../common/ReadMoreText";
import ChannelBadge from "../../common/ChannelBadge";
import PayPageStaticContent from "../Purchase/components/PaymentPage/PayPageContent";
import PayHeader from "../Purchase/components/PaymentPage/PayHeader";
import GroupDescription from "../Purchase/components/PaymentPage/GroupDescription";
import ExpiryDate from "./expiryDate";
function PreAuth(props) {
  let {
    setPurchaseScreen,
    handlePricingChange,
    pricingPayload,
    groupData,
    groupDataError,
    getGroupData,
    preAuthAction,
    purchaseScreen,
    applyCouponSuccess,
    applyCouponFailure,
    setCouponCodeValue,
    couponCode,
    applyCouponLoader,
    v2Enabled,
    resetCoupon,
    resetCouponError,
  } = props;
  let sm = [];
  const navigate = useNavigate();
  let [disabled, setDisabled] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const messageRouteFlag = window.location.pathname.includes("/m");
  const [currentPrice, setCurrentPrice] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedOffer, setSelectedOffer] = useState("");
  const [discount, setDiscount] = useState(0);
  const [delayLoader, setDelayLoader] = React.useState(true);

  // expiry block
  const [expiryDate, setExpiryDate] = useState("");
  const [diff, setDiff] = useState(0);
  const [currentplanDate, setCurrentPlanDate] = useState("");
  const [indexx, setIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(true);
  const [gId, setGroupId] = useState();
  const [lifetimeSubscription, setLifetimeSunscription] = useState(false);

  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [groupCurrentPlanV2, setGroupCurrentPlanV2] = useState(null);

  const [extraoff, setExtraoff] = useState(null);
  const [couponFinalPrice, setCouponFinalPrice] = useState(null);
  // coupon states
  const [applyBlockOpen, setApplyBlockOpen] = useState(false);
  const [codeApplied, setCodeApplied] = useState(false);
  // const [couponCode,setCouponCode]= useState();
  const [couponError, setCouponError] = useState(false);
  const [successCode, setSuccessCode] = useState(false);
  const [detailSheet, setDetailSheet] = useState(false);
  const [priceDetailSheetDesktop, setPriceDetailSheetDesktop] = useState(false);
  const [newStatus, setNewStatus] = useState(false);
  const [newData, setNewData] = useState(null);

  //plan orice
  const [codeText, setCodeText] = useState("");
  //price detail data and final price
  const [planPriceCoupon, setPlanPriceCoupon] = useState("");
  const [planDiscountCoupon, setPlanDiscountCoupon] = useState("");
  const [couponDiscountPlan, setCouponDiscountPlan] = useState("");
  const [finalPriceCoupon, setFinalPriceCoupon] = useState("");

  useScript("https://checkout.razorpay.com/v1/checkout.js");

  useEffect(() => {
    if (v2Enabled != null) {
      window.scrollTo(0, 0);
      let uid = window.location.pathname.split("/").pop();
      // console.log("uuiddd", uid);
      getGroupData({ id: uid, v2Enabled: v2Enabled });
    }
  }, [v2Enabled]);
  useEffect(() => {
    if (applyCouponSuccess) {
      setApplyBlockOpen(false);
      setSuccessCode(true);
      setCouponError(false);
      setPlanPriceCoupon(applyCouponSuccess?.currentPlanData?.planPrice);
      setPlanDiscountCoupon(applyCouponSuccess?.currentPlanData?.planDiscount);
      setFinalPriceCoupon(applyCouponSuccess?.currentPlanData?.finalPrice);
      setCouponDiscountPlan(
        applyCouponSuccess?.currentPlanData?.couponDiscount
      );
    } else if (applyCouponFailure) {
      setCouponError(true);
      setApplyBlockOpen(true);
      setSuccessCode(false);
      setNewData(null);
      setNewStatus(false);
      setExtraoff(null);
      setCouponFinalPrice(null);
    }
  }, [applyCouponSuccess, applyCouponFailure, applyCouponLoader]);
  useEffect(() => {
    if (
      applyCouponSuccess &&
      applyCouponSuccess?.allPlansData &&
      applyCouponSuccess?.allPlansData.length > 0
    ) {
      let couponsTobeApplied = [];
      couponsTobeApplied.push(selectedPlan?.id);
      for (let i = 0; i < applyCouponSuccess?.allPlansData.length; i++) {
        couponsTobeApplied.push(applyCouponSuccess?.allPlansData[i].planId);
      }
      let copy = [];
      let cnData = JSON.parse(groupData.group.subscriptionPlan);
      for (let i = 0; i < cnData.length; i++) {
        if (selectedPlan?.id == cnData[i].id) {
          let data = applyCouponSuccess?.currentPlanData;
          let oldData = { ...cnData[i] };
          oldData["couponData"] = data;
          copy.push(oldData);
          continue;
        }

        if (couponsTobeApplied.indexOf(cnData[i].id) >= 0) {
          let data = applyCouponSuccess?.allPlansData.find(
            (couponData) => couponData.planId == cnData[i].id
          );
          let oldData = { ...cnData[i] };
          oldData["couponData"] = data;
          copy.push(oldData);

          continue;
        }
        copy.push(cnData[i]);
      }

      if (couponsTobeApplied.indexOf(currentPlanId) >= 0) {
        let couponData = applyCouponSuccess?.allPlansData.find(
          (plan) => plan.planId == currentPlanId
        );
        if (couponData) {
          let data1 = { ...groupCurrentPlanV2, couponData };
          setGroupCurrentPlanV2(data1);
          setExtraoff(couponData?.couponDiscount);
          setCouponFinalPrice(couponData?.finalPrice);
        } else {
          let couponData = applyCouponSuccess?.currentPlanData;
          let data1 = { ...groupCurrentPlanV2, couponData };
          setGroupCurrentPlanV2(data1);
          setExtraoff(applyCouponSuccess?.currentPlanData.couponDiscount);
          setCouponFinalPrice(applyCouponSuccess?.currentPlanData?.finalPrice);
        }
      }
      setNewData(copy);
    }
  }, [applyCouponSuccess]);
  useEffect(() => {
    if (resetCouponError) {
      setNewData(null);
      setNewStatus(false);
      setExtraoff(null);
      setPlanPriceCoupon("");
      setPlanDiscountCoupon("");
      setCouponDiscountPlan("");
      setFinalPriceCoupon("");
      setApplyBlockOpen(false);
      setCodeApplied(false);
      setCouponError(false);
      setSuccessCode(false);
      setDetailSheet(false);
      props.resetCoupon(true);
      props.setCouponCodeValue("");
      setGroupCurrentPlanV2(JSON.parse(groupData?.memberdata?.currentPlan));
    }
  }, [resetCouponError]);
  useEffect(() => {
    // console.log("newDataStatus", sm, sm.length);
    if (newData && newData.length > 0) {
      setNewStatus(true);
    } else {
      setNewStatus(false);
    }
  }, [newData]);
  useEffect(() => {
    if (selectedPlan) {
      if (selectedPlan?.couponData) {
        if (selectedPlan?.id == currentPlanId) {
          // console.log("sfsdgsd", applyCouponSuccess?.currentPlanData);
          setPlanPriceCoupon(applyCouponSuccess?.currentPlanData?.planPrice);
          setPlanDiscountCoupon(
            applyCouponSuccess?.currentPlanData?.planDiscount
          );
          setFinalPriceCoupon(applyCouponSuccess?.currentPlanData?.finalPrice);
          setCouponDiscountPlan(
            applyCouponSuccess?.currentPlanData?.couponDiscount
          );
        }
        setPlanPriceCoupon(selectedPlan?.couponData?.planPrice);
        setPlanDiscountCoupon(selectedPlan?.couponData?.planDiscount);
        setFinalPriceCoupon(selectedPlan?.couponData?.finalPrice);
        setCouponDiscountPlan(selectedPlan?.couponData?.couponDiscount);
      } else {
        setApplyBlockOpen(false);
        setCodeApplied(false);
        setCouponError(false);
        setSuccessCode(false);
        setDetailSheet(false);
        setNewData(null);
        setNewStatus(false);
        setExtraoff(null);
        setCouponFinalPrice(null);
        props.resetCoupon(true);
        props.setCouponCodeValue("");
      }
    }
  }, [selectedPlan]);
  // useEffect(() => {
  //   if (couponCode && couponCode.length > 0) {
  //     setCodeApplied(true);
  //   } else {
  //     setCodeApplied(false);
  //   }
  // }, [couponCode]);
  const applyCouponAction = () => {
    // let stringPlan= {
    //   plan:JSON.stringify(selectedPlan)
    // }
    // console.log("sleedsf",selectedPlan);
    let payload = {
      couponName: couponCode,
      groupId: selectedPlan?.groupId,
      plan: selectedPlan,
    };
    // console.log("payloadd",payload);
    props.applyCouponApi(payload);
  };
  const onChangeValue = (value) => {
    setCouponCodeValue(value);
  };
  React.useEffect(() => {
    setTimeout(() => {
      setDelayLoader(false);
    }, 1000);
  }, []);
  useEffect(() => {
    groupDataError ? setDisabled(true) : setDisabled(false);

    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) setPurchaseScreen("userDetails");
    });
  }, []);

  useEffect(() => {
    if (selectedPlan == null) {
      setDefaultSelection();
    }
  }, [groupData]);

  useEffect(() => {
    if (groupData?.memberdata && groupData?.memberdata?.currentPlan !== "") {
      const { price, selectedPeriod, discount, selectedOffer, periodTitle } =
        JSON.parse(groupData?.memberdata?.currentPlan);
      if (selectedPeriod == "Lifetime") {
        setLifetimeSunscription(false);
      }
      // console.log("gorupdsfds", price, selectedPeriod, discount);
      setCurrentPrice(price);
      setSelectedPeriod(periodTitle ? periodTitle : selectedPeriod);
      setDiscount(discount);
      setSelectedPlan(JSON.parse(groupData?.memberdata?.currentPlan));
      setSelectedOffer(selectedOffer);
      setGroupId(groupData?.group?.id);
      if (JSON.parse(groupData?.memberdata?.currentPlan)?.id) {
        setCurrentPlanId(JSON.parse(groupData?.memberdata?.currentPlan)?.id);
        setGroupCurrentPlanV2(JSON.parse(groupData?.memberdata?.currentPlan));
      }
    }
    if (groupData?.memberdata) {
      const { differenceInDays, expiryDate, joinedDate } =
        groupData?.memberdata;
      // console.log("expirybolca", differenceInDays, expiryDate, joinedDate);
      setCurrentPlanDate(moment(joinedDate).format("DD MMMM, YYYY"));
      setExpiryDate(moment(expiryDate).format("DD MMMM, YYYY"));
      setDiff(differenceInDays);
      if (
        groupData?.memberdata?.currentPlan == "" &&
        groupData?.group?.subscriptionPlan
      ) {
        setMorePlanDefaultSelection();
      }
    }
  }, [groupData]);
  const setMorePlanDefaultSelection = async () => {
    console.log(
      "PreAuthSelectedPlan",
      JSON.parse(groupData?.group?.subscriptionPlan)[0]
    );
    await handlePricingChange(
      JSON.parse(groupData?.group?.subscriptionPlan)[0]
    );
    setSelectedPlan(JSON.parse(groupData?.group?.subscriptionPlan)[0]);
    setIndex(0);
    setCurrentIndex(false);
  };
  const setDefaultSelection = async () => {
    if (indexx) {
      await handlePricingChange(
        JSON.parse(groupData?.group?.subscriptionPlan[indexx])
      );
      setSelectedPlan(JSON.parse(groupData?.group?.subscriptionPlan[indexx]));
    } else {
      await handlePricingChange(
        groupData?.group?.subscriptionPlan
          ? JSON.parse(groupData?.group?.subscriptionPlan)[0]
          : null
      );
      setSelectedPlan(
        groupData?.group?.subscriptionPlan
          ? JSON.parse(groupData?.group?.subscriptionPlan)[0]
          : null
      );
    }
  };
  const preAuthActionDispatch = () => {
    let temp = {
      token: groupData?.token,
      groupId: groupData?.group?.id,
      period: selectedPlan?.selectedPeriod,
      phone: groupData?.user?.mobile,
      email: groupData?.user?.email,
      name: groupData?.user?.name,
    };
    if (selectedPlan?.periodTitle) {
      temp.periodTitle = selectedPlan?.periodTitle;
    }
    window.payload = temp;

    preAuthAction(temp);
  };
  const redirectGooglePlayStore = () => {
    // console.log('rediorectioon');
    let redirectionUrl =
      "https://play.google.com/store/apps/details?id=com.fankonnect.app";
    hitDeeplinkExecutedV2(
      JSON.stringify({
        screen: "UTIL_BROWSER",
        paramOne: redirectionUrl,
      }),
      JSON.stringify({
        screen: "UTIL_BROWSER",
        paramOne: redirectionUrl,
      })
    );
  };

  const renderInputBlock = () => {
    return (
      <div className="freeGroup">
        <div className="freeGroup_Input">
          <div className="freeGroup_InputBlock">
            <CouponInput
              placeholder="Enter Coupon Code"
              value={couponCode}
              onChange={(e) =>
                props.setCouponCodeValue(e.target.value.toUpperCase())
              }
              className="freeGroup_inputClass"
              maxLength={25}
            />
          </div>

          <div
            onClick={
              couponCode && couponCode.length > 0 ? applyCouponAction : null
            }
            className={
              couponCode && couponCode.length > 0
                ? "freeGroup_selected_Applied"
                : "freeGroup_Apply"
            }
          >
            {"Apply"}
          </div>
        </div>
      </div>
    );
  };
  const renderPriceDetail = () => {
    return (
      <div className="priceDetail">
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_bold">Order Summary</div>
          <div onClick={() => setDetailSheet(false)}>
            <img src={x} style={{ width: "21px", height: "21px" }} />
          </div>
        </div>
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_text">Original Price</div>
          <div className="priceDetail_priceDetailItem_price">
            ₹{planPriceCoupon}/-
          </div>
        </div>
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_text">Discount</div>
          <div className="priceDetail_priceDetailItem_price">
            - ₹{planDiscountCoupon}/-
          </div>
        </div>
        <div className="priceDetail_priceDetailItem">
          <div className="couponNameBox">
            <div className="priceDetail_priceDetailItem_text">
              Coupon Discount
            </div>
            <div className="priceDetail_priceDetailItem_links">
              ({couponCode})
            </div>
          </div>
          <div className="priceDetail_priceDetailItem_price">
            - ₹{couponDiscountPlan}/-
          </div>
        </div>
        <div
          style={{ width: "100%", height: "1px", border: "1px dashed #E5E5E5" }}
        ></div>
        <div className="priceDetail_priceDetailItem">
          <div className="priceDetail_priceDetailItem_bold">Payable Amount</div>
          <div className="priceDetail_priceDetailItem_payable">
            ₹{finalPriceCoupon}/-
          </div>
        </div>
      </div>
    );
  };

  const renderPriceDetailDesktop = () => {
    return (
      <div className="priceDetailDesktop" id="priceDesktop">
        <div className="priceDetailDesktop_box">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="priceDetailDesktop_box_heading">Order Summary</div>
            <div
              style={{ width: "16px", height: "16px", cursor: "pointer" }}
              onClick={() => setPriceDetailSheetDesktop(false)}
            >
              <img
                src={x}
                alt="Close Icon"
                style={{ width: "100%", height: "100%" }}
              ></img>
            </div>
          </div>
          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_title">
              Original Price
            </div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              ₹{planPriceCoupon}/-
            </div>
          </div>

          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_title">Discount</div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              - ₹{planDiscountCoupon}/-
            </div>
          </div>

          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_title">
              Coupon discount
              <div className="priceDetailDesktop_box_infoBox_title_couponname">
                ({couponCode})
              </div>
            </div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              - ₹{couponDiscountPlan}/-
            </div>
          </div>

          <div className="priceDetailDesktop_box_infoBox">
            <div className="priceDetailDesktop_box_infoBox_payableTitle">
              Payable Amount
            </div>
            <div className="priceDetailDesktop_box_infoBox_value">
              {" "}
              ₹{finalPriceCoupon}/-
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCouponCodeBlock = () => {
    return (
      <div className="CouponCodeBlock">
        {applyBlockOpen ? (
          <div className={couponError ? "errorContainer" : "CouponCodeOpen"}>
            {couponError && (
              <div className="errorContainer_redContainer">
                Code you entered is invalid
              </div>
            )}
            <div className="CouponCodeOpen_head">
              <div className="CouponCodeOpen_row">
                <div className="CouponCodeBlock_img">
                  <img
                    src={dShapeBlck}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="CouponCodeOpen_openText">
                  Have a Coupon code?
                </div>
              </div>
              {/* close btn */}
              <div
                onClick={() => {
                  setApplyBlockOpen(false);
                  setSuccessCode(false);
                  setCouponError(false);
                  setNewData(null);
                  setNewStatus(false);
                  setExtraoff(null);
                  setCouponFinalPrice(null);
                  props.resetCoupon(true);
                  props.setCouponCodeValue("");
                }}
                className="CouponCodeOpen_close"
              >
                <img src={x} style={{ width: "100%", height: "100%" }} />
              </div>
            </div>
            {renderInputBlock()}
          </div>
        ) : (
          <div
            onClick={!successCode ? () => setApplyBlockOpen(true) : null}
            className="CouponCodeBlock_haveACouponCode"
          >
            {!successCode && (
              <div className="CouponCodeBlock_img">
                <img
                  src={successCode ? dShapeBlck : dShape}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            )}
            <div className="CouponCodeBlock_text">
              {successCode ? (
                <div className="successCodeBlock">
                  <div className="CouponCodeBlock_img">
                    <img
                      src={successCode ? dShapeBlck : dShape}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="successCodeBlock_name">
                    {couponCode}
                    {/* TESTETESTETSTESTTSTETSTETS  */}
                  </div>
                  {/* <div>Applied</div> */}
                  <div>coupon code applied</div>
                  <div
                    onClick={() => {
                      setSuccessCode(false);
                      setApplyBlockOpen(true);
                    }}
                    className="editBtn"
                  >
                    <div className="editBtn_img">
                      <img
                        src={edit}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div className="editBtn_text">Edit</div>
                  </div>
                </div>
              ) : (
                <div style={{ fontSize: "12px" }}>Have a Coupon code?</div>
              )}
            </div>
          </div>
        )}
        {detailSheet && renderPriceDetail()}
      </div>
    );
  };

  const renderCouponCodeBlockDesktop = () => {
    return (
      <div className="CouponCodeBlockDesktop">
        {!applyBlockOpen && !successCode && (
          <div
            className="CouponCodeBlockDesktop_text"
            onClick={() => setApplyBlockOpen(true)}
          >
            <div className="CouponCodeBlockDesktop_text_image">
              <img src={dShape} style={{ width: "100%", height: "100%" }} />
            </div>
            Have a Coupon Code ?
          </div>
        )}
        {!applyBlockOpen && successCode && (
          <div className="CouponCodeBlockDesktop_couponAppliedtext">
            <div className="CouponCodeBlockDesktop_couponAppliedtext_image">
              <img src={dShapeBlck} style={{ width: "100%", height: "100%" }} />
            </div>
            <div className="CouponCodeBlockDesktop_couponAppliedtext_couponName">
              {couponCode}
            </div>
            <div>coupon code applied</div>

            <div
              className="CouponCodeBlockDesktop_couponAppliedtext_editBtn"
              onClick={() => {
                setSuccessCode(false);
                setApplyBlockOpen(true);
              }}
            >
              <div className="CouponCodeBlockDesktop_couponAppliedtext_editBtn_img">
                <img src={edit} style={{ width: "100%", height: "100%" }} />
              </div>
              Edit
            </div>
          </div>
        )}
        {applyBlockOpen && (
          <div className="CouponCodeBlockDesktop_applyCouponCodeBox">
            <div className="applyBox">
              <div style={{ display: "flex" }}>
                <span>
                  <img
                    src={dShapeBlck}
                    alt="Coupon apply icon"
                    style={{ width: "100%", height: "100%" }}
                  ></img>
                </span>
                <h2>Apply your coupon code</h2>
              </div>
              <div
                onClick={() => {
                  setApplyBlockOpen(false);
                  setSuccessCode(false);
                  setCouponError(false);
                  setNewData(null);
                  setNewStatus(false);
                  props.resetCoupon(true);
                  props.setCouponCodeValue("");
                }}
                style={{
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                  alignSelf: "center",
                }}
              >
                <img src={x} style={{ width: "100%", height: "100%" }} />
              </div>
            </div>
            <div className="inputBox">{renderInputBlock()}</div>
          </div>
        )}
      </div>
    );
  };
  if (delayLoader) {
    return (
      <div className="Loader">
        <ReactLoading
          type={"spinningBubbles"}
          color={"#2278FF"}
          height={50}
          width={50}
        />
      </div>
    );
  }
  if (lifetimeSubscription) {
    return <LifetimeSubscription />;
  }
  switch (purchaseScreen) {
    case "success":
      return <SuccessPopup />;
    case "failure":
      return <FailurePopup />;
    case "inprogress":
      return <Inprogress />;
    case "loader":
      return <Loading />;
    case "alreadyDone":
      return <AlreadyDone />;
    case "renewedSuccess":
      return <SuccessPage />;
    case "register":
      return <Register isPreauth={true} />;
    case "otp":
      return <Otp />;
    default:
      return (
        <div className="preAuth">
          {/* header */}
          <div className="renewHeaderContainer">
            <div>
              <img src={logo} alt="logo" height="40px" />
            </div>
            <div className="renewHeaderDownload">
              <img src={shield} className="shieldSize" />
              <p className="safeAndSecure">Safe and Secure</p>
            </div>
          </div>
          <PayHeader pageData={groupData?.group} />
          <ExpiryDate data={groupData?.memberdata} />

          {console.log("currenetplafd", groupCurrentPlanV2)}
          {groupData?.memberdata?.currentPlan &&
            groupData?.memberdata?.currentPlan != "" && (
              <div className="preAuth_pricing">
                <p className="preAuth_pricing-title">Your Current Plan</p>
                {/* {groupData?.memberdata?.currentPlan?.map((plan, index) => ( */}
                <div>
                  {selectedOffer?.length ? (
                    <div className="preAuth_SinglePlan_Offer">
                      {selectedOffer}
                    </div>
                  ) : null}

                  <div
                    className={`preAuth_SinglePlan ${
                      JSON.parse(groupData?.memberdata?.currentPlan) ==
                        selectedPlan || currentIndex
                        ? "selectedPlan"
                        : ""
                    }`}
                    onClick={() => {
                      handlePricingChange(
                        currentPlanId
                          ? groupCurrentPlanV2
                          : JSON.parse(groupData?.memberdata?.currentPlan)
                      );
                      setSelectedPlan(
                        currentPlanId
                          ? groupCurrentPlanV2
                          : JSON.parse(groupData?.memberdata?.currentPlan)
                      );
                      setCurrentIndex(true);
                      setIndex(null);
                    }}
                  >
                    <div className="preAuth_SinglePlan_Left">
                      <input
                        type="radio"
                        id={1}
                        // value={groupData?.subscriptionPlan?.paymentPeriod?.period}
                        checked={
                          JSON.parse(groupData?.memberdata?.currentPlan) ==
                            selectedPlan || currentIndex
                        }
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <div className="preAuth_pricing_Column">
                        <div className="preAuth_SinglePlan_Period">
                          {selectedPeriod}
                        </div>
                        {extraoff && (
                          <div className="groupDetails_pricing_list_extraoff">
                            {`Extra ₹${extraoff}/- off`}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="preAuth_SinglePlan_Right">
                      {discount ? (
                        <div className="preAuth_SinglePlan_Price">
                          ₹{currentPrice}/-
                        </div>
                      ) : null}

                      <div className="preAuth_SinglePlan_FinalPrice">
                        ₹
                        {couponFinalPrice
                          ? couponFinalPrice
                          : parseFloat(currentPrice) - parseFloat(discount)}
                        /-
                      </div>
                    </div>
                  </div>
                </div>
                {/* )
            )} */}
              </div>
            )}
          {/* end of current plan */}
          {groupData?.group?.subscriptionPlan && (
            <div className="preAuth_pricing">
              <div className="preAuth_pricing-content">
                <p className="preAuth_pricing-title">More Plans</p>
                {(newStatus
                  ? newData
                  : JSON.parse(groupData?.group?.subscriptionPlan)
                )?.map((plan, index) => {
                  return (
                    <div style={{ marginTop: "22px" }}>
                      {plan?.selectedOffer?.length ? (
                        <div className="preAuth_SinglePlan_Offer">
                          {plan?.selectedOffer}
                        </div>
                      ) : null}

                      <div
                        className={`preAuth_SinglePlan ${
                          //   plan === selectedPlan ? "selectedPlan" : ""
                          (
                            plan?.id
                              ? plan?.id == selectedPlan?.id
                              : indexx == index && !currentIndex
                          )
                            ? "selectedPlan"
                            : ""
                        }`}
                        onClick={() => {
                          handlePricingChange(plan);
                          setSelectedPlan(plan);
                          setIndex(index);
                          setCurrentIndex(false);
                        }}
                      >
                        <div className="preAuth_SinglePlan_Left">
                          <input
                            type="radio"
                            id={1}
                            checked={indexx == index && !currentIndex}
                            onKeyDown={(e) => e.preventDefault()}
                          />
                          <div className="preAuth_pricing_Column">
                            <div className="preAuth_SinglePlan_Period">
                              {plan?.selectedPeriod?.label == "Custom Period" ||
                              plan?.selectedPeriod == "Custom Period"
                                ? plan?.periodTitle
                                : plan?.selectedPeriod}
                            </div>
                            {plan?.couponData && (
                              <div className="preAuth_pricing_list_extraoff">
                                {`Extra ₹${plan?.couponData?.couponDiscount}/- off`}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="preAuth_SinglePlan_Right">
                          {plan?.discount ? (
                            <div className="preAuth_SinglePlan_Price">
                              ₹{plan?.price}/-
                            </div>
                          ) : null}
                          {plan?.price == 0 ? (
                            <div className="preAuth_SinglePlan_FinalPrice">
                              Free!
                            </div>
                          ) : (
                            <div className="preAuth_SinglePlan_FinalPrice">
                              ₹
                              {plan?.couponData
                                ? plan?.couponData?.finalPrice
                                : applyCouponSuccess &&
                                  plan.id == selectedPlan.id
                                ? applyCouponSuccess?.currentPlanData
                                    ?.finalPrice
                                : parseFloat(plan?.price) -
                                  parseFloat(plan?.discount)}
                              /-
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <GroupDescription pageData={groupData?.group} />
          <PayPageStaticContent
            pageData={groupData?.group?.type}
            navigate={navigate}
            sebiFlag={groupData?.group?.sebi}
          />

          {renderCouponCodeBlockDesktop()}

          {/* footer */}
          {groupData?.usageLimit ? (
            <div className="preAuth_footer">
              <div className="usageLimit_preAuth">
                <div className="usageLimit_preAuth_title_preAuth">Sorry !</div>
                <div className="usageLimit_preAuth_description_preAuth">
                  {`This ${
                    groupData?.group?.type === "whatsappGroup"
                      ? "Group"
                      : "Community"
                  } has reached its maximum user limit. Please contact the admin of the ${
                    groupData?.group?.type === "whatsappGroup"
                      ? "Group"
                      : "Community"
                  }`}
                </div>
              </div>
            </div>
          ) : (
            <div className="preAuth_footer">
              {/* {console.log("selecr", selectedPlan?.selectedOffer)} */}

              {selectedPlan?.selectedOffer != "Free Trial" &&
                v2Enabled &&
                renderCouponCodeBlock()}

              {applyCouponLoader ? (
                <div className="couponLoader">
                  <ReactLoading
                    type={"spin"}
                    color={"#000000"}
                    height={"40px"}
                    width={"40px"}
                  />
                </div>
              ) : selectedPlan?.price == 0 ? (
                <div className="preAuth_footer_free">
                  <div className="preAuth_footer-text">Free</div>
                  <p
                    style={{ color: "#C4C4C4", fontSize: "10px" }}
                  >{`for ${selectedPlan?.customValue} days`}</p>
                </div>
              ) : (
                <div className="priceOuterBlock">
                  <div className="priceBlock">
                    <p className="preAuth_footer-text">
                      ₹
                      {successCode
                        ? selectedPlan?.id == currentPlanId
                          ? couponFinalPrice
                          : finalPriceCoupon
                        : selectedPlan?.discount
                        ? parseFloat(selectedPlan?.price) -
                          parseFloat(selectedPlan?.discount)
                        : parseFloat(selectedPlan?.price)}
                      /-
                    </p>
                    {successCode && (
                      <div
                        onClick={() => setDetailSheet(true)}
                        className="priceBlock_img"
                      >
                        <img
                          src={info}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                  {/* PRICE BLOCK FOR DESKTOP */}
                  <div className="priceBlock_Desktop">
                    {priceDetailSheetDesktop && renderPriceDetailDesktop()}

                    {selectedPlan?.price !== undefined ? (
                      <p className="groupDetails_footer-text">
                        ₹
                        {successCode
                          ? finalPriceCoupon
                          : selectedPlan?.discount
                          ? parseFloat(selectedPlan?.price) -
                            parseFloat(selectedPlan?.discount)
                          : parseFloat(selectedPlan?.price)}
                        /-
                      </p>
                    ) : null}
                    {successCode && (
                      <div
                        onClick={() =>
                          setPriceDetailSheetDesktop(!priceDetailSheetDesktop)
                        }
                        className="priceBlock_img"
                      >
                        <img
                          src={info}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                  {successCode && (
                    <div className="savedPrice">
                      you saved ₹
                      {console.log(
                        "sdfdr",
                        couponDiscountPlan,
                        planDiscountCoupon
                      )}
                      {couponDiscountPlan + planDiscountCoupon}
                    </div>
                  )}
                </div>
              )}
              <div className="preAuth_footer-button">
                <Button
                  disabled={disabled}
                  onClick={() => {
                    // setPurchaseScreen("userDetails");
                    preAuthActionDispatch();
                  }}
                >
                  Renew Now
                </Button>
              </div>
            </div>
          )}
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    purchaseScreen: state?.purchaseReducer?.purchaseScreen,
    formDetails: state?.purchaseReducer?.formDetails,
    generateOtp: state?.purchaseReducer?.generateOtp,
    groupPricingPayload: state?.purchaseReducer?.groupPricingPayload,
    groupData: state?.purchaseReducer?.groupData?.data,
    groupDataError: state?.purchaseReducer?.groupDataError,
    applyCouponSuccess: state?.purchaseReducer?.applyCouponSuccess,
    applyCouponFailure: state?.purchaseReducer?.applyCouponFailure,
    couponCode: state?.purchaseReducer?.couponCode,
    applyCouponLoader: state?.purchaseReducer?.applyCouponLoader,
    v2Enabled: state?.user?.v2Enabled,
    resetCouponError: state?.purchaseReducer?.resetCouponError,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setPurchaseScreen: (payload) =>
      dispatch(actions.setPurchaseScreen(payload)),
    handlePricingChange: (payload) =>
      dispatch(actions.handlePricingChange(payload)),
    getGroupData: (payload) => dispatch(actions.getGroupData(payload)),
    preAuthAction: (payload) => dispatch(actions.preAuthAction(payload)),
    applyCouponApi: (payload) => dispatch(actions.applyCouponApi(payload)),
    setCouponCodeValue: (payload) =>
      dispatch(actions.setCouponCodevalue(payload)),
    resetCoupon: (payload) => dispatch(actions.resetCoupon(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchWithProps)(PreAuth);
