// FUNCTION TO GET USER TOKEN
const getUserToken = () => {
  // FETCHING ALL USER TOKENS STORED
  const userTokenQueryParam =
    typeof window !== "undefined"
      ? new URLSearchParams(window?.location?.search).get("token")
      : "";
  const userTokenLocalStorage =
    typeof localStorage !== "undefined" ? localStorage.getItem("token") : "";

  // ASSIGNING USER TOKEN BASED ON PRIORITY
  const userToken = userTokenQueryParam || userTokenLocalStorage;

  // RETURNING USER TOKEN
  return userToken;
};

export default getUserToken;
