const namespace = "PURCHASE_";

export default {
  SET_PURCHASE_SCREEN: `${namespace}SET_PURCHASE_SCREEN`,

  SET_FORM_DETAILS: `${namespace}SET_FORM_DETAILS`,
  RESET_FORM_DETAILS: `${namespace}RESET_FORM_DETAILS`,
  PRICING_CHANGE: `${namespace}PRICING_CHANGE`,

  GENERATE_OTP: `${namespace}GENERATE_OTP`,
  GENERATE_OTP_SUCCESS: `${namespace}GENERATE_OTP_SUCCESS`,
  GENERATE_OTP_FAILURE: `${namespace}GENERATE_OTP_FAILURE`,
  GENERATE_OTP_LOADER: `${namespace}GENERATE_OTP_LOADER`,

  VERIFY_OTP: `${namespace}VERIFY_OTP`,
  VERIFY_OTP_SUCCESS: `${namespace}VERIFY_OTP_SUCCESS`,
  VERIFY_OTP_FAILURE: `${namespace}VERIFY_OTP_FAILURE`,
  VERIFY_OTP_LOADER: `${namespace}VERIFY_OTP_LOADER`,

  PAGE_DATA: `${namespace}PAGE_DATA`,
  PAGE_DATA_SUCCESS: `${namespace}PAGE_DATA_SUCCESS`,
  PAGE_DATA_FAILURE: `${namespace}PAGE_DATA_FAILURE`,
  PAGE_DATA_LOADER: `${namespace}PAGE_DATA_LOADER`,

  GROUP_DATA: `${namespace}GROUP_DATA`,
  GROUP_DATA_SUCCESS: `${namespace}GROUP_DATA_SUCCESS`,
  GROUP_DATA_FAILURE: `${namespace}GROUP_DATA_FAILURE`,
  GROUP_DATA_LOADER: `${namespace}GROUP_DATA_LOADER`,

  REGISTER_USER: `${namespace}REGISTER_USER`,
  REGISTER_USER_SUCCESS: `${namespace}REGISTER_USER_SUCCESS`,
  REGISTER_USER_FAILURE: `${namespace}REGISTER_USER_FAILURE`,
  REGISTER_USER_LOADER: `${namespace}REGISTER_USER_LOADER`,

  OTP_VALUE: `${namespace}OTP_VALUE`,

  GET_ORDER_ID: `${namespace}GET_ORDER_ID`,

  GET_ORDER_STATUS: `${namespace}GET_ORDER_STATUS`,
  GET_ORDER_STATUS_SUCCESS: `${namespace}GET_ORDER_STATUS_SUCCESS`,
  GET_ORDER_STATUS_FAILURE: `${namespace}GET_ORDER_STATUS_FAILURE`,
  GET_ORDER_STATUS_LOADER: `${namespace}GET_ORDER_STATUS_LOADER`,

  SET_VERIFY_OTP: `${namespace}SET_VERIFY_OTP`,

  PRE_AUTH: `${namespace}PRE_AUTH`,

  CREATE_ORDER: `${namespace}CREATE_ORDER`,
  CREATE_ORDER_LOADER: `${namespace}CREATE_ORDER_LOADER`,
  CREATE_ORDER_FAILURE: `${namespace}CREATE_ORDER_FAILURE`,
  CREATE_ORDER_SUCCESS: `${namespace}CREATE_ORDER_SUCCESS`,
  SET_INVITE_LINK: `${namespace}SET_INVITE_LINK`,
  ORDER_STATUS_SUCCESS: `${namespace}ORDER_STATUS_SUCCESS`,

  // email check for migration
  EMAIL_EXISTS: `${namespace}EMAIL_EXISTS`,
  UPDATE_EMAIL: `${namespace}UPDATE_EMAIL`,
  UPDATE_EMAIL_LOADER: `${namespace}UPDATE_EMAIL_LOADER`,
  UPDATE_EMAIL_SUCCESS: `${namespace}UPDATE_EMAIL_SUCCESS`,
  UPDATE_EMAIL_FAILURE: `${namespace}UPDATE_EMAIL_FAILURE`,

  // WA communication
  WA_CHECKED: `${namespace}WA_CHECKED`,
  APPLY_COUPON: `${namespace}APPLY_COUPON`,
  APPLY_COUPON_LOADER: `${namespace}APPLY_COUPON_LOADER`,
  APPLY_COUPON_SUCCESS: `${namespace}APPLY_COUPON_SUCCESS`,
  APPLY_COUPON_FAILURE: `${namespace}APPLY_COUPON_FAILURE`,
  RESET_APPLY_COUPON: `${namespace}RESET_APPLY_COUPON`,
  COUPON_CODE_VALUE: `${namespace}COUPON_CODE_VALUE`,

  // reset on coupon data error
  RESET_COUPON_ERROR: `${namespace}RESET_COUPON_ERROR`,

  //check state exists
  STATE_EXISTS: `${namespace}STATE_EXISTS`,
};
