const preprodRazor = "rzp_test_zGURW0rJb0oxxd";
//"rzp_test_PChbEfOPed5cIV";
//rzp_test_zGURW0rJb0oxxd
// const preprodRazor = "rzp_live_uezcxcwQP92n4F";
const liveRazor = "rzp_live_UuTqzWqIeoTJiM";

const getRazorpayKey = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "PROD":
      return liveRazor;
    case "PREPROD":
      return preprodRazor;
    default:
      return preprodRazor;
  }
};

export const razorPayKey = getRazorpayKey();
