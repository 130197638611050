import axios from "axios";
import { BASE_URL } from "../../../axios-config";
import { getUserToken } from "../../../utils/getAxiosConfig";

export const createGSTProfileService = async (payload) => {
  console.log(payload);
  return await axios({
    url: `${BASE_URL}/user/receipt`,
    method: "POST",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload?.payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const editGSTProfileService = async (payload) => {
  return await axios({
    url: `${BASE_URL}/user/receipt`,
    method: "PUT",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload?.payload,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchProfileService = async () => {
  return await axios({
    url: `${BASE_URL}/user/receipt`,
    method: "GET",
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
