const namespace = "ALL_COUPONS";

const CONSTANTS = {
  ALL_COUPONS_GET: `${namespace}_GET`,
  ALL_COUPONS_LOADER: `${namespace}_LOADER`,
  ALL_COUPONS_SUCCESS: `${namespace}_SUCCESS`,
  ALL_COUPONS_FAILURE: `${namespace}_FAILURE`,
  COUPON_ACTIVE_INACTIVE_DELETE: `COUPON_ACTIVE_INACTIVE_DELETE`,
  COUPON_ACTIVE: `COUPON_ACTIVE`,
  COUPON_INACTIVE: `COUPON_INACTIVE`,
  COUPON_DELETE: `COUPON_DELETE`,
  COUPON_ACTION_LOADER: `COUPON_ACTION_LOADER`,
};

export default CONSTANTS;
