import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import bottomAngleIcon from "../../../assets/bottomAngleIcon.svg";

const CommonDatePicker = ({ date, changeDate }) => {
  const datepickerRef = useRef(null);
  const [datefocus, setDateFocus] = useState(false);
  const handleDatePickerRef = () => {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    setDateFocus(true);
    datepickerElement.setFocus(datefocus);
  };
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      className="custom-input"
      onClick={() => {
        handleDatePickerRef();
        onClick();
      }}
    >
      <input
        className="custom-input__text"
        ref={ref}
        value={value}
        placeholder="Select Date"
      />
      <span>
        <img
          src={bottomAngleIcon}
          alt="Dropdown Close"
          // className={datefocus ? "uparrow" : "downarrow"}
        />
      </span>
    </div>
  ));
  return (
    <>
      <DatePicker
        dateFormat={"MMMM YYYY"}
        value={date}
        onChange={(date) => changeDate(date)}
        // customInput={<CustomInput />}
        placeholderText="Select Date"
        ref={datepickerRef}
        isClearable
        popperPlacement="bottom-end"
        popperModifiers={[
          {
            name: "arrow",
            options: {
              padding: 100,
            },
          },
          {
            name: "offset",
            options: {
              offset: [5, 10],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
      />
    </>
  );
};

export default CommonDatePicker;
