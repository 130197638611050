import { call, put, takeLatest } from "redux-saga/effects";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";

function* clearChannelEarningsDataSaga(payload) {
  // const clearAction = yield call(
  //     getActionObject,
  //     CONSTANTS.CHANNEL_EARNINGS_GET_CLEAR
  // );
  // yield put({ ...clearAction });
  const loaderAction = yield call(
    getActionObject,
    CONSTANTS.CHANNEL_EARNINGS_GET_LOADER
  );
  // yield put({ ...loaderAction });
}

export default function* actionWatcher() {
  yield takeLatest(
    CONSTANTS.CHANNEL_EARNINGS_GET_CLEAR,
    clearChannelEarningsDataSaga
  );
}
