import CONSTANTS from "./constants";

//REDUX
export const setIsLoading = (payload) => {
  return {
    type: CONSTANTS.SET_IS_LOADING,
    payload,
  };
};

export const setSelectedTab = (payload) => {
  return {
    type: CONSTANTS.SET_SELECTED_TAB,
    payload,
  };
};

export const resetState = () => {
  return {
    type: CONSTANTS.RESET_STATE,
  };
};

//SAGA
export const fetchGroups = (payload) => {
  return {
    type: CONSTANTS.FETCH_GROUPS,
    payload,
  };
};

export const fetchMessages = (payload) => {
  return {
    type: CONSTANTS.FETCH_MESSAGES,
    payload,
  };
};
export const callPlatformBottomSheet = (payload) => {
  return {
    type: CONSTANTS.CREATE_BOTTOM_SHEET,
    payload,
  };
};
export const setWhatsAppConfirmationBottom = (payload) => {
  return {
    type: CONSTANTS.WHATSAPP_BOTTOM_SHEET,
    payload,
  };
};

export const setTermsAndConditions = (payload) => {
  console.log("PAYLOAD IS", payload);
  return {
    type: CONSTANTS.SET_TERMSANDCONDITIONS,
    payload,
  };
};
