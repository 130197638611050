import React from "react";
import "../../../../scss/Purchase/groupDetails.scss";
import ReadMore from "../../../../common/ReadMoreText";
const GroupDescription = (props) => {
  const { pageData } = props;
  return (
    <div className="groupDetails_description">
      <p className="groupDetails_description-title">Group Description</p>
      <ReadMore desc={pageData?.about || pageData?.description} />
    </div>
  );
};
export default GroupDescription;
