import React from "react";
import ReactGA from "react-ga";
import EVENTS from "../../../../utils/gaEvents";
import "./styles.scss";

const TabBar = ({ list, activeTabId, setTab }) => {
  let type = list[0].enum == "group" ? 1 : 2;
  // FETCHING STATES FROM REDUCER
  const appAccentColor = "2278ff";

  const tabClickEvent = (tabEnum) => {
    switch (tabEnum) {
      case "telegramGroup":
        ReactGA.event({
          category: EVENTS.HOME.CATEGORY,
          action: EVENTS.HOME.ACTIONS.TELERGAM_GROUP_TAB_CLICK,
        });
        break;
      case "lockedMessage":
        ReactGA.event({
          category: EVENTS.HOME.CATEGORY,
          action: EVENTS.HOME.ACTIONS.LOCKED_MESSAGE_TAB_CLICK,
        });
        break;
      default:
        return;
    }
  };

  return (
    <div className="TabBar blockSlide">
      <div className={type == 1 ? "TabBar__List" : "TabBar__ListReport"}>
        {list?.map((item) => (
          <div
            key={item?.id}
            className={
              type == 1
                ? `TabBar__List--Item ${
                    item?.id === activeTabId && "TabBar__List--Item--active"
                  }`
                : `TabBar__ListReport--Item ${
                    item?.id === activeTabId &&
                    "TabBar__ListReport--Item--active"
                  }`
            }
            onClick={() => {
              tabClickEvent(item?.enum);
              setTab(item?.id);
            }}
          >
            {item?.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabBar;
