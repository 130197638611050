import React, { useEffect } from "react";
import "../../../../scss/Purchase/groupDetails.scss";
import Accordion from "../Accordian";
import { accordionWhatsAppData, accordionTelegramData } from "./AccordionData";
import logo from "../../../../assets/logo-trimmed.png";
import gplay from "../../../../assets/gplay.svg";
const PayPageStaticContent = (props) => {
  const { pageData, navigate, sebiFlag } = props;
  const openPrivacyPolicy = () => {
    console.log("privacyPolicy");
    navigate("/privacy");
  };
  const openTnc = () => {
    console.log("privacyPolicy");
    navigate("/tnc");
  };

  useEffect(() => {
    console.log("SEBI FLAG IS", sebiFlag);
  }, [props]);
  return (
    <div>
      <div className="groupDetails_FAQs">
        <p className="groupDetails_FAQs_title">FAQs</p>
        {(pageData == "whatsappGroup" || pageData == "whatsappCommunity"
          ? accordionWhatsAppData
          : accordionTelegramData
        )?.map(({ title, content }) => (
          <Accordion title={title} content={content} />
        ))}
      </div>
      <h1 className="groupDetails_disclaimerheading">Disclaimer</h1>
      <div className="groupDetails_disclaimer">
        {sebiFlag?.sebi === 1 ? (
          <div className="groupDetails_sebidisclaimer">
            <div className="groupDetails_sebidisclaimer_heading">
              Name : {sebiFlag?.sebiName}
            </div>
            <div className="groupDetails_sebidisclaimer_heading">
              Registration Number : {sebiFlag?.sebiRegistration}
            </div>
            <div className="groupDetails_sebidisclaimer_text">
              {`The content provided by ${sebiFlag?.sebiName} is based on
  ${sebiFlag?.sebiName} professional experience, qualifications, and
  knowledge in the field. However, it is important to note that
  professional advice may vary based on individual circumstances,
  and the information provided should not be considered as a
  substitute for personalized advice from a qualified professional.
  You are encouraged to consult with a qualified expert or
  professional to address your specific needs and circumstances.`}
            </div>
          </div>
        ) : (
          `The Creator is not a SEBI registered or qualified investment advisor.
          The Creator compiles data, content and information from various sources
          with due care and caution. The channel/group is for informational,
          educational and discussion purposes only. Even though topics may be
          discussed on the channel/group that involve investment issues, nothing
          on the channel shall be deemed to constitute the practice of investment
          advice. However, it is important to note that the advice may vary based
          on individual circumstances, and the information provided should not be
          considered as a substitute for advice from a qualified professional.
          Neither the Creator, and where applicable, nor its directors,
          principals, agents, associates nor employees, are licensed to provide
          investment advice. You are encouraged to consult with a qualified expert
          or professional to address your specific needs and circumstances`
        )}
      </div>
      <div className="groupDetails_support">
        <div className="groupDetails_support_text">
          If you need any help please write to us at
        </div>
        <div className="groupDetails_support_mail">support@fankonnect.com</div>
      </div>

      <div className="groupDetails_bottom">
        <div className="groupDetails_bottom_top">
          <div className="groupDetails_bottom_top_left">
            <img src={logo} alt="logo" height="40px" />
            <div>Made with ❤️ in India</div>
          </div>
          <div className="groupDetails_bottom_top_right">
            <img
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.fankonnect.app"
                );
              }}
              src={gplay}
              alt="gplay"
              height="40px"
            />
            <div>Create your own paid telegram channel</div>
          </div>
        </div>
        <div className="groupDetails_bottom_foot">
          <div onClick={openPrivacyPolicy}>Privacy Policy</div>
          <div onClick={openTnc}>Terms and Conditions</div>
        </div>
      </div>
    </div>
  );
};
export default PayPageStaticContent;
