import React, { useEffect } from "react";
import "./adminList.scss";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import adminListUser from "../../assets/adminListUser.png";
import adminListPhone from "../../assets/adminListPhone.png";
import * as actions from "./store/actions";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const AdminList = (props) => {
  useLayoutPageHeading("List of Channel Admins");
  let { id } = useParams();
  const { coAdmins } = props;
  useEffect(() => {
    props.getCoAdmins(id);
  }, []);
  const navigate = useNavigate();

  const getFormattedMobileNumber = (number) => {
    // let temp = item.mobile.split('')
    // let now = temp.splice(2, 0, ' ')
    // console.log(temp.join(''))
    let temp = number.split("");
    let deletedArray = temp.splice(2, 0, " ");
    return `+${temp.join("")}`;
  };
  return (
    <div className="AdminList">
      {coAdmins?.map((item, index) => (
        <div className="AdminList_listItem">
          <div className="AdminList_rowOne">
            <div className="AdminList_rowOneLeft">
              <img className="AdminList_user" src={adminListUser} />
              <div className="AdminList_userName">{item?.name}</div>
            </div>
            <div
              className={
                item?.joinedBy
                  ? "AdminList_statusTextGreen"
                  : "AdminList_statusTextRed"
              }
            >
              {item?.joinedBy ? "Admin" : "Link Sent"}
            </div>
          </div>
          <div className="AdminList_rowTwo">
            <img className="AdminList_phone" src={adminListPhone} />
            <div className="AdminList_phoneText">
              {getFormattedMobileNumber(item?.mobile)}
            </div>
          </div>
          {index != coAdmins.length - 1 && (
            <hr className="AdminList_separator" />
          )}
        </div>
      ))}
      {coAdmins && coAdmins.length < 5 ? (
        <div className="AdminList_ButtonContainer">
          <div
            onClick={() => {
              navigate(`/group/${id}/addNewAdmin`, { replace: true });
            }}
            className="AdminList_ButtonContainer_Button"
          >
            {"Add Admin"}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchWithProps = (dispatch) => {
  return {
    getCoAdmins: (payload) => dispatch(actions.getCoAdmins(payload)),
  };
};

const mapStateToProps = ({ coAdminReducer }) => {
  return {
    coAdmins: coAdminReducer.coAdmins,
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(AdminList);
