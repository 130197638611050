import toast from "react-hot-toast";

export const categoryOptions = [
  {
    id: 1,
    label: "Category 1",
    value: "category1",
  },
  {
    id: 2,
    label: "Category 2",
    value: "category2",
  },
  {
    id: 3,
    label: "Category 3",
    value: "category3",
  },
];

export const subscriptionPeriodOptions = [
  {
    id: 1,
    label: "Lifetime",
    value: "lifetime",
  },
  {
    id: 2,
    label: "1 Week",
    value: "1week",
  },
  {
    id: 3,
    label: "2 Weeks",
    value: "2weeks",
  },
  {
    id: 4,
    label: "1 Months",
    value: "1months",
  },
  {
    id: 5,
    label: "3 Months",
    value: "3months",
  },
  {
    id: 6,
    label: "6 Months",
    value: "6months",
  },
];

export const offerTitleOptions = [
  {
    id: 1,
    label: "Limited Period Offer",
    value: "limitedPeriod",
  },
];

export const validateCurrentPlan = (pricing) => {
  console.log("pricing", pricing);
  if (!pricing || (pricing.price == "" && pricing.selectedPeriod == "")) {
    toast.error("Please complete all the payment plan before adding another.");
    return false;
  }
  if (!pricing?.selectedPeriod?.value) {
    toast.error("Please choose a period");
    return false;
  }

  if (!pricing?.price?.length) {
    toast.error("Please enter the price");
    return false;
  }
  if (
    pricing &&
    pricing?.customType &&
    pricing?.selectedOffer?.value !== "Free Trial"
  ) {
    if (pricing?.customType.value == "Days") {
      if (pricing.customValue < 1) {
        toast.error("Custom plan can’t be less than 1 day");
        return false;
      }
    } else if (pricing?.customType.value == "Months") {
      if (pricing.customValue == 0 || pricing.customValue == undefined) {
        toast.error("Custom plan can’t be less than 1 day");
        return false;
      }
    }
  }

  return true;
};

export const validateFields = (props) => {
  // if (!(groupName?.length && about?.length))
  //     return toast.error("Please enter the mandatory details");
  // if (groupName?.length < 3 || groupName?.length > 100) {
  //     toast.error("Title should be between 3 - 100 characters");
  //     return;
  // }
  // if (about?.length < 10 || about?.length > 200) {
  //     toast.error("Description should be between 10 - 200 characters");
  //     return;
  // }
  // if (accessPrice?.length && parseFloat(accessPrice) <= 0)
  //     return toast.error("One Time Price cannot be 0");
  // if (subscriptionPrice?.length && parseFloat(subscriptionPrice) <= 0)
  //     return toast.error("Subscription Price cannot be 0");
  // if (!(accessPrice?.length || subscriptionPrice?.length)) {
  //     toast.error("Please enter pricing details");
  //     return;
  // }
  // if (subscriptionPrice?.length && !selectedSubscriptionPeriod) {
  //     return toast.error("Please choose a Subscription Plan");
  // }
  //     if (!(groupName?.length && about?.length))
  //     return toast.error("Please enter the mandatory details");
  //   if (groupName?.length < 3 || groupName?.length > 100) {
  //     toast.error("Title should be between 3 - 100 characters");
  //     return;
  //   }
  //   if (about?.length < 10 || about?.length > 200) {
  //     toast.error("Description should be between 10 - 200 characters");
  //     return;
  //   }
  //   if (!(accessPrice?.length || subscriptionPrice?.length)) {
  //     toast.error("Please enter pricing details");
  //     return;
  //   }
  //   if (subscriptionPrice?.length && !selectedSubscriptionPeriod) {
  //     return toast.error("Please choose a Subscription Plan");
  //   }
};
