import React from "react";
import "./styles.scss";
import Bottomsheet from "../../../../common/Bottomsheet";
// assets
import wa from "../../../../assets/v2Automation/wa.png";
import tele from "../../../../assets/v2Automation/tele.png";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
const PhoneConfirmationBottom = (props) => {
  const { isPhoneVisible = false, actionOnBlock = () => {} } = props;
  // const platforms = [{name:,imageUrl}]
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const userData = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET
  );
  useEffect(() => {
    if (userData?.userDetails && userData?.userDetails?.mobile) {
      let mob = userData?.userDetails?.mobile;
      if (mob.length > 10) {
        let sepratedFrom91 = mob.split("91");
        setPhoneNumber(sepratedFrom91[1]);
      } else {
        setPhoneNumber(mob);
      }
    }
  }, [userData]);
  const whatsappFlow = () => {
    navigate("/platformAutomation");
  };
  const goBackToHome = () => {
    navigate(-1);
  };
  return (
    <Bottomsheet
      showModal={isPhoneVisible}
      // handleClose={() => handleClose()}
      blockBackdrop={true}
      hideCross={true}
      onblockBackdrop={() => actionOnBlock()}
    >
      <div className="PhoneConfirmation">
        <div className="PhoneConfirmation__tapBlock">
          <div className="PhoneConfirmation__tapBlock_tap" />
        </div>
        <div className="PhoneConfirmation_head">To Continue,</div>
        <div className="PhoneConfirmation_description">
          Please check if you’re on Whatsapp from{" "}
          <span style={{ fontWeight: "bold" }}>{`+91 ${phoneNumber}`}</span>{" "}
          number
        </div>
        <div className="ctaBlock">
          <div onClick={() => goBackToHome()} className="ctaBlock_cancelCTA">
            Cancel
          </div>
          <div onClick={() => actionOnBlock()} className="ctaBlock_confirmCTA">
            Confirm
          </div>
        </div>
      </div>
    </Bottomsheet>
  );
};

export default PhoneConfirmationBottom;
