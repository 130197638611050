import React, { useEffect, useState } from "react";
import "./styles.scss";
import wa from "../../../assets/v2Automation/walogo.png";
import tele from "../../../assets/v2Automation/tele.png";
import add from "../../../assets/v2Automation/add.png";
import link from "../../../assets/v2Automation/link.png";
import useLayoutPageHeading from "../../../customHooks/useLayoutPageHeading";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneConfirmationBottom from "../BottomSheets/PhoneConfirmation.jsx";
import { setChannelType } from "../../../store/User/action";
const PlatformAutomationPage = (props) => {
  const selectedPlatform = useSelector(
    (state) => state?.createGroup?.selectedPlatform
  );
  const channelType = useSelector((state) => state?.user?.channelType);
  useLayoutPageHeading(`${selectedPlatform} Automation`);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bottomSheet, setBottomSheet] = useState(false);
  const [localData, setLocalData] = useState(false);
  const setLocalStorage = async () => {
    await localStorage.setItem("phoneVisible1", 1);
    console.log("phoenBici");
    setLocalData(true);
  };
  useEffect(() => {
    const data = localStorage.getItem("phoneVisible1");
    console.log("phoenBicidata", data);
    if (data) {
      console.log("phoenBici");
      setLocalData(false);
    } else {
      setLocalStorage();
    }
  }, []);
  useEffect(() => {
    if (localData) {
      setBottomSheet(true);
    } else {
      setBottomSheet(false);
    }
  }, [localData]);
  const createNew = () => {
    dispatch(setChannelType("whatsappGroup"));
    navigate(`/group/whatsapp/create`);
  };
  const createCommunity = () => {
    dispatch(setChannelType("whatsappCommunity"));
    navigate(`/group/whatsapp/create`);
  };

  return (
    <div className="WhatsappAutomation">
      {/* create new block */}
      <div
        onClick={() => {
          createNew();
        }}
        className="WhatsappAutomation_blocks"
      >
        <div className="WhatsappAutomation_outerBlock">
          <div className="WhatsappAutomation_logoTextBlock">
            <img src={selectedPlatform == "Whatsapp" ? wa : tele} />
            <div className="WhatsappAutomation_headText">Create Group</div>
          </div>
          <div className="WhatsappAutomation_optionLogo">
            <img src={add} />
          </div>
        </div>
        <div className="WhatsappAutomation_description">
          {`Create new ${selectedPlatform} group & automate your payments, user addition &
          removal`}
        </div>
      </div>

      {/* link existing */}
      <div onClick={createCommunity} className="WhatsappAutomation_blocks">
        <div className="WhatsappAutomation_outerBlock">
          <div className="WhatsappAutomation_logoTextBlock">
            <img src={selectedPlatform == "Whatsapp" ? wa : tele} />
            <div className="WhatsappAutomation_headText">Create Community</div>
          </div>
          <div className="WhatsappAutomation_optionLogo">
            <img src={add} />
          </div>
        </div>
        <div className="WhatsappAutomation_description">
          {`Create new ${selectedPlatform} community & automate your payments, user addition
          & removal`}
        </div>
      </div>
      {bottomSheet && selectedPlatform == "Whatsapp" ? (
        <PhoneConfirmationBottom
          isPhoneVisible={bottomSheet}
          actionOnBlock={() => setBottomSheet(false)}
        />
      ) : null}
    </div>
  );
};
export default PlatformAutomationPage;
