import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";

import crossIcon from "../../assets/crossIcon.svg";
import "./styles.scss";
import { useSelector } from "react-redux";

const Bottomsheet = ({
  customClass,
  customProps,
  children,
  handleClose,
  header,
  blockBackdrop,
  onblockBackdrop = () => {},
  footer,
  style,
  hideCross = false,
  countryList = false,
  onCloseCountryList,
}) => {
  const [returnContent, setReturnContent] = useState(true);

  const timeoutRef = useRef(null);
  const closeCountryListBottomSheet = useSelector(
    (state) => state.getCountryCodeReducer.closeCountryListBottomSheet
  );

  useEffect(() => {
    if (returnContent) {
    } else {
      timeoutRef.current = setTimeout(() => {
        !hideCross && handleClose();
      }, 300);
    }
    return () => clearTimeout(timeoutRef?.current);
  }, [returnContent]);

  useEffect(() => {
    //CHECK WHETHER BOTTOMSHEET IS FOR COUNTRY LIST OR NOT
    if (countryList) {
      //CHECK WHETHER WE HAVE SELECTED ANY COUNTRY FROM BOTTOMSHEET,IF YES THEN CLOSE THE BOTTOMSHEET
      console.log("IN COUNTRY LIST ---");
      if (closeCountryListBottomSheet) {
        console.log("IN BOTTOMSHEET BLOCK", closeCountryListBottomSheet);
        setReturnContent(false);
        onCloseCountryList();
      }
    }
  }, [closeCountryListBottomSheet]);

  return (
    <div
      className={
        returnContent
          ? "ModalWrapper blockSlide ModalWrapper__Show"
          : "ModalWrapper blockSlide ModalWrapper__Hide"
      }
    >
      <div
        onClick={() => {
          if (!blockBackdrop) {
            onblockBackdrop();
            setReturnContent(false);
          }
        }}
        className={
          returnContent ? "Backdrop Backdrop__Show" : "Backdrop Backdrop__Hide"
        }
      />
      <div
        className={
          returnContent
            ? `Modal Modal__Show ${customClass ? customClass : ""}`
            : `Modal Modal__Hide ${customClass ? customClass : ""}`
        }
        {...customProps}
      >
        {!hideCross && (
          <div
            className="Modal--Close"
            onClick={() => {
              if (countryList) {
                setReturnContent(false);
                onCloseCountryList();
              } else {
                setReturnContent(false);
              }
            }}
          >
            <img src={crossIcon} alt="crossIcon" width="24px" height="24px" />
          </div>
        )}
        <div
          className="Modal__Head"
          style={{
            borderBottom: header ? "1px solid #e5e5e5" : "",
          }}
        >
          {header ? <div className="Modal__Head--Details">{header}</div> : ""}
        </div>
        <div className="Modal__Body">{children}</div>

        {footer ? (
          <div
            className={
              returnContent
                ? "Modal__Footer Modal__Footer__Fixed"
                : "Modal__Footer Modal__Footer__Absolute"
            }
          >
            {footer}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Bottomsheet;
