// IMPORTS

// UTIL TO HIT PERFORM ACTION DEEPLINK
// actionType STRING ACTION (first param of android performAction)
// deeplinkObj SHOULD BE STRINGIFIED AS ARGUMENTS
// android iOS BOOLEAN FLAGS DENOTING IF DEEPLINK TO BE EXECUTED FOR THE RESPECTIVE PLATFORM (true by default)
export const hitDeeplinkPerformAction = (
  actionType,
  androidDeeplinkObj,
  iosDeeplinkObj,
  android = true,
  iOS = true
) => {
  try {
    android && window?.mobile?.performAction(actionType, androidDeeplinkObj);
    iOS &&
      window?.webkit?.messageHandlers?.performAction?.postMessage(
        iosDeeplinkObj
      );
  } catch (err) {
    // toast.error(
    //     t("utils.hitDeeplink.somethingWrongErrorText", "Something went wrong...")
    // );
    alert("Something went wrong...");
  }
};

// UTIL TO HIT DEEPLINK EXECUTED V2
// deeplinkObj SHOULD BE STRINGIFIED AS ARGUMENTS
// android iOS BOOLEAN FLAGS DENOTING IF DEEPLINK TO BE EXECUTED FOR THE RESPECTIVE PLATFORM (true by default)
export const hitDeeplinkExecutedV2 = (
  androidDeeplinkObj,
  iosDeeplinkObj,
  android = true,
  iOS = true
) => {
  console.log("deeplink called", androidDeeplinkObj);
  try {
    android && window?.mobile?.onDeeplinkExecutedV2(androidDeeplinkObj);
    iOS &&
      window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
        iosDeeplinkObj
      );
  } catch (err) {
    // toast.error(
    //     t("utils.hitDeeplink.somethingWrongErrorText", "Something went wrong...")
    // );
    alert("Something went wrong...");
  }
};
