import React from "react";
import { useDispatch } from "react-redux";
import {
  layoutPageHeadingClear,
  layoutPageHeadingSet,
} from "../layout/store/actions";

const useLayoutPageHeading = (pageHeading) => {
  // REDUX DISPATCH
  const dispatch = useDispatch();

  // SETTING AND REMOVING PAGE HEADING ON MOUNT AND UNMOUNT
  React.useEffect(() => {
    dispatch(layoutPageHeadingSet(pageHeading));
    return () => {
      dispatch(layoutPageHeadingClear());
    };
  }, []);
};

export default useLayoutPageHeading;
