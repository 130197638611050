import React from "react";
import "./styles.scss";

export const AttentionCustomer = (props) => {
  const { data } = props;
  const handleUrl = () => {
    if (data) {
      console.log("urll:", data?.formLink);
      window.open(data?.formLink);
    }
  };
  return (
    <div className="AttentionContainer">
      <div className="AttentionContainer_outerBox">
        <div className="AttentionContainer_innerBox">
          <div className="AttentionContainer_head">Attention Customer !</div>
          <div className="AttentionContainer_description">
            It is mandatory to fill this form to get access to this paid
            channel.
          </div>
        </div>
      </div>
      <div
        onClick={() => handleUrl()}
        className="AttentionContainer_CTAContainer"
      >
        <div className="AttentionContainer_joinText">Fill Form</div>
      </div>
    </div>
  );
};
