import React, { useEffect, useRef, useState } from "react";
import "./profile.scss";
import axios from "axios";
import Switch from "react-js-switch";
import { BASE_URL } from "../../axios-config";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Dropdown from "../../components/Dropdown";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import uploadIcon from "../../assets/uploadIcon.png";
import { getUserToken } from "../../utils/getAxiosConfig";
import { connect, useSelector } from "react-redux";
import * as actions from "./store/actions";
import { useNavigate } from "react-router-dom";
import yellowInfo from "../../assets/yellowInfo.png";
import toast from "react-hot-toast";
import profileUserIcon from "../../assets/profileUserIcon.png";
import blueFile from "../../assets/blueFile.png";
import grayFile from "../../assets/grayFile.png";
import profileCross from "../../assets/profileCross.png";

const Profile = (props) => {
  const { brandName, address, pincode, state, gstNumber } = props;
  useLayoutPageHeading("My Profile");
  const userDetails = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.userDetails
  );
  const fileRef = useRef();
  let [states, setStates] = useState([]);
  let [switchValue, setSwitchValue] = useState(false);
  let [url, setUrl] = useState("");
  let [mainUrl, setMainUrl] = useState("");
  let [fileName, setFileName] = useState("");
  let [disabled, setDisabled] = useState(false);
  let [GSTDisabled, setGSTDisabled] = useState(false);
  let [isEdit, setIsEdit] = useState(false);
  let [imageUploaded, setImageUploaded] = useState(false);
  let [showGSTError, setShowGSTError] = useState(false);
  let [showPinCodeError, setShowPinCodeError] = useState(false);
  let [file, setFile] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    checkReceipt();
    getSignedUrl();
  }, []);

  const checkReceipt = () => {
    if (brandName && pincode && state && address) {
      setDisabled(true);
    }
    if (gstNumber) {
      setGSTDisabled(true);
      setSwitchValue(true);
    }
    if (brandName && pincode && state && address && !gstNumber) {
      setIsEdit(true);
    }
  };
  const checkBTNDisabled = () => {
    console.log(switchValue, fileName);
    if (isEdit) {
      if (gstNumber && fileName) {
        return false;
      }
    } else {
      if (
        switchValue &&
        brandName &&
        pincode &&
        state &&
        address &&
        gstNumber &&
        fileName
      ) {
        return false;
      } else if (brandName && pincode && state && address) {
        return false;
      }
    }
    return true;
  };
  const onFileChange = async (event) => {
    console.log(event.target.files[0].name);
    setFileName(event.target.files[0].name);
    setFile(event.target.files[0]);
    // await uploadImage(event.target.files[0]);
  };
  const uploadImage = async (file) => {
    axios({
      url: url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": `${file.type}`,
      },
    })
      .then((response) => {
        console.log("response of image upload: ", response);
        setImageUploaded(true);
      })
      .catch((error) => console.log("error in image upload: ", error));
  };
  const toggleAction = () => {
    setSwitchValue(!switchValue);
  };
  useEffect(() => {
    getStates();
  }, []);
  const getStates = async () => {
    await axios({
      method: "GET",
      url: `${BASE_URL}/states`,
    })
      .then(async (res) => {
        let temp = [];
        await res.data.data.forEach((obj) => {
          temp.push({
            id: obj.id,
            label: obj.name,
          });
        });
        setStates(temp);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  const settingState = (item) => {
    props.setStateValue(item?.label);
  };

  const getSignedUrl = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/uploadImage`,
      data: {
        fileExtension: "application/pdf",
        destination: "gst",
      },
      headers: {
        "x-access-token": getUserToken(),
      },
    })
      .then((response) => {
        response && setUrl(response.data.data.url);
        response && setMainUrl(response.data.data.mainUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createProfile = async () => {
    if (switchValue) {
      if (gstNumber?.length < 15) {
        toast.error("Please enter valid GST Number");
        return;
      }
      if (!fileName || fileName.length == 0) {
        toast.error("Please select GST Certificate");
        return;
      }
    }
    if (file) await uploadImage(file);
    let payload = {
      orgName: brandName,
      pinCode: pincode,
      state: state,
      address: address,
      navigate,
    };
    if (switchValue) {
      payload.gstNumber = gstNumber;
      payload.gstCertificate = mainUrl;
    }
    if (isEdit) {
      props.editGSTProfile(payload);
    } else {
      props.createGSTProfile(payload);
    }
  };
  return (
    <div className="profile">
      <div className="profile_userContainer">
        <div className="profile_userFirstRow">
          <img className="profile_userImage" src={profileUserIcon}></img>
          <div className="profile_nameIdContainer">
            <div className="profile_username">{userDetails?.name}</div>
            <div className="profile_userID">{`User Id: ${userDetails?.id}`}</div>
          </div>
        </div>
        <div className="profile_userSecondRow">
          <div className="profile_userPhoneContainer">
            <div className="profile_userPhoneText">Phone No.</div>
            <div className="profile_userPhone">
            {`+${userDetails?.countryCode? userDetails?.countryCode:''}  ${userDetails?.mobile.slice(-10)}`}
            </div>
          </div>
          <div className="profile_userEmailContainer">
            <div className="profile_userPhoneText">Email</div>
            <div className="profile_userPhone">{userDetails?.email}</div>
          </div>
        </div>
      </div>

      <hr className="profile_curveBorder" />

      <div className="profile_moreInfoText">More Info</div>

      <hr className="profile_dottedLine" />

      {!(disabled && GSTDisabled) && (
        <div className="profile_GSTRegisteredTitle">
          <div className="profile_GSTRegisteredLeft">
            <div className="profile_GSTRegisteredLeftOne">
              Are you GST registered?
            </div>
            {switchValue ? (
              <div className="profile_switchOnContainer">
                <img src={yellowInfo} height="20px" width={"20px"}></img>
                <div className="profile_fillDetailsText">
                  Fill details as per GST certificate
                </div>
              </div>
            ) : (
              <div className="profile_GSTRegisteredLeftTwo">
                Switch on the toggle to generate GST applied payment receipts if
                you have valid GST number
              </div>
            )}
          </div>
          <div className="profile_GSTRegisteredRight">
            <Switch
              disabled={GSTDisabled}
              size={50}
              value={switchValue}
              onChange={() => {
                toggleAction();
              }}
              borderColor={{ on: "white", off: "#e6e6e6" }}
              backgroundColor={{ on: "#2278FF", off: "#CED5E0" }}
            />
          </div>
        </div>
      )}
      {switchValue && (
        <div>
          <div className="profile_uploadLabel">
            {GSTDisabled
              ? "GST Certificate"
              : "Upload GST Certificate (In PDF Only)"}
          </div>
          <div
            className={
              fileName
                ? "profile_uploadContainerFull"
                : disabled && !(isEdit && switchValue)
                ? "profile_uploadContainerDisabled"
                : "profile_uploadContainer"
            }
          >
            <div
              onClick={async (event) => {
                await fileRef.current.click();
              }}
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                className="profile_uploadIcon"
                src={
                  fileName
                    ? blueFile
                    : disabled && !(isEdit && switchValue)
                    ? grayFile
                    : uploadIcon
                }
              />
              <div className="profile_fileName">
                {fileName
                  ? fileName
                  : disabled && GSTDisabled
                  ? "GST Certificate Uploaded"
                  : "Choose File"}
              </div>
              <input
                accept="application/pdf"
                disabled={GSTDisabled}
                type="file"
                onChange={onFileChange}
                ref={fileRef}
                hidden
              />
            </div>
            {fileName && (
              <div
                onClick={() => {
                  setFileName("");
                  setGSTDisabled(false);
                }}
              >
                <img
                  height={"25px"}
                  width={"25px"}
                  style={{ marginRight: "12px" }}
                  src={profileCross}
                />
              </div>
            )}
          </div>
          <div style={{ marginLeft: "16px", marginRight: "16px" }}>
            <Input
              onBlur={() => {
                if (gstNumber?.length < 15) {
                  setShowGSTError(true);
                } else {
                  setShowGSTError(false);
                }
              }}
              maxLength={15}
              disabled={GSTDisabled}
              label={"GST Number"}
              placeholder="Enter here..."
              onChange={(e) => props.setGSTNumber(e.target.value)}
              value={gstNumber}
            />
            {showGSTError && (
              <div className="profile_gstErrorText">
                *Please enter valid GST number.
              </div>
            )}
          </div>
        </div>
      )}
      <div className="profile_formContainer">
        <Input
          disabled={isEdit && switchValue == true ? false : disabled}
          label={"Brand/Company/Channel Name"}
          placeholder="Enter here..."
          onChange={(e) => props.setBrandName(e.target.value)}
          value={brandName}
        />
        <Input
          disabled={isEdit && switchValue ? false : disabled}
          maxLength={120}
          label={"Registered Address"}
          placeholder="House No, Area, Building, Street..."
          inputType="textarea"
          onChange={(e) => props.setAddress(e.target.value)}
          value={address}
        />
        <Input
          onBlur={() => {
            if (pincode?.length < 6) {
              setShowPinCodeError(true);
            } else {
              setShowPinCodeError(false);
            }
          }}
          type="number"
          maxLength={6}
          disabled={isEdit && switchValue ? false : disabled}
          label={"Pin Code"}
          placeholder="Enter here..."
          onChange={(e) => {
            if (e.target.value.length <= 6) {
              props.setPinCode(e.target.value);
            }
          }}
          value={pincode}
        />
        {showPinCodeError && (
          <div className="profile_gstErrorText">
            *Please enter a valid Pin Code.
          </div>
        )}
        {!disabled || (isEdit && switchValue) ? (
          <Dropdown
            editable={isEdit && switchValue ? true : !disabled}
            label={"Select State"}
            itemClickHandler={settingState}
            optionsList={states}
            selectedOption={{ label: state }}
          />
        ) : (
          <Input label={"State"} disabled={true} value={state} />
        )}
      </div>
      {(!disabled || (isEdit && switchValue)) && (
        <div className="profile_footerBTN">
          <Button disabled={checkBTNDisabled()} onClick={createProfile}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ gstProfileReducer }) => {
  return {
    brandName: gstProfileReducer.brandName,
    address: gstProfileReducer.address,
    pincode: gstProfileReducer.pincode,
    state: gstProfileReducer.state,
    gstNumber: gstProfileReducer.gstNumber,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setBrandName: (payload) => dispatch(actions.setBrandName(payload)),
    setAddress: (payload) => dispatch(actions.setAddress(payload)),
    setPinCode: (payload) => dispatch(actions.setPinCode(payload)),
    setGSTNumber: (payload) => dispatch(actions.setGSTNumber(payload)),
    setStateValue: (payload) => dispatch(actions.setState(payload)),
    createGSTProfile: (payload) => dispatch(actions.createGSTProfile(payload)),
    editGSTProfile: (payload) => dispatch(actions.editGSTProfile(payload)),
    fetchProfile: (payload) => dispatch(actions.fetchProfileAction(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(Profile);
