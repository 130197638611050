import { all } from "redux-saga/effects";
import AuthSagaWatcher from "./User/saga";
import HomeSagaWatcher from "./Home/saga";
import CreateGroupSaga from "./CreateGroup/saga";
import CreateMessageSaga from "./CreateMessage/saga";
import userDetailsSaga from "./userDetailsReducer/saga";
import DetailsSaga from "./Details/saga";
import PurchaseSaga from "./Purchase/saga";
import bankDetailsSaga from "../routes/bankDetails/store/saga";
import getEarningsSaga from "../routes/myEarnings/sagaStore/saga";
import channelEarningsSaga from "../routes/myEarnings/sagaStore/channelEarningsSaga";
import clearEarningsDataSaga from "../routes/myEarnings/sagaStore/clearEarningsDataSaga";
import clearChannelEarningsDataSaga from "../routes/myEarnings/sagaStore/clearChannelEarningsDataSaga";
import allCouponDataSaga from "../routes/coupons/store/saga";
import leadCaptureFormSaga from "../routes/LandingPage/store/saga";
import gstProfileSaga from "../routes/Profile/store/saga";
import getCoAdminsSaga from "../routes/coadmins/store/saga";
import getAutomatedChannelSaga from "../store/automatedGroup/saga";
import getCountryCodeSaga from "../store/GetCountryCode/saga";

export default function* rootSaga() {
  yield all([
    AuthSagaWatcher(),
    HomeSagaWatcher(),
    userDetailsSaga(),
    CreateGroupSaga(),
    CreateMessageSaga(),
    userDetailsSaga(),
    DetailsSaga(),
    PurchaseSaga(),
    bankDetailsSaga(),
    getEarningsSaga(),
    channelEarningsSaga(),
    clearEarningsDataSaga(),
    clearChannelEarningsDataSaga(),
    allCouponDataSaga(),
    leadCaptureFormSaga(),
    gstProfileSaga(),
    getCoAdminsSaga(),
    getAutomatedChannelSaga(),
    getCountryCodeSaga(),
  ]);
}
