import { put, call, select, takeLatest } from "redux-saga/effects";
import * as services from "./service";
import CONSTANTS from "./constants";

function* createMessage(payload, navigate) {
  try {
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: true,
    });
    const response = yield call(services.createMessageService, {
      data: payload,
    });
    payload?.navigate("/message/create/success");
    yield put({
      type: CONSTANTS.CREATE_MESSAGE_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: CONSTANTS.SET_IS_LOADING,
      payload: false,
    });
  }
}

function* editMessage(payload) {
  try {
    const response = yield call(services.editMessageService, {
      data: payload,
    });
    yield put({
      type: CONSTANTS.EDIT_MESSAGE_SUCCESS,
      payload: response.data,
    });
    payload?.navigate("/message");
  } catch (err) {
    console.log(err);
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.CREATE_MESSAGE, createMessage);
  yield takeLatest(CONSTANTS.EDIT_MESSAGE, editMessage);
}
