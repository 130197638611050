import React from "react";
import "./SingleContent.scss";
import CustomUserAvatar from "../../common/CustomUserAvator";
import rightArrow from "../../assets/rightArrow.png";
import { useNavigate } from "react-router-dom";

const SingleContent = (props) => {
  const navigate = useNavigate();

  const singleGroupClick = () => {
    window.payload = { groupId };
    navigate("/ChannelEarnings");
  };
  const { data } = props;
  const { groupId, groupName, totalIncome } = data;
  return (
    <div
      className="container"
      // ref={props.parentRef}
      onClick={singleGroupClick}
    >
      <div className="container_left">
        {data?.logoUrl ? (
          <img
            src={data?.logoUrl}
            height={"44px"}
            width={"44px"}
            style={{ borderRadius: "22px" }}
          />
        ) : (
          <CustomUserAvatar
            user={{
              name: groupName,
            }}
            size={"44px"}
          />
        )}
        <div className="container_left_text">
          <div className="container_left_text_top">{groupName}</div>
          <div className="container_left_text_bottom">{`Total Earnings: ₹${totalIncome}/-`}</div>
        </div>
      </div>
      <div className="container_right">
        <img className="container_right_arrow" src={rightArrow} />
      </div>
    </div>
  );
};

export default SingleContent;
