export default {
  GENERATE_OTP: {
    url: () => "/v1/otp/get",
    method: "POST",
    params: [],
  },
  VERIFY_OTP: {
    url: () => `/v1/otp/verify`,
    method: "POST",
    params: [],
  },
  PAGE_DATA: {
    url: (id, v2Enabled) =>
      window.location.pathname.includes("/m")
        ? `/messageUnauth/${id}`
        : `/groupUnauth/${id}?v2Enabled=${v2Enabled}`,
    method: "GET",
    params: [],
  },
  GROUP_DATA: {
    url: (id, v2Enabled) =>
      window.location.pathname.includes("/m")
        ? `/preAuthGroup/${id}?v2Enabled=${v2Enabled}`
        : `/preAuthGroup/${id}?v2Enabled=${v2Enabled}`,
    method: "POST",
    params: [],
  },
  REGISTER_USER: {
    url: () => `/v1/register`,
    method: "POST",
    params: [],
  },
  GET_ORDER_ID: {
    url: () => `/order/update`,
    method: "POST",
    params: [],
  },
  APPLY_COUPON: {
    url: () => `/checkCouponValidity`,
    method: "POST",
    params: [],
  },
  ORDER_STATUS: {
    url: (payload) =>
      `/orderStatus?orderType=${payload.orderType}${
        payload.groupId ? "&groupId=" + payload.groupId : ""
      }${payload.messageId ? "&messageId=" + payload.messageId : ""}${
        payload.freemium ? "&freemium=" + payload.freemium : ""
      }`,
    method: "GET",
    params: [],
  },
};
