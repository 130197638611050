import React from "react";
import styles from "./styles.module.scss";
// import Image from "next/image";
// import Modal from "@/root/src/components/UIElements/Modal";
import { useDispatch, useSelector } from "react-redux";
// import * as actionCreator from "../../store/actions";
// import { useRef } from "react";
// import { getAwsAssets } from "@/root/src/utils";
// import { directDeepLink } from "@/deepLinks/index.js";
// import { useTranslation } from "react-i18next";
import { hitDeeplinkExecutedV2 } from "../../utils/hitDeeplink";
import Bottomsheet from "../../common/Bottomsheet";
import { COMMON_DEEPLINK_ACTIONS } from "../../utils/constants";

import threeDotMenuIcon from "../../assets/threeDotMenuIcon.svg";

const ShareBottomsheet = (props) => {
  const { handleClose } = props;
  // const dispatch = useDispatch();
  // const state = useSelector(
  //     (state) => state?.bottomSheetReducer?.showShareContentViaBottomSheet
  // );
  const directDeepLink = () => {};
  //   const socialLinks = useSelector(
  //     (state) => state?.overviewReducer?.overViewDetails?.course?.socialLinks
  //   );

  const handleSocialShareClicked = ({ type, shareUrl, shareText }) => {
    // console.log(item);
    hitDeeplinkExecutedV2(
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.SOCIAL_LINKS_SHARE,
        paramOne: type,
        paramTwo: shareUrl,
        paramThree: shareText,
      }),
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.SOCIAL_LINKS_SHARE,
        paramOne: type,
        paramTwo: shareUrl,
        paramThree: shareText,
      })
    );
  };

  const handleMoreAppsClicked = (shareText) => {
    hitDeeplinkExecutedV2(
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.UTIL_SHARE,
        paramTwo: shareText,
      }),
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.UTIL_SHARE,
        paramTwo: shareText,
      })
    );
  };

  const handleCopyURLClicked = (copyURL) => {
    hitDeeplinkExecutedV2(
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.UTIL_COPY,
        paramOne: copyURL,
      }),
      JSON.stringify({
        screen: COMMON_DEEPLINK_ACTIONS.UTIL_COPY,
        paramOne: copyURL,
      })
    );
  };

  // const socialLinks = {
  //   links: [
  //     {
  //       type: "WHATSAPP",
  //       iconUrl:
  //         "https://dtxqtzf8mpl38.cloudfront.net/cams/store/icon/icon_whatsap.png",
  //       shareUrl:
  //         "https://api.whatsapp.com/send?text=Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=whatsapp&utm_medium=student-course-referral&utm_campaign=course-overview-app",
  //       shareText:
  //         "Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=whatsapp&utm_medium=student-course-referral&utm_campaign=course-overview-app",
  //       appName: "WhatsApp",
  //     },
  //     {
  //       type: "FACEBOOK",
  //       iconUrl:
  //         "https://dtxqtzf8mpl38.cloudfront.net/cams/store/icon/icon_fb.png",
  //       shareUrl:
  //         "https://www.facebook.com/sharer/sharer.php?u=Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=facebook&utm_medium=student-course-referral-fb&utm_campaign=course-overview-app",
  //       shareText:
  //         "Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=facebook&utm_medium=student-course-referral-fb&utm_campaign=course-overview-app",
  //       appName: "Facebook",
  //     },
  //     {
  //       type: "TELEGRAM",
  //       iconUrl:
  //         "https://dtxqtzf8mpl38.cloudfront.net/cams/store/icon/icon_telegram.png",
  //       shareUrl:
  //         "https://t.me/share/url?url=Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542&text=Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=telegram&utm_medium=student-course-referral&utm_campaign=course-overview-app",
  //       shareText:
  //         "Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=telegram&utm_medium=student-course-referral&utm_campaign=course-overview-app",
  //       appName: "Telegram",
  //     },
  //   ],
  //   copyUrl:
  //     "https://learn.courses.store/154542?utm_source=copy-link&utm_medium=student-course-referral&utm_campaign=course-overview",
  //   moreAppsText:
  //     "Hey! I’m learning with Learning Light and you can too. Here’s a course you might like: Physical shipment test by Gunjan\n    https://learn.courses.store/154542?utm_source=copy-link&utm_medium=student-course-referral&utm_campaign=course-overview",
  //   publishText: "You have Successfully Published Physical shipment test",
  // };

  return (
    <Bottomsheet
      showModal={true}
      handleClose={() => handleClose()}
      header={<div className="UpcomingLive__ModalHeader">Share Link Via</div>}
    >
      <div className={styles.ShareCourseViaBottomSheet}>
        <div className={styles.ShareCourseViaBottomSheet__socialIcons}>
          {props?.socialLinks?.links?.map((item, i) => (
            <div
              className={
                styles.ShareCourseViaBottomSheet__socialIcons__singleIcon
              }
              key={`${i}share`}
            >
              <div
                className={
                  styles.ShareCourseViaBottomSheet__socialIcons__singleIcon__image
                }
                onClick={() => handleSocialShareClicked(item)}
              >
                <img
                  src={item.iconUrl}
                  width="30px"
                  height="30px"
                  alt="itemIcon"
                />
              </div>
              <p
                className={
                  styles.ShareCourseViaBottomSheet__socialIcons__singleIcon__image__para
                }
              >
                {item.appName}
              </p>
            </div>
          ))}

          <div
            className={
              styles.ShareCourseViaBottomSheet__socialIcons__singleIcon
            }
            onClick={() =>
              handleMoreAppsClicked(props?.socialLinks?.moreAppsText)
            }
          >
            <div
              className={
                styles.ShareCourseViaBottomSheet__socialIcons__singleIcon__image
              }
            >
              <img
                src={threeDotMenuIcon}
                width="24px"
                height="24px"
                alt="threeDotMenuIcon"
              />
            </div>
            <p
              className={
                styles.ShareCourseViaBottomSheet__socialIcons__singleIcon__image__para
              }
            >
              More Apps
            </p>
          </div>
        </div>
        <p className={styles.ShareCourseViaBottomSheet__divider}>OR</p>
        <div className={styles.ShareCourseViaBottomSheet__UrlCopy}>
          <p>{props?.socialLinks?.copyUrl}</p>
          <div>
            <button
              onClick={() => handleCopyURLClicked(props?.socialLinks?.copyUrl)}
            >
              COPY
            </button>
          </div>
        </div>
      </div>
    </Bottomsheet>
  );
};

export default ShareBottomsheet;
