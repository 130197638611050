import CONSTANTS from "./constants";
import apiEndPoints from "./apiEndPoints";
import axios from "axios";
import getAxiosConfig from "../../../utils/getAxiosConfig";
import { BASE_URL } from "../../../axios-config";
import getUserToken from "../../../utils/getUserToken";

// AXIOS SERVICE TO GET COURSE CONTENT
export const getAllCouponsServices = async (payload) => {
  // console.log("payloadd", payload);
  return axios({
    method: "GET",
    url: `${BASE_URL}/coupons?groupId=${payload}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      // console.log("this jay: ", response);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const couponActionServices = async (payload) => {
  return axios({
    ...getAxiosConfig(
      apiEndPoints[CONSTANTS.COUPON_ACTIVE_INACTIVE_DELETE]?.method,
      apiEndPoints[CONSTANTS.COUPON_ACTIVE_INACTIVE_DELETE]?.url,
      payload
    ),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
