import CONSTANTS from "./constants";
import apiEndpoints from "./apiEndpoints";
import getAxiosConfig from "../../utils/getAxiosConfig";
import axios from "axios";

// AXIOS SERVICE TO GET COURSE CONTENT
export const getUserDetailsService = (payload) => {
  return axios({
    ...getAxiosConfig(
      apiEndpoints[CONSTANTS.USER_DETAILS_GET]?.method,
      apiEndpoints[CONSTANTS.USER_DETAILS_GET]?.url,
      payload
    ),
  });
};
