import CONSTANTS from "./constants";

const initialState = {
  isLoading: false,
  groupName: "",
  about: "",
  pricing: {
    accessPrice: "",
    paymentPeriod: {
      price: "",
      period: "",
    },
  },

  selectedPlanIndex: 0,
  prices: [
    {
      price: "",
      selectedPeriod: "",
      discount: "",
      selectedOffer: "",
    },
  ],
  selectedCategory: "",
  selectedSubscriptionPeriod: "",
  freeplanDays: null,
  conditonalAccessToggle: false,
  formLinkData: "",
  botLink: null,
  selectedPlatform: "",
  channelType: "",
  image: "",
  botLink: null,
};

const setIsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.payload,
  };
};

const setTitle = (state, action) => {
  return {
    ...state,
    groupName: action.payload,
  };
};

const setDescription = (state, action) => {
  return {
    ...state,
    about: action.payload,
  };
};

const setCategory = (state, action) => {
  return {
    ...state,
    selectedCategory: action.payload,
  };
};

const addPaymentPlan = (state, action) => {
  return {
    ...state,
    selectedPlanIndex: state.prices.length,
    prices: [
      ...state.prices,
      {
        price: "",
        selectedPeriod: "",
        discount: "",
        selectedOfferTitle: "",
      },
    ],
  };
};

const setPrice = (state, action) => {
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices[action.payload.index].price = action.payload.value;
  return {
    ...state,
    prices: newPrices,
  };
};

const setSelectedSubscriptionPeriod = (state, action) => {
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices[action.payload.index].selectedPeriod = action.payload.value;
  return {
    ...state,
    prices: newPrices,
  };
};

const setCustomPeriodDuration = (state, action) => {
  console.log("setcustomaaction", action);
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices[action.payload.index].customType = action.payload.value;
  return {
    ...state,
    prices: newPrices,
  };
};
const setCustomPeriodNumber = (state, action) => {
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices[action.payload.index].customValue = action.payload.value;
  return {
    ...state,
    prices: newPrices,
  };
};

const setDiscount = (state, action) => {
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices[action.payload.index].discount = action.payload.value;
  return {
    ...state,
    prices: newPrices,
  };
};

const setOfferTitle = (state, action) => {
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices[action.payload.index].selectedOffer = action.payload.value;
  return {
    ...state,
    prices: newPrices,
  };
};

const setSelectedPlan = (state, action) => {
  return {
    ...state,
    selectedPlanIndex: action.payload,
  };
};

const deletePlan = (state, action) => {
  let newPrices = JSON.parse(JSON.stringify(state.prices));
  newPrices.splice(action.payload, 1);
  return {
    ...state,
    prices: newPrices,
    selectedPlanIndex: null,
  };
};

const setPricingForEdit = (state, action) => {
  return {
    ...state,
    prices: [...action.payload],
  };
};

const setSubscriptionPrice = (state, action) => {
  return {
    ...state,
    pricing: {
      ...state.pricing,
      paymentPeriod: {
        ...state.pricing.paymentPeriod,
        price: action.payload,
      },
    },
  };
};
const setFreePlanDaysData = (state, action) => {
  return {
    ...state,
    freeplanDays: action.payload,
  };
};
const createGroupSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    ...action.payload,
  };
};
const resetPricesPlanReducer = (state, action) => {
  return {
    ...state,
    prices: [
      {
        price: "",
        selectedPeriod: "",
        discount: "",
        selectedOffer: "",
      },
    ],
  };
};
const setCondtionalAccessToggle = (state, action) => {
  return {
    ...state,
    conditonalAccessToggle: action.payload,
  };
};
const setFormLinkData = (state, action) => {
  return {
    ...state,
    formLinkData: action.payload,
  };
};
const setPlatformForFlow = (state, action) => {
  return {
    ...state,
    selectedPlatform: action?.payload,
  };
};

const setImage = (state, action) => {
  return {
    ...state,
    image: action.payload,
  };
};
const setBotLink = (state, action) => {
  return {
    ...state,
    botLink: action?.payload,
  };
};
const resetState = (state, action) => {
  return {
    isLoading: false,
    groupName: "",
    about: "",
    image: "",
    pricing: {
      accessPrice: "",
      paymentPeriod: {
        price: "",
        period: "",
      },
    },

    prices: [
      {
        price: "",
        selectedPeriod: "",
        discount: "",
        selectedOffer: "",
      },
    ],
    selectedCategory: "",
    selectedSubscriptionPeriod: "",
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE_GROUP_CLEAR: {
      return {
        ...state,
        groupName: "",
        about: "",
        pricing: {
          accessPrice: "",
          paymentPeriod: {
            price: "",
            period: "",
          },
        },
        selectedSubscriptionPeriod: "",
      };
    }
    case CONSTANTS.SET_IS_LOADING:
      return setIsLoading(state, action);
    case CONSTANTS.RESET_STATE:
      return resetState(state, action);
    case CONSTANTS.SET_TITLE:
      return setTitle(state, action);
    case CONSTANTS.SET_DESCRIPTION:
      return setDescription(state, action);
    case CONSTANTS.SET_CATEGORY:
      return setCategory(state, action);
    case CONSTANTS.ADD_PRICE_PLAN:
      return addPaymentPlan(state, action);
    case CONSTANTS.SET_ONE_TIME_PRICE:
      return setPrice(state, action);
    case CONSTANTS.BOT_LINK_DATA:
      return setBotLink(state, action);
    case CONSTANTS.SET_IMAGE:
      return setImage(state, action);
    case CONSTANTS.BOT_LINK_DATA:
      return setBotLink(state, action);
    case CONSTANTS.SET_DISCOUNT:
      return setDiscount(state, action);
    case CONSTANTS.SET_OFFER_TITLE:
      return setOfferTitle(state, action);
    case CONSTANTS.SET_SELECTED_PLAN:
      return setSelectedPlan(state, action);
    case CONSTANTS.DELETE_PLAN:
      return deletePlan(state, action);
    case CONSTANTS.SET_PRICING_DATA:
      return setPricingForEdit(state, action);
    case CONSTANTS.RESET_PRICES_PLANS:
      return resetPricesPlanReducer(state, action);
    case CONSTANTS.SET_CONDITIONAL_TOGGLE:
      return setCondtionalAccessToggle(state, action);
    case CONSTANTS.SET_FORM_LINK_DATA:
      return setFormLinkData(state, action);
    case CONSTANTS.SET_SUBSCRIPTION_PERIOD:
      return setSelectedSubscriptionPeriod(state, action);
    case CONSTANTS.CUSTOM_PERIOD:
      return setCustomPeriodDuration(state, action);
    case CONSTANTS.CUSTOM_DAYS:
      return setCustomPeriodNumber(state, action);
    case CONSTANTS.SET_SUBSCRIPTION_PRICE:
      return setSubscriptionPrice(state, action);
    case CONSTANTS.CREATE_GROUP_SUCCESS:
      return createGroupSuccess(state, action);
    case CONSTANTS.ADD_FREE_PLAN_DAYS:
      return setFreePlanDaysData(state, action);
    case CONSTANTS.PLATFORM_SELECTED:
      return setPlatformForFlow(state, action);
    case CONSTANTS.EDIT_GROUP_SUCCESS:
    default:
      return state;
  }
};

export default reducer;
