import React from "react";
import { useSelector } from "react-redux";
import GroupsTab from "../GroupsTab";
import MessagesTab from "../MessagesTab";

const SwitchTabs = ({ activeTabId }) => {
  switch (activeTabId) {
    case 1:
      return <GroupsTab />;
    case 2:
      return <MessagesTab />;
    default:
      return <h1>Loading...</h1>;
  }
};

export default SwitchTabs;
