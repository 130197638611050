import React, { useEffect } from "react";
import "../../../scss/Details/CouponListing.scss";
import discountShape from "../../../assets/coupon/discountShape.png";
import halfCircle from "../../../assets/coupon/halfCircle.svg";
import rightHalfCircle from "../../../assets/coupon/rightHalfCircle.svg";
import circl1 from "../../../assets/coupon/circl1.png";
import whiteDshape from "../../../assets/coupon/whiteDshape.png";
import rightArrow from "../../../assets/coupon/rightArrow.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function CouponListing(props) {
  const { data, viewAll, groupId } = props;
  // useEffect(()=>{

  // },[])
  const navigate = useNavigate();
  if (data.length) {
    return (
      <div className="CouponListing">
        {/* head */}
        <div className="CouponListing_header">
          <div className="CouponListing_header_text">All Coupons</div>
          <div className="CouponListing_header_btn">
            <div className="createMore_img">
              <img src={circl1} style={{ width: "100%", height: "100%" }} />
            </div>
            <div onClick={() => navigate(`/group/${groupId}/createCoupon`)}>
              Create More
            </div>
          </div>
        </div>
        {/* list */}
        <div className="scrollmenu">
          {/* <div className="CouponListing_coupons"> */}
          {data.map((item, index) => {
            console.log("iteme", item);
            if (index <= 2) {
              return (
                <div
                  onClick={() => navigate(`/group/${groupId}/coupons`)}
                  className="CouponListing_coupons_item"
                >
                  {/* left half circle */}
                  <div className="leftCircle">
                    <img
                      src={halfCircle}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* discount img */}
                  <div className="CouponListing_coupons_img">
                    <img
                      src={discountShape}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/* name and off */}
                  <div className="CouponListing_coupons_block">
                    <div className="CouponListing_coupons_name">
                      {item.name}
                    </div>
                    <div className="CouponListing_coupons_discount">
                      {item.type == "discount"
                        ? `₹ ${item.value} off`
                        : `${item.value}% off`}
                    </div>
                  </div>
                  {/* right half circle */}
                  <div className="rightCircle">
                    <img
                      src={rightHalfCircle}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              );
            } else if (viewAll) {
              return (
                <div
                  onClick={() => navigate(`/group/${groupId}/coupons`)}
                  className="viewAll"
                >
                  <div className="viewAll_text">View All</div>
                  <div className="viewAll_img">
                    <img
                      src={rightArrow}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
        {/* </div> */}
      </div>
    );
  }
  return (
    <div className="emptyCoupon">
      <div className="emptyCoupon_header">
        <div className="emptyCoupon_header_text">Coupons</div>
        <div className="emptyCoupon_header_description">
          Create you own custom coupons and boost your earnings.
        </div>
      </div>
      <div className="emptyCoupon_btn">
        <div className="emptyCoupon_btn_img">
          <img src={whiteDshape} style={{ width: "100%", height: "100%" }} />
        </div>
        <div
          onClick={() => navigate(`/group/${groupId}/createCoupon`)}
          className="emptyCoupon_btn_text"
        >
          Create
        </div>
      </div>
    </div>
  );
}
export default CouponListing;
