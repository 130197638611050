import { call, put, select, takeLatest } from "redux-saga/effects";
import getActionObject from "../../../utils/getActionObject";
import CONSTANTS from "./constants";
import { getChannelEarnings } from "./services";

function* getChannelEarningsSaga(payload) {
  console.log("saga: ", payload);
  try {
    let v2Enabled = yield select((state) => state?.user?.v2Enabled);
    payload.v2Enabled = v2Enabled;
    const apiCall = yield call(getChannelEarnings, payload);
    const { paymentBreakdown } = apiCall?.data?.data;
    if (paymentBreakdown.length > 0) {
      payload.payload.offset = payload.payload.offset + 10;
      let temp = { ...apiCall?.data.data };
      temp.offset = payload.payload.offset;
      const getAction = yield call(
        getActionObject,
        CONSTANTS.CHANNEL_EARNINGS_SUCCESS,
        temp
      );
      yield put({ ...getAction });
    }
  } catch (error) {
    const getAction = yield call(
      getActionObject,
      CONSTANTS.CHANNEL_EARNINGS_GET_FAILURE
    );
    yield put({ ...getAction });
  }
}

export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.CHANNEL_EARNINGS_GET, getChannelEarningsSaga);
}
