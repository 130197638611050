import { put, call, select, takeLatest, take } from "redux-saga/effects";
import {
  generateOtpService,
  verifyOtpService,
  pageDataService,
  registerUserService,
  orderStatusService,
  updateEmailAddressService,
  groupDataService,
  applyCouponService,
} from "../../store/Purchase/service";
import toast from "react-hot-toast";
import { createOrder } from "./service";
import CONSTANTS from "./constants";
import { razorPayKey } from "../../routes/Purchase/components/Payment/Razorpay";
import handleRazorpayUtil from "./handleRazorpayUtil";
import getUserToken from "../../utils/getUserToken";
import { useSelector } from "react-redux";

const getInvoiceEnabled = (state) => state?.purchaseReducer?.pageData?.data;

function* generateOtpSaga(data) {
  // let state = store.getState();
  yield put({
    type: CONSTANTS.GENERATE_OTP_LOADER,
    generateOtpLoading: true,
  });
  const response = yield call(generateOtpService, {
    ...data.payload,
    // token: state.persistedData && state.persistedData.token,
  });
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.GENERATE_OTP_SUCCESS,
      generateOtpLoading: false,
      generateOtp: response.data.data,
    });
    yield put({
      type: CONSTANTS.SET_PURCHASE_SCREEN,
      payload: "otp",
      internationalUser: data.internationalUser,
    });
  } else {
    yield put({
      type: CONSTANTS.GENERATE_OTP_FAILURE,
      generateOtpLoading: false,
      generateOtp: response.data.data,
      generateOtpError: true,
    });
    toast.error("Something went wrong");
  }
}

function* verifyOtpSaga(data) {
  const groupDetails = yield select(getInvoiceEnabled);
  console.log("gd: ", groupDetails);
  const { invoiceEnabled } = groupDetails;
  yield put({
    type: CONSTANTS.VERIFY_OTP_LOADER,
    verifyOtpLoading: true,
  });
  const response = yield call(verifyOtpService, {
    ...data.payload,
    // token: state.persistedData && state.persistedData.token,
  });

  console.log("responseVerifyOtp", response);
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.VERIFY_OTP_SUCCESS,
      // verifyOtpLoading: false,
      verifyOtp: response?.data?.data,
    });

    if (response?.data?.data?.token) {
      yield put({
        type: CONSTANTS.SET_VERIFY_OTP,
        payload: response?.data?.data?.token,
      });
    }

    if (response?.data?.data?.exists) {
      if (response?.data?.data?.email == null) {
        if (
          invoiceEnabled &&
          response?.data?.data?.state &&
          response?.data?.data?.state.length > 0
        ) {
          yield put({
            type: CONSTANTS.STATE_EXISTS,
            payload: response?.data?.data,
          });
          yield put({
            type: CONSTANTS.SET_PURCHASE_SCREEN,
            payload: "register",
          });
        } else {
          yield put({
            type: CONSTANTS.EMAIL_EXISTS,
            payload: response?.data?.data,
          });
          yield put({
            type: CONSTANTS.SET_PURCHASE_SCREEN,
            payload: "register",
          });
        }
      } else if (invoiceEnabled && !response?.data?.data?.state) {
        yield put({
          type: CONSTANTS.STATE_EXISTS,
          payload: response?.data?.data,
        });
        yield put({
          type: CONSTANTS.SET_PURCHASE_SCREEN,
          payload: "register",
        });
      } else {
        let period = yield select(
          (state) => state?.purchaseReducer?.pricingPayload?.selectedPeriod
        );
        let pricingPayload = yield select(
          (state) => state?.purchaseReducer?.pricingPayload
        );
        console.log("pricingpayloaddd", pricingPayload);
        var payload = {
          orderType: window.location.pathname.includes("/m")
            ? "message"
            : "group",
          groupId: window.location.pathname.includes("/g")
            ? parseInt(window.location.pathname.split("/").pop())
            : undefined,
          messageId: window.location.pathname.includes("/m")
            ? window.location.pathname.split("/").pop()
            : undefined,
          period: period || undefined,
          token: response?.data?.data?.token,
        };
        if (pricingPayload?.periodTitle) {
          payload.periodTitle = pricingPayload?.periodTitle;
        }
        if (pricingPayload?.selectedOffer == "Free Trial") {
          payload.freemium = 1;
        }

        let alreadyPurchasedUser = yield call(orderStatusService, payload);
        console.log("alreadyPurcahsedUser", alreadyPurchasedUser);
        if (
          alreadyPurchasedUser &&
          alreadyPurchasedUser?.data?.data?.latestOrderStatus ==
            "FreemiumAvailed"
        ) {
          window.payload = {
            preauthLink: alreadyPurchasedUser?.data?.data,
          };
          yield put({
            type: CONSTANTS.SET_PURCHASE_SCREEN,
            payload: "freemium",
          });
        } else if (
          alreadyPurchasedUser &&
          (alreadyPurchasedUser?.data?.data?.latestOrderStatus == "Attempted" ||
            alreadyPurchasedUser?.data?.data?.latestOrderStatus == "pending")
        ) {
          window.payload = {
            orderId: alreadyPurchasedUser?.data?.data?.orderId,
          };
          yield put({
            type: CONSTANTS.SET_PURCHASE_SCREEN,
            payload: "pendingPayment",
          });
        } else {
          if (alreadyPurchasedUser && alreadyPurchasedUser.status == 200) {
            yield put({
              type: CONSTANTS.SET_INVITE_LINK,
              payload: alreadyPurchasedUser?.data?.data?.inviteLink,
            });
            yield put({
              type: CONSTANTS.ORDER_STATUS_SUCCESS,
              orderStatus: alreadyPurchasedUser?.data?.data,
            });
          }
          if (!alreadyPurchasedUser?.data?.data?.alreadyPurchased) {
            if (
              groupDetails?.usageLimit &&
              !alreadyPurchasedUser?.data?.data?.existsInGroup
            ) {
              console.log("usageaLimit");
              yield put({
                type: CONSTANTS.SET_PURCHASE_SCREEN,
                payload: "usageLimit",
              });
            } else {
              let formDetails = yield select(
                (state) => state?.purchaseReducer?.formDetails
              );
              if (payload.hasOwnProperty("freemium")) {
                delete payload.freemium;
              }
              console.log("createOrderPayload", payload);
              let applyCouponSuccess = yield select(
                (state) => state?.purchaseReducer?.applyCouponSuccess
              );
              let v2Enabled = yield select((state) => state?.user?.v2Enabled);

              console.log("appply1", applyCouponSuccess);
              if (applyCouponSuccess) {
                let coupon = yield select(
                  (state) => state?.purchaseReducer?.couponCode
                );
                if (coupon) {
                  console.log("coup", coupon);
                  payload.couponName = coupon;
                }
              }
              payload.v2Enabled = v2Enabled;

              let createOrderRes = yield call(createOrder, payload);
              console.log("couponDataError", createOrderRes);
              if (createOrderRes?.response?.data?.data?.couponError) {
                yield put({
                  type: CONSTANTS.SET_PURCHASE_SCREEN,
                  payload: "pageDetails",
                });
              } else if (
                createOrderRes?.data?.status == "success" &&
                createOrderRes?.data?.data?.orderId == "freemium"
              ) {
                window.payload = {
                  freemiumCreateResponse:
                    createOrderRes?.data?.data?.freemiumCreateResponse,
                };
                yield put({
                  type: CONSTANTS.SET_PURCHASE_SCREEN,
                  payload: "success",
                });
                // createCheck
              } else if (createOrderRes?.status == 200) {
                handleRazorpayUtil(
                  createOrderRes?.data?.data?.payload?.id,
                  createOrderRes?.data?.data?.email,
                  formDetails?.mobile,
                  response?.data?.data?.token
                );
              } else if (createOrderRes?.response?.status == 400) {
                yield put({
                  type: CONSTANTS.SET_PURCHASE_SCREEN,
                  payload: "pageDetails",
                });
              }
            }
          } else {
            yield put({
              type: CONSTANTS.SET_PURCHASE_SCREEN,
              payload: "alreadyDone",
            });
          }
          //
        }
      }
    } else {
      if (!groupDetails?.usageLimit) {
        yield put({
          type: CONSTANTS.SET_PURCHASE_SCREEN,
          payload: "register",
        });
      } else {
        yield put({
          type: CONSTANTS.SET_PURCHASE_SCREEN,
          payload: "usageLimit",
        });
      }
    }
    yield put({
      type: CONSTANTS.VERIFY_OTP_LOADER,
      verifyOtpLoading: true,
    });
    // HANDLING RAZORPAY
  } else {
    toast.error("Invalid OTP");
    yield put({
      type: CONSTANTS.VERIFY_OTP_FAILURE,
      verifyOtpLoading: false,
      verifyOtp: response.data.data,
      verifyOtpError: true,
    });
  }
}

function* preAuthDataSaga(data) {
  if (data?.payload?.email == null) {
    yield put({
      type: CONSTANTS.EMAIL_EXISTS,
      payload: data?.payload, // have to pass name here....
    });
    yield put({
      type: CONSTANTS.SET_PURCHASE_SCREEN,
      payload: "register",
    });
  } else {
    var payload = {
      orderType: "group",
      // groupId: window.location.pathname.includes("/g")
      //   ? parseInt(window.location.pathname.split("/").pop())
      //   : undefined,
      groupId: parseInt(data?.payload?.groupId),
      // messageId: window.location.pathname.includes("/m")
      //   ? window.location.pathname.split("/").pop()
      //   : undefined,
      period: data?.payload?.period || undefined,
      token: data?.payload?.token,
    };
    console.log("preAuthSaga", payload);
    let alreadyPurchasedUser = yield call(orderStatusService, payload);
    console.log("alreadyPurcahsedUser", alreadyPurchasedUser);
    if (alreadyPurchasedUser && alreadyPurchasedUser.status == 200) {
      yield put({
        type: CONSTANTS.SET_INVITE_LINK,
        payload: alreadyPurchasedUser?.data?.data?.inviteLink,
      });
      yield put({
        type: CONSTANTS.ORDER_STATUS_SUCCESS,
        orderStatus: alreadyPurchasedUser?.data?.data,
      });
    }
    if (!alreadyPurchasedUser?.data?.data?.alreadyPurchased) {
      let formDetails = yield select(
        (state) => state?.purchaseReducer?.formDetails
      );
      console.log("formedjegf", formDetails);
      if (data?.payload?.periodTitle) {
        payload.periodTitle = data?.payload?.periodTitle;
      }
      let applyCouponSuccess = yield select(
        (state) => state?.purchaseReducer?.applyCouponSuccess
      );

      console.log("appply1", applyCouponSuccess);
      if (applyCouponSuccess) {
        let coupon = yield select(
          (state) => state?.purchaseReducer?.couponCode
        );
        if (coupon) {
          console.log("coup", coupon);
          payload.couponName = coupon;
        }
      }
      let v2Enabled = yield select((state) => state?.user?.v2Enabled);
      payload.v2Enabled = v2Enabled;
      let createOrderRes = yield call(createOrder, payload);
      if (createOrderRes?.response?.data?.data?.couponError) {
        yield put({
          type: CONSTANTS.RESET_COUPON_ERROR,
          payload: true,
        });
        yield put({
          type: CONSTANTS.SET_PURCHASE_SCREEN,
          payload: "pageDetails",
        });
      }
      // createCheck
      else if (createOrderRes?.status == 200) {
        handleRazorpayUtil(
          createOrderRes?.data?.data?.payload?.id,
          createOrderRes?.data?.data?.email || data?.payload?.email,
          formDetails?.mobile || data?.payload?.phone,
          data?.payload?.token
        );
      }
    } else {
      yield put({
        type: CONSTANTS.SET_PURCHASE_SCREEN,
        payload: "alreadyDone",
      });
    }
  }
}

function* pageDataSaga(data) {
  // let state = store.getState();
  yield put({
    type: CONSTANTS.PAGE_DATA_LOADER,
    pageDataLoading: true,
  });
  const response = yield call(pageDataService, {
    ...data.payload,
    // token: state.persistedData && state.persistedData.token,
  });
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.PAGE_DATA_SUCCESS,
      pageDataLoading: false,
      pageData: response.data,
    });
  } else {
    yield put({
      type: CONSTANTS.PAGE_DATA_FAILURE,
      pageDataLoading: false,
      pageData: response.data,
      pageDataError: true,
    });
  }
}

// group Data Saga

function* groupDataSaga(data) {
  yield put({
    type: CONSTANTS.GROUP_DATA_LOADER,
    groupDataLoading: true,
  });
  const response = yield call(groupDataService, {
    ...data.payload,
    // token: state.persistedData && state.persistedData.token,
  });
  // console.log("responseGroupData", response.data.data);
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.GROUP_DATA_SUCCESS,
      groupDataLoading: false,
      groupData: response.data,
    });
  } else {
    yield put({
      type: CONSTANTS.GROUP_DATA_FAILURE,
      groupDataLoading: false,
      groupData: response.data,
      groupDataError: true,
    });
  }
}

function* registerUserSaga(data) {
  // let state = store.getState();
  yield put({
    type: CONSTANTS.REGISTER_USER_LOADER,
    registerUserLoading: true,
  });
  const response = yield call(registerUserService, {
    ...data.payload,
    // token: state.persistedData && state.persistedData.token,
  });

  let verifyOtpToken = yield select(
    (state) => state?.purchaseReducer?.verifyOtpToken
  );
  let formDetails = yield select(
    (state) => state?.purchaseReducer?.formDetails
  );
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.REGISTER_USER_SUCCESS,
      // registerUserLoading: false,
      registerUser: response.data,
    });

    if (response?.data?.data?.token) {
      yield put({
        type: CONSTANTS.SET_VERIFY_OTP,
        payload: response?.data?.data?.token,
      });
    }
    let period = yield select(
      (state) => state?.purchaseReducer?.pricingPayload?.selectedPeriod
    );
    var payload = {
      orderType: window.location.pathname.includes("/m") ? "message" : "group",
      groupId: window.location.pathname.includes("/g")
        ? parseInt(window.location.pathname.split("/").pop())
        : undefined,
      messageId: window.location.pathname.includes("/m")
        ? window.location.pathname.split("/").pop()
        : undefined,
      period: period,
      token: response?.data?.data?.token,
    };
    let pricingPayload = yield select(
      (state) => state?.purchaseReducer?.pricingPayload
    );
    if (pricingPayload?.periodTitle) {
      payload.periodTitle = pricingPayload?.periodTitle;
    }
    if (pricingPayload?.selectedOffer == "Free Trial") {
      payload.freemium = 1;
    }
    let applyCouponSuccess = yield select(
      (state) => state?.purchaseReducer?.applyCouponSuccess
    );

    console.log("appply1", applyCouponSuccess);
    if (applyCouponSuccess) {
      let coupon = yield select((state) => state?.purchaseReducer?.couponCode);
      if (coupon) {
        console.log("coup", coupon);
        payload.couponName = coupon;
      }
    }
    let v2Enabled = yield select((state) => state?.user?.v2Enabled);
    payload.v2Enabled = v2Enabled;
    let createOrderRes = yield call(createOrder, payload);
    console.log("createorderresss", createOrderRes);
    if (
      createOrderRes?.data?.status == "success" &&
      createOrderRes?.data?.data?.orderId == "freemium"
    ) {
      yield put({
        type: CONSTANTS.REGISTER_USER_LOADER,
        registerUserLoading: false,
      });
      window.payload = {
        freemiumCreateResponse:
          createOrderRes?.data?.data?.freemiumCreateResponse,
      };
      yield put({
        type: CONSTANTS.SET_PURCHASE_SCREEN,
        payload: "success",
      });
      // createCheck
    } else if (createOrderRes.status == 200) {
      handleRazorpayUtil(
        createOrderRes?.data?.data?.payload?.id,
        formDetails?.email,
        formDetails?.mobile,
        response?.data?.data?.token
      );
      yield put({
        type: CONSTANTS.REGISTER_USER_LOADER,
        registerUserLoading: false,
      });
    }
  } else {
    toast.error(response?.data?.message);
    yield put({
      type: CONSTANTS.REGISTER_USER_FAILURE,
      registerUserLoading: false,
      registerUser: response.data,
      registerUserError: true,
    });
  }
}

function* orderStatusSaga(data) {
  // let state = store.getState();
  yield put({
    type: CONSTANTS.ORDER_STATUS_LOADER,
    orderStatusLoading: true,
  });
  var payload = {
    orderType: window.location.pathname.includes("/m") ? "message" : "group",
    groupId: window.location.pathname.includes("/g")
      ? parseInt(window.location.pathname.split("/").pop())
      : undefined,
    messageId: window.location.pathname.includes("/m")
      ? window.location.pathname.split("/").pop()
      : undefined,
  };
  const response = yield call(orderStatusService, {
    payload,
    // token: state.persistedData && state.persistedData.token,
  });
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.ORDER_STATUS_SUCCESS,
      orderStatusLoading: false,
      orderStatus: response.data,
    });
  } else {
    yield put({
      type: CONSTANTS.ORDER_STATUS_FAILURE,
      orderStatusLoading: false,
      orderStatus: response.data,
      orderStatusError: true,
    });
  }
}

function* createOrderSaga(data) {
  // let state = store.getState();
  yield put({
    type: CONSTANTS.CREATE_ORDER_LOADER,
    createOrderLoading: true,
  });
  let period = yield select(
    (state) => state?.purchaseReducer?.pricingPayload?.selectedPeriod
  );
  let pricingPayload = yield select(
    (state) => state?.purchaseReducer?.pricingPayload
  );
  let formDetails = yield select(
    (state) => state?.purchaseReducer?.formDetails
  );

  let verifyOtpToken = yield select(
    (state) => state?.purchaseReducer?.verifyOtpToken
  );
  var payload = {
    orderType: window.location.pathname.includes("/m") ? "message" : "group",
    groupId: window.location.pathname.includes("/g")
      ? parseInt(window.location.pathname.split("/").pop())
      : window?.payload.groupId
      ? window?.payload?.groupId
      : undefined,
    messageId: window.location.pathname.includes("/m")
      ? window.location.pathname.split("/").pop()
      : undefined,
    period: period || window?.payload?.period,
    token: verifyOtpToken || window?.payload?.token,
  };
  // let tokeennn = localStorage.getItem("preAuthToken");
  // console.log(tokeennn, "lllpppp");
  if (pricingPayload?.periodTitle) {
    console.log("pricingPayload", pricingPayload?.periodTitle);
    payload.periodTitle = pricingPayload?.periodTitle;
  }
  if (pricingPayload?.selectedOffer == "Free Trial") {
    payload.freemium = 1;
  }
  let applyCouponSuccess = yield select(
    (state) => state?.purchaseReducer?.applyCouponSuccess
  );
  let v2Enabled = yield select((state) => state?.user?.v2Enabled);

  console.log("appply1", applyCouponSuccess);
  if (applyCouponSuccess) {
    let coupon = yield select((state) => state?.purchaseReducer?.couponCode);
    if (coupon) {
      console.log("coup", coupon);
      payload.couponName = coupon;
    }
  }
  payload.v2Enabled = v2Enabled;
  const response = yield call(createOrder, payload);
  if (response && response.status == 200) {
    yield put({
      type: CONSTANTS.CREATE_ORDER_SUCCESS,
      createOrderLoading: false,
      createOrder: response?.data,
    });

    handleRazorpayUtil(
      response?.data?.data?.payload?.id,
      formDetails?.email ||
        response?.data?.data?.email ||
        window?.payload?.email,
      formDetails?.mobile || window?.payload?.phone,
      verifyOtpToken || window?.payload?.token
    );
  } else {
    yield put({
      type: CONSTANTS.CREATE_ORDER_FAILURE,
      createOrderLoading: false,
      createOrder: response.data,
      createOrderError: true,
    });
  }
}

function* updateEmailSaga(data) {
  yield put({
    type: CONSTANTS.UPDATE_EMAIL_LOADER,
    payload: true,
  });
  let token = yield select((state) => state?.purchaseReducer?.verifyOtpToken);
  token = token ? token : window?.payload?.token;
  let upData = { ...data, token };
  const response = yield call(updateEmailAddressService, upData);
  console.log("reposeaUPdateEmail", response);
  // rest flow of payment handles
  if (response?.data?.status == "success") {
    yield put({
      type: CONSTANTS.UPDATE_EMAIL_LOADER,
      payload: false,
    });
    let period = yield select(
      (state) => state?.purchaseReducer?.pricingPayload?.selectedPeriod
    );
    var payload = {
      orderType: window.location.pathname.includes("/m") ? "message" : "group",
      groupId: window.location.pathname.includes("/g")
        ? parseInt(window.location.pathname.split("/").pop())
        : window?.payload.groupId
        ? window?.payload?.groupId
        : undefined,
      messageId: window.location.pathname.includes("/m")
        ? window.location.pathname.split("/").pop()
        : undefined,
      period: period || window?.payload?.period,
      token: token || window?.payload?.token,
    };
    let pricingPayload = yield select(
      (state) => state?.purchaseReducer?.pricingPayload
    );
    if (pricingPayload?.periodTitle) {
      payload.periodTitle = pricingPayload?.periodTitle;
    }
    if (pricingPayload?.selectedOffer == "Free Trial") {
      payload.freemium = 1;
    }
    let alreadyPurchasedUser = yield call(orderStatusService, payload);
    console.log("alreadyPurcahsedUser", alreadyPurchasedUser);

    // comparing alreadyPurchased data
    // 1. dispatching invite link and order success status
    if (alreadyPurchasedUser && alreadyPurchasedUser.status == 200) {
      yield put({
        type: CONSTANTS.SET_INVITE_LINK,
        payload: alreadyPurchasedUser?.data?.data?.inviteLink,
      });
      yield put({
        type: CONSTANTS.ORDER_STATUS_SUCCESS,
        orderStatus: alreadyPurchasedUser?.data?.data,
      });
    }
    // 2. redirection to razorpay and screens.
    if (!alreadyPurchasedUser?.data?.data?.alreadyPurchased) {
      let v2Enabled = yield select((state) => state?.user?.v2Enabled);
      let formDetails = yield select(
        (state) => state?.purchaseReducer?.formDetails
      );
      if (payload.hasOwnProperty("freemium")) {
        delete payload.freemium;
      }
      console.log("createOrderPayload", payload);
      let applyCouponSuccess = yield select(
        (state) => state?.purchaseReducer?.applyCouponSuccess
      );
      // let v2Enabled = yield select((state) => state?.user?.v2Enabled);

      console.log("appply1", applyCouponSuccess);
      if (applyCouponSuccess) {
        let coupon = yield select(
          (state) => state?.purchaseReducer?.couponCode
        );
        if (coupon) {
          console.log("coup", coupon);
          payload.couponName = coupon;
        }
      }
      payload.v2Enabled = v2Enabled;

      let createOrderRes = yield call(createOrder, payload);
      if (createOrderRes?.response?.data?.data?.couponError) {
        yield put({
          type: CONSTANTS.SET_PURCHASE_SCREEN,
          payload: "pageDetails",
        });
      } else if (
        createOrderRes?.data?.status == "success" &&
        createOrderRes?.data?.data?.orderId == "freemium"
      ) {
        window.payload = {
          freemiumCreateResponse:
            createOrderRes?.data?.data?.freemiumCreateResponse,
        };
        yield put({
          type: CONSTANTS.SET_PURCHASE_SCREEN,
          payload: "success",
        });
        // createCheck
      } else if (createOrderRes?.status == 200) {
        handleRazorpayUtil(
          createOrderRes?.data?.data?.payload?.id,
          createOrderRes?.data?.data?.email,
          formDetails?.mobile,
          token
        );
      }
      // handleRazorpayUtil(
      //   createOrderRes?.data?.data?.payload?.id,
      //   createOrderRes?.data?.data?.email,
      //   formDetails?.mobile,
      //   token
      // );
    } else {
      yield put({
        type: CONSTANTS.SET_PURCHASE_SCREEN,
        payload: "alreadyDone",
      });
    }
  } else {
    yield put({
      type: CONSTANTS.UPDATE_EMAIL_LOADER,
      payload: false,
    });
    // toast.error(response?.data?.data?.message);
  }
}
function* applyCouponSaga(data) {
  // console.log("dataaa", data);
  yield put({
    type: CONSTANTS.APPLY_COUPON_LOADER,
    payload: true,
  });
  const { payload } = data;
  payload.token = getUserToken();
  // const token = getUserToken();
  // console.log("tokennn",token);
  const applyCouponResponse = yield call(applyCouponService, data.payload);
  // console.log("applyresposnee", applyCouponResponse);
  if (applyCouponResponse?.request?.status == "200") {
    yield put({
      type: CONSTANTS.APPLY_COUPON_LOADER,
      payload: false,
    });
    yield put({
      type: CONSTANTS.APPLY_COUPON_SUCCESS,
      payload: applyCouponResponse.data.data,
    });
  } else if (applyCouponResponse?.request?.status == "400") {
    yield put({
      type: CONSTANTS.APPLY_COUPON_LOADER,
      payload: false,
    });
    yield put({
      type: CONSTANTS.APPLY_COUPON_FAILURE,
      payload: true,
    });
  } else {
    yield put({
      type: CONSTANTS.APPLY_COUPON_LOADER,
      payload: false,
    });
  }
}
export default function* actionWatcher() {
  yield takeLatest(CONSTANTS.GENERATE_OTP, generateOtpSaga);
  yield takeLatest(CONSTANTS.VERIFY_OTP, verifyOtpSaga);
  yield takeLatest(CONSTANTS.PAGE_DATA, pageDataSaga);
  yield takeLatest(CONSTANTS.GROUP_DATA, groupDataSaga);
  yield takeLatest(CONSTANTS.REGISTER_USER, registerUserSaga);
  yield takeLatest(CONSTANTS.GET_ORDER_STATUS, orderStatusSaga);
  yield takeLatest(CONSTANTS.CREATE_ORDER, createOrderSaga);
  yield takeLatest(CONSTANTS.UPDATE_EMAIL, updateEmailSaga);
  yield takeLatest(CONSTANTS.PRE_AUTH, preAuthDataSaga);
  yield takeLatest(CONSTANTS.APPLY_COUPON, applyCouponSaga);
}
