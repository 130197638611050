import React from "react";
const OtpInput = ({
  style,
  type,
  placeholder,
  value,
  handleChange,
  handleBlur,
  additionalClasses,
  isEditable,
  refEl,
  ...props
}) => {
  return (
    <input
      className={`${additionalClasses || ""}`}
      style={style}
      type={type || "text"}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={isEditable}
      ref={refEl}
      {...props}
    />
  );
};

export default OtpInput;
