import CONSTANTS from "./constants";

export const setName = (payload) => {
  return {
    type: CONSTANTS.SET_NAME,
    payload,
  };
};

export const setAmount = (payload) => {
  return {
    type: CONSTANTS.SET_AMOUNT,
    payload,
  };
};

export const setType = (payload) => {
  return {
    type: CONSTANTS.SET_TYPE,
    payload,
  };
};

export const createCoupon = (payload) => {
  return {
    type: CONSTANTS.CREATE_COUPON_GET,
    payload,
  };
};

export const resetCoupon = () => {
  return {
    type: CONSTANTS.RESET,
  };
};
