import axios from "axios";
import { BASE_URL } from "../../axios-config";
import getAxiosConfig, { getUserToken } from "../../utils/getAxiosConfig";
// const token =
//   "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IlByYXRlZWsgSmFpc3dhbCIsImVtYWlsIjoiIiwibW9iaWxlIjoiOTE4MDkwOTg4OTgwIiwiaWF0IjoxNjUyNDgwNTQyLCJleHAiOjE2NTQyMDg1NDJ9.F4I1yLfZLjEq-l_-qNAEOACM7UsEd7mfaLeWYgoroGllLTSJxqrLPon6RRWdHIFS";

export const createMessageService = (payload) => {
  return axios({
    ...getAxiosConfig("POST", () => `/message`, payload?.data?.payload),
  });
  //   return axios({
  //     method: "POST",
  //     url: `${BASE_URL}/message`,
  //     headers: {
  //       "x-access-token": token,
  //     },
  //     data: {
  //       ...payload?.data?.payload,
  //     },
  //   })
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((err) => {
  //       return err.response;
  //     });
};

export const editMessageService = (payload) => {
  const formData = payload?.data?.payload;
  let messageId = payload?.data?.payload?.id;
  if (formData && !messageId) messageId = formData.get("id");
  return axios({
    ...getAxiosConfig(
      "PUT",
      () => `/message/${messageId}`,
      payload?.data?.payload
    ),
  });
};
