import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import "./styles.scss";

const TermsAndConditionsModal = ({
  text,
  checked,
  handleChecked,
  handleButtonClick,
}) => {
  return (
    <div className="backdrop">
      <div className="tcBox">
        <div className="tcBox__heading">Terms And Conditions</div>
        <div
          className="tcBox__text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <div className="tcBox__footer">
          <div className="tcBox__checkbox">
            <Checkbox checked={checked} onChange={handleChecked} />
            <div className="tcBox__checkbox__text">
              I have reviewed the above{" "}
              <a href="/tnc" className="tcBox__checkbox__linkText">
                T&C
              </a>
            </div>
          </div>
          <button
            className="tcBox__button"
            disabled={!checked}
            onClick={handleButtonClick}
          >
            I Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
