import axios from "axios";
import { BASE_URL } from "../../axios-config";
import { getUserToken } from "../../utils/getAxiosConfig";
import store from "../store";
// const token =
//   "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MywibmFtZSI6IlByYXRlZWsgSmFpc3dhbCIsImVtYWlsIjoiIiwibW9iaWxlIjoiOTE4MDkwOTg4OTgwIiwiaWF0IjoxNjUyNDgwNTQyLCJleHAiOjE2NTQyMDg1NDJ9.F4I1yLfZLjEq-l_-qNAEOACM7UsEd7mfaLeWYgoroGllLTSJxqrLPon6RRWdHIFS";
// const state = store.getState()
// const token = state?.user?.token || JSON.parse(localStorage.getItem('token'))
export const fetchGroupsService = (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/group?status=created&limit=${payload?.payload?.limit}&offset=${payload?.payload?.offset}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const fetchMessagesService = (payload) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}/message?limit=${payload?.payload?.limit}&offset=${payload?.payload?.offset}`,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const setTermsAndConditionsService = (payload) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/user/tnc`,
    data: payload,
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
