import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import SingleContent from "../singleContent";
import * as actions from "../../../../store/Home/actions";
import * as gstActions from "../../../Profile/store/actions";
import * as telegramActions from "../../../../store/automatedGroup/action";
import { resetState } from "../../../../store/CreateGroup/actions";
import ShareBottomsheet from "../../../../components/ShareBottomsheet";
import ReactLoading from "react-loading";
import InfiniteScroll from "react-infinite-scroll-component";

import telegram from "../../../../assets/emptyStates/telegram.png";
import PlatformBottomSheet from "./PlatformBottom/PlatformBottomSheet";
import WhatsappGroupConfirmation from "../../../Automation/BottomSheets/WhatsappGroupConfirmation";
const GroupsTab = (props) => {
  const [showShare, setShowShare] = useState(false);
  const [socialLinks, setSocialLinks] = useState({});
  const [showPlatformSheet, setShowPlatformSheet] = useState(true);
  const dispatch = useDispatch();
  const profileFilled = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.profileFilled
  );
  const whatsVisibleHome = useSelector(
    (state) => state?.home?.whatsVisibleHome
  );
  useEffect(() => {
    props.resetCreateGroupForm();
    props.fetchGroups({
      limit: props.limit,
      offset: 0,
    });
    if (profileFilled) {
      props.fetchProfile();
    }

    return () => {
      props.resetState();
      props.clearTelegramChannelIdAction();
    };
  }, []);

  return (
    <div className="Home_Groups">
      <InfiniteScroll
        dataLength={props?.groups?.length || 100}
        next={() =>
          props.fetchGroups({ limit: props.limit, offset: props.offset })
        }
        hasMore={true}
        height={"90vh"}
        refreshFunction={() =>
          props.fetchGroups({ limit: props.limit, offset: 0 })
        }
        pullDownToRefresh
        pullDownToRefreshThreshold={80}
      >
        {props?.isLoading ? (
          <div className="Loader">
            <ReactLoading
              type={"spinningBubbles"}
              color={"#2278FF"}
              height={50}
              width={50}
            />
          </div>
        ) : props?.groups?.length ? (
          <>
            {props.groups.map((message) => (
              <SingleContent
                data={message}
                key={message.id}
                type={1}
                setShowShare={setShowShare}
                setSocialLinks={setSocialLinks}
              />
            ))}
          </>
        ) : (
          <div className="Home_empty">
            <img src={telegram} className="Home_empty-img" />
            <p className="Home_empty-text">Create Your First Telegram Group</p>
            <p className="Home_empty-subtext">
              Tap the “+” icon to start monetising your knowledge
            </p>
          </div>
        )}
      </InfiniteScroll>

      {showShare ? (
        <ShareBottomsheet
          socialLinks={socialLinks}
          handleClose={() => setShowShare(false)}
        />
      ) : null}
      {props.platformBottom ? (
        <PlatformBottomSheet
          isVisible={props.platformBottom}
          // socialLinks={socialLinks}
          // handleClose={() => setShowShare(false)}
        />
      ) : null}
      {whatsVisibleHome ? (
        <WhatsappGroupConfirmation
          isPhoneVisible={whatsVisibleHome}
          actionOnBlock={() =>
            dispatch(actions.setWhatsAppConfirmationBottom(false))
          }
          groupNotCreated={whatsVisibleHome}
          navigateToGroupDetail={() => {
            dispatch(actions.setWhatsAppConfirmationBottom(false));
          }}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state?.home?.isLoading,
    limit: state?.home?.limit,
    offset: state?.home?.offset,
    hasMore: state?.home?.hasMore,
    groups: state?.home?.groups,
    platformBottom: state?.home?.platformBottom,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    fetchGroups: (payload) => dispatch(actions.fetchGroups(payload)),
    resetState: () => dispatch(actions.resetState()),
    resetCreateGroupForm: () => dispatch(resetState()),
    fetchProfile: (payload) => dispatch(gstActions.fetchProfileAction(payload)),
    clearTelegramChannelIdAction: (payload) =>
      dispatch(telegramActions.clearTelegramChannelIdAction(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(GroupsTab);
