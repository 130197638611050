import CONSTANTS from "./constants";

// INITIAL STATE
const intialState = {
  allCouponsData: [],
  isLoading: false,
  error: null,
  // active inactive
  toggleLoading: false,
  toggleError: null,
};

// REDUCER
const allCouponsReducer = (state = intialState, action) => {
  // console.log("actionnsff", action);
  switch (action.type) {
    case CONSTANTS.ALL_COUPONS_SUCCESS: {
      return {
        ...state,
        allCouponsData: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case CONSTANTS.ALL_COUPONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        allCouponsData: [],
      };
    }
    case CONSTANTS.ALL_COUPONS_LOADER: {
      return {
        ...state,
        isLoading: true,
        // allCouponsData: [],
        // toggleLoading:false,
        error: null,
      };
    }
    case CONSTANTS.COUPON_ACTION_LOADER: {
      return {
        ...state,
        toggleLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default allCouponsReducer;
