import CONSTANTS from "./constants";

export const getUserDetailsAction = () => {
  return {
    type: CONSTANTS.USER_DETAILS_GET,
  };
};

export const resetUserDetails = () => {
  return {
    type: CONSTANTS.USER_DETAILS_RESET,
  };
};
