import CONSTANTS from "./constants";

export const addBankAccountDetailsPostAction = (payload) => {
  return {
    type: CONSTANTS.ADD_BANK_DETAILS_POST,
    payload,
  };
};
export const getBankAccountsAction = (payload) => {
  return {
    type: CONSTANTS.GET_BANK_ACCOUNTS,
    payload,
  };
};
export const updateBankAccountsAction = (payload) => {
  return {
    type: CONSTANTS.DELETE_PRIMARY_BANK_ACCOUNT,
    payload,
  };
};
export const agreeTermsAndConditonsAction = (payload) => {
  return {
    type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS,
    payload,
  };
};
export const agreeTermsAndConditonsResetAction = (payload) => {
  return {
    type: CONSTANTS.AGREE_TERMS_AND_CONDITIONS_RESET,
    payload,
  };
};
