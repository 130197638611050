import React from "react";
import "../../../scss/Purchase/usageLimit.scss";
import "../../../scss/Purchase/loader.scss";
import usageLimit from "../../../assets/usageLimit.png";
import { useSelector } from "react-redux";
function UsageLimitScreen() {
  const data = useSelector(
    (state) => state?.purchaseReducer?.pageData?.data?.type
  );
  const type = data === "whatsappGroup" ? "Group" : "Community";
  return (
    <div className="usageLimit">
      <div className="usageLimit_container_logo">
        <img src={usageLimit} style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="usageLimit_container_message">
        <div className="usageLimit_container_message_title">Sorry !</div>
        <div className="usageLimit_container_message_description">
          {`This ${type} has reached its maximum user limit. Please contact the admin of the ${type}`}
        </div>
      </div>
    </div>
  );
}

export default UsageLimitScreen;
