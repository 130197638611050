import React from "react";
import "./styles.scss";
import Bottomsheet from "../../../../../common/Bottomsheet";
// assets
import wa from "../../../../../assets/v2Automation/wa.png";
import tele from "../../../../../assets/v2Automation/tele.png";
import { useDispatch, useSelector } from "react-redux";
import { callPlatformBottomSheet } from "../../../../../store/Home/actions";
import { Navigate, useNavigate } from "react-router-dom";
import { setPlatformSelection } from "../../../../../store/CreateGroup/actions";
import { useState } from "react";
import { useEffect } from "react";
import { hitDeeplinkExecutedV2 } from "../../../../../utils/hitDeeplink";
const PlatformBottomSheet = (props) => {
  const { isVisible } = props;
  // const platforms = [{name:,imageUrl}]
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orgIdNotPresent, setorgIdNotPresent] = useState(true);
  const [showBeta, setShowBeta] = useState(true);
  const orgId = useSelector(
    (state) => state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.orgId
  );
  const remoteOrgData = useSelector((state) => state?.user?.orgIds);
  useEffect(() => {
    if (orgId) {
      console.log("fsdfsd", orgId, remoteOrgData, remoteOrgData.length);
      for (let i = 0; i < remoteOrgData.length; i++) {
        console.log("comparision", remoteOrgData[i], orgId);
        if (remoteOrgData[i] == orgId) {
          console.log("got the id");
          setorgIdNotPresent(false);
          break;
        } else {
          setorgIdNotPresent(true);
        }
      }
    }
  }, [orgId, remoteOrgData]);

  useEffect(() => {
    if (!orgIdNotPresent) {
      setShowBeta(false);
    }
  }, [orgIdNotPresent]);

  const whatsappFlow = () => {
    if (showBeta) {
      hitDeeplinkExecutedV2(
        JSON.stringify({
          screen: "UTIL_BROWSER",
          paramOne: "https://forms.gle/vwsmUhcTYFnx2NuR6",
        }),
        JSON.stringify({
          screen: "UTIL_BROWSER",
          paramOne: "https://forms.gle/vwsmUhcTYFnx2NuR6",
        })
      );
    } else {
      dispatch(setPlatformSelection("Whatsapp"));
      navigate("/platformAutomation");
    }
  };
  const telegramFlow = () => {
    dispatch(setPlatformSelection("Telegram"));
    navigate("/telegramAutomation");
  };
  return (
    <Bottomsheet
      showModal={isVisible}
      // handleClose={() => handleClose()}
      hideCross={true}
      onblockBackdrop={() => dispatch(callPlatformBottomSheet(false))}
    >
      <div className="PlatformSelection">
        <div className="PlatformSelection__tapBlock">
          <div className="PlatformSelection__tapBlock_tap" />
        </div>
        <div className="PlatformSelection_head">Create</div>
        <div className="elementContainer">
          <div
            onClick={telegramFlow}
            className="elementContainer_telegramElement"
          >
            <div className="elementContainer_img">
              <img src={tele} style={{ width: "100%", height: "100%" }} />
            </div>
            <div className="elementContainer_text">Telegram</div>
          </div>
          <div className="elementContainer_beta">
            <div
              onClick={whatsappFlow}
              className="elementContainer_whatsappElement"
            >
              <div className="elementContainer_img">
                <img src={wa} style={{ width: "100%", height: "100%" }} />
              </div>
              <div className="elementContainer_text">WhatsApp</div>
            </div>
            {showBeta ? (
              <div className="PlatformSelection_Beta">BETA</div>
            ) : null}
          </div>
        </div>
      </div>
    </Bottomsheet>
  );
};

export default PlatformBottomSheet;
