// useLayoutBackNavigation

import React from "react";
import { useDispatch } from "react-redux";
import {
  layoutPageBackNavigationClear,
  layoutPageBackNavigationSet,
} from "../layout/store/actions";

const useLayoutPageBackNavigation = (pageHeading) => {
  // REDUX DISPATCH
  const dispatch = useDispatch();

  // SETTING AND REMOVING PAGE HEADING ON MOUNT AND UNMOUNT
  React.useEffect(() => {
    dispatch(layoutPageBackNavigationSet(pageHeading));
    return () => {
      dispatch(layoutPageBackNavigationClear());
    };
  }, []);
};

export default useLayoutPageBackNavigation;
