import React from "react";
import "../../../scss/Details/Description.scss";
import { useRef } from "react";
import ReadMore from "../../../common/ReadMoreText";

function Description(props) {
  // console.log("description",props?.description);
  const { description, about, pricePlan, price, isMessage, type } = props;

  return (
    <div className="Description">
      {description ? (
        <div className="Description_Top">
          <div className="Description_Head">
            {description?.type === "whatsappCommunity"
              ? "Community "
              : "Group "}
            Description
          </div>
          <ReadMore desc={description} />
        </div>
      ) : null}

      {pricePlan ? (
        <div className="Description_PricePlan">
          <div className="Description_Head">Price Plan</div>

          {pricePlan?.accessPrice ? (
            <div className="Description_Plan">
              <div className="Description_Text">
                <div>One Time Price :</div>
                <div className="Description_Price">
                  ₹{pricePlan?.accessPrice}/-
                </div>
              </div>
            </div>
          ) : null}

          {pricePlan?.paymentPeriod?.price ? (
            <div className="Description_Plan">
              <div className="Description_Text">
                <div>Subscription : {pricePlan?.paymentPeriod?.period}</div>
                <div className="Description_Price">
                  ₹{pricePlan?.paymentPeriod?.price}/-
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {price ? (
        <div className="Description_Singleplan">
          {/* <div> */}
          <div>Price :</div>
          <div className="Description_Price">₹{price}/-</div>
          {/* </div> */}
        </div>
      ) : null}
    </div>
  );
}

export default Description;
