const namespace = "COADMIN_";

export default {
  GET_OTP: `${namespace}GET_OTP`,

  SET_NAME: `${namespace}SET_NAME`,
  SET_MOBILE: `${namespace}SET_MOBILE`,
  RESET_STATE: `${namespace}RESET_STATE`,

  CREATE_CO_ADMIN: `${namespace}CREATE_CO_ADMIN`,
  GET_COADMINS: `${namespace}GET_COADMINS`,
  GET_COADMINS_SUCCESS: `${namespace}GET_COADMINS_SUCCESS`,
};
