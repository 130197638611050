const namespace = "CREATE_MESSAGE_";

export default {
  SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
  SET_TITLE: `${namespace}SET_TITLE`,
  SET_DESCRIPTION: `${namespace}SET_DESCRIPTION`,
  SET_PRICE: `${namespace}SET_PRICE`,
  SET_LOCKED_MESSAGE: `${namespace}SET_LOCKED_MESSAGE`,
  SET_ATTACHMENTS: `${namespace}SET_ATTACHMENTS`,
  REMOVE_ATTACHMENT: `${namespace}REMOVE_ATTACHMENT`,

  CREATE_MESSAGE: `${namespace}CREATE_MESSAGE`,
  CREATE_MESSAGE_SUCCESS: `${namespace}CREATE_MESSAGE_SUCCESS`,

  EDIT_MESSAGE: `${namespace}EDIT_MESSAGE`,
  EDIT_MESSAGE_SUCCESS: `${namespace}EDIT_MESSAGE_SUCCESS`,

  RESET_STATE: `${namespace}RESET_STATE`,
};
