import CONSTANTS from "./constants";

const initialState = {
  name: "",
  mobile: "",
  coAdmins: null,
};

const setName = (state, action) => {
  return {
    ...state,
    name: action.payload,
  };
};

const setMobile = (state, action) => {
  return {
    ...state,
    mobile: action.payload,
  };
};

const setCoAdmins = (state, action) => {
  return {
    ...state,
    coAdmins: action.payload,
  };
};

const reset = (state, action) => {
  return {
    ...state,
    name: "",
    mobile: "",
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_NAME:
      return setName(state, action);
    case CONSTANTS.SET_MOBILE:
      return setMobile(state, action);
    case CONSTANTS.GET_COADMINS_SUCCESS:
      return setCoAdmins(state, action);
    case CONSTANTS.RESET_STATE:
      return reset(state, action);
    default:
      return state;
  }
};

export default reducer;
