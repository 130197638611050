// REDUX CONSTANTS
const namespace = "LAYOUT";

const CONSTANTS = {
  LAYOUT_SIDEBAR_OPEN: `${namespace}_SIDEBAR_OPEN`,
  LAYOUT_SIDEBAR_CLOSE: `${namespace}_SIDEBAR_CLOSE`,

  LAYOUT_PAGE_HEADING_SET: `${namespace}_PAGE_HEADING_SET`,
  LAYOUT_PAGE_HEADING_CLEAR: `${namespace}_PAGE_HEADING_CLEAR`,

  LAYOUT_PAGE_BACK_NAVIGATION_SET: `${namespace}_PAGE_BACK_NAVIGATION_SET`,
  LAYOUT_PAGE_BACK_NAVIGATION_CLEAR: `${namespace}_PAGE_BACK_NAVIGATION_CLEAR`,
};

export default CONSTANTS;
