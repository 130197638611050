import CONSTANTS from "./constants";

const inititalState = {
  isLoading: false,
  v2Enabled: null,
  orgIds: null,
  channelType: "",
  editWhatsappType: false,
  version2: false,
};

const setIsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.payload,
  };
};
const setChannelTypeReducer = (state, action) => {
  return {
    ...state,
    channelType: action?.payload,
  };
};

const setUserData = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const getUserDetailsSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    ...action.payload.data,
  };
};
const setAPIVersionReducer = (state, action) => {
  return {
    ...state,
    v2Enabled: action.payload,
  };
};
const setOrgIdsReducer = (state, action) => {
  return {
    ...state,
    orgIds: action.payload,
  };
};
const setEditWhatsAppType = (state, action) => {
  return {
    ...state,
    editWhatsappType: action.payload,
  };
};

const setVersion2 = (state, action) => {
  console.log("reducer: ", action);
  return {
    ...state,
    version2: action.payload,
  };
};
const reducer = (state = inititalState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_IS_LOADING:
      return setIsLoading(state, action);
    case CONSTANTS.SET_USER_DATA:
      return setUserData(state, action);
    case CONSTANTS.GET_USER_DETAILS_SUCCESS:
      return getUserDetailsSuccess(state, action);
    case CONSTANTS.API_VERSION:
      return setAPIVersionReducer(state, action);
    case CONSTANTS.CHANNEL_TYPE:
      return setChannelTypeReducer(state, action);
    case CONSTANTS.ORG_IDS:
      return setOrgIdsReducer(state, action);
    case CONSTANTS.EDIT_GROUP_TYPE:
      return setEditWhatsAppType(state, action);
    case CONSTANTS.VERSION2:
      return setVersion2(state, action);

    default:
      return state;
  }
};

export default reducer;
