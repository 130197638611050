import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import GroupDetails from "./components/groupDetails";
import UserDetails from "./components/userDetails";
import Otp from "./components/otp";
import "../../scss/Purchase/index.scss";
import SuccessPopup from "../Purchase/components/successPopup";
import FailurePopup from "../Purchase/components/failurePopup";
import * as actions from "../../store/Purchase/actions";
import { useNavigate } from "react-router-dom";
import useScript from "./components/Payment/useScript";
import Register from "./components/register";
import AlreadyDone from "./components/alreadyDone";
import Inprogress from "./components/inprogress";
import Loading from "./components/Loading";
import SuccessPage from "./components/successPage";
import LifetimeSubscription from "./components/LifetimeSubscription";
import PendingPayment from "./components/PendingPayment";
import FreeTrialScreen from "./components/FreeTrialScreen";
import UsageLimitScreen from "./components/usageLimit";
import { redirectToHomePage } from "../../utils/redirectToHomePage";
function Purchase(props) {
  const navigate = useNavigate();
  let { purchaseScreen, getPageData, v2Enabled } = props;
  useScript("https://checkout.razorpay.com/v1/checkout.js");
  const dispatch = useDispatch();
  //  console.log("indexPurchase",v2Enabled);
  useEffect(() => {
    if (v2Enabled != null) {
      let uid = window.location.pathname.split("/").pop();
      // console.log("indexPurchase1",v2Enabled);
      getPageData({ id: uid, v2Enabled: v2Enabled });
    }
  }, [v2Enabled]);

  useEffect(() => {
    let uid = window.location.pathname.split("/").pop();
    let uidAsNumber = parseInt(uid, 10);
    const isUserBlocked = redirectToHomePage(uidAsNumber);
    if (isUserBlocked) {
      navigate("/notfound", { replace: true });
    }
  }, []);

  return (
    <div className="purchase">
      {(() => {
        switch (purchaseScreen) {
          case "pageDetails":
            return <GroupDetails />;
          case "userDetails":
            return <UserDetails />;
          case "otp":
            return <Otp />;
          case "success":
            return <SuccessPopup />;
          case "failure":
            return <FailurePopup />;
          case "inprogress":
            return <Inprogress />;
          case "loader":
            return <Loading />;
          case "register":
            return <Register isPreauth={false} />;
          case "alreadyDone":
            return <AlreadyDone />;
          case "renewedSuccess":
            return <SuccessPage />;
          case "lifetime":
            return <LifetimeSubscription />;
          case "pendingPayment":
            return <PendingPayment />;
          case "freemium":
            return <FreeTrialScreen />;
          case "usageLimit":
            return <UsageLimitScreen />;
          default:
            return <GroupDetails />;
        }
      })()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    purchaseScreen: state?.purchaseReducer?.purchaseScreen,
    formDetails: state?.purchaseReducer?.formDetails,
    pageData: state?.purchaseReducer?.pageData,
    pricingPayload: state?.purchaseReducer?.pricingPayload,
    verifyOtp: state?.purchaseReducer?.verifyOtp,
    v2Enabled: state?.user?.v2Enabled,
  };
};

const mapDispatchWithProps = (dispatch) => {
  return {
    setPurchaseScreen: (payload) =>
      dispatch(actions.setPurchaseScreen(payload)),
    getPageData: (payload) => dispatch(actions.getPageData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchWithProps)(Purchase);
