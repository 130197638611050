export const termsString = [
  `The website –‘https://web.fankonnect.com/’ , mobile application -‘FanKonnect ’ (“Application”) and the other associated/ancillary applications and websites (collectively “FanKonnect Platform” or “Platform”) are  property  of and  managed  by  Bunch  Microtechnologies  Private  Limited  (hereinafter  referred  to  as “Bunch”, “Company”, “we”,  “us”  and “our”), a  private  limited  company  incorporated  under  the Companies Act, 2013, and having its registered office at Mez & 1stFloor, A-83 Okhla Industrial Area, Okhla Ph-II, New Delhi –110020, India. `,
  `Bunch requests the User (as defined below) to carefully go through these terms and conditions (“Terms”) prior to accessing the FanKonnect Platformfor the purposes of updating his / her/ itsbank account details on the FanKonnectPlatform(“Update Request”). The Terms shall only govern Bunch’s relationship with the User in relation to Update Request andshall be in addition to any other previous oral, written terms and  conditions  (if  any)  communicated /  informed to and  agreed  with theUser,and all  such  terms  and conditions collectively read with these  Terms shall act  as a binding agreement between Bunch and theUser.`,
  `As used herein, “you", “your” or “User” shall meanany person who has availed the services of Bunch and for  whom  Bunch  has  created  a paid  channel  on  social  media to  facilitate  the  hosting,  display  and publication ofUser content.`,
  `Your access to the FanKonnectPlatform is subject to your acceptance of the Terms and such acceptance of the Terms forms a legally binding agreement between you and Bunch (“Agreement”). Hence, please take your time to read the Terms in their entirety`,
  `By clicking the submit button below, you hereby agree to and accept the following terms and conditions governing the Update Request:`,
  `1. You agree that the bank details and any other information provided in relation to the Update Request are complete and accurate and that you shall not hold the Company liable for any invoice,payment or other issues due to inaccuracy of the  bank details submittedor any other information provided for the Update Request. `,
  `2. In  the  event  that the  beneficiary  bank  accountthat  is  updated  by  the  User  is  not  that of the Useritself, the User agrees, acknowledges, undertakes and confirms that the use of / settlement of funds to this updated bank account shall not be in breach of any applicable laws, including any anti money laundering laws.`,
  `3. You shall  indemnify  and  hold  the  Company,  and  its affiliates,  officers,  directors,  managers, shareholders, agents, representatives and employees harmless from and against any and all direct and/or indirect losses (including any and all interest andpenalty thereof, and reasonable attorneys’ fees) that may befall on the Company due to any circumstances whatsoever relating to, arising out of or in connection with the  Update Request or due to breach of any applicable law or due to any notice received from any enforcement agency including but  not limited to Income Tax, Goods and Service Tax etc.relating to, arising out of or in connection with the Update Request.`,
  `4. You agree  that  if  the  Company  is questioned  by  any  governmental  authority  inrespect  to  any transaction pertaining to you, the Company may share all your transactional data and youshall be solely responsible for any actions that may be taken against you.`,
  `5. You acknowledge and agree that if the Company has to defend any notice or demand notice or any notice in connection with you, you shall bear all costs associated with it.`,
];
