import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import useLayoutPageHeading from "../../customHooks/useLayoutPageHeading";
import TabBar from "../Home/components/TabBar";
import SwitchTabs from "./tabs";

const EarningsTabs = (props) => {
  useLayoutPageHeading("Channel Insights");
  const [activeTab, setActiveTab] = React.useState(1);
  const tabs = [
    {
      id: 1,
      enum: "/my-earnings",
      label: "Total Earning",
    },
    {
      id: 2,
      enum: "/exportReport",
      label: "Report",
    },
  ];

  const swipeActions = useSwipeable({
    onSwipedLeft: (e) => {},
    onSwipedRight: (e) => {},
    delta: 50,
  });
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div>
      <TabBar
        activeTabId={activeTab}
        list={tabs}
        setTab={(id) => setActiveTab(id)}
      />
      <div
        style={{ minHeight: "80vh", backgroundColor: "white" }}
        {...swipeActions}
      >
        <SwitchTabs activeTabId={activeTab} />
      </div>
    </div>
  );
};

export default EarningsTabs;
