import CONSTANTS, { homeTabTypes } from "./constants";

const inititalState = {
  isLoading: false,
  limit: 20,
  offset: 0,
  selectedPlatform: "",
  hasMore: true,
  selectedTab: homeTabTypes.TELEGRAM_TAB,
  groups: [],
  messages: [],
  singleData: null,
  platformBottom: false,
  whatsVisibleHome: false,
};

const setCallPlatform = (state, action) => {
  return {
    ...state,
    platformBottom: action?.payload,
  };
};
const setIsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.payload,
  };
};

const setSelectedTab = (state, action) => {
  return {
    ...state,
    selectedTab: action.payload,
  };
};
const setWhatsAppFlag = (state, action) => {
  return {
    ...state,
    whatsVisibleHome: action?.payload,
  };
};

const fetchGroupsSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    // groups: [...state.groups,...action.payload],
    groups: [
      ...(action.offset === 0
        ? [...action.payload]
        : [...state.groups, ...action.payload]),
    ],
    offset: state.limit + action.offset,
  };
};

const fetchMessagesSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    // messages: [...action.payload],
    messages: [
      ...(action.offset === 0
        ? [...action.payload]
        : [...state.messages, ...action.payload]),
    ],
    offset: state.limit + action.offset,
  };
};

const resetState = (state, action) => {
  return {
    isLoading: false,
    selectedTab: homeTabTypes.TELEGRAM_TAB,
    messages: [],
    groups: [],
    singleData: null,
    limit: 10,
    offset: 0,
    hasMore: true,
  };
};
const reducer = (state = inititalState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_IS_LOADING:
      return setIsLoading(state, action);
    case CONSTANTS.SET_SELECTED_TAB:
      return setSelectedTab(state, action);
    case CONSTANTS.RESET_STATE:
      return resetState();
    case CONSTANTS.FETCH_GROUPS_SUCCESS:
      return fetchGroupsSuccess(state, action);
    case CONSTANTS.CREATE_BOTTOM_SHEET:
      return setCallPlatform(state, action);
    case CONSTANTS.WHATSAPP_BOTTOM_SHEET:
      return setWhatsAppFlag(state, action);
    case CONSTANTS.FETCH_MESSAGES_SUCCESS:
      return fetchMessagesSuccess(state, action);
    case CONSTANTS.SET_TERMSANDCONDITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
