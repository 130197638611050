const namespace = "PROFILE_";

export default {
  PROFILE_CREATE: `${namespace}CREATE`,
  PROFILE_CREATE_FAIULRE: `${namespace}CREATE_FAIULRE`,
  PROFILE_EDIT: `${namespace}EDIT`,
  PROFILE_CREATE_CALL: `${namespace}CREATE_CALL`,
  PROFILE_EDIT_CALL: `${namespace}EDIT_CALL`,
  PROFILE_EDIT_FAIULRE: `${namespace}EDIT_FAILURE`,
  PROFILE_FETCH: `${namespace}FETCH`,
  PROFILE_FETCH_SUCCESS: `${namespace}FETCH_SUCCESS`,
  PROFILE_FETCH_FAIULRE: `${namespace}FETCH_FAILURE`,

  SET_BRAND_NAME: `${namespace}SET_BRAND_NAME`,
  SET_ADDRESS: `${namespace}SET_ADDRESS`,
  SET_PINCODE: `${namespace}SET_PINCODE`,
  SET_GST_NUMBER: `${namespace}SET_GST_NUMBER`,
  SET_STATE: `${namespace}SET_STATE`,

  RESET: `${namespace}RESET`,
};
