import axios from "axios";
import { BASE_URL } from "../../axios-config";
import store from "../store";
import { default as apiEndPoints } from "./apiEndPoints";
// const state = store.getState()
//const token = state?.user?.token || JSON.parse(localStorage.getItem('token'))
export async function generateOtpService(payload) {
  console.log("PAYLOAD IS", payload);
  let groupType = "";
  let groupId = "";
  if (payload.hasOwnProperty("type")) {
    groupType = payload.type;
    delete payload.type;
  }
  if (payload.hasOwnProperty("groupId")) {
    groupId = payload.groupId;
    delete payload.groupId;
  }
  return axios
    .post(
      `${BASE_URL}${apiEndPoints.GENERATE_OTP.url()}?type=${groupType}&groupId=${groupId}`,
      payload
      // {
      //     headers: {
      //         "Api-Version": APP_API_VERSION,
      //         "device-id": hashNumber
      //         // "x-access-token": payload.token,
      //     }
      // }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function verifyOtpService(payload) {
  return axios
    .post(`${BASE_URL}${apiEndPoints.VERIFY_OTP.url()}`, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function pageDataService(payload) {
  return axios
    .get(
      `${BASE_URL}${apiEndPoints.PAGE_DATA.url(payload.id, payload.v2Enabled)}`,
      payload
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function groupDataService(payload) {
  let newPayload = { ...payload };
  delete newPayload.v2Enabled;
  // console.log("newPayloaddd",newPayload)
  return axios
    .post(
      `${BASE_URL}${apiEndPoints.GROUP_DATA.url(
        payload.id,
        payload.v2Enabled
      )}`,
      newPayload
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function registerUserService(payload) {
  return axios
    .post(`${BASE_URL}${apiEndPoints.REGISTER_USER.url(payload.id)}`, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}
export async function updateEmailAddressService(payload) {
  return axios
    .put(`${BASE_URL}/user/update`, payload?.payload, {
      headers: {
        "x-access-token": payload?.token,
      },
    })
    .then((response) => {
      console.log("successResponse");
      return response;
    })
    .catch((err) => {
      console.log("failureResponse");

      return err.response;
    });
}

export async function orderStatusService(payload) {
  console.log("orderStatusService", payload);
  return axios
    .get(`${BASE_URL}${apiEndPoints.ORDER_STATUS.url(payload)}`, {
      headers: {
        "x-access-token": payload?.token,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function createOrder(payload) {
  return axios
    .post(BASE_URL + "/order/create", payload, {
      headers: {
        "x-access-token": payload?.token,
      },
    })
    .then((res) => {
      // handleRazorPay(res?.data?.data?.payload?.id,res?.data?.data?.email);
      console.log("success", res);
      return res;
    })
    .catch((err) => {
      console.log("failure", err);
      return err;
    });
}

export async function paymentSuccess(payload) {
  return axios
    .put(BASE_URL + "/order/update", payload, {
      headers: {
        "x-access-token": payload?.token,
      },
    })
    .then((res) => {
      // setPurchaseScreen("success");
    })
    .catch((err) => {
      // setPurchaseScreen("failure");
    });
}

export async function applyCouponService(payload) {
  return axios
    .post(BASE_URL + "/checkCouponValidity", payload, {
      headers: {
        "x-access-token": payload?.token,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
