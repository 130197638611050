import React, { useEffect } from "react";
import "./CondtionalAccessStyles.scss";
import Switch from "react-switch";
import Input from "../../common/Input";
import isURL from "validator/lib/isURL";
import toast from "react-hot-toast";
import x from "../../assets/x.png";
import ConditionalInput from "../../common/ConditionalInput";
import { useDispatch, useSelector } from "react-redux";
import { setCAToggle, setFormLinkData } from "../../store/CreateGroup/actions";
export const ConditionalAccess = ({
  isVisible = true,
  disable = false,
  isEdit = false,
}) => {
  const dispatch = useDispatch();
  const toggleValue = useSelector(
    (state) => state?.createGroup?.conditonalAccessToggle
  );
  const link = useSelector((state) => state?.createGroup?.formLinkData);
  const editData = useSelector(
    (state) => state?.singleContentDetails?.singleGroupDetails
  );
  useEffect(() => {
    // console.log("conditionA:",isEdit,editData)
    if (isEdit && editData?.conditionalApproval) {
      dispatch(setCAToggle(editData?.conditionalApproval));
      dispatch(setFormLinkData(editData?.formLink));
    }
  }, [isEdit]);

  // const [link,setLink]= React.useState("");
  const toggleAction = () => {
    // setToggleValue(!toggleValue);
    dispatch(setCAToggle(!toggleValue));
  };
  const onLinkInputChange = (value) => {
    dispatch(setFormLinkData(value));
  };
  const handleBlur = (value) => {
    if (value.length > 0 && link !== "" && !isURL(value)) {
      toast.error("Please enter the valid link.");
      dispatch(setFormLinkData(""));
    }
  };
  const clearLinkText = () => {
    dispatch(setFormLinkData(""));
  };
  if (isVisible) {
    return (
      <div className="conditionalAccess">
        <div style={{ marginTop: "16px", border: "1px dashed #E5E5E5" }} />
        <br />
        <div className="conditionalAccess_outerBox">
          <div className="conditionalAccess_innerBox">
            <div className="conditionalAccess_head">On Request Addition</div>
            {!isEdit && (
              <div className="conditionalAccess_description">
                Use this to validate user information before giving access to
                paid channel. (E.g: Do a KYC before giving access to channel)
              </div>
            )}
          </div>
          {!isEdit && (
            <div className="conditionalAccess_toggle">
              <Switch
                //   handleDiameter={1}
                disabled={disable}
                checked={toggleValue}
                onChange={() => {
                  toggleAction();
                }}
                offColor="#CED5E0"
                onColor="#2278FF"
                offHandleColor="#ffffff"
                onHandleColor="#ffffff"
                handleDiameter={15}
                height={20}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
              />
            </div>
          )}
        </div>
        <div className="conditionalAccess_LinkBox">
          {toggleValue && (
            //      <div>
            //      <Input
            //      placeHolder={"Enter form Link here"}
            //      value={link}
            //      onChange={(e)=>onLinkInputChange(e.target.value)}
            //      style={{fontWeight: "600",
            //         fontSize: "14px",
            //         lineHeight: "24px",
            //         color: "#2278FF"}}
            //     accountStatus={true}
            //     onBlur={(e)=>handleBlur(e.target.value)}
            //      />
            //      <div className="container_description">
            //      Share the link of the form that users will fill post/after purchase
            //      </div>

            // </div>
            //  }
            <div>
              <div className="freeGroup1">
                <div className="freeGroup1_Input">
                  <div>
                    <ConditionalInput
                      placeholder="Enter form link here"
                      value={link}
                      onChange={(e) => onLinkInputChange(e.target.value)}
                      style={{
                        fontWeight: link?.length > 0 ? "600" : null,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#2278FF",
                      }}
                      onBlur={(e) => handleBlur(e.target.value)}
                      iconStatus={link?.length > 0 ? true : false}
                      onIconPress={() => {
                        clearLinkText();
                      }}
                    />
                  </div>
                  {/* {link.length>0 && 
          <div
           onClick={()=>{clearLinkText()}}
            className={
              "freeGroup_Apply"
            }
          >
            <img src={x} 
            style={{width:"20px",height:"20px"}}/>
          </div>} */}
                </div>
              </div>
              <div className="conditionalAccess_LinkBox_description">
                Create a google form & paste the form link above to capture
                additional user information post payment. (E.g: Capture KYC
                details or demat account opening proofs)
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return <div></div>;
};
