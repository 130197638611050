import CONSTANTS from "./constants";

export const setTitle = (payload) => {
  return {
    type: CONSTANTS.SET_TITLE,
    payload,
  };
};

export const setDescription = (payload) => {
  return {
    type: CONSTANTS.SET_DESCRIPTION,
    payload,
  };
};

export const setPrice = (payload) => {
  return {
    type: CONSTANTS.SET_PRICE,
    payload,
  };
};

export const setLockedMessage = (payload) => {
  return {
    type: CONSTANTS.SET_LOCKED_MESSAGE,
    payload,
  };
};

export const setAttachements = (payload) => {
  return {
    type: CONSTANTS.SET_ATTACHMENTS,
    payload,
  };
};

export const removeAttachment = (payload) => {
  return {
    type: CONSTANTS.REMOVE_ATTACHMENT,
    payload,
  };
};

export const createMessage = (payload, navigate) => {
  return {
    type: CONSTANTS.CREATE_MESSAGE,
    payload,
    navigate,
  };
};

export const editMessage = (payload, navigate) => {
  return {
    type: CONSTANTS.EDIT_MESSAGE,
    payload,
    navigate,
  };
};

export const resetState = () => {
  return {
    type: CONSTANTS.RESET_STATE,
  };
};
