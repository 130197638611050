import React, { useEffect, useState } from "react";
import "../../../scss/Purchase/successPopup.scss";
import "../../../scss/Purchase/loader.scss";

import success from "../../../assets/purchase/success.svg";
import lifetime from "../../../assets/life.png";

function LifetimeSubscription(props) {
  let grpName = localStorage.getItem("groupName");

  const handleThanksSheetClick = (url) => {
    if (url && url?.length) window.open(url, "_blank");
  };

  return (
    <div className="success">
      <img src={lifetime} style={{ width: "200px", height: "152px" }} alt="" />
      <br />
      <p className="success_title">Lifetime Subscription</p>
      <p className="success_text">You have lifetime access to this channel.</p>
    </div>
  );
}

export default LifetimeSubscription;
