import React from "react";
import "../../../scss/Purchase/inprogress.scss";
import processing_time from "../../../assets/processing_time.png";

const PendingPayment = () => {
  return (
    <div className="inprogress">
      <img className="inprogress_img" src={processing_time} />
      <div className="inprogress_title">
        We are trying to process your payment
      </div>
      {window?.payload?.orderId && (
        <p className="inprogress_orderid">
          {`Your payment against Order Id: ${window?.payload?.orderId} is still under process.`}
        </p>
      )}
      <div className="inprogress_point">
        1. It will take up to 2 hours to process this payment, till the time
        please don’t try to pay again.
      </div>
      <div className="inprogress_point">
        2. If the payment is successful, we wil share the link to join the
        channel via SMS and Email.
      </div>
      <div className="inprogress_point">
        3. In case it fails, the amount will be refunded to the source within
        2-3 business days.
      </div>
    </div>
  );
};

export default PendingPayment;
