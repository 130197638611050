import React, { useEffect } from "react";
import "./styles.scss";
import toast from "react-hot-toast";
import LayoutHeader from "./components/header";
import LayoutContent from "./components/content";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SideBar from "./components/sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailsAction,
  resetUserDetails,
} from "../store/userDetailsReducer/actions";
import logo from "../assets/logo.png";
import getUserToken from "../utils/getUserToken";
import LandingPage from "../routes/Purchase/components/landingPage";
import ReactLoading from "react-loading";
import {
  getTelegramChanelId,
  getAutomatedChannelAction,
} from "../store/automatedGroup/action";
import { isForceLogout } from "../utils/forceLogout";
const Layout = ({ whiteHeader, fixedHeader, hideHeader, hideFooter }) => {
  // REDUX DISPATCH
  const dispatch = useDispatch();
  const [delayLoader, setDelayLoader] = React.useState(true);
  const fetchedUserDetails = useSelector(
    (s) => Object.keys(s.userDetailsReducer.apiResUSER_DETAILS_GET).length > 0
  );

  // REF FOR LAYOUT COMPONENTS
  const headerRef = React.useRef();
  const contentRef = React.useRef();
  const footerRef = React.useRef();

  // isIOS CHECK FOR ENV SAFE AREA CSS FOR NOTCH
  const [isIOS, setIsIOS] = React.useState(true);
  // FETCHING USER DETAILS ON APP MOUNT
  React.useEffect(() => {
    // hit api only if token is there in query param
    if (getUserToken()) {
      dispatch(getUserDetailsAction());

      const queryParams = new URLSearchParams(window.location.search);
      const telegramChannelId = queryParams.get("telegramChannelId");
      if (telegramChannelId) {
        const payload = {
          telegramChannelId: telegramChannelId,
          navigate,
        };
        dispatch(getAutomatedChannelAction(payload));
      }
    }
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      setDelayLoader(false);
    }, 1000);
  }, []);

  // STATES
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [footerHeight, setFooterHeight] = React.useState(0);
  const [marginTop, setMarginTop] = React.useState(0);
  const lastScrollTop = React.useRef(0);

  React.useEffect(() => {
    setFooterHeight(footerRef?.current?.offsetHeight || 0);
  }, [footerRef?.current?.offsetHeight]);

  React.useEffect(() => {
    setHeaderHeight(headerRef?.current?.offsetHeight || 0);
  }, [headerRef?.current?.offsetHeight]);

  // HANDLING LAYOUT SCROLL TO HIDE/SHOW HEADER
  const handleLayoutScroll = () => {
    const currScroll = contentRef.current.scrollTop;
    const lastScroll = lastScrollTop.current;

    const heightDiff =
      contentRef?.current?.scrollHeight -
      (contentRef?.current?.offsetHeight + contentRef?.current?.scrollTop);

    if (!fixedHeader && !isIOS && heightDiff > 120) {
      if (marginTop <= 0 && marginTop >= -60) {
        if (currScroll > lastScroll) {
          if (marginTop > -60) {
            setMarginTop(marginTop - 5);
          }
        } else {
          if (marginTop < 0) {
            setMarginTop(marginTop + 5);
          }
        }
        // OTHER TABS
        // }
      }

      // TOP - BOTTOM;
      if (currScroll === 0) {
        setMarginTop(0);
      }
      lastScrollTop.current = currScroll;
    }
  };

  // REDUX STATES
  const isLayoutSideBarOpen = useSelector(
    (state) => state?.layoutReducer?.isLayoutSideBarOpen
  );

  const telegramGroupName = useSelector(
    (state) => state?.getAutomatedChannelReducer.groupName
  );

  const userId = useSelector(
    (state) =>
      state?.userDetailsReducer?.apiResUSER_DETAILS_GET?.userDetails?.id
  );

  // REACT ROUTER HOOKS
  const navigate = useNavigate();
  const params = useParams();

  const handleForceLogout = () => {
    toast.error("Session expired. Please login again !");
    localStorage.removeItem("token");
    dispatch(resetUserDetails());
    navigate("/", { replace: true });
    setTimeout(() => {
      window.location.reload();
    }, [1500]);
    localStorage.setItem("logoutSession", false);
  };

  useEffect(() => {
    if (window.localStorage !== undefined) {
      const logoutSession = localStorage.getItem("logoutSession");
      const forceLogout = isForceLogout(userId);
      if (forceLogout && logoutSession === null) {
        handleForceLogout();
      }
    }
  }, [userId]);

  return fetchedUserDetails ? (
    <div className="layout">
      {isLayoutSideBarOpen && params?.tabEnum && <SideBar />}
      {!hideHeader && window.location.pathname != "/EarningDashSearch" && (
        <LayoutHeader
          whiteHeader={whiteHeader}
          ref={headerRef}
          marginTop={marginTop}
        />
      )}
      <LayoutContent
        ref={contentRef}
        height={`-webkit-calc(100vh - ${
          isIOS ? "env(safe-area-inset-top)" : "0px"
        } - ${isIOS ? "env(safe-area-inset-bottom)" : "0px"} - ${
          footerHeight + headerHeight
        }px - ${marginTop}px)`}
        onScroll={handleLayoutScroll}
      >
        <Outlet />
      </LayoutContent>
      {/*{!hideFooter && <LayoutFooter ref={footerRef} />}*/}
    </div>
  ) : delayLoader ? (
    <div className="Loader">
      <ReactLoading
        type={"spinningBubbles"}
        color={"#2278FF"}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <LandingPage />
  );
};

export default Layout;
