import CONSTANTS from "./constants";
import { countryListJson } from "../../utils/countryExtensions";

const initialState = {
  data: null,
  isLoading: false,
  country: {
    countryCode: "IN",
    countryFlag: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    countryName: "India",
    description: "flag for India",
    countryISO: "91",
  },
  closeCountryListBottomSheet: false,
};

const getCountryCodeSuccess = (state, action) => {
  console.log("RESPONSE IS", action.payload);
  return {
    ...state,
    data: action.payload,
  };
};

const setIsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.payload,
  };
};

const setCountry = (state, action) => {
  const selectedCountry = countryListJson.filter(
    (item) => item.countryISO === action.payload
  );

  return {
    ...state,
    country: selectedCountry,
  };
};

const setCountryListBottomSheet = (state, action) => {
  return {
    ...state,
    closeCountryListBottomSheet: action.payload,
  };
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_LOADING:
      return setIsLoading(state, action);
    case CONSTANTS.GET_COUNTRY_CODE_SUCCESS:
      return getCountryCodeSuccess(state, action);
    case CONSTANTS.SET_COUNTRY:
      return setCountry(state, action);
    case CONSTANTS.SET_COUNTRY_LIST_BOTTOMSHEET:
      return setCountryListBottomSheet(state, action);
    default:
      return state;
  }
};

export default reducer;
