import CONSTANTS from "./constants";

// INITIAL STATE
const userDetailsReducerInitialState = {
  loadingStateUSER_DETAILS_GET: false,
  apiResUSER_DETAILS_GET: {},
};

// STATE ALTERING METHODS
const setLoadingUserDetailsReducerFn = (isLoading) => ({
  loadingStateUSER_DETAILS_GET: isLoading,
});
const setApiResUserDetailsReducerFn = (apiRes) => ({
  apiResUSER_DETAILS_GET: apiRes,
});

// REDUCER
const userDetailsReducer = (state = userDetailsReducerInitialState, action) => {
  switch (action.type) {
    case CONSTANTS.USER_DETAILS_GET_LOADER: {
      return {
        ...state,
        ...setLoadingUserDetailsReducerFn(true),
      };
    }
    case CONSTANTS.USER_DETAILS_GET_SUCCESS: {
      return {
        ...state,
        ...setLoadingUserDetailsReducerFn(false),
        ...setApiResUserDetailsReducerFn(action?.payload),
      };
    }
    case CONSTANTS.USER_DETAILS_GET_FAILURE: {
      return {
        ...state,
        ...setLoadingUserDetailsReducerFn(false),
        ...setApiResUserDetailsReducerFn({}),
      };
    }
    case CONSTANTS.USER_DETAILS_RESET: {
      return {
        loadingStateUSER_DETAILS_GET: false,
        apiResUSER_DETAILS_GET: {},
      };
    }
    default:
      return state;
  }
};

export default userDetailsReducer;
