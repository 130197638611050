import axios from "axios";
import { getUserToken } from "../../../utils/getAxiosConfig";
import { BASE_URL } from "../../../axios-config";

//API TO GET EARNINGS CONTENT
export const getMyEarnings = async (payload) => {
  let url;
  if (payload?.payload?.startDate && payload?.payload?.endDate) {
    if (payload?.payload?.version2) {
      url = `${BASE_URL}/user/earningDashboard?startDate=${
        payload.payload.startDate
      }&endDate=${payload.payload.endDate}&limit=${10}&offset=${
        payload.payload.offset
      }&version2=true`;
    } else {
      url = `${BASE_URL}/user/earningDashboard?startDate=${
        payload.payload.startDate
      }&endDate=${payload.payload.endDate}&limit=${10}&offset=${
        payload.payload.offset
      }`;
    }
  } else {
    if (payload?.payload?.version2) {
      url = `${BASE_URL}/user/earningDashboard?limit=${10}&offset=${
        payload.payload.offset
      }&version2=true`;
    } else {
      url = `${BASE_URL}/user/earningDashboard?limit=${10}&offset=${
        payload.payload.offset
      }`;
    }
  }

  return await axios({
    url: url,
    method: "GET",
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getChannelEarnings = async (payload) => {
  let url;
  if (payload?.payload?.startDate && payload?.payload?.endDate) {
    if (payload?.payload?.version2) {
      url = `${BASE_URL}/channelStats/?groupId=${
        payload.payload.groupId
      }&startDate=${payload.payload.startDate}&endDate=${
        payload.payload.endDate
      }&limit=${10}&offset=${payload.payload.offset}&v2Enabled=${
        payload.v2Enabled
      }&version2=true`;
    } else {
      url = `${BASE_URL}/channelStats/?groupId=${
        payload.payload.groupId
      }&startDate=${payload.payload.startDate}&endDate=${
        payload.payload.endDate
      }&limit=${10}&offset=${payload.payload.offset}&v2Enabled=${
        payload.v2Enabled
      }`;
    }
  } else {
    if (payload?.payload?.version2) {
      url = `${BASE_URL}/channelStats/?groupId=${
        payload.payload.groupId
      }&limit=${10}&offset=${payload.payload.offset}&v2Enabled=${
        payload.v2Enabled
      }&version2=true`;
    } else {
      url = `${BASE_URL}/channelStats/?groupId=${
        payload.payload.groupId
      }&limit=${10}&offset=${payload.payload.offset}&v2Enabled=${
        payload.v2Enabled
      }`;
    }
  }

  return await axios({
    url: url,
    method: "GET",
    headers: {
      "x-access-token": getUserToken(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const searchRecord = async (payload) => {
  return axios({
    url: `${BASE_URL}/user/search`,
    method: "POST",
    headers: {
      "x-access-token": getUserToken(),
    },
    data: payload,
  })
    .then((response) => {
      if (response?.status == 200) {
        return response.data.data;
      }
    })
    .catch((error) => {
      return error;
    });
};
