import React from "react";
import "../../../scss/Purchase/WhatsAppBlock.scss";
import { hitDeeplinkExecutedV2 } from "../../../utils/hitDeeplink";
export const WhatsappBlock = () => {
  // const navigateToGroupDetail = () => {};
  const gotoWhatsapp = () => {
    console.log("gotowhatsapp");
    window.open("https://wa.me");
  };
  return (
    <div className="WhatsAppBlock_container">
      <div className="content_head">In the meanwhile,</div>
      <div className="content_apiFail1">
        {/* points */}
        <div className="content_apiFail1_points1">
          1. Open “Settings” in Whatsapp
        </div>
        <div className="content_apiFail1_points1">
          2. In Account settings, go to “Privacy”
        </div>
        <div className="content_apiFail1_points1">
          3. In Groups settings, choose “Everyone” under “who can add me to
          group”
        </div>
        {/* cta */}
        {/* <div onClick={() => gotoWhatsapp()} className="whatsappctaBlock">
          <div className="whatsappctaBlock_confirmCTA">Go to WhatsApp</div>
        </div> */}
      </div>
    </div>
  );
};
